<template>
  <main>
    <div :class="inline ? 'container' : 'container-fluid'">
      <div :class="{ 'col-sm-10 py-5': !inline, 'col-sm-12': inline }" class="mx-auto">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <!-- <civilite ref="civiliteForm" v-if="demandeur" v-model="demandeur" /> -->
              <Identifiant ref="identifiantForm" v-if="demandeur" v-model="demandeur" />
              <Echelon ref="addressForm" v-if="demandeur" v-model="demandeur" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
import Civilite from "../../../components/register/demandeur/steps/Civilite.vue";
import Identifiant from "../../../components/register/demandeur/steps/Identifiant.vue";
import Echelon from "../../../components/common/echelon/Echelon.vue";
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  components: { Civilite, Identifiant, Echelon },
  props: {
    item: {
      type: Object,
      required: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [authMixin],
  data() {
    return {
      loading: false,
      demandeur: {
        ...this.item,
        typeActivation: "NORMAL",
        // nationalite: {
        //   prefix: "229",
        //   nom: "BENIN",
        // },
      },
    };
  },
  created() {},
  methods: {
    ...mapActions({
      updateDemandeurInfo: "demandeur/updateDemandeurInfo",
    }),
    async save() {
      //   if (
      //     !(await this.$refs.civiliteForm.isCompleted()) ||
      //     !(await this.$refs.identifiantForm.isCompleted())
      //   ) {
      //     return;
      //   }

      try {
        const data = await this.updateDemandeurInfo({
          ...this.demandeur,
        });
        this.$emit("updated", data);
        this.$toast.success("Modification enrégistrée avec succès!", {
          position: "top-right",
          duration: 5000,
        });
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
