<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <metier-auto-selector-dialog
        ref="editor"
        v-show="false"
        @save="saveAzoliEntrepreneur"
        :title="
          activeItem != null && activeItem.azoliEntrepreneur == null
            ? 'Ajouter métier independant'
            : 'Editer métier independant'
        "
        :item="activeItem"
      />
      <slot name="header" :addAzoliEntrepreneur="addAzoliEntrepreneur">
        <h6 class="mb-0 text-uppercase flex-fill">{{ title }}</h6>
        <button
          :disabled="azoliEntrepreneurs.length == maxItems"
          @click.prevent="addAzoliEntrepreneur"
          type="button"
          class="btn btn-primary"
        >
          Ajouter
        </button>
      </slot>
    </div>
    <div class="mb-5">
      <template v-if="azoliEntrepreneurs.length != 0">
        <div
          class="mb-4 border py-2 px-2"
          v-for="(emp, index) in azoliEntrepreneurs"
          :key="index"
        >
          <div class="d-flex justify-content-between mb-2 align-items-center">
            <div class="">
              <!-- <div class="d-flex">
              <span>Idée: </span>
              <div class="fw-bold text-uppercase mb-0"><span class="text-h4">{{ emp.idee }}</span></div>
            </div> -->
              <!-- <small class="">Metier: <span class="text-muted">{{ emp.metier.libelle }}</span></small> -->
              <div class="">
                Idée: <span class="fw-bold">{{ emp.idee }}</span>
              </div>
              <div class="">
                Metier: <span class="fw-bold">{{ emp.metier.libelle }}</span>
              </div>
            </div>
            <div>
              <a
                @click.prevent="updateAzoliEntrepreneur(emp)"
                class="btn btn-success rounded-0 btn-sm"
                ><i class="bi bi-pencil-square"></i
              ></a>
              <a
                @click.prevent="removeAzoliEntrepreneur(emp)"
                class="btn btn-danger rounded-0 btn-sm"
                ><i class="bi bi-trash"></i
              ></a>
            </div>
          </div>
          <div class="fw-">Qualifie: {{ emp.estQualifie ? "OUI" : "NON" }}</div>
        </div>
      </template>
      <div class="mb-4 bg-light" v-else>
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Aucun métier indépendant à afficher</span>
          <button
            @click.prevent="addAzoliEntrepreneur"
            type="button"
            class="btn btn-sm btn-outline-primary"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetierAutoSelectorDialog from "./editor/dialog/MetierAutoSelectorDialog.vue";
import { mapActions } from "vuex";

export default {
  props: ["value", "maxItems", "title", "azoli"],
  components: { MetierAutoSelectorDialog },
  data() {
    return {
      activeItem: {},
      azoliEntrepreneurs: [...this.value],
    };
  },
  watch: {
    azoliEntrepreneurs(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    emptyAzoliEntrepreneur() {
      return {
        idee: null,
        metier: null,
        activite: null,
        estQualifie: null,
        besoinDePerfectionnement: null,
        qualificationProfessionnelle: null,
        autreQualificationProfessionnelle: null,
        moyenQualification: null,
        autreMoyenQualification: null,
        nombreAnneeExperience: null,
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateAzoliEntrepreneur:
        "azoliEntrepreneur/createOrUpdateAzoliEntrepreneur",
      deleteAzoliEntrepreneur: "azoliEntrepreneur/deleteAzoliEntrepreneur",
    }),
    updateAzoliEntrepreneur(emp) {
      this.activeItem = { ...emp };
      this.$refs.editor.show();
    },
    addAzoliEntrepreneur() {
      // this.azoliEntrepreneurs.push(emp)
      this.activeItem = { ...this.emptyAzoliEntrepreneur };
      this.$refs.editor.show();
    },
    saveAzoliEntrepreneur(emp) {
      this.createOrUpdateAzoliEntrepreneur({
        ...emp,
        beneficiaire: this.azoli,
      }).then((savedItem) => {
        emp = savedItem;
        this.azoliEntrepreneurs = this.azoliEntrepreneurs.filter(
          (item) => item.metier.id != emp.metier.id
        );

        this.azoliEntrepreneurs.push(emp);
        this.activeItem = { ...this.emptyAzoliEntrepreneur };
      });
    },
    removeAzoliEntrepreneur(emp) {
      this.deleteAzoliEntrepreneur(emp).then((el) => {
        this.azoliEntrepreneurs = this.azoliEntrepreneurs.filter(
          (item) => item.metier.id != emp.metier.id
        );
      });
    },
  },
};
</script>
