<template>
  <div class="row bg-light mb-">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row mt-4">
          <div class="col-sm-12">
            <PTabView :activeIndex.sync="activeTab">
              <PTabPanel header="Fiche d'enquête">
                <template v-if="activeTab == 0">
                  <DemandeurDetail :demandeurId="editableItem.id" />
                  <div class="text-muted fw-bold" v-if="!editableItem.estPPI">
                    Ce demandeur INTRUIT n'a pas de fiche d'enquête.
                  </div>
                </template>
              </PTabPanel>
              <PTabPanel header="CV">
                <DemandeurMonCv
                  v-if="activeTab == 1"
                  :checkHasCv="false"
                  :demandeurId="editableItem.id"
                />
              </PTabPanel>
            </PTabView>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FicheAzoliEdit from "../../../../views/espace/conseiller/FicheAzoliEdit.vue";
import DemandeurDetail from "../../../../views/espace/conseiller/DemandeurDetailAzoli.vue";
import DemandeurMonCv from "../../../../views/espace/demandeur/DemandeurMonCv.vue";
export default {
  props: ["value"],
  data() {
    return {
      activeTab: this.value.estPPI ? 0 : 1,
      editableItem: this.value,
      dateNais: this.value.dateNais,
      civilite: null,
      officialNpi: null,
    };
  },
  created() {},
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchNpiInfo: "npi/fetchNpiInfo",
      validerNpiInfo: "demandeur/validerNpiInfo",
    }),
    loadNpiInfo() {
      this.fetchNpiInfo(this.officialNpi).then((data) => {
        this.civilite = data;
      });
    },
    ajusterAvecInfoOfficielsNPi() {
      this.editableItem = {
        ...this.editableItem,
        ...this.civilite,
      };
    },
    signalerDoublon() {},
    async validerNPI() {
      let demandeur = this.editableItem;
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider les informations du NPI de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerNpiInfo({
            ...this.editableItem,
            npiValide: this.civilite.npi,
          }).then((data) => {
            this.editableItem = {
              ...this.editableItem,
              npiValide: this.civilite.npi,
            };
            this.$toast.success("NPI validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    sameValueFor(attr) {
      return (
        this.editableItem != null &&
        this.civilite != null &&
        this.civilite[attr] == this.editableItem[attr]
      );
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
  components: { FicheAzoliEdit, DemandeurDetail, DemandeurMonCv },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly],
.form-select:disabled {
  background-color: #fefaf4;
  /* background-color: transparent; */
  opacity: 0.87;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
