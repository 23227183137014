<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6 for="libelle">
                  Opportunités sur le marché-constats qui confirment les besoins et la
                  demande des produits et services prévus
                </h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingOpportuniteMarche"
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Opportunités sur le marché-constats"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6 for="libelle">Mes clients</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingClients"
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Mes clients"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6 for="libelle">Mes concurrents</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingConcurrents"
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Mes concurrents"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6 for="libelle">Ma stratégie marketing</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingStrategie"
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Ma stratégie"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12">
            <CrudTable
              :formTitle="'Edition estimation vente/produit principal'"
              :tableFields="crudTableProduitsFields"
              :crudUrl="'estiomationVentes'"
              :extraQueryArg="`microProjet.id=${editableItem.id}&categorieArticle=PRODUIT&sousCategorieArticle=PRINCIPAL`"
              :emptyReferentielItem="emptyProduitPrincipalCrudItem"
            >
              <template #listTitle>
                <h4>Liste des estimations de vente/produit principal</h4>
              </template>

              <template #formTitle="{}">
                <h5>Edition estimation vente/produit principal</h5>
              </template>

              <template #formContent="{ editableItem, updateValue }">
                <MarketingEstinationVenteEditor
                  :value="editableItem"
                  @changed="(val) => updateValue(val)"
                />
              </template>
            </CrudTable>
            <PDivider />
            <CrudTable
              :formTitle="'Edition estimation vente/produit secondaire'"
              :extraQueryArg="`microProjet.id=${editableItem.id}&categorieArticle=PRODUIT&sousCategorieArticle=SECONDAIRE`"
              :tableFields="crudTableProduitsFields"
              :crudUrl="'estiomationVentes'"
              :emptyReferentielItem="emptyProduitSecondaireCrudItem"
            >
              <template #listTitle>
                <h4>Liste des estimations de vente/produit secondaire</h4>
              </template>
              <template #formContent="{ editableItem, updateValue }">
                <MarketingEstinationVenteEditor
                  :value="editableItem"
                  @changed="(val) => updateValue(val)"
                />
              </template>
            </CrudTable>
            <PDivider />
            <CrudTable
              :formTitle="'Edition estimation vente/service principal'"
              :extraQueryArg="`microProjet.id=${editableItem.id}&categorieArticle=SERVICE&sousCategorieArticle=PRINCIPAL`"
              :tableFields="crudTableServicesFields"
              :crudUrl="'estiomationVentes'"
              :emptyReferentielItem="emptyServicePrincipalCrudItem"
            >
              <template #listTitle>
                <h4>Liste des estimations de vente/service primaire</h4>
              </template>
              <template #formContent="{ editableItem, updateValue }">
                <MarketingEstinationVenteEditor
                  :value="editableItem"
                  @changed="(val) => updateValue(val)"
                />
              </template>
            </CrudTable>
            <PDivider />
            <CrudTable
              :formTitle="'Edition estimation vente/service secondaire'"
              :extraQueryArg="`microProjet.id=${editableItem.id}&categorieArticle=SERVICE&sousCategorieArticle=SECONDAIRE`"
              :tableFields="crudTableServicesFields"
              :crudUrl="'estiomationVentes'"
              :emptyReferentielItem="emptyServiceSecondaireCrudItem"
            >
              <template #listTitle>
                <h4>Liste des estimations de vente/service secondaire</h4>
              </template>
              <template #formContent="{ editableItem, updateValue }">
                <MarketingEstinationVenteEditor
                  :value="editableItem"
                  @changed="(val) => updateValue(val)"
                />
              </template>
            </CrudTable>
            <PDivider />
          </div>

          <div class="col-sm-12 mb-4">
            <h5>Les 5P</h5>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6>Promotion</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingPromotion"
                    class="col-12"
                    placeholder="Promotion"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6>Place</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingPlace"
                    class="col-12"
                    placeholder="Place"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6>Personne</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingPersonne"
                    class="col-12"
                    placeholder="Personne"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6>Processus</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingProcessus"
                    class="col-12"
                    placeholder="Processus"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <h6>Preuve matérielle</h6>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="3"
                    cols="15"
                    v-model="editableItem.marketingPreuveMaterielle"
                    class="col-12"
                    placeholder="Preuve matérielle"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import FfomCrudForm from "./ffom/FfomCrudForm.vue";
import MarketingEstinationVenteEditor from "./marketing/MarketingEstinationVenteEditor.vue";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";

export default {
  components: {
    CrudTable,
    FfomCrudForm,
    MarketingEstinationVenteEditor,
  },
  props: ["value"],
  mixins: [stepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({}),
    crudTableProduitsFields() {
      return [
        "#",
        { key: "libelle", label: "Libellé" },
        { key: "description", label: "Description" },
        { key: "prixVente", label: "Prix Vente" },
        { key: "quantiteMoyenne", label: "Quantité moyenne/période" },
        { key: "observation", label: "Observation" },
        "actions",
      ];
    },
    crudTableServicesFields() {
      return [
        "#",
        { key: "libelle", label: "Libellé" },
        { key: "description", label: "Description" },
        { key: "prixVente", label: "Prix Vente" },
        { key: "quantiteMoyenne", label: "Vente moyenne/période" },
        { key: "observation", label: "Observation" },
        "actions",
      ];
    },
    emptyCrudItem() {
      return {
        description: null,
        prixVente: null,
        quantiteMoyenne: null,
        observation: null,
        microProjet: this.editableItem,
      };
    },
    emptyProduitPrincipalCrudItem() {
      return {
        ...this.emptyCrudItem,
        categorieArticle: "PRODUIT",
        sousCategorieArticle: "PRINCIPAL",
        microProjet: this.editableItem,
      };
    },
    emptyProduitSecondaireCrudItem() {
      return {
        ...this.emptyCrudItem,
        categorieArticle: "PRODUIT",
        sousCategorieArticle: "SECONDAIRE",
        microProjet: this.editableItem,
      };
    },
    emptyServicePrincipalCrudItem() {
      return {
        ...this.emptyCrudItem,
        categorieArticle: "SERVICE",
        sousCategorieArticle: "PRINCIPAL",
        microProjet: this.editableItem,
      };
    },
    emptyServiceSecondaireCrudItem() {
      return {
        ...this.emptyCrudItem,
        categorieArticle: "SERVICE",
        sousCategorieArticle: "SECONDAIRE",
        microProjet: this.editableItem,
      };
    },
  },

  created() {},
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
