<template>
  <div
    class="bg align-items-center justify-content-center"
    style="min-height: 40vh"
  >
    <div class="row">
      <div class="col-sm-12">
        <div lass="cv-show">
          <div class="container mb-5">
              <div>
                <div class="bg-white">
                  <!-- <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :filename="'cv'"
                    :pdf-quality="4"
                    :paginate-elements-by-height="800"
                    :manual-pagination="true"
                    pdf-format="a4"
                    height="800px"
                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                    ref="html2Pdf"
                  >
                    <section slot="pdf-content">
                      <demandeur-cv-report
                        :demandeurId="de.id"
                      ></demandeur-cv-report>
                    </section>
                  </vue-html2pdf> -->
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 bg-dark">
                  <div class="p-3 p-lg-5 text-white">
                    <div class="mb-4">
                      <a href="#" class="">
                        <div
                          class="rounded-circle style_bg_img border"
                          :style="`background-image: url(${
                            de != null && de.fullPhotoUrl
                              ? de.fullPhotoUrl
                              : '/assets/img/profilMan.jpg'
                          }); height: 120px; width: 120px;`"
                        >
                          <profile-photo-drag-and-drop
                            :autoUpload="true"
                            :hidePreview="true"
                            :method="'POST'"
                            v-if="demandeur"
                            @fileSelected="fileChanged"
                            @completed="profileUpdate"
                            :uploadUrl="
                              API_URL +
                              `/demandeurs/${demandeur.id}/update/photo`
                            "
                            icon="fa fa-3x fa-cloud-upload-alt"
                            action-label=""
                          />
                        </div>
                      </a>
                    </div>
                    <h2 class="text-uppercase">
                      {{ demandeur.nom }} {{ demandeur.prenom }}
                    </h2>
                    <small class="text-uppercase text-white"
                      >
                      {{ de.libelleProfil }} 
                      </small
                    >
                    <!-- Button trigger modal -->
                    <br />
                    <modal ref="languageDialog" title="Ajouter une langues" />
                    <hr class="my-4" />
                    <div class="mb-4">
                      <div
                        class="d-flex justify-content-between align-items-center mb-4"
                      >
                        <h6 class="mb-0 text-uppercase flex-fill">Profil</h6>
                        <cv-description-editor-dialog
                          title="Edition profile"
                          ref="profilEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                      </div>
                      <small class="text-muted">{{
                        demandeur.descriptionProfil
                      }}</small>
                    </div>
                    <div class="mb-3">
                      <div
                        class="d-flex justify-content-between align-items-center mb-4"
                      >
                        <contact-editor-dialog
                          title="Edition contacts"
                          ref="contactEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                        <h6 class="mb-0 text-uppercase flex-fill">Contact</h6>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="bi bi-telephone"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">229 {{ demandeur.phone }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="be bi-envelope"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.email }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="be bi-whatsapp"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.whatsapp }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-geo-alt-fill"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.adresse }}</h6>
                        </div>
                      </div>
                    </div>
                    <block-competences
                      v-model="demandeur.competences"
                      :displayMode="'cv'"
                      @change="saveCompetences"
                    >
                    </block-competences>
                  </div>
                </div>
                <div class="col bg-white">
                  <div class="p-3 p-lg-5">
                    <!-- <section-donnees-personnelle v-model="demandeur" class="mb-3" />             -->
                    <!-- <section-qualification v-model="demandeur" class="mb-3" /> -->
                    <block-qualifications
                      v-model="demandeur.qualifications"
                      @change="saveQualifications"
                      :readOnly="true"
                    >
                      <template #header="{ addQualification }">
                        <h5
                          class="mb-0 bg-warning text-uppercase p-1 flex-fill"
                        >
                          Formations et diplômes
                        </h5>
                      </template>
                    </block-qualifications>
                    <block-emplois
                      v-model="demandeur.emplois"
                      :displayMode="'nice'"
                      :readOnly="true"
                      @change="saveEmplois"
                    >
                      <template #header="{ }">
                        <h5
                          class="mb-0 bg-warning text-uppercase p-1 flex-fill"
                        >
                          Expérience professionnelle
                        </h5>
                      </template>
                    </block-emplois>
                    <block-langues
                      v-model="demandeur.langues"
                      :displayMode="'nice'"
                      :readOnly="true"
                      @change="saveLangues"
                    >
                      <template #header="{ }">
                        <h5
                          class="mb-0 bg-warning text-uppercase p-1 flex-fill"
                        >
                          Langues
                        </h5>
                      </template>
                    </block-langues>

                    <block-centre-interets
                      v-model="demandeur.centreInterets"
                      :displayMode="'nice'"
                      :readOnly="true"
                      @change="saveCentreInterets"
                    >
                      <template #header="{ }">
                        <h5
                          class="mb-0 bg-warning text-uppercase p-1 flex-fill"
                        >
                          Centre d'interêt
                        </h5>
                      </template>
                    </block-centre-interets>
                  </div>
                  <div class="d-flex justify-content-end mb-5">
                      <div>
                        <img v-if="demandeur" height="200px" :src="demandeur.fullSignatureUrl" />
                        <h6>{{de.nom}} {{de.prenom}}</h6>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockQualifications from "../../../components/espace/demandeur/cv/BlockQualifications.vue";
import BlockEmplois from "../../../components/espace/demandeur/cv/BlockEmplois.vue";
import BlockLangues from "../../../components/espace/demandeur/cv/BlockLangues.vue";
import BlockCentreInterets from "../../../components/espace/demandeur/cv/BlockCentreInterets.vue";
import BlockCompetences from "../../../components/espace/demandeur/cv/BlockCompetences.vue";
import BlockSignatures from "../../../components/espace/demandeur/cv/BlockSignature.vue";
import ContactEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/ContactEditorDialog.vue";
import CvDescriptionEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/CvDescriptionEditorDialog.vue";
// import Emplois from '../../../components/espace/demandeur/cv/steps/Emplois.vue'
// import Qualifications from '../../../components/espace/demandeur/cv/steps/Qualifications.vue'
import ProfilePhotoDragAndDrop from "../../../components/uploader/ProfilePhotoDragAndDrop.vue";
// import signature from '../../../components/Signature.vue'
import Modal from "../../../components/Modal.vue";
import DemandeurCvExport from "./DemandeurMonCv.vue";

import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    Modal,
    BlockEmplois,
    BlockLangues,
    ContactEditorDialog,
    CvDescriptionEditorDialog,
    ProfilePhotoDragAndDrop,
    BlockCentreInterets,
    BlockQualifications,
    BlockSignatures,
    BlockCompetences,
    VueHtml2pdf,
    DemandeurCvExport,
  },
  data() {
    return {
      API_URL,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        whatsapp: null,
        adresse: null,
        lieuNaissance: null,
        paysNaissance: null,
        departementNaissance: null,
        communeNaissance: null,
        arrondissementNaissance: null,
        villeNaissance: null,
        niveauInstruction: null,
        disponible: null,
        sousContrat: null,
        dejaInscrit: null,
        domaine: null,
        estInstruit: null,
        competencePrincipale: null,
        publicProfil: true,
        demandeurActif: true,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        arrondissement: null,
        ville: null,
        departement: null,
        emplois: [],
        metiers: [],
        poste: null,

        qualifications: [],
        phone: null,
        nomMere: null,
        prenomMere: null,
        nomPere: null,
        prenomPere: null,
        lignee: null,
        contactMere: null,
        contactPere: null,
        urlPhoto: null,
        tauxRemplissageProfil: null,
        libelleProfil: null,
        descriptionProfil: null,
        permis: null,
        situationMatrimoniale: null,
        ethnie: null,
        nationalite: null,
        signature: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      // console.log(this.dossier);
      return this.demandeur;
    },
    hasCv() {
      // return this.isAzoli && this.de.azoli
      // return this.de != null && this.de.emplois.length != 0
      return true;
      // return this.de != null && this.de.descriptionProfil != null && this.de.emplois.lenght != 0
    },
    isAzoli() {
      return false;
      // return this.de != null && this.de.azoli != null
      // return this.de != null && (this.de.niveauInstruction == null || this.de.niveauInstruction.id <= 10);
    },
  },
  watch: {
    dossier() {
      if (this.dossier != null) {
        this.demandeur = {
          ...this.dossier,
          // emplois:
          //   this.dossier.emplois != undefined ? this.dossier.emplois : [],
          // qualifications:
          //   this.dossier.qualifications != undefined
          //     ? this.dossier.qualifications
          //     : [],
          // qualifications:
          //   this.dossier.qualifications != undefined
          //     ? this.dossier.qualifications
          //     : [],
        };
      }
    },
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    this.fetchMonDossier();
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonDossier: "demandeur/fetchMonDossier",
    }),
    editProfile() {
      this.$refs.languageDialog.show();
    },
    gotoCvEdit() {
      this.$router.push({ name: "espace.de.cv.edit" });
    },
    fileChanged(e) {
      console.log(e);
      this.demandeur.urlPhoto = e.previewUrl;
    },
    profileUpdate(e) {
      console.log(e);
      this.demandeur = {
        ...this.demandeur,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    saveQualifications(qualifications) {
      console.log(qualifications);
      this.save({
        ...this.demandeur,
        qualifications,
      });
    },
    saveEmplois(emplois) {
      this.save({
        ...this.demandeur,
        emplois,
      });
    },
    saveLangues(langues) {
      this.save({
        ...this.demandeur,
        langues,
      });
    },
    saveCompetences(competences) {
      this.save({
        ...this.demandeur,
        competences,
      });
    },
    saveCentreInterets(centreInterets) {
      this.save({
        ...this.demandeur,
        centreInterets,
      });
    },
    save(item) {
      this.demandeur = {
        ...this.demandeur,
        ...item,
      };
      console.log(this.demandeur.signature);
      this.updateDemandeurCv(this.demandeur).then((data) => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
        console.log(data);
      });
    },
  },
};
</script>
