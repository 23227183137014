<template>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <VueSignaturePad
        id="signature"
        width="100%"
        height="300px"
        ref="signPad"
        :options="options"
      />
      <div class="d-flex justify-content-between mt-3">
        <button
          type="button"
          class="rounded-0 btn btn-outline-secondary"
          title="Effacer"
          @click="clean"
        >
          <i class="be bi-trash" aria-hidden="true"></i>
        </button>
        <!-- <button
          type="button"
          title="Modifier"
          class="rounded-0 btn btn-outline-dark"
          @click="change"
        >
          <i class="be bi-pencil"></i>
        </button>
        <button
          type="button"
          title="Approuver"
          class="rounded-0 btn btn-outline-primary"
          @click="save"
        >
          <i class="be bi-check"></i>
        </button> -->
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <h5 class="mb-2">Fonctionnement</h5>
      <p class="small text-muted mb-2">
        Vous générez votre signature ici qui sera apposée sur la convention:
      </p>
      <ul class="small text-muted pl-4 mb-0">
        <li>Maintenez votre souris tout en déplaçant</li>
        <li>En cas d'erreur, effacez puis recommencez</li>
        <!-- <li>Vous pouvez changer de couleur avec le bouton <em>Changer</em></li> -->
        <li>Une fois la signature est bien apposée, valider pour enregistrer</li>
        <!-- <li>Vous pouvez à tout moment remodifier en signant à nouveau</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FileService } from "../../../../api/file-service";
import { FILE_UPLOAD_API_URL } from "../../../../config/api-config";
export default {
  name: "Signature",
  props: {
    method: {
      type: String,
      default: "POST",
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    fileKey: {
      type: String,
      default: "file",
    },
    uploadUrl: {
      type: String,
    },
  },
  data: () => ({ options: {}, loading: false, signature: {} }),
  methods: {
    ...mapActions({}),
    onSubmit(value) {
      this.signature = value;
    },
    undo() {
      this.$refs.signPad.undoSignature();
    },
    fromDataURL(data, options = {}, callback) {
      return this.$refs.signPad.fromDataURL(data, options, callback);
    },
    save(clbk) {
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      // const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (!isEmpty) {
        this.onSubmit(data);
        // this.$emit('sign',data);
        // this.$emit('save', data)
        // this.uploadSignature(data)
        let url = data;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "Signature.png", { type: "image/png" });
            const onSuccess = (evt) => {
              let resp = JSON.parse(evt.currentTarget.response);
              this.$emit("sign", resp.url);
              this.$emit("save", resp.url);
              if (clbk) {
                clbk(resp.url);
              }
            };
            const onError = (evt) => {
              if (clbk) {
                return clbk(null);
              }
            };
            FileService.uploadFile(
              FILE_UPLOAD_API_URL,
              file,
              {},
              "POST",
              "file",
              onSuccess,
              onError
            );
          });
      }
    },
    base64ToFile(base64data) {
      base64data = base64data.replace("data:image/png;base64,", "");
      var bs = Buffer.from(base64data, "base64");
      var buffer = new ArrayBuffer(bs.length);
      var ba = new Uint8Array(buffer);
      for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }
      var file = new Blob([ba], { type: "image/png" });
      return file;

      //   const url = 'data:image/png;base6....';
      // let await rest = fetch(url)
      //   .then(res => res.blob())
      //   .then(blob => {
      //     const file = new File([blob], "File name",{ type: "image/png" })
      //   })
    },
    // uploadSignature(sign) {
    //   console.clear()
    //   console.log(sign)
    // },
    change() {
      this.options = {
        penColor: "#00f", // "#c0f"
      };
    },
    // onFileChange(e){

    //   let files = e.target.files || e.dataTransfer.files

    //   if (!files.length) {
    //     return
    //   }
    //   const file = files[0]

    //   let path=(window.URL || window.webkitURL).createObjectURL(file)

    //   this.file = file
    //   const reader = new FileReader()
    //   reader.onload = (e) => {
    //     this.previewUrl = e.target.result
    //       this.emitter('fileSelected', {
    //         'source': {
    //           key: this.key
    //         },
    //         'file': this.file,
    //         'filePath': path,
    //         'previewUrl': e.target.result,
    //       })

    //       if(this.autoUpload){
    //           this.uploadSelectedFile()
    //       }
    //   }
    //   reader.readAsDataURL(file)
    // },
    uploadSignature(file) {
      let vm = this;

      if (!this.uploadUrl || this.uploadUrl === "") {
        console.log("Please provide the target uploadUrl");
      } else if (!this.method || this.method === "") {
        console.log("Please provide file upload url ( POST / PUT )");
      }

      let formData = new FormData();
      let fileKey = this.fileKey;
      //Update fileKey Name to ensure it a valide
      fileKey = fileKey ? fileKey : "file";

      //Send request only when user choosed a file
      if (file) formData.append(fileKey, file);

      if (true) {
        console.log("Default upload mode");
        this.progress = 0;
        var xhr = new XMLHttpRequest();
        console.log(this.$axios);
        // let url = !this.url.startsWith('http')? this.$http.defaults.baseUrl + this.url : this.url
        xhr.open(this.method, this.uploadUrl);

        xhr.onloadstart = function (e) {
          vm.emitter("start", e);
        };
        xhr.onloadend = function (e) {
          vm.emitter("finish", e);
        };
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            var status = xhr.status;
            if (status || (status >= 200 && status < 400)) {
              vm.emitter("completed", JSON.parse(e.currentTarget.response));
            } else {
              this.notifyServerError();
              console.log(xhr.statusText, e);
            }
          }
        };
        xhr.upload.onprogress = vm.uploadProgress;
        xhr.send(formData);
      } else {
        console.log("Axios Upload mode");
        let responsePromise = this.$http[this.method.toLowerCase()](
          this.uploadUrl,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: vm.uploadProgress,
          }
        );

        responsePromise
          .then((resp) => {
            this.$emit("finish", resp.data);
          })
          .catch((err) => {
            console.log(err);
            this.notifyServerError();
          });

        return responsePromise;
      }
    },
    clean() {
      this.$refs.signPad.clearSignature();
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
