<template>
  <PDialog
    :header="'Clonagewqrewre de microprojet.'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '60vw', background: 'white' }"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 md:col-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="typeOperation"
                >Quelle opération voulez-vous vraiment effectuer?</label
              >
              <div>
                <!-- xm8xtaysggvmgiymrkie1mka -->
                <p-dropdown
                  id="typeOperation"
                  name="typeOperation"
                  class="flex-grow-1 me-2 col-sm-12 md:col-12"
                  v-model="typeOperation"
                  :options="typeOperationOptions"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="'Sélectionner le type d\'opération'"
                  display="chip"
                />
                <!-- <c-select
                    id="typeOperation"
                    name="typeOperation"
                    option-label="label"
                    option-value="value"
                    :emitObject="false"
                    v-model="editableItem.typeOperation"
                    :options="raisonRetraits"
                    class="form-select"
                  /> -->
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <template v-if="typeOperation == 'SUSPENSION'">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="raisonRetraitId"
                    name="raisonRetraitId"
                    option-label="libelle"
                    option-value="id"
                    :emitObject="false"
                    v-model="editableItem.raisonRetraitId"
                    :options="raisonRetraits"
                    class="form-select"
                  />

                  <label for="raisonRetraitId">Raison de suspension</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12 md:col-12 field">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="commentaireRetrait">Commentaire raison de suspension</label>
                <div>
                  <PTextarea
                    id="commentaireRetrait"
                    name="commentaireRetrait"
                    v-uppercase
                    class="col-12 md:col-12"
                    v-model="editableItem.commentaireRetrait"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </template>

          <div class="col-sm-12 md:col-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="reference">Référence du microprojet à cloner</label>
              <div>
                <PInputText
                  v-uppercase
                  class="col-12 md:col-12"
                  v-model="editableItem.reference"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input ref="btnUpload" type="file" name="file" @change="onFileChange" />
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <template #footer>
      <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      display: false,
      statutAcceptation: null,
      typeOperation: null,
      editableItem: {
        reference: null,
        file: null,
        raisonRetraitId: null,
        commentaireRetrait: null,
      },
      typeOperationOptions: [
        { value: "GENERERATION", label: "GENERERATION DE MICROPROJET(S)" },
        { value: "MODIFICATION", label: "MODIFICATION DE MICROPROJET(S)" },
        { value: "SUSPENSION", label: "SUSPENSION DE MICROPROJET(S)" },
      ],
    };
  },
  created() {
    this.fetchRaisonRetraits();
  },
  computed: {
    ...mapGetters({
      raisonRetraits: "raisonRetrait/raisonRetraits",
    }),
  },
  watch: {
    item() {},
  },
  methods: {
    ...mapActions({
      clonerMicroprojet: "microprojet/clonerMicroprojet",
      modifierMicroprojet: "microprojet/modifierMicroprojet",
      suspendreMicroprojet: "microprojet/suspendreMicroprojet",
      fetchRaisonRetraits: "raisonRetrait/fetchRaisonRetraits",
    }),

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      const file = files[0];
      this.editableItem.file = file;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (this.typeOperation == null) {
        this.$toast.error("Vous devez choisir le type d'opération.", {
          duration: 28000,
          position: "bottom-right",
        });
        return;
      }
      this.$toast.success(
        "Opération de clonage en cours. Cette opération pourrait durer plusieurs minutes....",
        {
          duration: 28000,
          position: "bottom-right",
        }
      );
      let method = "";
      if (this.typeOperation == "GENERERATION") {
        method = this.clonerMicroprojet;
      } else if (this.typeOperation == "MODIFICATION") {
        method = this.modifierMicroprojet;
      } else if (this.typeOperation == "SUSPENSION") {
        method = this.suspendreMicroprojet;
      }
      //   if (!(await this.isCompleted())) return;
      method(this.editableItem)
        .then(() => {
          this.$emit("save", {
            ...this.editableItem,
          });
          this.$toast.success("Opération réussie!", {
            duration: 8000,
            position: "top-right",
          });
          this.hide();
        })
        .catch((err) => {
          this.$toast.error("Une erreur s'est produite lors de l'opération.");
        });
    },
  },
};
</script>
