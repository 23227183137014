<template>
  <div class="row bg-light">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="alert alert-danger mt-2" v-if="npiDejaUtilise && !estSignaleDoublon">
          Le npi
          <span class="text-danger fw-bold">{{ officialNpi }} </span>a déjà été utilisé
          pour valider le dossier d'un autre DE.
          <button class="btn btn-danger" @click.prevent="signalerDoublon">
            Signaler comme doublon</button
          >.
        </div>
        <div class="row 3 mt-4">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-header bg-white">
                <h5 class="text-primary">INFORMATION OFFICIELLE</h5>
              </div>
              <div class="card-body">
                <ValidationProvider
                  :rules="
                    !hasNpiValidated
                      ? `numeric|equal:10|available:NPI_VALIDE`
                      : `numeric|equal:10`
                  "
                  v-slot="{ errors }"
                >
                  <div class="form-floating mb-3">
                    <input
                      v-model="officialNpi"
                      type="text"
                      class="form-control"
                      id="officialNpi"
                      name="officialNpi"
                      :disabled="hasNpiValidated"
                      placeholder="Votre NPI"
                    />
                    <label for="equal">NPI</label>
                    <span v-if="hasNpiValidated" class="text-help"
                      >Ce NPI est déjà validé et ne peut donc plus être modifié. Vous
                      pouvez toutefois recharger les informations officielles</span
                    >
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      v-html="err"
                    ></span>
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="civilite.nom"
                      @input="handleInput"
                      disabled
                      type="text"
                      v-uppercase
                      class="form-control"
                      id="nom"
                      name="nom"
                      placeholder="Votre nom"
                    />
                    <label for="nom">Nom</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="civilite.prenom"
                      @input="handleInput"
                      disabled
                      type="text"
                      v-uppercase
                      class="form-control"
                      id="prenom"
                      name="prenom"
                      placeholder="Votre prénom"
                    />
                    <label for="prenom">Prénom</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <select
                      :value="civilite.sexe"
                      @input="handleInput"
                      class="form-select form-select mb-4"
                      id="sexe"
                      disabled
                      name="sexe"
                      aria-label="Votre sexe"
                    >
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                    <label for="sexe">Sexe</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form mb-3">
                    <label for="dateNais">Date de naissance</label>
                    <p-calendar
                      id="dateNais"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      disabled
                      v-model="civilite.dateNais"
                      name="dateNais"
                      :manualInput="true"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <span class="Phone NIP:">{{ civilite.phone }}</span>
              <div class="card-footer">
                <div class="d-flex justify-content-center">
                  <button class="btn btn-primary" @click.prevent="loadNpiInfo">
                    Charger les informations du NPI
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="card"
              :class="{ 'text-white bg-danger': npiDejaUtilise && !estSignaleDoublon }"
            >
              <div
                class="card-header"
                :class="{
                  'text-white bg-danger': npiDejaUtilise && !estSignaleDoublon,
                  'bg-white': !npiDejaUtilise || estSignaleDoublon,
                }"
              >
                <h5 :class="{ 'text-primary': !npiDejaUtilise }">
                  INFORMATION RENSEIGNEE
                  <template v-if="!estSignaleDoublon">
                    <PTag severity="success" v-if="editableItem.npiValide">
                      <i class="pi pi-check"></i> NIP VALIDE</PTag
                    >
                  </template>

                  <PTag severity="danger" v-if="estSignaleDoublon">
                    <i class="pi pi-check"></i> DOSSIER DOUBLON</PTag
                  >
                  <!-- <a
                    v-if="npiDejaUtilise"
                    class="btn text-white btn-outline-danger"
                    href="#"
                    >Signaler comme doublon</a
                  > -->
                </h5>
              </div>
              <div class="card-body">
                <ValidationProvider rules="numeric|equal:10" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.npi"
                      @input="handleInput"
                      :class="{
                        'is-valid': sameValueFor('npi'),
                        'is-invalid': civilite != null && !sameValueFor('npi'),
                      }"
                      type="text"
                      class="form-control"
                      id="npi"
                      name="npi"
                      placeholder="Votre NPI"
                    />
                    <label for="npi">NPI</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.nom"
                      @input="handleInput"
                      required
                      type="text"
                      :class="{
                        'is-valid': sameValueFor('nom'),
                        'is-invalid': civilite != null && !sameValueFor('npi'),
                      }"
                      v-uppercase
                      class="form-control"
                      id="nom"
                      name="nom"
                      placeholder="Votre nom"
                    />
                    <label for="nom">Nom</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.prenom"
                      @input="handleInput"
                      :class="{
                        'is-valid': sameValueFor('prenom'),
                        'is-invalid': civilite != null && !sameValueFor('npi'),
                      }"
                      type="text"
                      v-uppercase
                      class="form-control"
                      id="prenom"
                      name="prenom"
                      placeholder="Votre prénom"
                    />
                    <label for="prenom">Prénom</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <select
                      :value="editableItem.sexe"
                      :class="{
                        'is-valid': sameValueFor('sexe'),
                        'is-invalid': civilite != null && !sameValueFor('npi'),
                      }"
                      @input="handleInput"
                      class="form-select form-select mb-4"
                      id="sexe"
                      name="sexe"
                      aria-label="Votre sexe"
                    >
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </select>
                    <label for="sexe">Sexe</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form mb-3">
                    <label for="dateNais">Date de naissance</label>
                    <!-- :icon="civilite != null && !sameValueFor('dateNais')? 'pi pi-calendar': 'is-invalid'" -->
                    <p-calendar
                      id="dateNais"
                      class="col-12 md:col-12"
                      :class="{
                        'p-invalid': civilite != null && !sameValueFor('dateNais'),
                      }"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateNais"
                      name="dateNais"
                      :manualInput="true"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                    <!-- 
                      <span class="p-input-icon-right">
                        <i class="pi pi-spin pi-spinner" />
                      </span> -->
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <PTag severity="success" v-if="editableItem.npiValide">
                <i class="pi pi-check"></i> NIP DEJA VALIDE</PTag
              >
            </div>
            <div
              class="alert alert-success"
              v-if="isCiviliteLoaded && editableItem.npiValide && npiConforme"
            >
              Les informations de ce DE sont conformes aux informations officielles. Pas
              besoin de mettre à jour.
            </div>
            <div class="alert alert-danger" v-if="npiDejaUtilise && !estSignaleDoublon">
              Le npi
              <span class="text-danger fw-bold">{{ officialNpi }} </span>a déjà été
              utilisé pour valider le dossier d'un autre DE.
              <br />
              <button class="btn btn-danger" @click.prevent="signalerDoublon">
                Signaler comme doublon
              </button>
            </div>

            <PTag severity="danger" v-if="estSignaleDoublon">
              <i class="pi pi-check"></i> DOSSIER DOUBLON</PTag
            >
            <template
              v-if="
                !npiDejaUtilise &&
                ((!editableItem.npiValide && isCiviliteLoaded) ||
                  (editableItem.npiValide && isCiviliteLoaded && !npiConforme))
              "
            >
              <h5>Observation</h5>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <PTextarea
                  v-model="editableItem.observation"
                  :autoResize="true"
                  class="col-md-12"
                  rows="5"
                  cols="30"
                />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>

              <div class="card-body border">
                <h5>Veuillez lier une pièce justificative.</h5>
                <file-pond-uploader ref="fileUploader" @save="fileSaved" />
                <small class="text-help"
                  >Vous pouvez joindre l'une des pièces suivantes: NPI/Carte CEDAO/CIP/CNI
                </small>
              </div>
              <div class="card-footer">
                <div
                  class="d-flex justify-content-between"
                  v-if="(isCiviliteLoaded && !npiConforme) || !editableItem.npiValidate"
                >
                  <button
                    class="btn btn-outline-primary"
                    v-if="civilite && civilite.npi != null"
                    @click.prevent="ajusterAvecInfoOfficielsNPi"
                  >
                    Ajuster avec les infos du NPI
                  </button>
                  <div>
                    <!-- <button
                    class="btn btn-outline-danger ms-1"
                    @click.prevent="signalerDoublon"
                  >
                    Signaler doublon
                  </button> -->
                    <button
                      class="btn ms-1"
                      :class="{
                        'btn-primary': !editableItem.npiValide,
                        'btn-success': editableItem.npiValide,
                      }"
                      @click.prevent="validerNPI"
                    >
                      {{ editableItem.npiValide ? "Mettre à jour" : "Valider" }}
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FilePondUploader from "../../../uploader/FilePondUploader.vue";

export default {
  components: { FilePondUploader },
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value, observation: null },
      dateNais: this.value.dateNais,
      civilite: null,
      officialNpi: null,
      notSubmitted: false,
    };
  },
  created() {},
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
    hasNpiValidated() {
      const { npiValide } = this.value;
      return npiValide;
    },
    estSignaleDoublon() {
      return (
        this.editableItem != null && this.editableItem.modeDeclarationDoublon != null
      );
    },
    npiDejaUtilise() {
      return (
        this.civilite != null &&
        this.civilite.idExistantNpiValide != null &&
        this.civilite.idExistantNpiValide != this.editableItem.id
      );
    },
    isCiviliteLoaded() {
      return this.civilite != null && this.civilite.sexe != null;
    },
    npiConforme() {
      if (this.notSubmitted) return false;

      let fields = ["nom", "prenom", "sexe"];
      if (!this.isCiviliteLoaded || this.editableItem == null) return false;
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        console.log(
          this.civilite[field],
          this.editableItem[field],
          this.civilite[field] != this.editableItem[field]
        );
        if (this.civilite[field] != this.editableItem[field]) return false;
      }
      return true;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          console.log(val);
          const { npiValide } = val;
          this.officialNpi = npiValide;
          this.civilite = {
            npiValide,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchNpiInfo: "npi/fetchNpiInfo",
      validerNpiInfo: "demandeur/validerNpiInfo",
      signalerDossierDoublon: "demandeur/signalerDossierDoublon",
    }),
    fileSaved(files) {
      console.log(files);
      if (files) {
        this.editableItem.pieceJointe = files[0];
      }
    },
    async loadNpiInfo() {
      //   const valid = await this.$refs.observer.validate();

      //   if (!valid) {
      //     this.$toast.error(
      //       `Le NPI ${this.officialNpi} a déjà été utilisé pour un autre DE.`,
      //       {
      //         position: "top-right",
      //         duration: 8000,
      //       }
      //     );
      //     return;
      //   }

      this.fetchNpiInfo(this.officialNpi).then((data) => {
        this.civilite = data;
        this.notSubmitted = false;
      });
    },
    ajusterAvecInfoOfficielsNPi() {
      this.notSubmitted = true;
      this.editableItem = {
        ...this.editableItem,
        ...this.civilite,
      };
    },
    async signalerDoublon() {
      let demandeur = this.editableItem;
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment signaler ce dossier du DE ${demandeur.nom} ${demandeur.prenom} comme doublon?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.signalerDossierDoublon({
            ...this.editableItem,
          }).then((data) => {
            this.editableItem = {
              ...this.editableItem,
              modeDeclarationDoublon: "SIGNALE",
            };
            this.$toast.success("NPI validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    async validerNPI() {
      let demandeur = this.editableItem;
      if (!(await this.isCompleted)) return;

      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider les informations du NPI de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //Override with official informaiton
          this.editableItem = {
            ...this.editableItem,
            ...this.civilite,
          };

          this.validerNpiInfo({
            ...this.editableItem,
            npiValide: this.civilite.npi,
          }).then((data) => {
            this.notSubmitted = false;
            this.editableItem = {
              ...this.editableItem,
              npiValide: this.civilite.npi,
            };
            this.$toast.success("NPI validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    sameValueFor(attr) {
      return (
        this.editableItem != null &&
        this.civilite != null &&
        this.civilite[attr] == this.editableItem[attr]
      );
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly],
.form-select:disabled {
  background-color: #fefaf4;
  /* background-color: transparent; */
  opacity: 0.87;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
