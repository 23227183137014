<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider rules="required|exact:8" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.contactProche"
              @input="handleInput"
              v-telephone
              type="number"
              class="form-control"
              id="contactProche"
              name="contactProche"
              placeholder="Personne à contacter"
            />
            <label for="contactProche">Téléphone personne à contacter</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <div class="row">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form- mb-3">
              <label for="metierAppris">Métier que vous avez appris</label>
              <metier-select v-model="editableItem.metierAppris" :options="romes">
                <template #label>
                  <label for="metierAppris">Métier que vous avez appris</label>
                </template>
              </metier-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.metierApprisDescription"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="metierApprisDescription"
                name="metierApprisDescription"
                placeholder="Votre métier actuel"
              />
              <label for="metierApprisDescription"
                >Description du métier appris choisi</label
              >
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <!-- <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form- mb-3">
                <label for="metierDesire">Métier (s) que vous voulez exercer</label>
                <metier-select v-model="editableItem.metierDesire" :options="romes">
                  <template #label> </template>
                </metier-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.metierDesireDescription"
                  @input="handleInput"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="metierDesireDescription"
                  name="metierDesireDescription"
                  placeholder="Votre métier actuel"
                />
                <label for="metierDesireDescription"
                  >Description du métier à exercer choisi</label
                >
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider> -->

          <div class="col-sm-12 mb-4 mt-2">
            <h5>
              Avez-vous une adresse email active devant servir de compte de connexion?
            </h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.typeActivation"
                  class="form-check-input"
                  type="radio"
                  id="typeActivation1"
                  name="typeActivation"
                  :value="'NORMAL'"
                />
                <label class="form-check-label" for="typeActivation1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.typeActivation"
                  class="form-check-input"
                  type="radio"
                  id="typeActivation2"
                  name="typeActivation"
                  :value="'CAS_SPECIAL'"
                />
                <label class="form-check-label" for="typeActivation2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <template v-if="editableItem.typeActivation == 'NORMAL'">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.email"
                  @input="handleInput"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Votre email"
                />
                <label for="email">Adresse Email</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
            <div class="row">
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      v-model="editableItem.password"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-valid':
                          editableItem.password != undefined &&
                          editableItem.password == editableItem.password1,
                      }"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                    <label for="password">Tapez votre mot de passe de connexion</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      v-model="editableItem.password1"
                      :class="{
                        'is-valid':
                          editableItem.password != undefined &&
                          editableItem.password == editableItem.password1,
                      }"
                      type="password"
                      class="form-control"
                      id="password1"
                      name="password1"
                      placeholder="Password"
                    />
                    <label for="password1">Confirmer le mot de passe</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MetierSelect from "../../../common/MetierSelect.vue";

export default {
  components: { MetierSelect },
  props: ["value"],
  data() {
    return {
      editableItem: this.value,
      dateNais: this.value.dateNais,
    };
  },
  created() {
    this.fetchRomes();
  },
  computed: {
    ...mapGetters({
      romes: "rome/romes",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {
    dateNais(val) {
      this.editableItem.dateNais = this.$moment(val).format("DD-MM-YYYY");
      this.$emit("input", this.editableItem);
    },
  },
  methods: {
    ...mapActions({
      fetchRomes: "rome/fetchRomes",
    }),
    custom(context) {
      // if (context.value === "yes") {
      //   return {
      //     on: ["input"],
      //   };
      // }

      return { on: ["blur"] };
    },
    asyncFind(query) {
      this.isLoading = true;
      console.log(query);
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
