<template>
  <main>
    <div :class="{ container: $screen.breakpoint != 'sm' }">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                  class="bg-primary style_level d-flex justify-content-center align-items-center"
                >
                  <h1 class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                </div>
              </div>
              <div class="text-center mb-4">
                <h4 class="text-primary">Constitution du dossier de CV</h4>
                <!-- <h6 class="text-muted">Je suis une <a href="inscription_2.html" class="badge bg-success text-white">Cv</a></h6> -->
                <h6 class="text-muted">
                  Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }}
                </h6>
              </div>
            </div>
          </div>
          <!-- <AsyncMultiselect /> -->
          <div class="row">
            <PProgressSpinner v-if="loading" />
            <div class="col-sm-12" v-if="cv !== null">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="cv"
                  v-if="index == currentStep"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <!-- <b-alert variant="danger" :show="error">
                    {{
                      `Le numéro de téléphone '${this.cv.phone}' existe déjà. Essayez avec un autre numéro de téléphone.`
                    }}
                  </b-alert> -->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep"
                      @click.prevent="nextStep"
                      type="submit"
                      :disabled="loadingNextStep"
                      class="btn btn-primary btn-lg"
                    >
                      <span
                        v-if="loadingNextStep"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Suivant
                    </button>
                    <button
                      @click.prevent="submitForm"
                      v-else
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
        <p-progress-bar
          v-if="loadingNextStep"
          style="height: 0.33em"
          mode="indeterminate"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Contacts from "../../../components/espace/demandeur/cv/steps/Contacts.vue";
import Identification from "../../../components/espace/demandeur/cv/steps/Identification.vue";
import NaissanceEhtnie from "../../../components/espace/demandeur/cv/steps/NaissanceEhtnie.vue";
import Qualifications from "../../../components/espace/demandeur/cv/steps/Qualifications.vue";
import Emplois from "../../../components/espace/demandeur/cv/steps/Emplois.vue";
import CompetenceInterets from "../../../components/espace/demandeur/cv/steps/CompetenceInterets.vue";
import AppositionSignature from "../../../components/espace/demandeur/cv/steps/AppositionSignature.vue";
import AsyncMultiselect from "../../../components/AsyncMultiselect.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AsyncMultiselect,
  },
  data() {
    return {
      currentStep: 0,
      cv: {
        langues: [],
        qualifications: [],
        paysDeResidence: {},
        emplois: [],
      },
      error: false,
      loading: false,
      loadingNextStep: false,
      steps: [
        {
          icon: "fa fa-home",
          name: "first",
          title: "Identification",
          component: Identification,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Date et lieu de naissance",
          component: NaissanceEhtnie,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Données psersonnelles",
          component: Contacts,
          hideFooter: true,
          hiddeHeader: false,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Qualifications",
          component: Qualifications,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Emplois",
          subtitle: "Emplois",
          component: Emplois,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Compétences et centres d'intérets",
          component: CompetenceInterets,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Apposition de signature",
          component: AppositionSignature,
        },
      ],
    };
  },
  created() {
    // this.cv = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.cv
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    this.loading = true;
    this.fetchMonDossier()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },
  watch: {
    demandeur() {
      this.cv = {
        ...this.demandeur,
      };
    },
  },
  computed: {
    ...mapGetters({
      demandeur: "demandeur/demandeur",
    }),
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonDossier: "demandeur/fetchMonDossier",
    }),
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    partialSubmitForm() {
      return this.updateDemandeurCv({
        ...this.cv,
        status: "EN_COURS",
      }).then((data) => {
        this.cv = {
          ...data,
        };
        return data;
      });
    },
    async submitForm() {
      if (await this.validateStep(this.currentStep)) {
        this.updateDemandeurCv(this.cv)
          .then(async (de) => {
            this.$router.push({ name: "espace.de.moncv" });
            this.$emit("completed");
            this.$toast.success("Opération réussie!", {
              position: "bottom-right",
              duration: 5000,
            });
          })
          .catch((error) => {
            // this.error = true;
            this.$toast.error(
              `Le numéro de téléphone '${error}' existe déjà. Essayez avec un autre numéro de téléphone.`,
              {
                position: "bottom-right",
                duration: 35000,
              }
            );
          });
      }
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.loadingNextStep = true;
        this.partialSubmitForm()
          .then(() => {
            this.loadingNextStep = false;
            this.currentStep++;
          })
          .catch((errr) => {
            this.loadingNextStep = false;
          });
      }
    },
    async validateStep(step) {
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
