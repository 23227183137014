<template>
  <div class="">
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '40vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12 field">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="statutProfessionnel"
                    name="statutProfessionnel"
                    placeholder="Statut Professionnel"
                    option-label="libelle"
                    option-value="id"
                    v-model="editableItem"
                    :options="statutProfessionnels"
                    class="form-select"
                  >
                  </c-select>
                  <div v-if="editableItem" class="text-">
                    {{ editableItem.description }}
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="statutProfessionnel">Statut Professionnel</label>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          :disabled="readOnly"
          class="p-button-text"
        />
        <PButton
          :disabled="readOnly"
          label="Valider"
          icon="pi pi-check"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Object, Array],
    },
  },
  components: {},
  data() {
    return {
      display: false,
      editableItem: this.value,
    };
  },
  created() {
    this.fetchStatutProfessionnels();
  },
  watch: {},
  computed: {
    ...mapGetters({
      statutProfessionnels: "statutProfessionnel/statutProfessionnels",
    }),
  },
  methods: {
    ...mapActions({
      fetchStatutProfessionnels: "statutProfessionnel/fetchStatutProfessionnels",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
