<template>
  <div class="">
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '40vw' }"
      :contentStyle="{ overflow: 'scroll', height: '50vh' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <h6 v-if="demandeur.estPPI" class="mb-2">
          Ce PPI avait opté pour l'emploi
          <PTag severity="danger">{{ demandeur.azoli.typeEmploi }}</PTag
          >.
        </h6>
        <div>
          <div v-if="metierChoisis.length == 0">
            Aucun métié n'avait été choisi sur la fiche.
          </div>
          <div v-if="metierChoisis.length != 0" >
            <h6class="mb-4">
              Les métiers choisis sur sa fiche d'enquête étaient les suivants:
            </h6class=>
            <ul class="list-group">
              <li class="list-group-item" v-for="(metier, i) in metierChoisis" :key="i">
                {{ i + 1 }}. {{ metier.libelle }}
              </li>
            </ul>
          </div>
        </div>
        <PDivider />
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row mt-4">
            <div class="col-sm-12 field mb-5">
              <h6>Métier recherché</h6>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <MetierSelect v-model="editableItem" :multiple="true" :options="romes">
                  <template #label>
                    <label for="metier">Compétences</label>
                  </template>
                </MetierSelect>

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import MetierSelect from "../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    demandeur: {
      type: Object,
      required: true,
    },
    item: {
      type: [Number, String, Object, Array],
    },
  },
  components: {
    Multiselect,
    MetierSelect,
  },
  data() {
    return {
      display: false,
      editableItem: [...this.item],
    };
  },
  created() {
    this.fetchRomes();
  },
  computed: {
    ...mapGetters({
      romes: "rome/romes",
    }),
    metierChoisis() {
      //   if (this.demandeur.azoli == null) return [];
      let metiers = [];
      if (this.demandeur.azoli != null) {
        metiers = this.demandeur.azoli.metierSalaries.map((el) => el.metier);
        const metierIds = metiers.map((el) => el.id);
        metiers = [
          ...metiers,
          ...this.demandeur.azoli.metierIndependants
            .map((el) => el.metier)
            .filter((it) => !metierIds.includes(it.id)),
        ];
      }
      return metiers;
    },
  },
  methods: {
    ...mapActions({
      fetchRomes: "rome/fetchRomes",
    }),
    addTag(newTag) {
      const tag = {
        name: newTag,
        intitule: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
};
</script>
