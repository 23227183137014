<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <c-yes-or-no-input
              question="Etes-vous allé(e) à l'école primaire ou secondaire?"
              v-model="editableItem.estInstruitEnquete"
            >
              <!-- <template #subtitle>
              <span class="text-muted">Choix à l'inscription <yes-or-no-badge :value="editableItem.estInstruit"/></span>
            </template> -->
            </c-yes-or-no-input>
          </div>
          <div v-if="editableItem.estInstruitEnquete === true" class="col-sm-12">
            <h5>Quel est votre dernier niveau d'étude?</h5>
            <div class="text-muted">
              Choix à l'inscription
              <span class="badge bg-danger text-bold h6">{{
                editableItem.niveauInstruction
                  ? editableItem.niveauInstruction.description
                  : "Aucun"
              }}</span>
            </div>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <select :value="editableItem.niveauInstructionEnquete"  @input="handleInput" class="form-select" id="niveauInstructionEnquete" name="niveauInstructionEnquete" aria-label="Sélection votre niveau d'étude">
                <option :value="n.id" v-for="n in niveauInstructionEnquetes" :key="n.id">{{ n.description }}</option>
              </select> -->
                <c-select
                  id="niveauInstructionEnquete"
                  name="niveauInstructionEnquete"
                  option-label="description"
                  option-value="id"
                  v-model="editableItem.niveauInstructionEnquete"
                  :options="niveauInstructionEnquetes"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="niveauInstructionEnquete">Niveau d'étude</label>
              </div>
            </ValidationProvider>
          </div>
          <div v-if="editableItem.estInstruitEnquete === true" class="col-sm-12 mb-4">
            <c-yes-or-no-input
              question="Etes-vous actuellement inscrit(e) dans une école primaire ou secondaire pour l’année en cours?"
              v-model="editableItem.actuellementInscritPourEcole"
            />
          </div>

          <div class="col-sm-12">
            <h5>Quel est votre niveau d’alphabétisation en français?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.niveauAlphabetisationFrancais"
                  @input="handleInput"
                  class="form-select"
                  id="niveauAlphabetisationFrancais"
                  name="niveauAlphabetisationFrancais"
                  aria-label="Sélection votre niveau d'alphabétisation en français"
                >
                  <option :value="n.value" v-for="n in niveauAlphabetions" :key="n.id">
                    {{ n.label }}
                  </option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="niveauAlphabetisationFrancais"
                  >Niveau d'alphabétisation en français</label
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <h5>Quel est votre niveau d’alphabétisation en langues locales?</h5>
            <block-maitrise-langues
              :max-items="5"
              :hideNiveau="true"
              :azoli="editableItem"
              v-model="editableItem.maitriseLangues"
            />
          </div>

          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Disposez-vous d’une qualification professionnelle?"
                v-model="editableItem.hasQualificationProfessionnelle"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div v-if="hasQualificationProfessionnelle" class="col-sm-12">
            <h5>De quelle qualification professionnelle disposez-vous?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <select :value="editableItem.qualificationProfessionnelle"  @input="handleInput" class="form-select" id="qualificationProfessionnelle" name="qualificationProfessionnelle" aria-label="Sélection votre qualification professionnelle">
                <option :value="n.id" v-for="n in qualificationProfessionnelles" :key="n.id">{{ n.libelle }}</option>
              </select> -->
                <c-select
                  id="qualificationProfessionnelle"
                  name="qualificationProfessionnelle"
                  aria-label="Sélection votre qualification professionnelle"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.qualificationProfessionnelle"
                  :options="qualificationProfessionnelles"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="qualificationProfessionnelle"
                  >Qualification professionnelle</label
                >
              </div>
            </ValidationProvider>

            <h5>Dans quel métier avez-vous cette qualification?</h5>

            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form- mb-3">
                <!-- <label for="metierQualification">Le métier de la formation</label> -->
                <metier-select
                  v-model="editableItem.metierQualification"
                  :options="romes"
                >
                  <template #label>
                    <label for="metierQualification">Le métier de la qualification</label>
                  </template>
                </metier-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Suivez-vous actuellement une formation professionnelle?"
                v-model="editableItem.hasFormationProfessionnelle"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div v-if="hasFormationProfessionnelle" class="col-sm-12">
            <h5>Quelle formation professionnelle suivez-vous actuellement?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="formationProfessionnelle"
                  name="formationProfessionnelle"
                  aria-label="Sélection votre formation professionnelle"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.formationProfessionnelle"
                  :options="formationProfessionnelles"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="formationProfessionnelle">Formation professionnelle</label>
              </div>
            </ValidationProvider>

            <h5>Dans quel métier suivez-vous cette formation?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form- mb-3">
                <metier-select v-model="editableItem.metierFormation" :options="romes">
                  <template #label>
                    <label for="metierFormation">Le métier de la formation</label>
                  </template>
                </metier-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import BlockMaitriseLangues from "../BlockMaitriseLangues.vue";
import MetierSelect from "../../../../../components/common/MetierSelect.vue";

export default {
  components: {
    CYesOrNoInput,
    CSelect,
    BlockMaitriseLangues,
    MetierSelect /* YesOrNoBadge */,
  },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      niveauAlphabetions: "instruction/niveauAlphabetions",
      romes: "rome/romes",
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      formationProfessionnelles: "formationProfessionnelle/formationProfessionnelles",
    }),
    niveauInstructionEnquetes() {
      return this.editableItem.typeActivation != "CAS_SPECIAL"
        ? this.instructions
        : this.instructions.slice(0, 10);
    },
    estPeuOuPasInstruit() {
      return (
        this.estInstruitEnquete != null &&
        (!this.estInstruitEnquete ||
          (this.estInstruitEnquete &&
            this.editableItem.niveauInstructionEnquete != null &&
            this.editableItem.niveauInstructionEnquete <= 10))
      );
    },
    hasQualificationProfessionnelle() {
      return this.editableItem.hasQualificationProfessionnelle === true;
    },
    hasFormationProfessionnelle() {
      return this.editableItem.hasFormationProfessionnelle === true;
    },
  },
  watch: {
    // value () {
    //   this.editableItem = { ...this.value }
    // },
    estInstruitEnquete() {
      this.editableItem.estInstruitEnquete = this.estInstruitEnquete;
      this.$emit("input", this.editableItem);
      if (this.estInstruitEnquete !== null && !this.estInstruitEnquete) {
        this.editableItem.niveauInstructionEnquete = null;
      }
    },
    "editableItem.maitriseLangues": {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchInstructions();
    this.fetchQualificationProfessionnelles();
    this.fetchFormationProfessionnelles();
    this.fetchRomes();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
      fetchRomes: "rome/fetchRomes",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
    }),
    handleInput(e) {
      if (e.target.name === "niveauInstructionEnquete") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
