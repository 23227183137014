<template>
  <modal ref="modal" :title="'Profile'" @save="save">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <textarea
                  :value="editableItem.libelleProfil"
                  @input="handleInput"
                  type="text"
                  id="libelleProfil"
                  name="libelleProfil"
                  style="height: 100px"
                  class="form-control"
                />
                <label for="libelleProfil">Profil</label>

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <textarea
                  :value="editableItem.descriptionProfil"
                  @input="handleInput"
                  type="text"
                  id="descriptionProfil"
                  name="descriptionProfil"
                  style="height: 240px"
                  class="form-control"
                />
                <label for="libelleProfil">Description Profil</label>

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  mixins: [],
  components: { Modal },
  data() {
    return {
      editableItem: this.item,
    };
  },
  created() {
    // this.editableItem = { ...this.item }
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    save() {
      this.$emit("save", this.editableItem);
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
