<template>
  <div class="">
    <div class="mb-4">
      <slot name="title"> Contenu du slot #title non renseigné </slot>
    </div>
    <ul class="list-group">
      <li
        class="list-group-item"
        style="cursor: pointer"
        v-for="(item, i) in items"
        :key="i"
      >
        <input
          class="form-check-input me-1"
          type="checkbox"
          :value="item.checked"
          v-model="item.checked"
          @change="selectionChange"
          :id="`checkbox${item.id}`"
        />
        <label
          v-tooltip.top="item.competenceBase"
          class="form-check-label stretched-link"
          :for="`checkbox${item.id}`"
          >{{ item.competenceBase }}</label
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { get } from "@/api/api.js";
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    metier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: [...this.value],
      items: [],
    };
  },
  watch: {
    metier: {
      handler(val, oldVal) {
        if (val != null && !["AUCUN", "AUTRES"].includes(val.libelle)) {
          get(
            `competenceMetiers?familleRome.id=${val.famille.id}&type=BASE&size=100`
          ).then(({ data }) => {
            if (val != null && oldVal != null) {
              this.selectedItems = []; //Reset selectedItems
              this.selectionChange();
            }
            const selectedIds = this.selectedItems.map((it) => it.id);
            this.items = data.content.map((el) => ({
              ...el,
              checked: selectedIds.includes(el.id),
            }));
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectionChange() {
      this.$emit(
        "input",
        this.items.filter((el) => el.checked)
      );
      this.$emit(
        "save",
        this.items.filter((el) => el.checked)
      );
    },
  },
};
</script>
