<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="ethnie"
                    name="ethnie"
                    option-label="nom"
                    option-value="id"
                    v-model="editableItem.ethnie"
                    :options="ethnies"
                    class="form-select"
                  >
                  </c-select>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="ethnie">Langue</label>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12" v-if="!hideNiveau">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <select
                    id="niveauLangue"
                    name="niveauLangue"
                    :value="editableItem.niveauLangue"
                    @input="handleInput"
                    class="form-select"
                    aria-label="Sélection niveau langue"
                  >
                    <option v-for="n in niveauLangues" :key="n.value" :value="n.value">
                      {{ n.label }}
                    </option>
                  </select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="niveauLangue">Niveau</label>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12" v-if="!hideAlphabetisation">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <select
                    :value="editableItem.niveauAlphabetisation"
                    @input="handleInput"
                    id="niveauAlphabetisation"
                    name="niveauAlphabetisation"
                    class="form-select"
                    aria-label="Sélection niveau d'instruction"
                  >
                    <option
                      v-for="n in niveauAlphabetions"
                      :key="n.value"
                      :value="n.value"
                    >
                      {{ n.label }}
                    </option>
                  </select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="niveau">Votre niveau d'instruction</label>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import CSelect from "@/components/CSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    hideAlphabetisation: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [],
  components: { Modal, CSelect },
  data() {
    return {
      editableItem: this.item,
    };
  },
  created() {
    this.fetchEthnies();
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({
      ethnies: "ethnie/ethnies",
      niveauAlphabetions: "instruction/niveauAlphabetions",
      niveauLangues: "instruction/niveauLangues",
    }),
  },
  methods: {
    ...mapActions({
      fetchEthnies: "ethnie/fetchEthnies",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
