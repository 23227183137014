<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Comment le site du sous projet a-t-il
                    été choisi ?</h6>
                </div>
                <div class="col-sm-7">
                  <PInputText type="text" class="col-12 " v-model="editableItem.commentChoisiSiteSP" />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Origine ethnique ou sociale :</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.origineEthique"
                    class="col-12 md:col-12"
                    :options="OrigineEthiques"
                    optionLabel="code"
                    optionValue="code"
                    >
                   
                  </PDropdown>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Statut du site d’implantation du sous-projet :</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.statutSiteImplantation"
                    class="col-12 md:col-12"
                    :options="statutSiteImplantations"
                    optionLabel="code"
                    optionValue="code"
                    >
                   
                  </PDropdown>
                  
                </div>
              </div>
             
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Y’a-t-il un acte attestant la propriété, la location ou la cession gratuite ?</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.acteAttestantPropriete"
                    class="col-12 md:col-12"
                    :options="acteAttestantProprietes"
                    optionLabel="name"
                    optionValue="code"
                    >
                   
                  </PDropdown>
                 
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nombre de bénéficiaires directs</h6>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Hommes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareDirectHomme" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Femmes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareDirectFemme" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Enfants</h6>
                    </div>
                    <div class="col-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareDirectEnfant" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Total</h6>
                    </div>
                    <div class="col-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareDirectTotal" />
                    </div>
                  </div>
                </div>

              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nombre de bénéficiaires indirects</h6>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Hommes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareIndirectHomme" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Femmes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareIndirectFemme" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Enfants</h6>
                    </div>
                    <div class="col-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareIndirectEnfant" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Total</h6>
                    </div>
                    <div class="col-7">
                      <PInputText type="text" class="col-12 " v-model="editableItem.nombreBeneficiareIndirectTotal" />
                    </div>
                  </div>
                </div>

              </div>
              <hr />
              


            </div>
          </div>
          <div class="d-grid gap-2">
            <button @click.prevent="save" type="submit" class="style_btn btn btn-success btn-lg">
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'


export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },


  },
  mixins: [],
  components: {
    Modal,

  },
  data() {
    return {
      editableItem: {},
      etatMiseEnOeuvre: [
        { code: "ENCOURS", name: "En cours" },
        { code: "FAIT", name: "Fait" },
      ],
      OrigineEthiques:[
      { code: "Autochtones ", name: "Autochtones" },
        { code: "Allogènes", name: "Allogènes" },
        { code: "Migrants", name: "Migrants" },
        { code: "Mixtes", name: "Mixtes" },
      ],
      statutSiteImplantations:[
      { code: "Propriété", name: "Propriété" },
        { code: "Location", name: "Location" },
        { code: "Cession gratuite", name: "Cession gratuite" },
      ],
      acteAttestantProprietes:[
      { code: true, name: "Oui" },
        { code: false, name: "Non" },
      ]
    };
  },
  created() {

  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
      // console.log(this.editableItem.femme);
    },
  },
  computed: {
    ...mapGetters({

    }),

    // total() {
    //   return this.editableItem.homme + this.editableItemfemme;
    // },
  },
  methods: {
    ...mapActions({


    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
