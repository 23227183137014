<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <CrudTable
          :formTitle="'Edition investissement (Matériels et Equipement) (INV)'"
          :tableFields="crudTableFields"
          :crudUrl="'budgetMicroprojets'"
          :extraQueryArg="`microProjet.id=${editableItem.id}&typeBudget=INVESTISSEMENT`"
          :emptyReferentielItem="emptyInvestissementCrudItem"
          :totalComputingFields="tableTotalComputingFields"
          @change="reloadMicroprojet"
        >
          <template #listTitle>
            <h4>Liste des investissements (Matériels et Equipement) (INV)</h4>
          </template>

          <template #formTitle="{}">
            <h5>Edition investissement (Matériels et Equipement) (INV)</h5>
          </template>

          <template #formContent="{ editableItem, updateValue }">
            <BudgetEditor :value="editableItem" @changed="(val) => updateValue(val)" />
          </template>
        </CrudTable>

        <PDivider />

        <CrudTable
          :formTitle="'Edition fonds de roulement (BFR)'"
          :tableFields="crudTableFields"
          :crudUrl="'budgetMicroprojets'"
          :extraQueryArg="`microProjet.id=${editableItem.id}&typeBudget=FOND_DE_ROULEMENT`"
          :emptyReferentielItem="emptyFondRoulementCrudItem"
          :totalComputingFields="tableTotalComputingFields"
          @change="reloadMicroprojet"
        >
          <template #listTitle>
            <h4>Liste des fonds de roulement (BFR)</h4>
            <i> (Tous les besoins qui ne sont pas des investissements)</i>
          </template>
          <template #formTitle="{}">
            <h5>Edition fonds de roulement (BFR)l</h5>
          </template>

          <template #formContent="{ editableItem, updateValue }">
            <BudgetEditor :value="editableItem" @changed="(val) => updateValue(val)" />
          </template>
        </CrudTable>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import FfomCrudForm from "./ffom/FfomCrudForm.vue";
import BudgetEditor from "./budget/BudgetEditor.vue";
export default {
  components: {
    CYesOrNoInput,
    CSelect,
    CrudTable,
    FfomCrudForm,
    BudgetEditor,
  },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({}),
    crudTableFields() {
      return [
        "#",
        { key: "designation", label: "Désignation" },
        { key: "quantite", label: "Quantité (Qi)" },
        { key: "prixUnitaire", label: "Prix unitaire (Pi)" },
        { key: "cout", label: "Coût (Pi x Qi)" },
        { key: "apportPropre", label: "Apport propre" },
        { key: "subventionSollicite", label: "Subvention sollicitée" },
        {
          key: "besoinFinancement",
          label: "Besoin de financement/Crédit sollicité ",
        },
        "actions",
      ];
    },
    tableTotalComputingFields() {
      return [
        // { key: "quantite" },
        { key: "cout" },
        { key: "apportPropre" },
        { key: "subventionSollicite" },
        { key: "besoinFinancement" },
      ];
    },
    emptyCrudItem() {
      return {
        libelle: null,
        description: null,
        microProjet: this.editableItem,
      };
    },
    emptyInvestissementCrudItem() {
      return {
        ...this.emptyCrudItem,
        typeBudget: "INVESTISSEMENT",
      };
    },
    emptyFondRoulementCrudItem() {
      return {
        ...this.emptyCrudItem,
        typeBudget: "FOND_DE_ROULEMENT",
      };
    },
  },
  watch: {
    "editableItem.travaillerAuMoinsUneHeureSemainePasse": {
      handler(val) {
        if (!val) {
          this.editableItem.serviceRenduSemaineDerniere = null;
        }
      },
    },
  },
  created() {
    this.fetchInstructions();
    this.fetchServiceRendus();
    this.fetchFormationProfessionnelles();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
      fetchServiceRendus: "serviceRendu/fetchServiceRendus",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async reloadMicroprojet() {
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
    async save() {
      if (!(await this.isCompleted())) return; //No validation needed
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
