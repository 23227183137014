<template>
  <div>
    <langue-editor-dialog
      @save="saveLangue"
      :title="
        activeItem != null && activeItem.langue == null
          ? 'Ajouter langue'
          : 'Editer langue'
      "
      ref="editor"
      :item="activeItem"
    />
    <div class="mb-5">
      <div
        v-if="displayMode == 'cv'"
        class="d-flex justify-content-between align-items-center mb-4"
      >
        <slot name="header" :addLangue="addLangue">
          <h6 class="mb-0 text-uppercase flex-fill">Langues parlées</h6>
          <button @click.prevent="addLangue()" type="button" class="btn btn-primary">
            Ajouter
          </button>
        </slot>
      </div>
      <div v-else class="d-flex justify-content-between flex-nowrap mb-4">
        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">Langues parlées</h5>
        <a @click.prevent="addLangue()" class="btn btn-primary rounded-0 btn-sm"
          ><i class="bi bi-clipboard-plus"></i
        ></a>
      </div>
      <slot :addLangue="addLangue" :isEmpty="langues.length == 0"></slot>
      <div class="mb-2">
        <div
          v-for="(lang, index) in langues"
          :key="index"
          class="d-flex justify-content-between mb-2 align-items-center"
        >
          <div class="">
            <h6 class="mb-0">{{ lang.langue.nom }} : {{ lang.niveau }}</h6>
          </div>
          <div v-if="!readOnly">
            <a
              @click.prevent="updateLangue(lang, index)"
              class="btn btn-success rounded-0 btn-sm"
              ><i class="bi bi-pencil-square"></i
            ></a>
            <a
              @click.prevent="removeLangue(lang, index)"
              class="btn btn-danger rounded-0 btn-sm"
              ><i class="bi bi-trash"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LangueEditorDialog from "./editor/dialog/LangueEditorDialog.vue";

export default {
  components: { LangueEditorDialog },
  props: {
    value: {
      type: Array,
    },
    displayMode: {
      default: "cv", // 'cv', 'nice'
    },
    demandeur: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: {},
      langues: [...this.value],
      activeItemIndex: -1,
    };
  },
  //   watch: {
  //     value: {
  //       handler() {
  //         this.langues = this.value != null ? [...this.value] : [];
  //       },
  //       deep: true,
  //       immediate: true,
  //     },
  //   },
  methods: {
    ...mapActions({
      createOrUpdateFinOcLangue: "finOcLangue/createOrUpdateFinOcLangue",
      deleteFinOcLangue: "finOcLangue/deleteFinOcLangue",
    }),
    updateLangue(langue, index) {
      this.activeItem = { ...langue };
      this.activeItemIndex = index;
      this.$refs.editor.show();
    },
    addLangue() {
      this.activeItem = { ...this.emptyLangue };
      this.activeItemIndex = -1;
      this.$refs.editor.show();
    },
    saveLangue(langue) {
      //   if (this.activeItemIndex == -1) {
      //     this.langues.push(langue);
      //   } else {
      //     this.langues[this.activeItemIndex] = langue;
      //   }
      //   this.activeItem = { ...this.emptyLangue };
      //   this.activeItemIndex = -1;
      //   this.$emit("input", this.langues);
      //   this.$emit("change", this.langues);
      this.createOrUpdateFinOcLangue({
        ...langue,
        offreurId: this.demandeur.id,
      }).then((savedItem) => {
        langue = savedItem;
        this.langues = this.langues.filter((item) => item.id != langue.id);
        this.langues.push(langue);
        this.activeItem = { ...this.emptyLangue };
      });
    },
    removeLangue(langue, index) {
      this.deleteFinOcLangue(langue).then((el) => {
        this.langues = this.langues.filter((item) => item.id != langue.id);
      });

      //   this.langues = this.langues.filter((langue, idx) => idx != index);
      //   this.$emit("input", this.langues);
      //   this.$emit("change", this.langues);
      //   this.activeItem = { ...this.emptyLangue };
      //   this.activeItemIndex = -1;
    },
  },
  methodss: {
    updateLangue(lang, index) {
      this.activeItem = { ...lang };
      this.activeItemIndex = index;
      this.$refs.editor.show();
    },
    addLangue() {
      this.activeItem = { langue: null, niveau: null };
      this.activeItemIndex = -1;
      this.$refs.editor.show();
    },
    saveLangue(lang) {
      if (this.activeItemIndex == -1) {
        this.langues.push(lang);
      } else {
        this.langues[this.activeItemIndex] = lang;
      }

      this.$emit("input", this.langues);
      this.$emit("change", this.langues);
      this.activeItem = { langue: null, niveau: null };
      this.activeItemIndex = -1;
    },
    deleteLangue(lang, index) {
      this.langues = this.langues.filter((item, idx) => idx == index);
      this.$emit("input", this.langues);
      this.$emit("change", this.langues``);
      this.activeItem = { langue: null, niveau: null };
      this.activeItemIndex = -1;
    },
  },
};
</script>
