<template>
  <div>
    <slot name="action">
      <b-button variant="warning" v-b-modal.modal-footer-sm>Ajouter</b-button>
    </slot>
    <modal
      id="modal-footer-sm"
      size="md"
      hide-footer
      ref="modal"
      @ok="ok"
      @cancel="cancel"
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row px-3">
                <div class="col-md-12 col-md-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <!-- <select :value="editableItem.niveau" @input="handleInput" class="form-select" id="niveau" name="niveau" aria-label="Votre niveau">
                        <option :value="niveau.id" v-for="niveau in niveaux" :key="niveau.id">{{ niveau.libelle }}</option>
                      </select> -->
                      <c-select
                        id="typePiece"
                        name="typePiece"
                        option-label="libelle"
                        option-value="id"
                        v-model="editableItem.typePiece"
                        :options="typePieces"
                        class="form-select"
                      >
                      </c-select>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                      <label for="typePiece">Type de piece</label>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 col-md-12">
                  <ValidationProvider
                    :rules="numeroPieceRules"
                    v-if="numeroPieceRules != ''"
                    v-slot="{ errors }"
                  >
                    <div class="form-floating mb-3">
                      <div class="form-floating mb-3">
                        <input
                          :value="editableItem.numero"
                          @input="handleInput"
                          type="text"
                          v-uppercase
                          class="form-control"
                          id="numero"
                          name="numero"
                        />
                        <label for="numero">Numéro de la pièce d’identification</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 mb-4">
                  <div class="row">
                    <div class="col-sm-12">
                      <ValidationProvider
                        :rules="dateExpRules"
                        v-if="dateExpRules == 'required'"
                        v-slot="{ errors }"
                      >
                        <div class="form-floating mb-3">
                          <div class="field col-12 md:col-12">
                            <label for="dateExp">Date d'expiration</label>
                            <p-calendar
                              id="dateExp"
                              class="col-12 md:col-12"
                              :showIcon="true"
                              size="sm"
                              locale="fr"
                              v-model="editableItem.dateExp"
                              name="dateExp"
                              dateFormat="dd/mm/yy"
                            ></p-calendar>
                          </div>

                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" class="btn rounded-0" @click="cancel()"> Annuler </b-button>
        <b-button size="sm" class="btn rounded-0 btn btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CSelect from "@/components/CSelect.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: { CSelect, Modal },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateExp: null,
      editableItem: this.item,
    };
  },
  created() {
    this.fetchTypePieces();
  },
  watch: {
    item: {
      handler() {
        (this.dateExp = this.item.dateExp), (this.editableItem = { ...this.item });
      },
      deep: true,
    },
    editableItem(val, oldVal) {
      console.log(val, oldVal);
    },
    dateExp(val) {
      this.editableItem.dateExp = this.$moment(val).format("YYYY-MM-DD");
      this.$emit("input", this.editableItem);
    },
  },
  computed: {
    ...mapGetters({
      typePieces: "piece/typePieces",
    }),
    dateExpRules() {
      return this.editableItem.typePiece && this.editableItem.typePiece.canExpire
        ? "required"
        : "";
    },
    numeroPieceRules() {
      return this.editableItem.typePiece && this.editableItem.typePiece.codeLength != null
        ? `required|exact:${this.editableItem.typePiece.codeLength}`
        : "";
    },
    countValidationRules() {
      return this.editableItem.typePiece.codeLength;
    },
    minDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  methods: {
    ...mapActions({
      fetchTypePieces: "piece/fetchTypePieces",
    }),
    handleInput(e) {
      if (e.target.name === "profession") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },

    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("canceled");
    },
  },
};
</script>
