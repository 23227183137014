<template>
  <div class="row bg-light">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="col-sm-12 mt-4">
          <div class="card bg-transparent shadow-0 border-0">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="h4 mb-2" v-if="editableItem">
                    {{
                      editableItem.estPPI
                        ? `Vous êtes sur le point de valider la fiche Azoli de ${editableItem.nom} ${editableItem.prenom}.`
                        : `Vous êtes sur le point de valider le CV de ${editableItem.nom} ${editableItem.prenom}.`
                    }}
                  </div>
                </div>

                <div class="col-sm-12 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <h6>
                      Combien noteriez-vous
                      {{ editableItem.estPPI ? " cette fiche" : " ce CV" }} sur une
                      échelle de 10?
                    </h6>
                    <ul class="text-">
                      <li>
                        1 à 3 étoiles: DE peu motivé; capacité de réaction, de réponse
                        jugée peu satisfaisante .
                      </li>
                      <li>
                        4 à 5 étoiles: DE moyennement motivé, capacité de réaction, de
                        réponse jugée moyenne.
                      </li>
                      <li>6 à 8 étoiles: DE motivé, dynamique.</li>
                      <li>9 à 10 étoiles: DE très motivé.</li>
                    </ul>
                    <div class="d-flex justify-content- mt-3">
                      <PRating
                        v-if="editableItem.estPPI"
                        v-model="editableItem.score"
                        :stars="maxScore"
                      />
                      <PRating v-else v-model="editableItem.score" :stars="maxScore">
                      </PRating>
                      <span v-if="editableItem.score != null" class="text-muted ms-2 mb-4"
                        >({{ editableItem.score }}/{{ maxScore }}) points</span
                      >
                    </div>
                    <div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}. <br />Vous devez noter avant de valider le dossier.
                      </span>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 mb-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="d-flex flex-column">
                      <label class="h6 mt-4" for="">Observation</label>
                      <PTextarea v-model="editableItem.commentaireValidation" rows="10" />
                    </div>
                    <span v-for="(err, index) in errors" :key="index" class="text-danger"
                      >{{ err }}. <br />Vous devez noter avant de valider le dossier.
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      note: null,
      display: false,
      editableItem: { ...this.value, offreurId: this.value.id },
      maxScore: 10,
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", {
          ...val,
        });
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
