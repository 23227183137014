<template>
  <div class="row mt-3">
    <div class="col-sm-6 col-lg mb-2">
      <div
        v-tooltip.top="'Microprojets en cours de remplissage'"
        @click.prevent="showPopup($event, '_offres_count_popup_')"
        class="d-flex shadow align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-danger': activeBlock == 'EN_COURS' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-danger style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-danger"><i class="bi-briefcase"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" :class="{ active: activeBlock == 'EN_COURS' }">
          <h6 class="my-0 text-uppercase">Nouveau</h6>
          <small class="text-muted">Nouvelle de fiche</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp
              :delay="delay"
              :endVal="stat.projetNouveauCount"
              :options="options"
            />
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <div
        v-tooltip.top="'Microprojets en cours de remplissage'"
        @click.prevent="showPopup($event, '_offres_count_popup_')"
        class="d-flex shadow align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-danger': activeBlock == 'EN_COURS' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-danger style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-danger"><i class="bi-briefcase"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" :class="{ active: activeBlock == 'EN_COURS' }">
          <h6 class="my-0 text-uppercase">EN COURS</h6>
          <small class="text-muted">En cours de remplissage</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp
              :delay="delay"
              :endVal="stat.projetEnCoursCount"
              :options="options"
            />
          </h2>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="
          selectBlock({
            option: 'isCandidatAzoli',
            value: true,
          })
        "
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-info': activeBlock == 'TERMINE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-info style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-info"><i class="bi-eye"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0">TERMINE</h6>
          <small class="text-muted">Total rempli</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp
              :delay="delay"
              :endVal="stat.projetTermineCount"
              :options="options"
            />
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="
          selectBlock({
            option: 'estAudite',
            value: true,
          })
        "
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-primary': activeBlock == 'VALIDE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-primary style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-primary"><i class="bi-patch-check"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0 text-uppercase">VALIDE</h6>
          <small class="text-muted">Total validé</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp
              :delay="delay"
              :endVal="stat.projetValideCount"
              :options="options"
            />
          </h2>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock(null)"
        ripple
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-secondary style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-secondary"><i class="bi-broadcast"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0">TOTAL</h6>
          <small class="text-muted">Total des microprojets</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="stat.projetTotalCount" :options="options" />
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ICountUp from "vue-countup-v2";
import ConfirmPopup from "primevue/confirmpopup";

export default {
  props: {
    dateDeb: {
      type: [String, Date],
    },
    dateFin: {
      type: [String, Date],
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    ICountUp,
    ConfirmPopup,
  },
  data() {
    return {
      delay: 5000,
      offreStatusOptions: [
        "EN_ATTENTE",
        "VALIDE",
        "PUBLIE",
        "APPROUVE",
        "DEPUBLIE",
        "CLOTURE",
      ],
      offreTypeOptions: ["EXTERNE", "INTERNE"],
      offreNiveauServiceOptions: ["SERVICE_DELEGUE", "SERVICE_SEMI_DELEGUE"],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      activeBlock: null,
      stat: {
        projetNouveauCount: 0,
        projetEnCoursCount: 0,
        projetTermineCount: 0,
        projetValideCount: 0,
        projetTotalCount: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dateDeb() {
      this.loadData();
    },
    dateFin() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      fetchMicroprojetStatistics: "statistic/fetchMicroprojetStatistics",
    }),
    getCamelCaseName(name, separator = "_") {
      let camelCaseName = "";
      name.split("_").forEach((el, i) => {
        if (i == 0) {
          camelCaseName += el.toLowerCase();
        } else {
          camelCaseName += el[0].toUpperCase() + el.toLowerCase().slice(1);
        }
      });
      return camelCaseName;
    },
    getTypeOffreColor(type) {
      let color = "EXTERNE";
      if (type == "INTERNE") {
        color = "danger";
      }
      return color;
    },
    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "warning";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "APPROUVE") {
        color = "info";
      } else if (status == "DEPUBLIE") {
        color = "danger";
      } else if (status == "CLOTURE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    getNiveauServiceOffreColor(service) {
      let color = "";

      if (service == "SERVICE_DELEGUE") {
        color = "success";
      } else if (service == "SERVICE_NON_DELEGUE") {
        color = "warning";
      } else if (service == "SERVICE_SEMI_DELEGUE") {
        color = "danger";
      }
      return color;
    },
    getNiveauServiceOffreLibelle(service) {
      let label = "DELEGUE";

      if (service == "SERVICE_DELEGUE") {
        label = "DELEGUE";
      } else if (service == "SERVICE_NON_DELEGUE") {
        label = "NON DELEGUE";
      } else if (service == "SERVICE_SEMI_DELEGUE") {
        label = "SEMI DELEGUE";
      }
      return label;
    },
    showPopup(event, popupGroupName) {
      this.$confirm.require({
        group: popupGroupName,
        target: event.currentTarget,
        message: "L'activité a-t-elle pu tenir?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Ok",
        rejectLabel: "Fermer",
        accept: () => {},
        reject: () => {},
      });
    },
    reload() {
      this.loadData();
    },
    selectBlock(status) {
      this.activeBlock = this.activeBlock != status ? status : null;
      this.$emit("selectionChange", this.activeBlock);
    },
    loadData() {
      this.fetchMicroprojetStatistics({
        extraQueryArg: this.extraQueryArg,
      }).then((data) => {
        this.stat = data;
      });
    },
  },
};
</script>

<style scoped>
.bg-block {
  background: #ebe2a3;
  background: white;
  /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%) !important; */
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%) !important;
}

.shadow:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 6%) !important;
}

.offre-stat-value {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
