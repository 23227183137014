var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.loading)?_c('div',{staticClass:"field col-12 md:col-6"},[_c('PSkeleton',{staticClass:"mb-2"}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"width":"10rem"}}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"width":"5rem"}}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"height":"2rem"}}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"height":"3rem"}}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"height":"4rem"}}),_c('PSkeleton',{staticClass:"mb-2",attrs:{"height":"2rem"}}),_c('PSkeleton',{attrs:{"height":"5rem"}})],1):_vm._e(),(!_vm.loading)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{ref:"form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Participation aux séances d'information et de sensibilisation sur les opportunités économiques locales"},model:{value:(_vm.editableItem.prerequisMicroprojet.participationSeanceInformation),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet, "participationSeanceInformation", $$v)},expression:"editableItem.prerequisMicroprojet.participationSeanceInformation"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Participation au module sur les compétences transversales"},model:{value:(
                _vm.editableItem.prerequisMicroprojet
                  .participationModuleCompetenceTransversale
              ),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet
                  , "participationModuleCompetenceTransversale", $$v)},expression:"\n                editableItem.prerequisMicroprojet\n                  .participationModuleCompetenceTransversale\n              "}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Participation au module sur les compétences entrepreneuriales"},model:{value:(
                _vm.editableItem.prerequisMicroprojet
                  .participationModuleCompetenceEntrepreneuriale
              ),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet
                  , "participationModuleCompetenceEntrepreneuriale", $$v)},expression:"\n                editableItem.prerequisMicroprojet\n                  .participationModuleCompetenceEntrepreneuriale\n              "}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Participation au module sur les compétences financières"},model:{value:(
                _vm.editableItem.prerequisMicroprojet
                  .participationModuleCompetenceFinanciere
              ),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet
                  , "participationModuleCompetenceFinanciere", $$v)},expression:"\n                editableItem.prerequisMicroprojet\n                  .participationModuleCompetenceFinanciere\n              "}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Analyse des Besoins en Formation Technique de Courte Durée effectuée ?"},model:{value:(_vm.editableItem.prerequisMicroprojet.analyseBesoinEnFormation),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet, "analyseBesoinEnFormation", $$v)},expression:"editableItem.prerequisMicroprojet.analyseBesoinEnFormation"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),(_vm.editableItem.prerequisMicroprojet.analyseBesoinEnFormation == true)?_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"col-sm-12 field md:col-12 field"},[_c('label',{attrs:{"for":"libelle"}},[_vm._v("Description Formation Technique de Courte Durée identifiée/retenue")]),_c('div',{staticClass:"col-12 md:col-12"},[_c('PTextarea',{staticClass:"col-12",attrs:{"rows":"5","cols":"30","optionLabel":"label","optionValue":"value","placeholder":"Observation"},model:{value:(
                    _vm.editableItem.prerequisMicroprojet.descriptionFormationRetenue
                  ),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet, "descriptionFormationRetenue", $$v)},expression:"\n                    editableItem.prerequisMicroprojet.descriptionFormationRetenue\n                  "}})],1),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})],2)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"col-sm-12 field md:col-12 field"},[_c('label',{attrs:{"for":"libelle"}},[_vm._v("Centre de formation retenue")]),_c('div',{staticClass:"col-12 md:col-12"},[_c('PTextarea',{staticClass:"col-12",attrs:{"rows":"5","cols":"30","optionLabel":"label","optionValue":"value","placeholder":"Centre de formation"},model:{value:(_vm.editableItem.prerequisMicroprojet.centreFormationRetenue),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet, "centreFormationRetenue", $$v)},expression:"editableItem.prerequisMicroprojet.centreFormationRetenue"}})],1),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})],2)]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Participation effective à la Formation Technique de Courte Durée identifiée?"},model:{value:(
                _vm.editableItem.prerequisMicroprojet.participationEffectiveFormation
              ),callback:function ($$v) {_vm.$set(_vm.editableItem.prerequisMicroprojet, "participationEffectiveFormation", $$v)},expression:"\n                editableItem.prerequisMicroprojet.participationEffectiveFormation\n              "}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1)])])]}}],null,false,2342425451)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }