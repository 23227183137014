<template>
  <PDialog
    :header="'Suspension d\'un microprojet'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '60vw', background: 'white' }"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <!-- <div class="col-sm-12 field mb-3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div>Date de cessation de service</div>
              <p-calendar
                class="col-12 md:col-12"
                :showIcon="true"
                size="sm"
                locale="fr"
                name="dateDemarrage"
                :manualInput="false"
                v-model="editableItem.dateArret"
                :showWeek="true"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div> -->

          <div class="col-sm-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="raisonRetrait"
                  name="raisonRetrait"
                  option-label="libelle"
                  option-value="id"
                  :emitObject="true"
                  v-model="editableItem.raisonRetrait"
                  :options="raisonRetraits"
                  class="form-select"
                >
                </c-select>
                <label>Raison de retrait du microprojet</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="mt-2">Observation/Commentaire</div>
              <Textarea
                rows="6"
                class="form-control"
                v-model="editableItem.commentaireRetrait"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <template #footer>
      <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions } from "vuex";
import Echelon from "@/components/common/echelon/Echelon.vue";
import CSelect from "@/components/CSelect.vue";

export default {
  components: {
    Echelon,
    CSelect,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    microProjet: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      statutAcceptation: null,
      raisonRetraits: [],
      editableItem: {},
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {
    this.fetchRaisonRetraits({ extraQueryArg: "size=20" }).then((data) => {
      this.raisonRetraits = data.content;
    });
  },
  methods: {
    ...mapActions({
      fetchRaisonRetraits: "raisonRetrait/fetchRaisonRetraits",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", {
        ...this.editableItem,
      });
      this.hide();
    },
  },
};
</script>
