<template>
  <div>
    <PDialog
      :header="'Validation du choix de candidature'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'scroll' }"
      :containerStyle="{ width: '50vw' }"
    >
      <div class="card mt border-0">
        <PlacementAccepterForm ref="form" v-if="display" v-model="editableItem" />
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ParticipantSelectorDialog from "../../ParticipantSelectorDialog.vue";
import { mapActions, mapGetters } from "vuex";
import MetierSelect from "../../../../common/MetierSelect.vue";
import PlacementAccepterForm from "./PlacementAccepterForm.vue";

export default {
  components: {
    ParticipantSelectorDialog,
    MetierSelect,
    PlacementAccepterForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: { ...this.item },
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
    // 'editableItem.typePlacement': {
    //   handler(val){
    //   }
    // }
  },
  created() {
    // this.fetchNatureContrats()
    // this.fetchTypePlacements()
  },
  computed: {
    ...mapGetters({
      typePlacements: "typePlacement/typePlacements",
      natureContrats: "natureContrat/natureContrats",
    }),
    steps() {
      return [
        {
          label: "SELECTION TYPE DE PLACEMENT",
          subtitle: "Choix Formation/Stage",
          to: "#/1",
          completed: this.editableItem != null && this.editableItem.typePlacement != null,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          label: "SELECTION DE L'ENTREPRISE",
          subtitle: "Sélection de l'entreprise(s)",
          to: "#/2",
          completed: this.editableItem != null && this.editableItem.entreprise != null,
          command: () => {
            if (this.autonomieCompleted) this.activeStepIndex = 1;
          },
        },
        {
          label: "SELECTION DU(S) DEMANDEUR(S)",
          to: "#/3",
          subtitle: "Sélection du(s) demandeur(s) concerné(s)",
          completed: this.editableItem != null && this.editableItem.demandeur != null,
          command: () => {
            if (this.autonomieCompleted) this.activeStepIndex = 2;
          },
        },
      ];
    },
    options() {
      return this.structures.map((item) => ({
        value: item,
        text: item.libelle,
      }));
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    ...mapActions({
      fetchTypePlacements: "typePlacement/fetchTypePlacements",
      fetchNatureContrats: "natureContrat/fetchNatureContrats",
    }),
    resetContent() {
      this.mxInitializePaginator({
        queryModel: "entreprise",
        search: this.search,
        fetcherMethod: "entreprise/fetchEntreprises",
        dataGetter: "entreprise/entreprises",
        paginationGetter: "entreprise/pagination",
        pagination: {
          sortBy: "desc",
          descending: false,
          page: 0,
          rowsPerPage: 6,
        },
        filterOptions: this.filterOptions,
        searchFields: ["search", "ifu", "raisonSocial"],
      });
    },
    selectTypePlacement() {
      this.error = false;
      this.activeStepIndex = 1;
    },
    participantSelectionChanged(items) {
      this.editableItem = {
        ...this.editableItem,
        beneficiaires: items,
      };
    },
    show() {
      if (this.typePlacements.length == 0) {
        this.fetchNatureContrats();
        this.fetchTypePlacements();
      }
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.form.isCompleted();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
