<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit()"
        class=""
        method="post"
        accept-charset="utf-8"
        action=""
      >
        <Echelon v-model="editableItem" />

        <template>
          <div class="row">
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.rue"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="rue"
                    name="rue"
                    placeholder="Votre rue"
                  />
                  <label for="rue">Rue</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.carre"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="carre"
                    name="carre"
                    placeholder="Carré"
                  />
                  <label for="carre">Carrée</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.maison"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="maison"
                    name="maison"
                    placeholder="Votre maison"
                  />
                  <label for="maison">Maison</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.hameau"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="hameau"
                    name="hameau"
                    placeholder="Hameau"
                  />
                  <label for="codePostal">Hameau</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import Echelon from "../../../../common/echelon/Echelon.vue";

export default {
  components: { Echelon },
  props: ["value"],
  data() {
    return {
      editableItem: {},
    };
  },
  mixins: [setepperFormMixin],
  computed: {
    ...mapGetters({
      departements: "departement/departements",
      communes: "departement/communes",
      arrondissements: "departement/arrondissements",
      villes: "departement/villes",
    }),
  },
  watch: {
    "editableItem.departement": {
      handler(val, oldVal) {
        console.log(val, oldVal, this.editableItem.departement);
        if (val != oldVal) {
          this.fetchDepartementCommunes({
            departementId: this.editableItem.departement.id,
          });
        }
      },
    },
    "editableItem.commune": {
      handler(val, oldVal) {
        console.log(val, oldVal, this.editableItem.commune);
        if (val != oldVal) {
          this.fetchCommuneArrondissements({
            communeId: this.editableItem.commune.id,
          });
        }
      },
    },
    "editableItem.arrondissement": {
      handler(val, oldVal) {
        console.log(val, oldVal, this.editableItem.arrondissement);
        if (val != oldVal) {
          this.fetchArrondissementVilles({
            arrondissementId: this.editableItem.arrondissement.id,
          });
        }
      },
    },
    "editableItem.ville": {
      handler(val, oldVal) {
        console.log(val, oldVal, this.editableItem.ville);
        if (val != oldVal) {
          this.$emit("input", this.editableItem);
        }
      },
      deep: true,
    },
  },
  created() {
    this.editableItem = { ...this.value };
    this.fetchDepartements();
    this.fetchDepartementCommunes({
      departementId: this.editableItem.departement.id,
    });
    this.fetchCommuneArrondissements({
      communeId: this.editableItem.commune.id,
    });
    this.fetchArrondissementVilles({
      arrondissementId: this.editableItem.arrondissement.id,
    }).then(() => (this.editableItem = { ...this.value }));
  },
  methods: {
    ...mapActions({
      fetchDepartements: "departement/fetchDepartements",
      fetchCommuneArrondissements: "departement/fetchCommuneArrondissements",
      fetchDepartementCommunes: "departement/fetchDepartementCommunes",
      fetchArrondissementVilles: "departement/fetchArrondissementVilles",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
