<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <!-- <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <select :value="editableItem.ethnie" @input="handleInput" class="form-select" id="ethnie" name="ethnie" aria-label="Votre ethinie">
            <option selected>Sélection votre ethnie</option>
            <option :value="n.id" v-for="n in ethnies" :key="n.id">{{ n.nom }}</option>
          </select>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          <label for="ethnie">Votre ethnie</label>
        </div>
      </ValidationProvider> -->
        <!-- <hr> -->
        <block-emplois
          v-model="editableItem.emplois"
          :demandeur="editableItem"
          @change="handleValueChange"
        />
      </form>
      <!-- {{editableItem}}---- -->
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import BlockEmplois from "../BlockEmplois.vue";

export default {
  components: { BlockEmplois },
  props: ["value"],
  data() {
    return {
      editableItem: this.value,
    };
  },
  watch: {},
  mixins: [setepperFormMixin],
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    handleValueChange(emplois) {
      this.$emit("input", {
        ...this.editableItem,
        emplois,
      });
    },
  },
};
</script>

<style scoped></style>
