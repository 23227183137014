<template>
  <div class="row">
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.designation"
            type="text"
            class="form-control"
            id="designation"
            name="designation"
            placeholder="Désignation"
            :autoResize="true"
            rows="1"
            cols="30"
          />
          <label for="description">Désignation</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.prixUnitaire"
            type="number"
            class="form-control"
            id="prixUnitaire"
            name="prixUnitaire"
            placeholder="Prix Unitaire"
          />
          <label for="prixUnitaire">Prix unitaire (Pi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.quantite"
            type="number"
            class="form-control"
            id="quantite"
            name="quantite"
            placeholder="Quantité"
          />
          <label for="quantite">Quantité (Qi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            :value="cout"
            type="number"
            class="form-control"
            id="cout"
            name="cout"
            placeholder="Coût"
            readonly
          />
          <label for="cout">Coût (Pi x Qi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <PDivider />
    <div class="col-sm-12">
      <h5>Schéma de financement</h5>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.apportPropre"
            type="number"
            class="form-control"
            id="apportPropre"
            name="apportPropre"
            placeholder="Apport propre"
          />
          <label for="apportPropre">Apport propre</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.subventionSollicite"
            type="number"
            class="form-control"
            id="subventionSollicite"
            name="subventionSollicite"
            placeholder="Subvention sollicitée"
          />
          <label for="subventionSollicite">Subvention sollicitée</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="besoinFinancement"
            type="number"
            class="form-control"
            id="besoinFinancement"
            readonly
            name="besoinFinancement"
            placeholder="Besoin de financement"
          />
          <label for="besoinFinancement">Besoin de financement</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
          <small class="text-helop">Besoin de financement/Crédit sollicité</small>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  watch: {
    // item: {
    //   handler(val) {
    //     this.editableItem = { ...this.item };
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    cout() {
      return (
        parseInt(this.editableItem.prixUnitaire || 0) *
        parseInt(this.editableItem.quantite || 0)
      );
    },
    besoinFinancement() {
      return (
        this.cout -
        (parseInt(this.editableItem.apportPropre || 0) +
          parseInt(this.editableItem.subventionSollicite || 0))
      );
    },
  },
  created() {},
  methods: {},
};
</script>
