<template>
  <div>
    <interet-editor-dialog
      @save="saveInterets"
      :title="'Editer interet'"
      ref="editor"
      :item="activeItem"
    />
    <div class="mb-5">
      <div
        v-if="displayMode == 'cv'"
        class="d-flex justify-content-between align-items-center mb-4"
      >
        <h6 class="mb-0 text-uppercase flex-fill">Centre d'interêts</h6>
        <button
          v-if="!readOnly"
          @click.prevent="addInteret()"
          type="button"
          class="btn btn-primary"
        >
          Ajouter
        </button>
      </div>
      <div v-else class="d-flex justify-content-between flex-nowrap mb-3">
        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">Centre d'interêts</h5>
        <a
          v-if="!readOnly"
          @click.prevent="addInteret()"
          class="btn btn-primary rounded-0 btn-sm"
          ><i class="bi bi-plus-square"></i
        ></a>
      </div>
      <slot :addInteret="addInteret" :isEmpty="interets.length == 0"></slot>

      <div class="mb">
        <PBadge
          class="badge-primary mb-0 ms-1"
          size="md"
          v-for="(inter, index) in interets"
          :key="index"
          :value="inter"
          >{{ inter }}</PBadge
        >
      </div>
    </div>
  </div>
</template>

<script>
import InteretEditorDialog from "./editor/dialog/InteretEditorDialog.vue";
export default {
  components: { InteretEditorDialog },
  props: {
    value: {
      type: Array,
    },
    displayMode: {
      default: "cv", // 'cv', 'nice'
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: {},
      interets: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.interets = [...this.value];
      },
      immediate: true,
    },
  },
  methods: {
    addInteret() {
      this.activeItem = [...this.interets];
      this.$refs.editor.show();
    },
    saveInterets(interets) {
      this.interets = [...interets];
      this.$emit("save", this.interets);
      this.$emit("change", this.interets);
    },
  },
};
</script>
