<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <CrudTable
          :formTitle="'Edition personnel déjà en place'"
          :tableFields="crudTableFields"
          :crudUrl="'besoinRessourceHumaines'"
          :extraQueryArg="`groupePersonnel=DEJA_EN_PLACE&microProjet.id=${editableItem.id}`"
          :totalComputingFields="tableTotalComputingFields"
          :emptyReferentielItem="emptyPersonnelExistantCrudItem"
        >
          <template #listTitle>
            <h4>
              Liste du personnel déjà en place (en plus du promoteur ou de la promotrice,
              indiquer tous les autres employés déjà en place si l'entreprise existe déjà)
            </h4>
          </template>

          <template #formTitle="{}">
            <h5>Edition personnel déjà en place</h5>
          </template>
          <template #formContent="{ editableItem, updateValue }">
            <RessourceHumaineEditor
              :value="editableItem"
              @changed="(val) => updateValue(val)"
            />
          </template>
          <!-- <Teleport :to="#extra"></Teleport> -->
          <template #cell(effectif)="data">
            {{ data }}
          </template>
        </CrudTable>

        <PDivider />
        <CrudTable
          :formTitle="'Edition besoins en personnel'"
          :tableFields="crudTableFields"
          :extraQueryArg="`groupePersonnel=NOUVEAU_RECRUTEMENT&microProjet.id=${editableItem.id}`"
          :crudUrl="'besoinRessourceHumaines'"
          :totalComputingFields="tableTotalComputingFields"
          :emptyReferentielItem="emptyNouveauPersonnelCrudItem"
        >
          <template #listTitle>
            <h4>Liste des besoins en personnel (nouveaux recrutements nécessaires)</h4>
          </template>
          <template #formTitle="{}">
            <h5>besoins en personnel</h5>
          </template>
          <template #formContent="{ editableItem, updateValue }">
            <RessourceHumaineEditor
              :value="editableItem"
              @changed="(val) => updateValue(val)"
            />
          </template>
        </CrudTable>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import FfomCrudForm from "./ffom/FfomCrudForm.vue";
import RessourceHumaineEditor from "./ressource-humaine/RessourceHumaineEditor.vue";

export default {
  components: {
    CYesOrNoInput,
    CSelect,
    CrudTable,
    FfomCrudForm,
    RessourceHumaineEditor,
  },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({}),
    crudTableFields() {
      return [
        "#",
        { key: "typePosteRh", label: "Poste" },
        { key: "metierQualification.libelle", label: "Métier" },
        { key: "qualificationProfessionnelle.libelle", label: "Qualification" },
        { key: "effectifHomme", label: "Eff.Hom." },
        { key: "effectifFemme", label: "Eff.Fem." },
        {
          key: "effectif",
          label: "Eff.Tot",
          formatter: (value, key, item) => {
            return parseInt(item.effectifFemme || 0) + parseInt(item.effectifHomme || 0);
          },
        },
        { key: "salaireMensuel", label: "Salaire Mensuel" },
        { key: "taches", label: "Tâches", isDate: true },
        "actions",
      ];
    },
    tableTotalComputingFields() {
      return [
        { key: "effectifHomme" },
        { key: "effectifFemme" },
        { key: "salaireMensuel" },
      ];
    },
    emptyCrudItem() {
      return {
        qualificationProfessionnelle: null,
        metierQualification: null,
        effectifHomme: null,
        effectifFemme: null,
        salaireMensuel: null,
        taches: null,
        microProjet: this.editableItem,
      };
    },
    emptyNouveauPersonnelCrudItem() {
      return {
        ...this.emptyCrudItem,
        groupePersonnel: "NOUVEAU_RECRUTEMENT",
      };
    },
    emptyPersonnelExistantCrudItem() {
      return {
        ...this.emptyCrudItem,
        groupePersonnel: "DEJA_EN_PLACE",
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return; //No validation needed
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
