<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '70vw' }"
    >
      <h4>Offre #{{ offre.reference }}/{{ offre.intitule }}</h4>
      <h5>Nombre de postes disponibles: {{ offre.nombre }}</h5>
      <SearchableItemCandidatureTransmissibleSelector
        :listTitle="title"
        :referentielName="referentielName"
        :tableFields="tableFields"
        :topSelectionCount="offre.nombre"
        v-model="participants"
        fetcherMethod="candidatureSelector/fetchTopNCandidatureSelectors"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import SearchableItemCandidatureTransmissibleSelector from "./SearchableItemCandidatureTransmissibleSelector.vue";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    offre: {
      type: Object,
      required: true,
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "candidatureSelector",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "candidat.nom", label: "Nom" },
        { key: "candidat.prenom", label: "Prénom" },
        { key: "candidat.sexe", label: "Sexe" },
        { key: "candidat.phone", label: "Téléphone" },
        { key: "candidat.departement.nom", label: "Département" },
        { key: "candidat.commune.nom", label: "Commune" },
        { key: "candidat.ville.nom", label: "Ville" },
        { key: "totalPoints", label: "Points" },
        { key: "rang", label: "Range" },
        { key: "status", label: "Statut" },
      ],
    },
  },
  components: {
    SearchableItemCandidatureTransmissibleSelector,
  },
  data() {
    return {
      display: false,
      participants: [],
    };
  },
  created() {},
  watch: {
    items: {
      handler() {
        this.participants = [...this.items];
      },
      immediate: true,
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return `offreId=${this.offre.id}`;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.$emit("input", this.participants);
    },
    async save() {
      this.$emit("saved", this.participants);
      this.$emit("save", this.participants);
      this.hide();
    },
  },
};
</script>
