<template>
  <div>
    <PDialog class="stepper-dialog" :header="''" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'auto'}" :containerStyle="{width: '70vw'}">
      <template #header>
        <div class="card shadow-3 px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-">
              <!-- <PButton icon="pi pi-times" class="p-button-rounded p-button-primary" /> -->
            </h4>
            <h4 class="d-flex justify-content-between">
              <div>
                Validation de CV/Fiche - Taux <span class="text-primary">{{ editableItem.tauxRemplissageProfil }}%</span>
              </div>
              <PButton @click="cancel()" icon="pi pi-times" class="p-button-rounded p-button-outlined p-button-danger" />
            </h4>
            <PDivider />
          </div>
          <DStepper :steps="steps" :activeStepIndex="activeStepIndex" :readonly="false" class="" />
        </div>
      </template>
      <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
        <div class="card mt border-0 ps-3">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
            <div class="">
              <component ref="currentStepComponent" v-model="editableItem" :is="steps[activeStepIndex].component"/>
            </div>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Précédent" icon="pi pi-chevron-left" :disabled="activeStepIndex <= 0" class="p-button-outlined" @click.prevent="goToPrevious"  />
        <PButton label="Suivant" icon="pi  pi-chevron-right" v-if="activeStepIndex < steps.length-1" @click.prevent="goToNext"  />
        <PButton label="Valider" icon="pi pi-check" @click="save"  v-if="activeStepIndex >= steps.length-1" />
      </template>
    </PDialog>
  </div>
</template>

<script>
import DStepper from '../../common/DStepper.vue'
import CiviliteValidator from './dossier-validator-steps/CiviliteValidator.vue';
import ScoreObservation from './dossier-validator-steps/ScoreObservation.vue';
import DossierEditableDetail from './dossier-validator-steps/DossierEditableDetail.vue';

export default {
    props: ["demandeur"],
    data() {
        return {
          note: null,
          display: false,
          editableItem: {},
          maxScore: 10,
          activeStepIndex: 0,
        };
    },
    watch: {
        demandeur: {
          handler() {
              this.editableItem = { ...this.demandeur };
          },
          deep: true,
          immediate: true
        }
    },
    computed: {
      steps(){
        return [{
              label: 'VALIDATION NPI',
              subtitle: 'Validation de la civilité du DE par NPI',
              to: '#npiValidation',
              component: CiviliteValidator,
              completed: this.activeStepIndex >= 0,
              command:() => {
              }
          },
          {
              label: 'VERIFICATION DU DOSSIER',
              subtitle: 'Vérification et ajustement du dossier',
              to: '#verification-dossier',
              component: DossierEditableDetail,
              completed: this.activeStepIndex >= 1,
              command:() => {
              }
              
            },
            {
              label: 'APPRECIATION',
              subtitle: 'Appréciation et commentaire du dossier',
              to: '#appreciation-dossier',
              component: ScoreObservation,
              completed: this.activeStepIndex >= 2,
              command:() => {
              }
              
          },
        ]
      },
    },
    methods: {
        goToNext(){
          this.activeStepIndex += (this.activeStepIndex < this.steps.length - 1)? 1 : 0
        },
        goToPrevious(){
          this.activeStepIndex -= (this.activeStepIndex > 0)? 1 : 0
        },
        show() {
          this.display = true;
        },
        hide() {
          this.display = false;
          this.resetContent()
        },
        resetContent(){
          this.editableItem = {};
          this.activeStepIndex = 0
        },
        cancel() {
            this.hide();
            this.$emit("canceled");
        },
        async isCompleted() {
            return await this.validateStep(this.activeStepIndex)
        },
        async validateStep (step){
          let target = this.$refs[`currentStepComponent`]
          return  target.isCompleted()
        },
        async save() {
          if (!await this.isCompleted())
              return;
          this.$emit("save", {
              ...this.editableItem
          });
          this.resetContent()
          this.hide();
        },
    },
    components: { DStepper }
}
</script>

<style>
  .stepper-dialog .p-dialog-header-icons{
    display: none;
  }

  .stepper-dialog .p-dialog-content{
    background: #FAF4FA;
  }
  
  .stepper-dialog .p-dialog-footer{
    box-shadow: 0px 2px 0px rgba(0,0,0, 0.2), 0px -1px 1px rgba(0,0,0, 0.2);
  }
  
  .stepper-dialog .p-dialog-header{
    box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.5);
  }
  
  .stepper-dialog .p-dialog-header-icons{
    display: none;
  }
</style>