<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="card border-none" v-if="evaluationEntrepreneuriale != null">
          <div class="card-header bg-light">
            <div class="row">
              <div class="col">
                <h6 class="d-flex justify-content-between">PROFIL ENTREPRENEURIAL</h6>
              </div>
              <div class="col-auto">
                {{
                  evaluationEntrepreneuriale.totalPointsEvaluation ||
                  computedNoteEvaluation
                }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="row py-2 border-top"
              v-for="(resp, i) in evaluationEntrepreneuriale.reponseEntrepreneuriales"
              :key="resp.id"
            >
              <div class="col">
                {{ i + 1 }}. <span>{{ resp.aptitudeEntrepreneuriale.libelle }}</span>
              </div>
              <div class="col-sm-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floatin">
                    <PDropdown
                      v-model="resp.appreciationAptitude"
                      :options="appreciationAptitudes"
                      optionLabel="libelle"
                      style="width: 100%"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="evaluationEntrepreneuriale != null"
          class="bg bg-white shadow-top px-2 mt-2 pt-3 shadow-light"
        >
          <div class="note d-flex justify-content-between">
            <div class="note-module"><h6>TOTAL</h6></div>
            <div class="note-value text-">
              {{ computedNoteEvaluation }}
            </div>
          </div>
          <hr />
          <div
            class="bg-white d-flex justify-content-between pa-0"
            v-if="computedNoteEvaluation > 0 && computedProfilEntrepreneurial != null"
          >
            <div class="">
              <h6>
                <PTag
                  v-tooltip.top="computedProfilEntrepreneurial.libelle"
                  :value="computedProfilEntrepreneurial.code"
                  :severity="
                    ['success', 'warning', 'primary', 'danger'][
                      computedProfilEntrepreneurial.id - 1
                    ]
                  "
                />
              </h6>
              <small class>{{ computedProfilEntrepreneurial.libelle }}</small>
            </div>
            <div class="">
              <button @click="saveEvaluation" class="btn btn-success h-100">
                Valider l'évaluation
              </button>
            </div>
          </div>
          <PDivider />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    prescription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      evaluationEntrepreneuriale: null,
      reponseEntrepreneuriales: [],
      appreciationAptitudes: [],
      profilEntrepreneurials: [],
    };
  },
  created() {
    this.fetchAppreciationAptitudes().then((data) => {
      this.appreciationAptitudes = [...data];
    });
    this.fetchProfilEntrepreneurials().then((data) => {
      this.profilEntrepreneurials = [...data];
    });
  },
  computed: {
    isCompleted() {
      if (this.evaluationEntrepreneuriale == null) return false;
      for (
        let i = 0;
        i < this.evaluationEntrepreneuriale.reponseEntrepreneuriales.length;
        i++
      ) {
        if (
          this.evaluationEntrepreneuriale.reponseEntrepreneuriales[i]
            .appreciationAptitude == null
        ) {
          return false;
        }
      }
      return true;
    },
    computedNoteEvaluation() {
      let note = null;
      if (this.evaluationEntrepreneuriale != null) {
        this.evaluationEntrepreneuriale.reponseEntrepreneuriales.forEach((el) => {
          if (note == null) note = 0;
          if (el.appreciationAptitude != null) {
            note += el.appreciationAptitude.note;
          }
        });
      }
      return note;
    },
    computedProfilEntrepreneurial() {
      const note = this.computedNoteEvaluation;
      if (note == null) return;

      for (let i = 0; i < this.profilEntrepreneurials.length; i++) {
        const profil = this.profilEntrepreneurials[i];
        if (note >= profil.noteMin && note <= profil.noteMax) return profil;
      }
    },
  },
  watch: {
    prescription: {
      handler(val) {
        if (val && val.id) {
          //   this.fetchReponseEntrepreneuriales({
          //     extraQueryArg: `prescription.id=${val.id}&size=100`,
          //   }).then((data) => {
          //     this.reponseEntrepreneuriales = [...data];
          //   });
          this.fetchEvaluationEntrepreneuriales({
            extraQueryArg: `prescription.id=${val.id}&size=100`,
          }).then((data) => {
            console.log(data);
            this.evaluationEntrepreneuriale = data.length > 0 ? data[0] : {};
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchReponseEntrepreneuriales:
        "reponseEntrepreneuriale/fetchReponseEntrepreneuriales",
      fetchEvaluationEntrepreneuriales:
        "evaluationEntrepreneuriale/fetchEvaluationEntrepreneuriales",
      fetchAppreciationAptitudes: "appreciationAptitude/fetchAppreciationAptitudes",
      fetchProfilEntrepreneurials: "profilEntrepreneurial/fetchProfilEntrepreneurials",
      createOrUpdateEvaluationEntrepreneuriale:
        "evaluationEntrepreneuriale/createOrUpdateEvaluationEntrepreneuriale",
    }),
    saveEvaluation() {
      if (!this.isCompleted) {
        this.$toast.error(
          "Toutes les aptitudes entrepreneuriales n'ont pas été évaluée."
        );
        return;
      }
      this.createOrUpdateEvaluationEntrepreneuriale(this.evaluationEntrepreneuriale).then(
        (data) => {
          this.evaluationEntrepreneuriale = data;
          this.$toast.success({
            message: "Opération réussie!",
          });
        }
      );
    },
  },
};
</script>

<style></style>
