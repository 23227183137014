<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <block-competences
          v-model="editableItem.competences"
          @change="handleCompetencesChange"
          :btnType="'add'"
        />

        <block-centre-interets
          v-model="editableItem.centreInterets"
          @change="handleInteretsChange"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import BlockCompetences from "../BlockCompetences.vue";
import BlockCentreInterets from "../BlockCentreInterets.vue";

export default {
  components: { BlockCompetences, BlockCentreInterets },
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  watch: {},
  mixins: [setepperFormMixin],
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    handleCompetencesChange(competences) {
      this.$emit("input", {
        ...this.editableItem,
        competences,
      });
    },
    handleInteretsChange(interets) {
      this.$emit("input", {
        ...this.editableItem,
        interets,
      });
    },
  },
};
</script>

<style scoped></style>
