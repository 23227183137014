<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit()"
        class=""
        method="post"
        accept-charset="utf-8"
        action=""
      >
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select
              id="paysDeResidence"
              name="paysDeResidence"
              aria-label="Votre pays de résidence"
              option-label="nom"
              option-value="id"
              v-model="editableItem.paysDeResidence"
              :options="pays"
              class="form-select"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <label for="paysDeResidence">Pays de résidence</label>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select
              id="nationalite"
              name="nationalite"
              aria-label="Votre pays de résidence"
              option-label="nom"
              option-value="id"
              v-model="editableItem.nationalite"
              :options="pays"
              class="form-select"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <label for="nationalite">Nationalité</label>
          </div>
        </ValidationProvider>
        <template
          v-if="editableItem.nationalite && editableItem.nationalite.code == 'BJ'"
        >
          <echelon v-model="editableItem" :residence="true" />
        </template>
        <template v-if="!estPeuOuPasInstruit">
          <div class="row">
            <div class="col-sm-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.lieuNaissance"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="lieuNaissance"
                    name="lieuNaissance"
                    placeholder="Votre email"
                  />
                  <label for="lieuNaissance">Lieu de naissance</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.codePostal"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="codePostal"
                    name="codePostal"
                    placeholder="Boîte postale"
                  />
                  <label for="codePostal">Boîte postale</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <textarea
                :value="editableItem.adresse"
                @input="handleInput"
                class="form-control"
                id="adresse"
                name="adresse"
                v-uppercase
                placeholder="Adresse physique"
                rows="3"
              />
              <label for="adresse">Adresse complète</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import Echelon from "../../../common/echelon/Echelon.vue";

export default {
  props: ["value"],
  components: { Echelon },
  data() {
    return {
      editableItem: this.value,
    };
  },
  mixins: [setepperFormMixin],
  computed: {
    ...mapGetters({
      departements: "departement/departements",
      communes: "departement/communes",
      arrondissements: "departement/arrondissements",
      villes: "departement/villes",
      nationalites: "nationalite/nationalites",
    }),
    pays() {
      return [{ id: null, code: null }, ...this.nationalites];
    },
    estPeuOuPasInstruit() {
      return (
        this.editableItem.estInstruit != null &&
        (!this.editableItem.estInstruit ||
          (this.editableItem.estInstruit &&
            this.editableItem.niveauInstruction != null &&
            this.editableItem.niveauInstruction <= 10))
      );
    },
  },
  //   watch: {
  //     "editableItem.departement": {
  //       handler(val, oldVal) {
  //         console.log(val, oldVal, this.editableItem.departement);
  //         if (val != oldVal) {
  //           this.fetchDepartementCommunes({
  //             departementId: this.editableItem.departement,
  //           });
  //         }
  //       },
  //     },
  //     "editableItem.commune": {
  //       handler(val, oldVal) {
  //         console.log(val, oldVal, this.editableItem.commune);
  //         if (val != oldVal) {
  //           this.fetchCommuneArrondissements({
  //             communeId: this.editableItem.commune,
  //           });
  //         }
  //       },
  //     },
  //     "editableItem.arrondissement": {
  //       handler(val, oldVal) {
  //         console.log(val, oldVal, this.editableItem.arrondissement);
  //         if (val != oldVal) {
  //           this.fetchArrondissementVilles({
  //             arrondissementId: this.editableItem.arrondissement,
  //           });
  //         }
  //       },
  //     },
  //   },
  created() {
    // this.fetchDepartements();
    this.fetchNationalites();
  },
  methods: {
    ...mapActions({
      fetchDepartements: "departement/fetchDepartements",
      fetchCommuneArrondissements: "departement/fetchCommuneArrondissements",
      fetchDepartementCommunes: "departement/fetchDepartementCommunes",
      fetchArrondissementVilles: "departement/fetchArrondissementVilles",
      fetchNationalites: "nationalite/fetchNationalites",
    }),
    handleInput(e) {
      if (e.target.name === "commune" || e.target.name === "departement") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
