<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row px-3 mb-4">
          <div
            class="col-sm-12 field mb-1"
            v-if="$can('CHANGER_ACCOMPAGNATEUR', 'MICRO_PROJET')"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <h5>Info Accompagnateur du microprojet</h5>
              <AsyncSearchInput
                v-model="editableItem.accompagnateur"
                :multiple="false"
                :queryUrl="'agents'"
                :optionLabel="'nomPrenom'"
                :searchOptions="['nomPrenom']"
                placeholder="Rechercher un agent"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <PDivider class="mb-4" />
          <div class="col-sm-12 field">
            <h5>Info de base du microprojet</h5>
            <div class="form-floating mb-3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="col-sm-12 field md:col-12 field">
                  <label for="libelle">Programme</label>
                  <div class="col-12 md:col-12">
                    <PDropdown
                      v-model="editableItem.programme"
                      class="col-12 md:col-12"
                      :options="programmes"
                      optionLabel="nom"
                      placeholder="Nom du programme"
                    >
                    </PDropdown>
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-sm-12 md:col-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="titre">Titre du microprojet</label>
              <div>
                <PInputText
                  v-uppercase
                  class="col-12 md:col-12"
                  v-model="editableItem.titre"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 field">
            <div class="form-floating mb-3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="col-sm-12 field md:col-12 field">
                  <label for="libelle">Etape/Phase du microprojet</label>
                  <div class="col-12 md:col-12">
                    <PDropdown
                      v-model="editableItem.etapeMicroprojet"
                      class="col-12 md:col-12"
                      :options="etapes"
                      optionLabel="libelle"
                      placeholder="Etape du microprojet"
                    >
                    </PDropdown>
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row px-3 mb-4">
          <h5 class="mb-2">Secteur et métier du microprojet</h5>
          <EchelonArtisanat v-model="editableItem" />
        </div>
        <div class="row px-3 mb-4">
          <h5 class="mb-2">Localisation du site du microprojet</h5>
          <Echelon v-model="editableItem" />
        </div>

        <div class="row px-3">
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.latitude"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="latitude"
                  name="latitude"
                  placeholder="Latitude GPS"
                />
                <label for="latitude">Latitude GPS</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.longitude"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="longitude"
                  name="longitude"
                  placeholder="Longitude GPS"
                />
                <label for="codePostal">Longitude GPS</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row px-3 mb-4">
          <div class="col-12 mb-2">
            <h5 class="">Coût et financement du microprojet (en FCFA).</h5>
            <span class="text-danger">
              Non modifiable. Auto calculé à partir des données de l'étape 8.</span
            >
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partInverstissementPropre"
                >Montant d'investissement propre</label
              >
              <div>
                <PInputNumber
                  id="partInverstissementPropre"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partInverstissementPropre"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partFonctionnementPropre"
                >Montant de fonctionnement propre</label
              >
              <div>
                <PInputNumber
                  id="partFonctionnementPropre"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partFonctionnementPropre"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalApportPersonnel" class="fw-bold"
                >Total apport personnel (B)</label
              >
              <div>
                <PInputNumber
                  id="totalApportPersonnel"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalApportPersonnel"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partInverstissementSubvention"
                >Montant investissement subvention</label
              >
              <div>
                <PInputNumber
                  id="partInverstissementSubvention"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partInverstissementSubvention"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partFonctionnementSubvention"
                >Montant fonctionnement subvention</label
              >
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partFonctionnementSubvention"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalSubvention" class="fw-bold">Total subvention(A)</label>
              <div>
                <PInputNumber
                  id="totalSubvention"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalSubvention"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partInverstissementCredit">Montant investissement crédit</label>
              <div>
                <PInputNumber
                  id="partInverstissementCredit"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partInverstissementCredit"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="partFonctionnementCredit">Montant fonctionnement crédit</label>
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.partFonctionnementCredit"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalCredit" class="fw-bold"
                >Total Crédit à mobiliser (C)</label
              >
              <div>
                <PInputNumber
                  id="totalCredit"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalCredit"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalInvestissement" class="fw-bold"
                >Coût des investissements (Inv)</label
              >
              <div>
                <PInputNumber
                  id="totalInvestissement"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalInvestissement"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalFonctionnement" class="fw-bold"
                >Besoins en fonds de roulement (BFR)
              </label>
              <div>
                <PInputNumber
                  id="totalFonctionnement"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalFonctionnement"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-4 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="totalProjet" class="fw-bold"
                >Coût Total du Projet(Inv + BFR)</label
              >
              <div>
                <PInputNumber
                  id="totalProjet"
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  :value="totalProjet"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row px-3 mb-4">
          <div class="col-12 mb-2">
            <h5 class="">
              Prévision de rentabilité sur les six (6) premiers mois (en FCFA)
            </h5>
            <span class="text-danger">
              Non modifiable. Auto calculé à partir des données de l'étape 10.</span
            >
          </div>
          <div class="col-sm-6 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="titre">Total Recettes prévisionnelles sur 6 mois</label>
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.recettePrevisionelleSur6Mois"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-6 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="titre">Total Charges prévisionnelles sur 6 mois</label>
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  disabled
                  readonly
                  v-model="editableItem.chargePrevisionelleSur6Mois"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-6 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="titre" class="fw-bold"
                >Bénéfice brut prévisionnel sur 6 mois</label
              >
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  :value="beneficeBrutPrevisionelleSur6Mois"
                  disabled
                  aria-readonly="true"
                  readonly
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-6 field">
            <ValidationProvider rules="" v-slot="{ errors }">
              <label for="titre" class="fw-bold">Taux brut de rentabilité</label>
              <div>
                <PInputNumber
                  class="col-12 md:col-12"
                  v-model="tauxBrutRentabilite"
                  suffix="%"
                  disabled
                  readonly
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div>SFD de financement</div>
              <AsyncSearchInput
                v-model="editableItem.sfdFinancier"
                :queryUrl="'serviceFinancierDecentralises'"
                :optionLabel="'denomination'"
                placeholder="Rechercher un SFD financier"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <label for="libelle">Observations</label>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.observation"
                  class="col-12"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Observation"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Echelon from "@/components/common/echelon/Echelon.vue";
import EchelonArtisanat from "@/components/common/echelon-artisanat/EchelonArtisanat.vue";
import MetierSelect from "@/components/common/MetierSelect.vue";
import NaemaSelect from "@/components/common/NaemaSelect.vue";
import { mapActions, mapGetters } from "vuex";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";

export default {
  props: ["value"],
  components: {
    Echelon,
    MetierSelect,
    NaemaSelect,
    AsyncSearchInput,
    EchelonArtisanat,
  },
  mixins: [stepperFormMixin],
  data() {
    return {
      editableItem: {
        ...this.value,
      },
    };
  },
  created() {
    this.fetchProgrammes();
    this.fetchEtapeMicroprojets();
  },
  computed: {
    ...mapGetters({
      programmes: "programme/programmes",
      etapes: "etapeMicroprojet/etapeMicroprojets",
    }),
    totalApportPersonnel() {
      return (
        parseInt(this.editableItem.partInverstissementPropre || 0) +
        parseInt(this.editableItem.partFonctionnementPropre || 0)
      );
    },
    totalSubvention() {
      return (
        parseInt(this.editableItem.partInverstissementSubvention || 0) +
        parseInt(this.editableItem.partFonctionnementSubvention || 0)
      );
    },
    totalCredit() {
      return (
        parseInt(this.editableItem.partInverstissementCredit || 0) +
        parseInt(this.editableItem.partFonctionnementCredit || 0)
      );
    },
    totalInvestissement() {
      return (
        parseInt(this.editableItem.partInverstissementPropre || 0) +
        parseInt(this.editableItem.partInverstissementSubvention || 0) +
        parseInt(this.editableItem.partInverstissementCredit || 0)
      );
    },
    totalFonctionnement() {
      return (
        parseInt(this.editableItem.partFonctionnementPropre || 0) +
        parseInt(this.editableItem.partFonctionnementSubvention || 0) +
        parseInt(this.editableItem.partFonctionnementCredit || 0)
      );
    },
    totalProjet() {
      return this.totalInvestissement + this.totalFonctionnement;
    },
    beneficeBrutPrevisionelleSur6Mois() {
      return (
        parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0) -
        parseInt(this.editableItem.chargePrevisionelleSur6Mois || 0)
      );
    },
    tauxBrutRentabilite() {
      return (
        (parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0) != 0
          ? this.beneficeBrutPrevisionelleSur6Mois /
            parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0)
          : 0) * 100
      );
    },
  },
  methods: {
    ...mapActions({
      fetchProgrammes: "programme/fetchProgrammes",
      fetchEtapeMicroprojets: "etapeMicroprojet/fetchEtapeMicroprojets",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style>
.field {
  margin-bottom: 10px;
}
</style>
