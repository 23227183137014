<template>
  <div>
    <!-- <h6 class="text-uppercase">Profil entrepreneurial</h6> -->
    <ProfilEntrepreneurial :prescription="prescription" />

    <div class="card border-none mt-4">
        <div class="card-header bg-light">
        <div class="row">
            <div class="col">
                <h6 class="d-flex justify-content-between">EXPERIENCE PRATIQUE EN ENTREPRENEURIAT</h6>
            </div>
            <div class="col-auto">
            </div>
        </div>
        </div>
        <div class="card-body">
            <div class="col-sm-12 mb-4">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <c-yes-or-no-input
            question="Avez-vous déjà initié une fois une activité économique ?"
            v-model="editableItem.dejaMeneUneActivite"
          />
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </ValidationProvider>
      </div>
      <div class="col-sm-12 mb-4" v-if="editableItem.dejaMeneUneActivite == true">
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="col-sm-12 field md:col-12 field">
            <label for="libelle">Laquelle?</label>
            <div class="col-12 md:col-12">
              <PTextarea
                rows="5"
                cols="30"
                v-model="editableItem.activitePrecedemmentMenee"
                class="col-12"
                optionLabel="label"
                optionValue="value"
                placeholder="Description de l'activité"
              />
            </div>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12 mb-4" v-if="editableItem.dejaMeneUneActivite == true">
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="col-sm-12 field md:col-12 field">
            <label for="libelle"
              >Quelles sont les leçons apprises de cette initiative ?</label
            >
            <div class="col-12 md:col-12">
              <PTextarea
                rows="5"
                cols="30"
                v-model="editableItem.leconsApprises"
                class="col-12"
                optionLabel="label"
                optionValue="value"
                placeholder="Leçons apprises"
              />
            </div>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12 mb-4">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <c-yes-or-no-input
            question="Aujourd’hui, avez-vous une idée de création ou d’extension d’entreprise ?"
            v-model="editableItem.asIdeeEntreprise"
          />
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </ValidationProvider>
      </div>
    </div>
        </div>
    </div>
  </div>
</template>

<script>
import CYesOrNoInput from "../../../../CYesOrNoInput.vue";
import ProfilEntrepreneurial from "./ProfilEntrepreneurial.vue";

export default {
  props: ["prescription"],
  components: { ProfilEntrepreneurial, CYesOrNoInput },
  data() {
    return {
      editableItem: {},
    };
  },
};
</script>

<style></style>
