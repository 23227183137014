<template>
  <div class="row">
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.activite"
            type="text"
            class="form-control"
            id="activite"
            name="activite"
            placeholder="Activité"
            :autoResize="true"
            rows="2"
            cols="30"
          />
          <label for="activite">Activité</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <div class="field col-12 md:col-12">
            <label for="dateDebut">Date début</label>

            <DatePicker
              class="flex-grow"
              :first-day-of-week="2"
              :masks="{ title: 'DD-MM-YYYY' }"
              locale="fr"
              v-model="editableItem.dateDebut"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="form-floating mb-3 flex w-full">
                  <input
                    class="form-control"
                    :value="inputValue"
                    required
                    type="text"
                    v-on="inputEvents"
                  />
                  <label for="titre">Date début</label>
                </div>
              </template>
            </DatePicker>
          </div>

          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <div class="field col-12 md:col-12">
            <label for="dateFin">Date Fin</label>

            <DatePicker
              class="flex-grow"
              :first-day-of-week="2"
              :masks="{ title: 'DD-MM-YYYY' }"
              locale="fr"
              v-model="editableItem.dateFin"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="form-floating mb-3 flex w-full">
                  <input
                    class="form-control"
                    :value="inputValue"
                    required
                    type="text"
                    v-on="inputEvents"
                  />
                  <label for="titre">Date Fin</label>
                </div>
              </template>
            </DatePicker>
          </div>

          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.personneResponsable"
            type="text"
            class="form-control"
            id="personneResponsable"
            name="personneResponsable"
            :autoResize="true"
            rows="1"
            cols="30"
          />
          <label for="personneResponsable">Personne responsable</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.personnesAssocies"
            type="text"
            class="form-control"
            id="personnesAssocies"
            name="personnesAssocies"
            :autoResize="true"
            rows="2"
            cols="30"
          />
          <label for="personnesAssocies">Personnes associées</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <h6>Statut mise en oeuvre</h6>
        <div class="form-floating mb-3">
          <c-select
            id="statut"
            name="statut"
            option-label="label"
            option-value="value"
            :emitObject="false"
            v-model="editableItem.statut"
            :options="statutMiseEnOeuvres"
            class="form-select"
          >
          </c-select>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
          <label for="typePosteRh">Type de poste</label>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";

export default {
  props: ["value"],
  components: { DatePicker, CYesOrNoInput },
  data() {
    return {
      editableItem: { ...this.value },
      statutMiseEnOeuvres: [
        { value: "NON_DEMARRE", label: "NON_DEMARRE" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "TERMINE", label: "TERMINE" },
      ],
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {},
};
</script>
