<template>
  <div class="container-fluid px-0" style="background: #faF4Fa">
    <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()" >
        <div class="card border-none" v-for="(mod) in modulesWithReponseCandidats" :key="mod.id">
          <div class="card-header bg-light">
            <div class="row">
              <div class="col">
                <h6 class="d-flex justify-content-between">{{ mod.libelle }}</h6>
              </div>
              <div class="col-auto">
                {{noteTotaleModules[mod.id].note}}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row py-2 border-top" v-for="(question, index) in mod.questions" :key="index">
              <div class="col">
                <span v-if="question">{{ question.libelle }}</span>
              </div>
              <div class="col-sm-3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floatin">
                    <!-- <c-select size="sm" name="appreciation" option-label="libelle" option-value="id" v-model="editableItem.appreciation" :options="mod.appreciations" class="form-select">
                    </c-select> -->
                    <PDropdown :disabled="!canEdit" v-model="question.reponseCandidat.appreciation" :options="mod.appreciations" optionLabel="libelle" style="width: 100%" />
                    <!-- <label for="libelle">Type de d'activité</label> -->
                    <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="bg bg-white shadow-top px-2 mt-2 pt-3 shadow-light">
          <template v-for="(noteKey, index) in Object.keys(noteTotaleModules)">
            <div :key="index" class="note d-flex justify-content-between">
              <div class="note-module"><h6>{{ noteTotaleModules[noteKey].libelle }}</h6></div>
              <div class="note-value">{{ noteTotaleModules[noteKey].note }}</div>
            </div>
            <hr>
          </template>
          <div class="note d-flex justify-content-between">
            <div class="note-module"><h6>TOTAL</h6></div>
            <div class="note-value text-">{{ noteTotale }}</div>
          </div>
          <hr>
          <div class="bg-white d-flex justify-content-between pa-0" v-if="noteTotale >= 0 && positionnement != null">
            <div class="">
              <h6><PTag v-tooltip.top="positionnement.description" :value="positionnement.code" :severity="getClassementColor(positionnement.code)" /></h6>
              <small class>{{ positionnement.description }}</small>
            </div>
            <div class="">
              <button :disabled="!canEdit" @click="saveParticipation" class="btn btn-success h-100">Valider l'évaluation</button>
            </div>
            <!-- <PBadge v-tooltip.top="positionnement.description" :value="positionnement.code" :severity="getClassementColor(positionnement.code)" /> -->
          </div>
          <PDivider />  
          
        </div>
        <div class="d-flex justify-content-end mb-4 pb- px-2">
          <!-- <button :disabled="!canEdit" @click="saveParticipation" class="btn btn-success">Valider</button> -->
        </div>
      </form>
    </ValidationObserver>
  </div>

</template>

<script>
import { get } from '@/api/api'
import { mapActions } from 'vuex'

export default {
  props: {
    modules: {
      type: Array
    },
    candidat: {
      type: Object
    }
  },
  data () { 
    return {
      value1: 10,
      editableItem: {
        appreciation: null,
        question: null,
        candidat: this.candidat
      },
      modulesWithReponseCandidats: [],
      classements: []
    }
  },
  async created () {
    await this.fetchClassements()
    // this.saveParticipation()
    this.modulesWithReponseCandidats = this.modules.map(mod => {
      return {
        ...mod,
        questions: this.candidat.grilleReponses.length > 0?
          this.candidat.grilleReponses.filter(
            r => mod.questions.map(q => q.id).includes(r.question.id)
          ).map(r => {
            console.log(r.question)
            return {
              ...r.question,
              reponseCandidat:  {
                ...r,
                appreciation: r.appreciation,
                question: r.question,
                candidat: this.candidat
              }
            }
          }) : mod.questions.map(q => {
          return {
            ...q,
            reponseCandidat:  {
              appreciation: null,
  
              question: q,
              candidat: this.candidat
            }
          }
        })
      }
    })

  },
  watch: {
    modules: {
      handler(){
      },
      immediate: true
    }
  },
  computed: {
    noteTotaleModules() {
      let notes = {}
      this.modulesWithReponseCandidats.forEach(mod => {
        notes[mod.id] = { ...mod, note: 0 }
        mod.questions.forEach(quest => {
          console.log(quest)
          // notes[mod.id].note = 0
          notes[mod.id].note += quest.reponseCandidat.appreciation != null? quest.reponseCandidat.appreciation.note : 0 
        })
      })
      return notes
    },
    noteTotale(){
      let total = 0
      Object.values(this.noteTotaleModules).forEach(item => total += item.note  )
      return total
    },
    canEdit(){
      return this.candidat.grilleReponses instanceof Array && this.candidat.grilleReponses.length == 0 
    },
    positionnement () {
      let position = null
      this.classements.forEach(item => {
        if(item.noteMin <= this.noteTotale && this.noteTotale <=item.noteMax){
          position = item
        }
      })
      return position
    }
  },
  methods: {
    ...mapActions({
      fetchGrilleModules: 'grille/fetchGrilleModules',
      updateParticipation: 'participation/updateParticipation'
    }),
    getClassementColor(code){
      let color = 'primary'
      if(code == 'DENA'){
        color = 'danger'
      } else if(code == 'DEA'){
        color = 'success'
      }
      return color
    },
    fetchClassements(){
      get(`/classements`)
      .then(({ data }) => {
        this.classements = data.content
      }).catch(err => {
        console.error(err)
      })
    },
    saveParticipation(){
      let questions = []
      this.modulesWithReponseCandidats.forEach(mod => {
        questions = questions.concat(mod.questions)
      })
      let item = {
        ...this.candidat,
        classement: this.positionnement,
        grilleReponses: questions.map(quest => {
          return quest.reponseCandidat
        }),
      }
      this.updateParticipation(item).then((data) => this.$emit('evaluationChange', data))
    }
  }
}
</script>

<style>
  .b-table-has-details{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    background: var(--gray-50);
  }
  .note-value{
    padding: 5px;
    font-size: 1.12rem;
    border: 1px solid rgb(0 0 0 / 15%);
  }
  .shadow-light{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
  }
  hr {
    color: #bfa9a9;
    margin: 5px 5px;
  }
</style>
