<template>
  <div class="container-">
    <screening-editor-dialog
      v-show="false"
      @save="saveScreening($event)"
      :title="
        activeItem != null && activeItem == null
          ? 'Ajouter un screening'
          : 'Editer un screening'
      "
      ref="editor"
      :item="activeItem"
      :entreprise="entreprise"
    />

    <partie-a
      v-show="false"
      @save="saveScreening($event)"
      :title="
        activeItem != null && activeItem == null
          ? 'BREVE DESCRIPTION DU SOUS PROJET'
          : 'BREVE DESCRIPTION DU SOUS PROJET'
      "
      ref="editorPartieA"
      :item="activeItem"
    />

    <consultation-editor-dialog
      v-show="false"
      @save="saveScreening($event)"
      :title="
        activeItem != null && activeItem == null
          ? 'CONSULTATION DU PUBLIC'
          : 'CONSULTATION DU PUBLIC'
      "
      ref="editorPartieA"
      :item="activeItem"
    />
    <div class="row">
      <div class="col-md-12 mb-4">
        <template v-if="loading">
          <div class="card shadow-0 border-0 px-3">
            <div class="field col-12 md:col-6 mt-2" v-for="i in 3" :key="i">
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2" height="2rem"></PSkeleton>
            </div>
          </div>
        </template>
        <div
          v-else
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                SCREENING - #{{ screening.id }}
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span>
                      <!-- <b-btn size="xs" @click.prevent="updateScreening(screening)"
                        v-tooltip.top="'Modifier la mission d\'évaluation'" variant="success" class="m-1"><i
                          class="bi bi-pencil"></i>
                      </b-btn>
                      <b-btn size="xs" @click.prevent="updateRapport(screening)" v-tooltip.top="'Ajouter un rapport'"
                        variant="warning" class="m-1"><i class="bi bi-card-text"></i>
                      </b-btn> -->
                      <b-btn
                        size="xs"
                        v-if="screening && screening.statutScreening != 'TERMINE'"
                        @click.prevent="terminerScreening(screening)"
                        v-tooltip.top="'Terminer le screening'"
                        variant="danger"
                        class="m-1"
                        >Terminer le screening
                      </b-btn>
                      <span></span>
                    </span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Bénéficiaire, initiateur du sous-projet :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Nom et Prénoms</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{
                            microprojet != null ? microprojet.beneficiaireNomPrenom : "-"
                          }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Sexe</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ microprojet != null ? microprojet.beneficiaireSexe : "-" }}
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Lieu où le sous-projet sera mis en œuvre :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Village/quartier :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ microprojet != null ? microprojet.ville.nom : "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Commune</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ microprojet != null ? microprojet.commune.nom : "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Arrondissement</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ microprojet != null ? microprojet.arrondissement.nom : "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Département</h6>
                        </div>
                        {{ microprojet != null ? microprojet.departement.nom : "-" }}
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Personne chargée de remplir le présent formulaire :
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Nom et prénoms :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Titre/fonction</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Téléphone</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Date</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Signature</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Personne chargée d’attester la réalisation du screening :
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Nom et prénoms :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Titre/fonction</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Téléphone</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Date</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Signature</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <!-- {{ screening.origineEthique || "-" }} -->
                        </div>
                      </div>
                      <hr />

                      <!-- <table class="table table-borderless">
                        <thead class="border-bottom border-top">
                          <tr>
                            <th scope="col">Nom et Prénoms</th>
                            <th scope="col">Titre/fonction</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Date</th>
                            <th scope="col">Signature</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span> {{ screening.nombreBeneficiareDirectTotal || "-" }}</span>
                            </td>
                            <td>
                              <span>
                                {{ screening.nombreBeneficiareDirectHomme || "-" }}</span>
                            </td>
                            <td>
                              <span>
                                {{ screening.nombreBeneficiareDirectFemme || "-" }}</span>
                            </td>
                            <td>
                              <span>
                                {{ screening.nombreBeneficiareDirectEnfant || "-" }}</span>
                            </td>
                            <td>
                              <span>
                                {{ screening.nombreBeneficiareDirectEnfant || "-" }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                PARTIE A : BREVE DESCRIPTION DU SOUS PROJET
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span>
                      <b-btn
                        size="xs"
                        @click.prevent="updateScreening(screening)"
                        v-tooltip.top="'Modifier la mission d\'évaluation'"
                        variant="success"
                        class="m-1"
                        ><i class="bi bi-pencil"></i>
                      </b-btn>
                    </span>
                  </div>
                </slot>
              </h5>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Comment le site du sous projet a-t-il été choisi ?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ screening.commentChoisiSiteSP || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nombre de bénéficiaires directs :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="col-sm-7 text-secondary">
                        <table class="table table-borderless">
                          <thead class="border-bottom border-top">
                            <tr>
                              <th scope="col">Total</th>
                              <th scope="col">Hommes</th>
                              <th scope="col">Femmes</th>
                              <th scope="col">Enfants</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareDirectTotal || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareDirectHomme || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareDirectFemme || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareDirectEnfant || "-"
                                  }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nombre de bénéficiaires indirects :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div class="col-sm-7 text-secondary">
                        <table class="table table-borderless">
                          <thead class="border-bottom border-top">
                            <tr>
                              <th scope="col">Total</th>
                              <th scope="col">Hommes</th>
                              <th scope="col">Femmes</th>
                              <th scope="col">Enfants</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareIndirectTotal || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareIndirectHomme || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareIndirectFemme || "-"
                                  }}</span
                                >
                              </td>
                              <td>
                                <span>
                                  {{
                                    screening.nombreBeneficiareIndirectEnfant || "-"
                                  }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Origine ethnique ou sociale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ screening.origineEthique || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Statut du site d’implantation du sous-projet :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ screening.statutSiteImplantation || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Y’a-t-il un acte attestant la propriété, la location ou la cession
                        gratuite ?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="screening.acteAttestantPropriete" />
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                PARTIE B : IDENTIFICATION DES IMPACTS ENVIRONNEMENTAUX ET SOCIAUX
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span>
                      <!-- <b-btn size="xs" @click.prevent="updateScreening(screening)"
                        v-tooltip.top="'Modifier la mission d\'évaluation'" variant="success" class="m-1"><i
                          class="bi bi-pencil"></i>
                      </b-btn>
                      <b-btn size="xs" @click.prevent="updateRapport(screening)" v-tooltip.top="'Ajouter un rapport'"
                        variant="warning" class="m-1"><i class="bi bi-card-text"></i>
                      </b-btn> -->
                    </span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="col-sm-12 text-secondary">
                    <table class="table">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Secteur</th>
                          <th scope="col">Question</th>
                          <th scope="col">Reponse</th>
                          <th scope="col">Observation</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Impacts ou préoccupations</th>
                          <th scope="col">Mesures d’atténuations</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(reponse, index) in screening.reponseScreening"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <span>
                              {{
                                reponse.questionScreening.secteurScreening.libelle || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span> {{ reponse.questionScreening.libelle || "-" }}</span>
                          </td>
                          <td>
                            <span>
                              <yes-or-no-badge :value="reponse.reponse" />
                            </span>
                          </td>
                          <td>
                            <span> {{ reponse.observation || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ reponse.Nombre || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ reponse.impact || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ reponse.mesureAttenuation || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                CONSULTATION DU PUBLIC
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span>
                      <b-btn
                        size="xs"
                        @click.prevent="updatePartieA(screening)"
                        v-tooltip.top="'Modifier la mission d\'évaluation'"
                        variant="success"
                        class="m-1"
                        ><i class="bi bi-pencil"></i>
                      </b-btn>
                    </span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        La consultation et la participation du public ont-elles été
                        recherchées ? (Coopératives, populations, services techniques,
                        ONG, mouvements associatifs locaux, etc.) :
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="screening.consultationPublic" />
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Si “Oui”, décrire brièvement les mesures qui ont été prises à cet
                        effet.)
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ screening.descripionConsultationPublic || "-" }}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Contact
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.phone || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.whatsapp || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Site web :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.siteWeb || "-" }}
                    </div>
                  </div>
                  
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.email || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Contact du dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephoneDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappDirigent || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Personne à contacter
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomContact || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonction :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.fonction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.emailContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephonePersonneContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappPersonneContact || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                ACTIVITES DE L'ENTREPRISE
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activité pricipale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <PChip v-if="entreprise.activitePrincipale !== null" :label="entreprise.activitePrincipale.libelle" />
                      <span v-else >-</span>

                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activités secondaires :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <PChip v-for="(act, index) in entreprise.activiteSecondaires" :key="index" :label="act.libelle" />
                      <span v-if="entreprise.activiteSecondaires.length==0">-</span>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Produits et services :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.produitService || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Chiffre d'affaire :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.chiffreAffaire.description || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Cycle de production :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.cycleProduction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Production en rotation :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="entreprise.productionRotation == 'OUI'" />
                      {{ entreprise.productionRotation || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                PERSONNEL EN FORMATION
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="col">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifTotal || "-" }}</span>
                          </td>
                          <td>
                            <span>
                              {{ entreprise.effectifTotalHomme || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{ entreprise.effectifTotalFemme || "-" }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
 
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total permanent</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifPermanent || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total temporaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifTemporaire || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total stagiaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifStagiaire || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total stagiaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifStagiaire || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Formation des personnels :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.formationPersonnel" />
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Garderie personnel :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.garderiePersonnel" />
                  </div>
                </div>

                <hr />
                <div class="card mb-3 border-1">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      BESOIN EN PERSONNEL
                      <span
                        ><b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i></b-btn
                      ></span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Categorie</th>
                                <th scope="col">Metier</th>
                                <th scope="col">Autre Metier</th>
                                <th scope="col">Total</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  besoin, index
                                ) in entreprise.besoinPersonnel"
                                :key="index"
                              >
                                <td>
                                  <span> {{ besoin.categorie || "-" }}</span>
                                </td>
                                <td>
                                  <span>
                                    {{ besoin.metier.libelle || "-" }}</span
                                  >
                                </td>
                                <td>
                                  <span> {{ besoin.autreMetier || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ besoin.besoinTotal || "-" }}</span>
                                </td>
                                <td>
                                  <span>
                                    {{ besoin.besoinTotalHomme || "-" }}</span
                                  >
                                </td>
                                <td>
                                  <span>
                                    {{ besoin.besoinTotalFemme || "-" }}</span
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
import partieA from "./PartieAEditorDialog.vue";
import ConsultationEditorDialog from "./ConsultationEditorDialog.vue";
import ScreeningEditorDialog from "./ScreeningEditorDialog.vue";
// import ScreeningEditorDialog from "../../../components/espace/entreprise/screening/editor/dialog/ScreeningEditorDialog.vue";
// import RapportEditorDialog from "../../../components/espace/entreprise/screening/editor/dialog/RapportEditorDialog.vue";

export default {
  props: ["microprojetId", "value"],
  components: {
    YesOrNoBadge,
    partieA,
    ConsultationEditorDialog,
    ScreeningEditorDialog,
    // RapportEditorDialog,
  },
  data() {
    return {
      entreprise: this.value,

      loading: true,
      activeItem: {},
      microprojet: null,
    };
  },
  created() {
    // this.entreprise = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.entreprise
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    microprojetId: {
      handler() {
        if (this.microprojetId) {
          this.fetchMicroprojet({ microprojetId: this.microprojetId }).then((data) => {
            this.microprojet = data;
          });
          this.fetchSecteurs();
          this.fetchMicropPro;
          this.loading = true;
          this.fetchScreeningsMicroProjet(this.microprojetId).then((data) => {
            this.loading = false;
            // this.screening = { ...data }
            console.log(data);
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      // entreprise: "entreprise/entreprise",
      screening: "screening/screening",
      constats: "constat/constats",
    }),
    de() {
      return this.entreprise;
      // eslint-disable-next-line no-unreachable
      // console.log(this.entreprise);
    },
  },
  methods: {
    ...mapActions({
      fetchScreenings: "screening/fetchScreenings",
      fetchScreeningsMicroProjet: "screening/fetchScreeningsMicroProjet",
      createOrUpdateScreening: "screening/createOrUpdateScreening",
      fetchSecteurs: "screeningsecteur/fetchSecteurs",
      fetchMicroprojet: "microprojet/fetchMicroprojet",
    }),
    async terminerScreening(screening) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Avez-vous vraiment terminé cette fiche de screening?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.saveRapport({
            ...screening,
            statutScreening: "TERMINE",
          });
        },
      });
    },
    editerDossier() {
      this.$emit("edit");
    },
    fileChanged(e) {
      console.log(e);
      this.entreprise.urlPhoto = e.previewUrl;
    },
    saveScreening(payload) {
      payload = {
        ...payload,
        entreprise: this.entreprise,
      };
      console.log(payload);
      this.mxLoading = true;

      this.createOrUpdateScreening(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
          this.mxLoading = false;
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
          this.mxLoading = false;
        });
    },

    saveRapport(payload) {
      payload = {
        ...payload,
        entreprise: this.entreprise,
      };
      console.log(payload);

      this.createOrUpdateScreening(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },

    addScreening() {
      // this.screening.push(emp)
      this.activeItem = {};
      this.$refs.editor.show();
    },

    updatePartieA(payload) {
      // this.screening.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editorPartieA.show();
    },
    updateScreening(payload) {
      // this.screening.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    updateRapport(payload) {
      // this.screening.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editorRap.show();
    },
    profileUpdate(e) {
      console.log(e);
      this.entreprise = {
        ...this.entreprise,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}

hr {
  color: #bfa9a9;
  margin: 10px 5px;
}

.vert {
  background: #00561b;
  color: white;
}

.jaune {
  background: #ffff00;
  color: black;
}

.orange {
  background: #faa401;
}

.rouge {
  background: #ff0000;
}
</style>
