<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4 mt-4">
            <h5>Etes-vous allé(e) à l’école?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-check form-check-inline">
                <input
                  v-model="estInstruit"
                  class="form-check-input"
                  type="radio"
                  id="estInstruit1"
                  name="estInstruit"
                  :value="true"
                />
                <label class="form-check-label" for="estInstruit1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="estInstruit"
                  class="form-check-input"
                  type="radio"
                  id="estInstruit2"
                  name="estInstruit"
                  :value="false"
                />
                <label class="form-check-label" for="estInstruit2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div v-if="estInstruit" class="col-sm-12">
            <h5>Quel est votre dernier niveau d'étude?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <select
                  v-model="editableItem.niveauInstruction"
                  class="form-select"
                  id="niveauInstruction"
                  name="niveauInstruction"
                  aria-label="Sélection votre niveau d'étude"
                >
                  <option :value="n.id" v-for="n in niveauInstructions" :key="n.id">
                    {{ n.description }}
                  </option>
                </select> -->
                <c-select
                  id="niveauInstruction"
                  name="niveauInstruction"
                  option-label="description"
                  option-value="id"
                  :emitObject="true"
                  v-model="editableItem.niveauInstruction"
                  :options="niveauInstructions"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="niveauInstruction">Niveau d'étude</label>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <MutationVersPpiForm v-if="estPeuOuPasInstruit" v-model="editableItem" />
            <MutationVersInstruitForm
              :demandeur="demandeur"
              v-else-if="editableItem.niveauInstruction != null"
              v-model="editableItem"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import MutationVersPpiForm from "./MutationVersPpiForm.vue";
import MutationVersInstruitForm from "./MutationVersInstruitForm.vue";
export default {
  components: { MutationVersInstruitForm, MutationVersPpiForm },
  props: ["value", "demandeur"],
  mixins: [setepperFormMixin],
  data() {
    return {
      estInstruit: this.value.estInstruit,
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
    }),
    niveauInstructions() {
      return this.instructions;
    },
    estPeuOuPasInstruit() {
      return (
        this.estInstruit != null &&
        (!this.estInstruit ||
          (this.estInstruit &&
            this.editableItem.niveauInstruction != null &&
            this.editableItem.niveauInstruction <= 10))
      );
    },
  },
  watch: {
    estInstruit() {
      this.editableItem.estInstruit = this.estInstruit;
      this.$emit("input", this.editableItem);
      if (this.estInstruit !== null && !this.estInstruit) {
        this.editableItem.niveauInstruction = null;
      }
    },
  },
  created() {
    this.fetchInstructions();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
    }),
  },
};
</script>

<style scoped></style>
