<template>
  <div class="">
    <div class="field col-12 md:col-6" v-if="loading">
      <PSkeleton class="mb-2"></PSkeleton>
      <PSkeleton width="10rem" class="mb-2"></PSkeleton>
      <PSkeleton width="5rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="3rem" class="mb-2"></PSkeleton>
      <PSkeleton height="4rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="5rem"></PSkeleton>
    </div>
    <ValidationObserver v-if="!loading" ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Participation aux séances d'information et de sensibilisation sur les opportunités économiques locales"
                v-model="editableItem.prerequisMicroprojet.participationSeanceInformation"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Participation au module sur les compétences transversales"
                v-model="
                  editableItem.prerequisMicroprojet
                    .participationModuleCompetenceTransversale
                "
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Participation au module sur les compétences entrepreneuriales"
                v-model="
                  editableItem.prerequisMicroprojet
                    .participationModuleCompetenceEntrepreneuriale
                "
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Participation au module sur les compétences financières"
                v-model="
                  editableItem.prerequisMicroprojet
                    .participationModuleCompetenceFinanciere
                "
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Analyse des Besoins en Formation Technique de Courte Durée effectuée ?"
                v-model="editableItem.prerequisMicroprojet.analyseBesoinEnFormation"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div
            class="col-sm-12 mb-4"
            v-if="editableItem.prerequisMicroprojet.analyseBesoinEnFormation == true"
          >
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <label for="libelle"
                  >Description Formation Technique de Courte Durée
                  identifiée/retenue</label
                >
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="5"
                    cols="30"
                    v-model="
                      editableItem.prerequisMicroprojet.descriptionFormationRetenue
                    "
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Observation"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="col-sm-12 field md:col-12 field">
                <label for="libelle">Centre de formation retenue</label>
                <div class="col-12 md:col-12">
                  <PTextarea
                    rows="5"
                    cols="30"
                    v-model="editableItem.prerequisMicroprojet.centreFormationRetenue"
                    class="col-12"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Centre de formation"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Participation effective à la Formation Technique de Courte Durée identifiée?"
                v-model="
                  editableItem.prerequisMicroprojet.participationEffectiveFormation
                "
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";

export default {
  components: { CYesOrNoInput },
  props: ["value"],
  mixins: [stepperFormMixin],
  data() {
    return {
      loading: false,
      editableItem: {
        ...this.value,
        prerequisMicroprojet: {
          participationSeanceInformation: null,
          participationModuleCompetenceTransversale: null,
          participationModuleCompetenceEntrepreneuriale: null,
          participationModuleCompetenceFinanciere: null,
          analyseBesoinEnFormation: null,
          descriptionFormationRetenue: null,
          metierFormationRetenue: null,
          centreFormationRetenue: null,
          participationEffectiveFormation: null,
        },
      },
    };
  },
  created() {
    this.loading = true;
    this.fetchPrerequisMicroprojets({
      extraQueryArg: `microProjet.id=${this.value.id}`,
    }).then((data) => {
      if (data.length != 0) {
        this.editableItem = {
          ...this.editableItem,
          prerequisMicroprojet: data[0],
        };
      }
      this.loading = false;
    });
  },
  methods: {
    ...mapActions({
      fetchPrerequisMicroprojets: "prerequisMicroprojet/fetchPrerequisMicroprojets",
      createOrUpdatePrerequisMicroprojet:
        "prerequisMicroprojet/createOrUpdatePrerequisMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdatePrerequisMicroprojet({
        microProjet: this.value,
        ...this.editableItem.prerequisMicroprojet,
      });
      this.$emit("save", { ...this.editableItem });
    },
  },
};
</script>

<style scoped></style>
