<template>
  <div class="">
    <PDialog
      class="paddingless-dialog"
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '70vw' }"
    >
      <SearchableItemDemandeurProfilSelector
        :listTitle="title"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="participants"
        :offre="offre"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
    <template v-else>
      <SearchableItemDemandeurProfilSelector
        :listTitle="title"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="participants"
        :offre="offre"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      >
        <template #actions="{ data }">
          <slot name="selectorActions" :data="data">
            Pas d'action. slot selectorActions
          </slot>
        </template>
      </SearchableItemDemandeurProfilSelector>
    </template>
  </div>
</template>

<script>
import SearchableItemDemandeurProfilSelector from "../../common/SearchableItemDemandeurProfilSelector.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    offre: {
      type: Object,
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "rechercherProfil",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "departement.nom", label: "Département" },
        { key: "commune.nom", label: "Commune" },
        { key: "estPPI", label: "Statut" },
        // { key: 'statut', label: 'Statut' },
      ],
    },
  },
  components: {
    SearchableItemDemandeurProfilSelector,
  },
  data() {
    return {
      display: false,
      participants: [],
    };
  },
  created() {},
  watch: {
    items: {
      handler() {
        this.participants = [...this.items];
      },
      immediate: true,
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return ``;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.$emit("input", this.participants);
    },
    async save() {
      this.$emit("saved", this.participants);
      this.$emit("save", this.participants);
      this.hide();
    },
  },
};
</script>

<style>
/* .stepper-dialog .p-dialog-header-icons{
    display: none;
  } */

.paddingless-dialog .p-dialog-content {
  padding-top: 0px;
  background: #faf4fa;
}
/*   
  .stepper-dialog .p-dialog-footer{
    box-shadow: 0px 2px 0px rgba(0,0,0, 0.2), 0px -1px 1px rgba(0,0,0, 0.2);
  }
  
  .stepper-dialog .p-dialog-header{
    box-shadow: 1px 2px 0px 0px rgba(0,0,0,0.5);
  } */
</style>
