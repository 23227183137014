<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="col-sm-12 mb-4">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <h6>Description de l’activité économique objet du micro-projet</h6>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.ffomActiviteEconomique"
                  class="col-12"
                  placeholder="Description de l’activité économique"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-12">
          <CrudTable
            :formTitle="'Edition force microprojet'"
            :tableFields="forceTableFields"
            :crudUrl="'ffoms'"
            :extraQueryArg="`microProjet.id=${editableItem.id}&typeFfom=FORCE`"
            :emptyReferentielItem="emptyForceCrudItem"
          >
            <template #listTitle>
              <h4>Liste des forces du microprojet</h4>
            </template>

            <template #formTitle="{}">
              <h5>Edition force microprojet</h5>
            </template>
            <template #formContent="{ editableItem }">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PTextarea
                    v-model="editableItem.libelle"
                    type="text"
                    class="form-control"
                    id="contenu"
                    rows="10"
                    cols="30"
                    name="contenu"
                    placeholder="Description du point fort"
                  />
                  <label for="nom">Description du point fort</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </template>
            <!-- <template #tableCellsSlot="{ data }">
        <template v-if="data.label == 'libelle'"> </template>
        {{ data.index + 1 }}
      </template> -->
          </CrudTable>
          <PDivider />
          <CrudTable
            :formTitle="'Edition faiblesse microprojet'"
            :referentielName="faiblesseCrudName"
            :tableFields="faiblesseTableFields"
            :crudUrl="'ffoms'"
            :extraQueryArg="`microProjet.id=${editableItem.id}&typeFfom=FAIBLESSE`"
            :emptyReferentielItem="emptyFaiblessCrudItem"
          >
            <template #listTitle>
              <h4>Liste des faiblesses du microprojet</h4>
            </template>
            <template #formContent="{ editableItem }">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PTextarea
                    v-model="editableItem.libelle"
                    type="text"
                    class="form-control"
                    id="contenu"
                    rows="10"
                    cols="30"
                    name="contenu"
                    placeholder="Description du point faible"
                  />
                  <label for="nom">Description du point faible</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </template>
          </CrudTable>
          <PDivider />
          <CrudTable
            :formTitle="'Edition opportunite microprojet'"
            :referentielName="opportuniteCrudName"
            :tableFields="opportuniteTableFields"
            :crudUrl="'ffoms'"
            :extraQueryArg="`microProjet.id=${editableItem.id}&typeFfom=OPPORTUNITE`"
            :emptyReferentielItem="emptyOpportuniteCrudItem"
          >
            <template #listTitle>
              <h4>Liste des opportunites du microprojet</h4>
            </template>
            <template #formContent="{ editableItem }">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PTextarea
                    v-model="editableItem.libelle"
                    type="text"
                    class="form-control"
                    id="contenu"
                    rows="10"
                    cols="30"
                    name="contenu"
                    placeholder="Description de l'opportunite"
                  />
                  <label for="nom">Description de l'opportunité</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </template>
          </CrudTable>
          <PDivider />
          <CrudTable
            :formTitle="'Edition menace microprojet'"
            :referentielName="menaceCrudName"
            :tableFields="menaceTableFields"
            :crudUrl="'ffoms'"
            :extraQueryArg="`microProjet.id=${editableItem.id}&typeFfom=MENACE`"
            :emptyReferentielItem="emptyMenaceCrudItem"
          >
            <template #listTitle>
              <h4>Liste des menaces du microprojet</h4>
            </template>
            <template #formContent="{ editableItem }">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <textarea
                    v-model="editableItem.libelle"
                    type="text"
                    class="form-control"
                    id="contenu"
                    rows="10"
                    cols="30"
                    name="contenu"
                    style="height: 250px"
                    placeholder="Description de la menace"
                  />
                  <label for="nom">Description de l'opportunité</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </template>
          </CrudTable>
          <PDivider />
        </div>

        <div class="col-sm-12 mb-4">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <h6>Problèmes ou contraintes essentiels à lever</h6>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.ffomContraintes"
                  class="col-12"
                  placeholder="Problèmes ou contraintes essentiels à lever "
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-12 mb-4">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <h6>Besoins à combler <i> (autres que les formations)</i></h6>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.ffomBesoins"
                  class="col-12"
                  placeholder="Besoins à combler"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-12 mb-4">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <h6>
                Actions à mettre en œuvre pour combler les besoins ou lever les
                contraintes
              </h6>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.ffomActions"
                  class="col-12"
                  placeholder="Actions à mettre en œuvre pour combler les besoins"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-12 mb-4">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12 field md:col-12 field">
              <h6>Caractère innovant du micro-projet : innovations apportées</h6>
              <div class="col-12 md:col-12">
                <PTextarea
                  rows="5"
                  cols="30"
                  v-model="editableItem.ffomCaractereInnovant"
                  class="col-12"
                  placeholder="Caractère innovant du micro-projet : innovations apportées"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";

export default {
  components: { CYesOrNoInput, CSelect, CrudTable },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({}),
    faiblesseCrudName() {
      return "faiblesse";
    },
    faiblesseTableFields() {
      return [
        "#",
        { key: "libelle", label: "Libelle" },
        { key: "description", label: "Description" },
        "actions",
      ];
    },
    emptyFaiblessCrudItem() {
      return {
        libelle: null,
        description: null,
        microProjet: this.editableItem,
        typeFfom: "FAIBLESSE",
      };
    },

    forceTableFields() {
      return [
        "#",
        { key: "libelle", label: "Libelle" },
        { key: "description", label: "Description" },
        "actions",
      ];
    },
    forceCrudName() {
      return "force";
    },
    emptyForceCrudItem() {
      return {
        libelle: null,
        description: null,
        microProjet: this.editableItem,
        typeFfom: "FORCE",
      };
    },
    opportuniteTableFields() {
      return [
        "#",
        { key: "libelle", label: "Libelle" },
        { key: "description", label: "Description" },
        "actions",
      ];
    },
    opportuniteCrudName() {
      return "opportunite";
    },
    emptyOpportuniteCrudItem() {
      return {
        libelle: null,
        description: null,
        microProjet: this.editableItem,
        typeFfom: "OPPORTUNITE",
      };
    },
    menaceTableFields() {
      return [
        "#",
        { key: "libelle", label: "Libelle" },
        { key: "description", label: "Description" },
        "actions",
      ];
    },
    menaceCrudName() {
      return "menace";
    },
    emptyMenaceCrudItem() {
      return {
        libelle: null,
        description: null,
        microProjet: this.editableItem,
        typeFfom: "MENACE",
      };
    },
  },
  created() {},
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
