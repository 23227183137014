import { render, staticRenderFns } from "./DeStatistic.vue?vue&type=template&id=2d42a4fc&scoped=true&"
import script from "./DeStatistic.vue?vue&type=script&lang=js&"
export * from "./DeStatistic.vue?vue&type=script&lang=js&"
import style0 from "./DeStatistic.vue?vue&type=style&index=0&id=2d42a4fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d42a4fc",
  null
  
)

export default component.exports