<template>
  <div class="row">
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.designation"
            type="text"
            class="form-control"
            id="designation"
            name="designation"
            placeholder="Désignation"
            :autoResize="true"
            rows="3"
            cols="30"
          />
          <label for="designation">Désignation</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.caracteristiqueSommaire"
            type="text"
            class="form-control"
            id="caracteristiqueSommaire"
            name="caracteristiqueSommaire"
            placeholder="Caractéristiques sommaires"
            :autoResize="true"
            rows="3"
            cols="30"
          />
          <label for="caracteristiqueSommaire">Caractéristiques sommaires</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.fournisseurPotentiel"
            type="text"
            class="form-control"
            id="fournisseurPotentiel"
            name="fournisseurPotentiel"
            placeholder="Fournisseurs potentiels"
            :autoResize="true"
            rows="3"
            cols="30"
          />
          <label for="caracteristiqueSommaire">Fournisseurs potentiels</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <div class="field col-12 md:col-12">
            <label for="dateDebut">Date prévisionnelle d’acquisition</label>

            <DatePicker
              class="flex-grow"
              :first-day-of-week="2"
              :masks="{ title: 'DD-MM-YYYY' }"
              locale="fr"
              v-model="editableItem.datePrevisonnelleAcquisition"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="form-floating mb-3 flex w-full">
                  <input
                    class="form-control"
                    :value="inputValue"
                    required
                    type="text"
                    v-on="inputEvents"
                  />
                  <label for="titre">Date prévisionnelle d’acquisition</label>
                </div>
              </template>
            </DatePicker>
          </div>

          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 mb-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <c-yes-or-no-input
          question="Pièces de rechange au niveau local?"
          v-model="editableItem.pieceRechangeEnLocal"
        />
        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
          err
        }}</span>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 mb-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <c-yes-or-no-input
          question="Capacité de maintenance au niveau local?"
          v-model="editableItem.capaciteMaintenanceEnLocal"
        />
        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
          err
        }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";

export default {
  props: ["value"],
  components: { DatePicker, CYesOrNoInput },
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {},
};
</script>
