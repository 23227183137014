<template>
  <div class="">
    <div class="field col-12 md:col-6" v-if="loading">
      <PSkeleton class="mb-2"></PSkeleton>
      <PSkeleton width="10rem" class="mb-2"></PSkeleton>
      <PSkeleton width="5rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="3rem" class="mb-2"></PSkeleton>
      <PSkeleton height="4rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="5rem"></PSkeleton>
    </div>
    <ValidationObserver v-if="!loading" ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <h6>
                Formation ou Qualification professionnelle dans le métier objet du
                microprojet
              </h6>
              <div class="form-floating mb-3">
                <c-select
                  id="qualificationProfessionnelle"
                  name="qualificationProfessionnelle"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.besoinFormation.qualificationProfessionnelle"
                  :options="qualificationProfessionnelles"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="qualificationProfessionnelle"
                  >Qualification pour le méter</label
                >
              </div>
            </ValidationProvider>
          </div>
          <div
            v-if="
              editableItem.besoinFormation &&
              editableItem.besoinFormation.qualificationProfessionnelle &&
              editableItem.besoinFormation.qualificationProfessionnelle.code == 'AUTRES'
            "
            class="col-sm-12 col-md-12"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.besoinFormation.autreFormation"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="autreFormation"
                    name="autreFormation"
                  />
                  <label for="autreFormation">Autres formations</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>
                Besoin en Formation Technique de Courte Durée du promoteur pour la mise en
                œuvre du microprojet
              </h5>
            </div>
            <div class="card-body">
              <div class="col-md-12 col-md-12">
                <h6>Dans quel métier?</h6>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form- mb-3">
                    <metier-select
                      v-model="editableItem.besoinFormation.metierQualification"
                    >
                      <template #label>
                        <label for="metierQualification">Métier de qualification</label>
                      </template>
                    </metier-select>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <template
                v-if="
                  editableItem.besoinFormation &&
                  editableItem.besoinFormation.metierQualification &&
                  editableItem.besoinFormation.metierQualification.libelle !== 'AUCUN'
                "
              >
                <div
                  class="col-sm-12 col-md-12 mb-4"
                  v-if="
                    editableItem.besoinFormation &&
                    editableItem.besoinFormation.metierQualification
                  "
                >
                  <CompetenceMetierSelector
                    :metier="editableItem.besoinFormation.metierQualification"
                    v-model="editableItem.besoinFormation.competenceMetiers"
                    @save="saveCompetenceMetiers"
                  >
                    <template #title>
                      <h6>Compétences de base (Cocher les de compétences)</h6>
                    </template>
                  </CompetenceMetierSelector>
                </div>
                <div class="col-sm-12 mb-4">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="col-sm-12 field md:col-12 field">
                      <h6 for="libelle">
                        Si la compétence n'est pas retrouvée dans la liste de choix, alors
                        mentionner la formation souhaitée ici
                      </h6>
                      <div class="col-12 md:col-12">
                        <PTextarea
                          rows="5"
                          cols="30"
                          v-model="editableItem.besoinFormation.autresCompetencesMetiers"
                          class="col-12"
                          optionLabel="label"
                          optionValue="value"
                          placeholder="Autres compétences"
                        />
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 mb-4">
                  <h6>
                    Durée des expériences professionnelles dans le métier objet du
                    microprojet
                  </h6>
                  <div class="row">
                    <div class="col-sm-8">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <div class="form-floating mb-3">
                            <input
                              v-model="editableItem.besoinFormation.durreExperience"
                              type="number"
                              class="form-control"
                              id="durreExperience"
                              name="durreExperience"
                            />
                            <label for="idee">
                              Durée des expériences professionnelles</label
                            >
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-4">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <c-select
                            id="uniteDuree"
                            name="uniteDuree"
                            option-label="label"
                            option-value="code"
                            :emit-object="false"
                            v-model="editableItem.besoinFormation.uniteDuree"
                            :options="uniteExperiences"
                            class="form-select"
                          >
                          </c-select>
                          <label for="uniteDuree">Unité expérence</label>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-sm-12 field">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div>Centre de formation</div>
                        <AsyncSearchInput
                          v-model="editableItem.besoinFormation.centreFormation"
                          :queryUrl="'centreFormations'"
                          :optionLabel="'libelle'"
                          placeholder="Rechercher un centre de formation"
                        />
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-12 mb-4">
                      <ValidationProvider rules="" v-slot="{ errors }">
                        <div class="col-sm-12 field md:col-12 field">
                          <div>
                            Si le centre de formation n'est pas retrouvé dans la liste,
                            saisir ici
                          </div>
                          <div class="col-12 md:col-12">
                            <PTextarea
                              rows="5"
                              cols="30"
                              v-model="editableItem.besoinFormation.autresCentreFormation"
                              class="col-12"
                              placeholder="Nom du centre"
                            />
                          </div>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- 
          <div class="card">
            <div class="card-header">
              <h6 for="libelle">
                Besoin en Formation Technique de Courte Durée du promoteur pour la mise en
                œuvre du microprojet
              </h6>
            </div>
            <div class="card-body">
             
            </div>
          </div> -->
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";
import MetierSelect from "@/components/common/MetierSelect.vue";
import CompetenceMetierSelector from "@/components/common/CompetenceMetierSelector.vue";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";

export default {
  components: {
    CYesOrNoInput,
    MetierSelect,
    CompetenceMetierSelector,
    AsyncSearchInput,
  },
  props: ["value"],
  mixins: [stepperFormMixin],
  data() {
    return {
      loading: false,
      editableItem: {
        ...this.value,
        besoinFormation: {
          metier: null,
          qualificationProfessionnelle: null,
          besoinEnFormationDuPromoteur: null,
          qualificationProfessionnelle: null,
          metierQualification: null,
          autreFormation: null,
          autresCentreFormation: null,
          centreFormation: null,
          durreExperience: null,
          uniteDuree: null,
          competenceMetiers: [],
        },
      },
    };
  },
  created() {
    this.loading = true;
    this.fetchBesoinFormations({
      extraQueryArg: `microProjet.id=${this.value.id}&size=1&sort=id,asc`,
    }).then((data) => {
      if (data.length != 0) {
        this.editableItem = {
          ...this.editableItem,
          besoinFormation: data[0],
        };
      }
      this.loading = false;
    });
    this.fetchQualificationProfessionnelles();
    this.fetchMoyenQualifications();
  },
  computed: {
    ...mapGetters({
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      moyenQualifications: "moyenQualification/moyenQualifications",
      uniteExperiences: "setting/uniteExperiences",
    }),
  },
  methods: {
    ...mapActions({
      fetchBesoinFormations: "besoinFormation/fetchBesoinFormations",
      createOrUpdateBesoinFormation: "besoinFormation/createOrUpdateBesoinFormation",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchMoyenQualifications: "moyenQualification/fetchMoyenQualifications",
      fetchMoyenQualifications: "moyenQualification/fetchMoyenQualifications",
    }),
    async saveCompetenceMetiers(competences) {
      await this.createOrUpdateBesoinFormation({
        microProjet: this.value,
        ...this.editableItem.besoinFormation,
        competenceMetiers: competences,
      });
    },
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateBesoinFormation({
        microProjet: this.value,
        ...this.editableItem.besoinFormation,
      });
      this.$emit("save", { ...this.editableItem });
    },
  },
};
</script>

<style scoped></style>
