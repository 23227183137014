<template>
<div>
  <langue-editor-dialog @save="saveLangue" :title="activeItem != null && activeItem.langue == null ? 'Ajouter langue' : 'Editer langue'" ref="editor" :item="activeItem" />
   <!--<div class="d-flex justify-content-between align-items-center mb-4">
    <h6 class="mb-0 text-uppercase flex-fill">Langues locales parlées</h6>
    <button @click.prevent="addLangue()" type="button" class="btn  btn-primary">Ajouter</button>
  </div> -->
  <div class="mb-5">
    <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">Langues</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->
    <div class="mb-2">
      <div v-for="(lang, index) in languesParles" :key="index"  class="d-flex justify-content-between mb-2 align-items-center">
        <div class="">
          <h6 class="mb-0">{{ lang.langue.nom }} : {{lang.niveau}}</h6>
        </div>
       <!-- <div>
          <a @click.prevent="updateLangue(lang)" class="btn btn-success rounded-0 btn-sm"><i class="bi bi-pencil-square"></i></a>
          <a  @click.prevent="deleteLangue(lang)" class="btn btn-danger rounded-0 btn-sm"><i class="bi bi-trash"></i></a>
        </div>-->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import LangueEditorDialog from '../editor/dialog/LangueEditorDialog.vue'
export default {
  components: { LangueEditorDialog },
  props: ['value'],
  data() {
    return {
      activeItem: {},
      languesParles:this.value
    }
  },
  methods: {
    updateLangue(lang){
      this.activeItem = { ...lang }
      this.$refs.editor.show()
    },
    addLangue(){
      // this.languesParles.push(lang)
      this.activeItem = { langue: null, niveau: null }
      this.$refs.editor.show()
    },
    saveLangue(lang){
      this.languesParles = this.languesParles.filter(item => item.langue.id != lang.langue.id)

      this.languesParles.push(lang)
      this.activeItem = { langue: null, niveau: null }
      this.$emit('input', this.languesParles)
    },
    deleteLangue(lang){
      this.languesParles = this.languesParles.filter(item => item.langue.id != lang.langue.id)
    },
  },
}
</script>