<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit()"
        class=""
        method="post"
        accept-charset="utf-8"
        action=""
      >
        <!-- <div class="row">
          <div class="col-sm-10">
            <label for="beninois">Etes-vous un Béninois ?</label>
          </div>
          <div class="col-sm-auto">
            <InputSwitch v-model="estBeninois" id="beninois" />
          </div>
        </div> -->
        <!-- <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select
              id="paysDeResidence"
              name="paysDeResidence"
              aria-label="Votre pays de naissance"
              option-label="nom"
              option-value="id"
              v-model="editableItem.paysDeResidence"
              :options="nations"
              class="form-select"
            >
            </c-select>
            <span
              v-for="(err, index) in errors"
              :key="index"
              class="text-danger"
              >{{ err }}</span
            >
            <label for="paysDeResidence">Pays de résidence</label>
          </div>
        </ValidationProvider> -->
        <Echelon v-model="editableItem" v-if="estBeninois" />

        <div class="row" v-else>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.lieuNaissance"
                  @input="handleInput"
                  type="text"
                  class="form-control"
                  id="lieuNaissance"
                  name="lieuNaissance"
                  placeholder="Votre email"
                />
                <label for="lieuNaissance">Lieu de naissance</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="field col-12 md:col-12">
              <label for="dateNais">Date de naissance</label>
              <p-calendar
                id="dateNais"
                class="col-12 md:col-12"
                :showIcon="true"
                size="sm"
                v-model="editableItem.dateNais"
                name="dateNais"
                :manualInput="false"
                dateFormat="dd/mm/yy"
              ></p-calendar>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <br />
        <PDivider />
        <block-langues
          v-model="editableItem.langues"
          :demandeur="editableItem"
          @change="handleValueChange"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import InputSwitch from "primevue/inputswitch";
import BlockLangues from "../BlockLangues.vue";
import Echelon from "@/components/common/echelon/Echelon.vue";

export default {
  props: ["value"],
  data() {
    return {
      editableItem: {},
      dateNais: this.value.dateNais,
    };
  },
  components: { InputSwitch, BlockLangues, Echelon },
  mixins: [setepperFormMixin],
  computed: {
    ...mapGetters({
      nations: "departement/nations",
    }),
    estBeninois() {
      // return this.editableItem.paysDeResidence != null && this.editableItem.paysDeResidence.code == 'BJ'
      return true;
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log(this.editableItem.langues);
        this.editableItem = { ...val };
      },
      deep: true,
    },
  },
  created() {
    this.fetchNations();
  },
  methods: {
    ...mapActions({
      fetchNations: "departement/fetchNations",
    }),
    handleInput(e) {
      if (e.target.name === "commune" || e.target.name === "departement") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
    handleValueChange(langues) {
      this.$emit("input", {
        ...this.editableItem,
        langues,
      });
    },
  },
};
</script>

<style scoped></style>
