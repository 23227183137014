<template>
  <div class="">
    <CrudTable
      :formTitle="'Edition d\'équipements microprojet'"
      :tableFields="crudTableFields"
      :crudUrl="'equipementMateriels'"
      :extraQueryArg="`microProjet.id=${editableItem.id}&typeEquipement=EQUIPEMENT`"
      :emptyReferentielItem="emptyEquipementCrudItem"
    >
      <template #listTitle>
        <h4>Liste des équipements microprojet</h4>
      </template>

      <template #formTitle="{}">
        <h5>Edition des équipements</h5>
      </template>

      <template #formContent="{ editableItem, updateValue }">
        <EquipementEditor :value="editableItem" @changed="(val) => updateValue(val)" />
      </template>
    </CrudTable>
    <PDivider />
    <CrudTable
      :formTitle="'Edition matériels et outillages'"
      :tableFields="crudTableFields"
      :crudUrl="'equipementMateriels'"
      :extraQueryArg="`microProjet.id=${editableItem.id}&typeEquipement=MATERIEL_ET_OUTILLAGE`"
      :emptyReferentielItem="emptyMaterielEtOutillageCrudItem"
    >
      <template #listTitle>
        <h4>Liste des matériels et outillages</h4>
      </template>
      <template #formContent="{ editableItem, updateValue }">
        <EquipementEditor :value="editableItem" @changed="(val) => updateValue(val)" />
      </template>
    </CrudTable>
    <PDivider />

    <CrudTable
      :formTitle="'Edition équipement de protection individuelle'"
      :tableFields="crudTableFields"
      :extraQueryArg="`microProjet.id=${editableItem.id}&typeEquipement=EQUIPEMENT_PROTECTION_INDIVIDUELLE`"
      :crudUrl="'equipementMateriels'"
      :emptyReferentielItem="emptyEquipementProtectionIndividuelleCrudItem"
    >
      <template #listTitle>
        <h4>Liste des Equipements de protection individuelle</h4>
      </template>
      <template #formContent="{ editableItem, updateValue }">
        <EquipementEditor :value="editableItem" @changed="(val) => updateValue(val)" />
      </template>
    </CrudTable>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <PTextarea
                  v-model="editableItem.processusDeProduction"
                  type="text"
                  class="form-control"
                  id="processusDeProduction"
                  name="processusDeProduction"
                  placeholder="Processus de production"
                  :autoResize="true"
                  rows="3"
                  cols="30"
                />
                <label for="processusDeProduction"
                  >Processus de production <i>(principaux produits/services)</i></label
                >
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import FfomCrudForm from "./ffom/FfomCrudForm.vue";
import EquipementEditor from "./equipement/EquipementEditor.vue";
export default {
  components: {
    CYesOrNoInput,
    CSelect,
    CrudTable,
    FfomCrudForm,
    EquipementEditor,
  },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({}),
    crudTableFields() {
      return [
        "#",
        { key: "designation", label: "Désignation" },
        { key: "caracteristiqueSommaire", label: "Caractéristiques sommaires" },
        { key: "fournisseurPotentiel", label: "Fournisseurs potentiels" },
        { key: "datePrevisonnelleAcquisition", label: "Date prévi. acqu.", isDate: true },
        {
          key: "pieceRechangeEnLocal",
          label: "Pièces de rechange au niveau local",
          isBoolean: true,
        },
        {
          key: "capaciteMaintenanceEnLocal",
          label: "Capacité de maintenance au niveau local",
          isBoolean: true,
        },
        "actions",
      ];
    },
    emptyCrudItem() {
      return {
        caracteristiqueSommaire: null,
        designation: null,
        fournisseurPotentiel: null,
        datePrevisonnelleAcquisition: null,
        equipementMaterielspieceRechangeEnLocal: null,
        equipementMaterielscapaciteMaintenanceEnLocal: null,
        microProjet: this.editableItem,
      };
    },
    emptyMaterielEtOutillageCrudItem() {
      return {
        ...this.emptyCrudItem,
        typeEquipement: "MATERIEL_ET_OUTILLAGE",
      };
    },
    emptyEquipementCrudItem() {
      return {
        ...this.emptyCrudItem,
        typeEquipement: "EQUIPEMENT",
      };
    },
    emptyEquipementProtectionIndividuelleCrudItem() {
      return {
        ...this.emptyCrudItem,
        typeEquipement: "EQUIPEMENT_PROTECTION_INDIVIDUELLE",
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
