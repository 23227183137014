<template>
  <div>
    <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
    <PCard class="shadow-0 border-0">
      <template #content>
        <template v-if="!loading">
          <div>
            <h2 class="mb-2">
              Points totalisés =
              <span class="text-primary">{{ evaluation.totalPoints }}</span>
            </h2>
            <template v-if="evaluationType === 'MOTIVATION_ET_CV'">
              <div class="card eval-box mb-3">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Lettre de motivation
                    <span
                      >{{ calculerPointMotivationCv(evaluation.motivationNote) }}%</span
                    >
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small>Cliquez sur pour cocher la note correspondante.</small>
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        <PChip>Motivation</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating
                          v-model="evaluation.motivationNote"
                          :stars="starsCount"
                        />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2"  :class="!domaine.checked? 'p-button-outlined' : ''" v-for="domaine in evaluation.domaines" :severity="domaine.checked? 'primary' : ''" :key="domaine.id" :label="domaine.nom" :icon="domaine.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="domaine.checked = !domaine.checked " /> -->
                </div>
              </div>

              <div class="card eval-box mb-3">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Curriculum Vitae[CV]
                    <span>{{ calculerPointMotivationCv(evaluation.cvNote) }}%</span>
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small>Cliquez sur pour cocher la note correspondante.</small>
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        <PChip>CV</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating v-model="evaluation.cvNote" :stars="starsCount" />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2"  :class="!domaine.checked? 'p-button-outlined' : ''" v-for="domaine in evaluation.domaines" :severity="domaine.checked? 'primary' : ''" :key="domaine.id" :label="domaine.nom" :icon="domaine.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="domaine.checked = !domaine.checked " /> -->
                </div>
              </div>
            </template>

            <template v-else>
              <div class="card eval-box mb-3">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Domaines
                    <span>{{ calculerPoint(evaluation.domaineEtudeNotes) }}%</span>
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small>Cliquez sur pour cocher les diplômes du candidat.</small>
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      v-for="note in evaluation.domaineEtudeNotes"
                      :key="note.id"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        <PChip>{{ note.domaineEtude.nom }}</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating v-model="note.note" :stars="starsCount" />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2"  :class="!domaine.checked? 'p-button-outlined' : ''" v-for="domaine in evaluation.domaines" :severity="domaine.checked? 'primary' : ''" :key="domaine.id" :label="domaine.nom" :icon="domaine.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="domaine.checked = !domaine.checked " /> -->
                </div>
              </div>

              <div class="card border-none mb-3 shadow-0 eval-box">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Langues
                    <span>{{ calculerPoint(evaluation.langueNotes) }}%</span>
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small>Cliquez sur pour cocher les langues du candidat.</small>
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      v-for="note in evaluation.langueNotes"
                      :key="note.id"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        <PChip>{{ note.langue.nom }}</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating v-model="note.note" :stars="starsCount" />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2" :class="!langue.checked? 'p-button-outlined' : ''" v-for="langue in evaluation.langues" :severity="langue.checked? 'primary' : ''" :key="langue.id" :label="langue.nom" :icon="langue.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="langue.checked = !langue.checked " /> -->
                </div>
              </div>

              <div class="card mb-3 eval-box">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Niveaux
                    <span>{{ calculerPoint(evaluation.niveauDiplomeNotes) }}%</span>
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small>Cliquez sur pour cocher les niveaux du candidat.</small>
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      v-for="(note, i) in evaluation.niveauDiplomeNotes"
                      :key="note.id"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        {{ i + 1 }}.
                        <PChip class="h6">{{ note.diplome.description }}</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating v-model="note.note" :stars="starsCount" />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2" :class="!niveau.checked? 'p-button-outlined' : ''" v-for="niveau in evaluation.niveaus" :severity="niveau.checked? 'primary' : ''" :key="niveau.id" :label="niveau.description" :icon="niveau.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="niveau.checked = !niveau.checked " /> -->
                </div>
              </div>

              <div class="card eval-box">
                <div class="card-header bg-white eval-box-title">
                  <h4 class="d-flex justify-content-between">
                    Pièces
                    <span>{{ calculerPoint(evaluation.typePieceNotes) }}%</span>
                  </h4>
                </div>
                <div class="card-body eval-box-content">
                  <div>
                    <small
                      >Cliquez sur pour cocher les types de pièces fournis par le
                      candidat.</small
                    >
                  </div>
                  <div v-if="loading" class="d-flex mb-2 mt-2">
                    <PSkeleton
                      class="me-2"
                      borderRadius="16px"
                      v-for="i in 5"
                      height="40px"
                      width="20%"
                      :key="i"
                    />
                  </div>
                  <ul class="list-group" v-else>
                    <li
                      v-for="(note, i) in evaluation.typePieceNotes"
                      :key="note.id"
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div class="h">
                        {{ i + 1 }}.
                        <PChip class="h6">{{ note.typePiece.libelle }}</PChip>
                      </div>
                      <span class="rounded-pill">
                        <PRating v-model="note.note" :stars="starsCount" />
                      </span>
                    </li>
                  </ul>
                  <!-- <PButton v-else class="p-button-rounded p-button-sm me-2 mt-2 border-none" :class="!piece.checked? 'p-button-outlined' : ''" v-for="piece in evaluation.typePieces" :severity="piece.checked? 'primary' : ''" :key="piece.id" :label="piece.libelle" :icon="piece.checked? 'pi pi-check-circle' : 'pi pi-circle'" @click.stop="piece.checked = !piece.checked " /> -->
                </div>
              </div>
            </template>

            <div class="d-flex justify-content-end">
              <PButton
                @click.stop="submitForm()"
                class="p-button-rounded p-button-sm me-2 mt-2 border-none"
                >Valider évaluation</PButton
              >
            </div>
          </div>
        </template>
      </template>
    </PCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    candidatureId: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      default: "PSidebar",
    },
    evaluationType: {
      type: String,
      validate: (value) => {
        return ["MOTIVATION_ET_CV", "NORMAL"].includes(value);
      },
      default: "NORMAL",
    },
  },
  computed: {
    pointPieces() {
      return this.calculerPoint(this.evaluation.typePieces);
    },
  },
  components: {},
  data() {
    return {
      evaluation: {},
      duration: 0,
      starsCount: 5,
      loading: true,
      showViewer: false,
    };
  },
  watch: {
    candidatureId: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    ...mapActions({
      fetchCandidatureEvaluation: "evaluation/fetchCandidatureEvaluation",
      updateEvaluation: "evaluation/updateEvaluation",
    }),
    loadData() {
      this.loading = true;
      this.evaluation = {};

      this.fetchCandidatureEvaluation({
        candidatureId: this.candidatureId,
      }).then((data) => {
        this.loading = false;
        this.evaluation = data;
        // this.evaluation = {
        //   ...data.offre,
        //   domaines: data.offre.domaines.map(d => ({ ...d, checked: false })),
        //   langues: data.offre.langues.map(l => ({ ...l, checked: false })),
        //   niveaus: data.offre.niveaus.map(n => ({ ...n, checked: false })),
        //   typePieces: data.offre.typePieces.map(tp => ({ ...tp, checked: false })),
        // }
      });
    },
    hide() {
      this.showViewer = false;
    },
    show() {
      this.loading = true;
      this.evaluation = {};
      this.showViewer = true;
    },
    calculerPointMotivationCv(point) {
      return (100 * point) / this.starsCount;
    },
    calculerPoint(items) {
      let points = 0.0;

      items.forEach((el) => {
        points += el.note | 0;
      });

      return ((100 * points) / (this.starsCount * items.length)).toFixed(2);
    },
    submitForm() {
      this.updateEvaluation({
        ...this.evaluation,
      }).then((data) => {
        console.log(data);
        this.evaluation = data;
        this.duration = 4000;
        this.$emit("change", data);
        this.$toast.info("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style>
.p-rating-star {
  color: pink !important;
  background: orange !important;
}

.p-sidebar-header {
  display: none;
}
</style>
