<template>
  <div class="row">
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PInputText
            v-model="editableItem.libelle"
            type="text"
            class="form-control"
            id="libelle"
            name="libelle"
            placeholder="Libelle"
            :autoResize="true"
          />
          <label for="libelle">Libelle</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.description"
            type="text"
            class="form-control"
            id="description"
            name="description"
            placeholder="Description"
            :autoResize="true"
            rows="3"
            cols="30"
          />
          <label for="description">Description</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-8">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.prixVente"
            type="number"
            class="form-control"
            id="prixVente"
            rows="10"
            cols="30"
            name="prixVente"
            placeholder="Prix de vente"
          />
          <label for="prixVente">Prix de vente</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.quantiteMoyenne"
            type="number"
            class="form-control"
            id="quantiteMoyenne"
            rows="10"
            cols="30"
            name="quantiteMoyenne"
            placeholder="Quantité moyenne/période "
          />
          <label for="quantiteMoyenne">Quantité moyenne/période </label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.observation"
            type="text"
            class="form-control"
            id="observation"
            :autoResize="true"
            rows="2"
            cols="30"
            name="observation"
            placeholder="Observation"
          />
          <label for="observation">Observation</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  watch: {
    // item: {
    //   handler(val) {
    //     this.editableItem = { ...this.item };
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {},
};
</script>
