<template>
  <div>
    <slot name="action">
      <b-button variant="warning" v-b-modal.modal-footer-sm
        >Ajouter idée d'entreprise</b-button
      >
    </slot>
    <modal
      id="modal-footer-sm"
      size="md"
      hide-footer
      ref="modal"
      @ok="ok"
      @cancel="cancel"
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row px-3">
                <div class="col-md-12 col-md-12">
                  <!-- <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <c-select id="metier" name="metier" option-label="libelle" option-value="id" v-model="editableItem.metier" :options="romes" class="form-select">
                      </c-select>
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      <label for="metier">Type de métier</label>
                    </div>
                  </ValidationProvider> -->
                  <h5>Dans quel métier?</h5>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form- mb-3">
                      <metier-select v-model="editableItem.metier" :options="romes">
                        <template #label>
                          <label for="metier">Type de métier</label>
                        </template>
                      </metier-select>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 col-md-12">
                  <h5>Quelle est votre idée d’entreprise?</h5>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <div class="form-floating mb-3">
                        <textarea
                          :value="editableItem.idee"
                          rows="8"
                          @input="handleInput"
                          type="text"
                          style="min-height: 150px"
                          v-uppercase
                          class="form-control"
                          id="idee"
                          name="idee"
                        >
                        </textarea>
                        <label for="idee">Idée d’entreprise</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 col-md-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <div class="form-floating mb-3">
                        <c-yes-or-no-input
                          v-model="editableItem.estQualifie"
                          question="Etes-vous qualifié(e) pour ce métier?"
                        />
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <template v-if="editableItem.estQualifie">
                  <div class="col-sm-12 col-md-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select
                          id="qualificationProfessionnelle"
                          name="qualificationProfessionnelle"
                          option-label="libelle"
                          option-value="id"
                          v-model="editableItem.qualificationProfessionnelle"
                          :options="qualificationProfessionnelles"
                          class="form-select"
                        >
                        </c-select>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <label for="qualificationProfessionnelle"
                          >Qualification pour le méter</label
                        >
                      </div>
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="
                      editableItem.qualificationProfessionnelle &&
                      editableItem.qualificationProfessionnelle.code == 'AUTRES'
                    "
                    class="col-sm-12 col-md-12"
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <div class="form-floating mb-3">
                          <input
                            :value="editableItem.autreQualificationProfessionnelle"
                            @input="handleInput"
                            type="text"
                            v-uppercase
                            class="form-control"
                            id="autreQualificationProfessionnelle"
                            name="autreQualificationProfessionnelle"
                          />
                          <label for="autreQualificationProfessionnelle"
                            >Nom de la qualification professionnelle</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </template>
                <template v-else-if="editableItem.estQualifie === false">
                  <div class="col-sm-12 col-md-12">
                    <h5>Comment compter vous vous qualifier?</h5>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select
                          id="moyenQualification"
                          name="moyenQualification"
                          option-label="libelle"
                          option-value="id"
                          v-model="editableItem.moyenQualification"
                          :options="moyenQualifications"
                          class="form-select"
                        >
                        </c-select>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <label for="moyenQualification">Moyen de qualification</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="
                      editableItem.moyenQualification &&
                      editableItem.moyenQualification.code == 'AUTRES'
                    "
                    class="col-sm-12 col-md-12"
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <div class="form-floating mb-3">
                          <input
                            :value="editableItem.autreMoyenQualification"
                            @input="handleInput"
                            type="text"
                            v-uppercase
                            class="form-control"
                            id="autreMoyenQualification"
                            name="autreMoyenQualification"
                          />
                          <label for="autreMoyenQualification"
                            >Votre moyen de qualification professionnelle</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" class="btn rounded-0" @click="cancel()"> Annuler </b-button>
        <b-button size="sm" class="btn rounded-0 btn btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CSelect from "@/components/CSelect.vue";
import Modal from "@/components/Modal.vue";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import MetierSelect from "../../../../../common/MetierSelect.vue";

// import  from '@/components/.vue'

export default {
  components: { CSelect, Modal, CYesOrNoInput, MetierSelect },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateExp: null,
      editableItem: this.item,
    };
  },
  created() {
    this.fetchRomes();
    this.fetchQualificationProfessionnelles();
    this.fetchMoyenQualifications();
  },
  watch: {
    item: {
      handler() {
        (this.dateExp = this.item.dateExp), (this.editableItem = { ...this.item });
      },
      deep: true,
    },
    editableItem(val, oldVal) {
      console.log(val, oldVal);
    },
    dateExp(val) {
      this.editableItem.dateExp = this.$moment(val).format("YYYY-MM-DD");
      this.$emit("input", this.editableItem);
    },
  },
  computed: {
    ...mapGetters({
      romes: "rome/romes",
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      moyenQualifications: "moyenQualification/moyenQualifications",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  methods: {
    ...mapActions({
      fetchRomes: "rome/fetchRomes",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchMoyenQualifications: "moyenQualification/fetchMoyenQualifications",
    }),
    handleInput(e) {
      if (e.target.name === "profession") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },

    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("canceled");
    },
  },
};
</script>
