<template>
  <div class="">
    <!-- {{ editableItem }} -->
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.nom"
                  @input="handleInput"
                  required
                  type="text"
                  class="form-control"
                  id="nom"
                  name="nom"
                  placeholder="Votre nom"
                />
                <label for="nom">Nom(s)</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.prenom"
                  @input="handleInput"
                  type="text"
                  class="form-control"
                  id="prenom"
                  name="prenom"
                  placeholder="Votre prénom"
                />
                <label for="prenom">Prénom(s)</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input :value="editableItem.libelleProfil" @input="handleInput" type="text" id="libelleProfil" name="libelleProfil" class="form-control"/>
                <label for="libelleProfil">Libellé profil</label>
                <small>Ex. Développeur Web</small>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required|max:350" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <textarea :value="editableItem.descriptionProfil" @input="handleInput" type="text" id="descriptionProfil" name="descriptionProfil" style="height: 240px" class="form-control"/>
                <label for="descriptionProfil">Description profil</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div>
        
        <!-- <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.nomPere"
                  @input="handleInput"
                  required
                  type="text"
                  class="form-control"
                  id="nomPere"
                  name="nomPere"
                  placeholder="Nom du père"
                />
                <label for="nomPere">Nom du père</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.prenomPere"
                  @input="handleInput"
                  type="text"
                  class="form-control"
                  id="prenomPere"
                  name="prenomPere"
                  placeholder="Prénom du père"
                />
                <label for="prenomPere">Prénom du père</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.nomMere"
                  @input="handleInput"
                  required
                  type="text"
                  class="form-control"
                  id="nomMere"
                  name="nomMere"
                  placeholder="Nom de la mère"
                />
                <label for="nomMere">Nom de la mère</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.prenomMere"
                  @input="handleInput"
                  type="text"
                  class="form-control"
                  id="prenomMere"
                  name="prenomMere"
                  placeholder="Prénom de la mère"
                />
                <label for="prenomMere">Prénom de la mère</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required|min:8" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.contactPere"
                  @input="handleInput"
                  required
                  type="number"
                  class="form-control"
                  id="contactPere"
                  name="contactPere"
                  placeholder="Nom du père"
                />
                <label for="contactPere">Contact du mère</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required|min:8" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.contactMere"
                  @input="handleInput"
                  type="number"
                  class="form-control"
                  id="contactMere"
                  name="contactMere"
                  placeholder="Contact de la mère"
                />
                <label for="contactMere">Contact de la mère</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div> -->
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: {},
      dateNais: this.value.dateNais,
    };
  },
  created() {
    // this.editableItem = this.value;
  },
  watch: {
    value: {
      handler() {
        this.editableItem = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
