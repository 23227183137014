<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <h5>Adresse et contacts</h5>
            <Adresse v-model="editableItem" />

            <h5 class="mt-3">Identifiant</h5>
            <IdentifiantMutation
              v-model="editableItem"
              :currentEmail="demandeur.email"
              :currentPhone="demandeur.phone"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MetierSelect from "../../../common/MetierSelect.vue";
import Adresse from "../../../register/demandeur/steps/Adresse.vue";
import IdentifiantMutation from "./IdentifiantMutation.vue";

export default {
  components: { MetierSelect, Adresse, IdentifiantMutation },
  props: ["value", "demandeur"],
  data() {
    return {
      editableItem: this.value,
      dateNais: this.value.dateNais,
    };
  },
  created() {},
  computed: {
    ...mapGetters({}),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {
    editableItem(val) {
      this.$emit("input", this.editableItem);
    },
  },
  methods: {
    ...mapActions({}),
    asyncFind(query) {
      this.isLoading = true;
      console.log(query);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
