/* eslint-disable brace-style */
<template>
  <div>
    <slot name="action">
      <b-button variant="warning" v-b-modal.modal-footer-sm>Ajouter</b-button>
    </slot>
    <modal
      id="modal-footer-sm"
      size="lg"
      hide-footer
      ref="modal"
      @ok="ok"
      @cancel="cancel"
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row px-3">
                <div class="col-sm-12 mb-4">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <input
                                :value="editableItem.poste"
                                @input="handleInput"
                                type="text"
                                class="form-control"
                                id="poste"
                                name="poste"
                              />
                              <label for="poste">Poste</label>
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-12 col-md-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <input
                                :value="editableItem.intitule"
                                @input="handleInput"
                                type="text"
                                class="form-control"
                                id="intitule"
                                name="intitule"
                              />
                              <label for="intitule">Nom de l'entreprise</label>
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-md-6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <!-- <select :value="editableItem.niveau" @input="handleInput" class="form-select" id="niveau" name="niveau" aria-label="Votre niveau">
                                <option :value="niveau.id" v-for="niveau in niveaux" :key="niveau.id">{{ niveau.libelle }}</option>
                              </select> -->
                            <c-select
                              id="typePosition"
                              name="position"
                              option-label="libelle"
                              option-value="id"
                              v-model="editableItem.typePosition"
                              :options="typePositions"
                              class="form-select"
                            >
                            </c-select>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                            <label for="typePosition">Position</label>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-6 col-md-6" v-if="showAutrePosition">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <input
                                :value="editableItem.autreposition"
                                @input="handleInput"
                                type="text"
                                class="form-control"
                                id="autreposition"
                                name="autreposition"
                              />
                              <label for="autreposition">Autre Position</label>
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <c-select
                              id="nature"
                              name="nature"
                              option-label="description"
                              option-value="id"
                              v-model="editableItem.nature"
                              :options="natureContracts"
                              class="form-select"
                            >
                            </c-select>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                            <label for="nature">Nature du contrat</label>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <c-select
                              id="competencePrincipale"
                              name="competencePrincipale"
                              option-label="intitule"
                              option-value="id"
                              v-model="editableItem.competencePrincipale"
                              :options="competences"
                              class="form-select"
                            >
                            </c-select>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                            <label for="competencePrincipale">Aptitude Principale</label>
                          </div>
                        </ValidationProvider>
                      </div>
                      <!-- <div class="col-sm-12 col-md-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <h6>Aptitudes secondaires</h6>

                            <multiselect
                              v-model="editableItem.competenceSecondaires"
                              id="competenceSecondaires"
                              name="competenceSecondaires"
                              tag-placeholder="Compétences secondaires"
                              placeholder="Compétences secondaires"
                              label="intitule"
                              track-by="id"
                              :options="competences"
                              :multiple="true"
                              :taggable="true"
                              @tag="addTag"
                            ></multiselect>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                          </div>
                        </ValidationProvider>
                      </div> -->
                      <div class="col-sm-12 col-md-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <h6>Métiers</h6>
                            <MetierSelect
                              :multiple="true"
                              v-model="editableItem.metiers"
                              :options="romes"
                            >
                              <template #label>
                                <label for="romes">Compétences</label>
                              </template>
                            </MetierSelect>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 mb-4">
                  <div class="row">
                    <div class="col-sm-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <!-- <label for="">Date de début</label> -->
                          <h6>Date de début</h6>
                          <p-calendar
                            id="dateDeb"
                            class="col-12 md:col-12"
                            :showIcon="true"
                            size="sm"
                            v-model="editableItem.dateDeb"
                            name="dateDeb"
                            view="month"
                            dateFormat="MM yy"
                            :max="maxDate"
                            :manualInput="false"
                            :yearNavigator="true"
                          ></p-calendar>

                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <!-- <label for="dateFin">Date de fin</label> -->
                          <h6>Date de fin</h6>
                          <p-calendar
                            id="dateFin"
                            class="col-12 md:col-12"
                            :showIcon="true"
                            size="sm"
                            v-model="editableItem.dateFin"
                            name="dateFin"
                            :max="maxDate"
                            view="month"
                            dateFormat="MM yy"
                            :yearNavigator="true"
                            :manualInput="false"
                          ></p-calendar>

                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <textarea
                      :value="editableItem.descriptionTache"
                      @input="handleInput"
                      rows="5"
                      placeholder="Description de la tâche"
                      class="form-control"
                      id="descriptionTache"
                      name="descriptionTache"
                    ></textarea>

                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" class="btn rounded-0" @click="cancel()"> Annuler </b-button>
        <b-button size="sm" class="btn rounded-0 btn btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CSelect from "@/components/CSelect.vue";
import Modal from "@/components/Modal.vue";
import Multiselect from "vue-multiselect";
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  components: { CSelect, Modal, Multiselect, MetierSelect },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: this.item,
      // autre: false,
      autrePosition: "",
    };
  },
  created() {
    this.fetchNatureContracts();
    this.fetchTypePositions();
    this.fetchCompetences();
    this.fetchDomaineEtudes();
    this.fetchRomes();
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
    editableItem(val, oldVal) {
      console.log(val, oldVal);
    },
    // "editableItem.typePosition": {
    //   handler() {
    //     if (this.editableItem.typePosition && this.editableItem.typePosition.id == 6) {
    //       this.autre = true;
    //       // this.editableItem.typePosition = this.emp.typePosition
    //     // eslint-disable-next-line brace-style
    //     } else {
    //       this.autre = false;
    //       // this.editableItem.typePosition = null;
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters({
      typePositions: "position/typePositions",
      domaines: "domaine/domaines",
      romes: "rome/romes",
      competences: "competence/competences",
      natureContracts: "nature/natures",
    }),
    showAutrePosition() {
      return this.editableItem.typePosition && this.editableItem.typePosition.id == 6;
    },
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  methods: {
    ...mapActions({
      fetchTypePositions: "position/fetchTypePositions",
      fetchCompetences: "competence/fetchCompetences",
      fetchNatureContracts: "nature/fetchNatures",
      fetchDomaineEtudes: "domaine/fetchDomaines",
      fetchRomes: "rome/fetchRomes",
    }),
    handleInput(e) {
      if (e.target.name === "profession") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
        // eslint-disable-next-line brace-style
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },

    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("canceled");
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        intitule: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>
