<template>
  <div class="">
    <CDetailViewer
      v-if="activeDemandeur"
      title="Fichier/CV du DE"
      ref="detailViewer"
      :item="activeDemandeur"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="CV">
          <DemandeurMonCv :checkHasCv="false" :demandeurId="activeDemandeur.id" />
        </PTabPanel>
        <PTabPanel header="Fiche d'enquête">
          <FicheAzoli
            v-if="activeDemandeur.azoli != null"
            :demandeurId="activeDemandeur.id"
          />
          <div v-else>Ce demandeur n'a pas de fiche d'enquête</div>
        </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <DossierValidationDialog
      :ref="'dossierValidator'"
      v-if="activeDemandeur.id"
      :demandeur="activeDemandeur"
      @save="validerFiche"
    ></DossierValidationDialog>

    <PDialog
      class="stepper-dialog"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>
                {{ title }} - N°000<span>{{ activite.id }}</span
                ><span
                  v-tooltip.left="activite.typeActivite.libelle"
                  class="text-uppercase text-truncate"
                  >[{{ activite.typeActivite.libelle }}]</span
                >
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
          <PMessage
            class="mb-0 p-0 msg-cloture"
            v-if="activite.statusActivite == 'CLOTUREE'"
            severity="error"
            >Ce rapport est déjà clôturé. Aucune modification de son contenu ne sera plus
            pris en compte</PMessage
          >
        </div>
      </template>

      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <ValidationObserver
          ref="observer0"
          v-if="activeStep.name == 'PRESENCE'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form0" method="post" @submit.prevent="handleSubmit()">
            <div class="row fw-bold">
              <div class="col">PRESENT: {{ stat.presentCount }}</div>
              <div class="col">ABSENT: {{ stat.absentCount }}</div>
              <div class="col">INCONNU: {{ stat.presenceInconnueCount }}</div>
              <div class="col">TOTAL: {{ stat.totalCount }}</div>
            </div>
            <PDivider />
            <div class="row">
              <div class="col-sm-12 field" v-if="editableItem.id != null">
                <searchable-item-activite-participant-selector
                  :listTitle="'Liste des participants'"
                  :referentielName="referentielName"
                  :tableFields="tableFields"
                  :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
                  v-model="participations"
                  :selectable="false"
                  :emptyReferentielItem="emptyReferentielItem"
                  @presenceChange="presenceChanged"
                >
                  <template #listTitle>
                    <h6 v-if="editableItem.statusPresence == 'TERMINE'">
                      Liste des participants
                    </h6>
                    <div v-else>
                      <!-- <h6>Liste des de présence à la séance</h6> -->
                      <!-- <div class="text-muted">Cocher les participants qui étaient présent à la séance.</div> -->
                      <PButton
                        @click="setAllPresent"
                        label="Tous étaient présents."
                        class="p-button-outlined"
                      />
                    </div>
                  </template>
                  <template #listDescription> </template>
                  <template #rowDetails="{ row }"> </template>
                  <template #actions="{ data }">
                    <!-- <button class="btn btn-sm py-1 " :class="{ 'btn-success': data.item.grilleReponses.length == 0, 'btn-Ppiosuccess': data.item.grilleReponses.length != 0, }"  @click="data.toggleDetails"><i class="bi bi-journal-check"></i></button> -->
                    <button
                      class="btn btn-sm py-1"
                      :class="{
                        'btn-outline-primary': data.item.grilleReponses.length == 0,
                        'btn-outline-success': data.item.grilleReponses.length != 0,
                      }"
                      @click="data.toggleDetails"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </template>
                </searchable-item-activite-participant-selector>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver
          ref="observer1"
          v-if="activeStep.name == 'EVALUATION'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form1" method="post" @submit.prevent="handleSubmit()">
            <div class="row fw-bold" v-if="editableItem.id != null && hasGrille">
              <div class="col">DENA: {{ stat.autonomieDenaCount }}</div>
              <div class="col">DESA: {{ stat.autonomieDesaCount }}</div>
              <div class="col">DEA: {{ stat.autonomieDeaCount }}</div>
              <div class="col">TOTAL: {{ stat.totalCount }}</div>
            </div>
            <PDivider />
            <div class="row">
              <template>
                <div class="col-sm-12 field">
                  <div class="h6 text- body-3">
                    Sélectionnez la grille à utiliser pour l'évaluation du candidat
                  </div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <c-select
                        id="grille"
                        name="grille"
                        option-label="libelle"
                        option-value="id"
                        v-model="editableItem.grille"
                        :options="grilles"
                        class="form-select"
                      >
                      </c-select>
                      <label for="libelle">Grille d'évaluation</label>
                    </div>
                  </ValidationProvider>
                </div>
                <div
                  v-if="
                    editableItem.id != null && !hasGrille && editableItem.grille != null
                  "
                  class="action"
                >
                  <button
                    @click.prevent="generateEvaluationRapport(editableItem)"
                    class="btn btn-primary"
                  >
                    Evaluer les participants suivant la grille
                    {{ editableItem.grille.libelle }}
                  </button>
                </div>

                <div class="col-sm-12 field" v-if="editableItem.id != null && hasGrille">
                  <searchable-item-activite-participant-selector
                    :listTitle="'Liste des participants'"
                    :referentielName="referentielName"
                    :tableFields="tableFields"
                    :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
                    v-model="participations"
                    :selectable="false"
                    :emptyReferentielItem="emptyReferentielItem"
                    @presenceChange="presenceChanged"
                  >
                    <template #listTitle>
                      <h6 v-if="editableItem.statusPresence == 'TERMINE'">
                        Liste des participants
                      </h6>
                      <div v-else>
                        <!-- <h6>Liste des de présence à la séance</h6> -->
                        <!-- <div class="text-muted">Cocher les participants qui étaient présent à la séance.</div> -->
                        <PButton
                          @click="setAllPresent"
                          label="Tous étaient présents."
                          class="p-button-outlined"
                        />
                      </div>
                    </template>
                    <template #listDescription> </template>
                    <template #rowDetails="{ row }">
                      <PTabView :activeIndex.sync="activeTab">
                        <PTabPanel header="Evaluation EVADA">
                          <PMessage
                            v-if="row.item.participant.ficheValide"
                            class="mb-0 p-0 msg-cloture"
                            severity="success"
                          >
                            Le dossié de {{ row.item.participant.nom }} a été validé avec
                            succès.
                          </PMessage>
                          <div
                            class="card"
                            v-if="row.item.participant.status != 'VALIDE'"
                          >
                            <div class="card-body">
                              <div>
                                Le dossié de {{ row.item.participant.nom }}
                                {{ row.item.participant.prenom }} doit d'abord être validé
                                avant de procéder à son évaluation. Son statut actuel est
                                {{ row.item.participant.status }}.
                              </div>
                              <PButton
                                @click="showDossierValidationDialog(row.item.participant)"
                                >Valider le dossier du DE</PButton
                              >
                            </div>
                          </div>
                          <grille-evaluation
                            v-else
                            :modules="modules"
                            :candidat="row.item"
                            @evaluationChange="evaluationSaved"
                          />
                        </PTabPanel>
                        <PTabPanel header="Confirmation de l'appréciation du DE">
                          <ScoreObservation v-model="row.item.participant" />
                        </PTabPanel>
                      </PTabView>
                    </template>
                    <template #actions="{ data }">
                      <button
                        v-tooltip.left="'Evaluation du DE'"
                        v-if="data.item.presence"
                        class="btn btn-sm py-1 me-3"
                        :class="{
                          'btn-outline-primary': data.item.grilleReponses.length == 0,
                          'btn-outline-success': data.item.grilleReponses.length != 0,
                        }"
                        @click="data.toggleDetails"
                      >
                        <i class="bi bi-journal-check"></i>
                      </button>
                      <button
                        v-tooltip.left="'Voir le dossier du DE'"
                        class="btn btn-sm btn-outline-info py-1"
                        @click="showDemandeurDossier(data.item)"
                      >
                        <i class="bi bi-info-square"></i>
                      </button>
                    </template>
                  </searchable-item-activite-participant-selector>
                </div>
              </template>
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
          <!-- <PMessage v-if="error" severity="error">{{ error }}</PMessage> -->
          <form
            ref="form"
            method="post"
            v-if="activeStep.name == 'RESUME'"
            @submit.prevent="handleSubmit()"
          >
            <div class="row">
              <template>
                <h5>{{ currentActiviteTitle }}</h5>
                <!-- <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                   
                    <input
                      :value="editableItem.titre"
                      @input="handleInput"
                      required
                      type="text"
                      class="form-control"
                      id="titre"
                      name="titre"
                      placeholder="Titre  du rapport"
                    />
                    <label for="titre">Titre du rapport</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider> -->

                <!-- <PMessage v-if="error" severity="error">{{ error }}</PMessage> -->
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <!-- <label for="titre mb-2">Résumé</label> -->
                  <div class="form-floating mb-3">
                    <PEditor
                      v-model="editableItem.description"
                      editorStyle="height: 120px"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </template>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <!-- <PButton label="Valider" icon="pi pi-check" @click="cloturerRapport" autofocus v-if="activeStepIndex >= steps.length-1"/> -->
        <PButton
          v-if="editableItem.tilte != null || activeStepIndex >= steps.length - 1"
          label="Clôturer le rapport"
          icon="pi pi-check"
          @click="cloturerRapport"
          class="p-button p-button-danger"
        />
        <PButton
          class="p-button p-button-success"
          label="Continuer plus tard"
          icon="pi pi-clock"
          @click="continueLater"
          autofocus
        />
        <!--         
        <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton v-if="modeEdition == 'PRESENCE'" label="Valider la liste de presence" icon="pi pi-times" @click="validerListePresence" class="p-button p-button-outlined p-button-primary"/>
        -->
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchableItemActiviteParticipantSelector from "../../common/SearchableItemActiviteParticipantSelector.vue";
import GrilleEvaluation from "../../../components/espace/common/GrilleEvaluation.vue";
import DStepper from "../../common/DStepper.vue";
import CDetailViewer from "../../common/CDetailViewer.vue";
import DemandeurMonCv from "../../../views/espace/demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../../../views/espace/conseiller/DemandeurDetailAzoli.vue";
import DossierValidationDialog from "../demandeur/DemandeurDossierValidationDialog.vue";
import ScoreObservation from "../../../components/espace/demandeur/dossier-validator-steps/ScoreObservation.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    activite: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SearchableItemActiviteParticipantSelector,
    GrilleEvaluation,
    DStepper,
    CDetailViewer,
    FicheAzoli,
    DemandeurMonCv,
    ScoreObservation,
    DossierValidationDialog,
  },
  data() {
    return {
      activeStepIndex: 0,
      display: false,
      activeDemandeur: {},
      ficheValide: false,
      currentActiviteTitle: null,
      activeTab: 0,
      stat: {
        presentCount: 0,
        absentCount: 0,
        presenceInconnueCount: 0,
        autonomieDeaCount: 0,
        autonomieDenaCount: 0,
        autonomieDesaCount: 0,
        autonomieInconnueCount: 0,
        totalCount: 0,
      },
      hasGrille: false,
      editableItem: {
        id: null,
        datePrevue: null,
        grille: null,
        statusPresence: null,
        participants: [],
      },
      error: null,
      participations: [],
      grille: { id: 1 },
      modules: [],
      submitting: false,
      loading: false,
    };
  },
  created() {
    // this.$confirm.require({
    //   message: '\'activité?',
    //   header: 'Confirmation',
    //   icon: 'pi pi-exclamation-triangle',
    //   acceptLabel: 'Je veux continuer plus tard!',
    //   rejectLabel: 'Je veux clôturer',
    //   accept: () => {
    //     //callback to execute when user confirms the action
    //   },
    //   reject: () => {
    //     //callback to execute when user rejects the action
    //   }
    // })
    this.fetchGrilles();
  },
  watch: {
    activite() {
      // this.editableItem = { ...this.activite }
      if (this.activite.id) {
        this.editableItem.titre = "RAPPORT/" + this.activite.titre;
        this.currentActiviteTitle = this.editableItem.titre;
        this.loadParticipationStatistics();
        this.fetchActiviteRapportActivite({
          activiteId: this.activite.id,
        }).then((data) => {
          this.editableItem = {
            ...data,
            // activite: { id: this.activite.id }
          };
          this.hasGrille = data.grille != null;
        });
      }
    },
    rapportActivite(val) {
      if (val && val.id != null) {
        this.hasGrille = val.grille != null;
        this.editableItem = {
          ...val,
        };
        if (this.hasGrille && this.modules.length == 0) {
          this.fetchGrilleModules({
            grilleId: val.grille.id,
          }).then((modules) => {
            this.modules = modules;
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      grilles: "grille/grilles",
      activites: "activite/activites",
      rapportActivite: "activitePlanifie/rapportActivite",
    }),
    steps() {
      let steps = [
        {
          name: "PRESENCE",
          label: "VALIDATION PRESENCE",
          subtitle: "Liste de présence à la séance",
          to: "#npiValidation",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
      ];

      if (
        this.activite != null &&
        this.activite.typeActivite != null &&
        this.activite.typeActivite.code == "ENTRETIEN_DIAGNOSTIC"
      ) {
        steps.push({
          name: "EVALUATION",
          label: "EVALUATION DES PARTICIPANTS",
          subtitle: "Evaluation des participants",
          to: "#verification-dossier",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        });
      }

      steps.push({
        name: "RESUME",
        label: "RAPPORT DE PARTICIPATION",
        subtitle: "Observations et points saillants",
        to: "#appreciation-dossier",
        completed: this.activeStepIndex >= 2,
        command: () => {},
      });

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    modeEdition() {
      return this.editableItem.statusPresence != "TERMINE" ? "PRESENCE" : "RAPPORT";
    },
    emptyReferentielItem() {
      return {
        titre: null,
        description: null,
        grille: null,
        code: null,
        activite: null,
      };
    },
    referentielName() {
      return "participation";
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "participant.nom", label: "Nom" },
        { key: "participant.prenom", label: "Prénom" },
        { key: "participant.phone", label: "Téléphone" },
        { key: "participant.commune.nom", label: "Commune" },
        { key: "presence", label: "Présence" },
      ];

      if (this.activeStep.name == "EVALUATION") {
        fields.push({ key: "classement", label: "D° Autonomie" });
      }

      if (this.activeStep.name == "PRESENCE") {
        fields.push({ key: "urlSignature", label: "Signature" });
        fields.push({ key: "motifAbsence", label: "Motif absence/Observation" });
      }

      if (
        this.editableItem.statusPresence == "TERMINE" &&
        this.activeStep.name == "EVALUATION"
      ) {
        fields = [...fields, { key: "actions", label: "Actions" }];
      }

      return fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    ...mapActions({
      fetchGrilles: "grille/fetchGrilles",
      fetchGrilleModules: "grille/fetchGrilleModules",
      fetchActiviteRapportActivite: "activitePlanifie/fetchActiviteRapportActivite",
      fetchActiviteParticipationStatistics:
        "activitePlanifie/fetchActiviteParticipationStatistics",
      createOrUpdateRapportActivite: "rapportActivite/createOrUpdateRapportActivite",
      cloturerRapportActivite: "rapportActivite/cloturerRapportActivite",
      validerDossier: "demandeur/validerDossier",
      renseignerPresence: "participation/renseignerPresence",
      updateParticipation: "participation/updateParticipation",
    }),
    loadParticipationStatistics() {
      this.fetchActiviteParticipationStatistics({
        activiteId: this.activite.id,
      }).then((data) => {
        this.stat = data;
      });
    },
    async showDossierValidationDialog(demandeur) {
      this.activeDemandeur = demandeur;
      this.$refs.dossierValidator.show();
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info(demandeur.estPPI ? "Fiche déjà validée!" : "CV déjà validé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error(demandeur.estPPI ? "Fiche non terminée" : "CV non terminé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: demandeur.estPPI
          ? `Voulez-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`
          : `Voulez-vous vraiment valider le CV de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            this.activeDemandeur.ficheValide = true;
            this.activeDemandeur.status = "VALIDE";
            this.$toast.success("Fiche validée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    showDemandeurDossier(item) {
      this.activeDemandeur = { ...item };
      this.$refs.detailViewer.show();
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      await this.validerListePresence();
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    evaluationSaved(participation) {
      this.loadParticipationStatistics();
    },
    presenceChanged(participation) {
      this.renseignerPresence({
        participationId: participation.id,
        ...participation,
        presence: participation.presence,
      }).then(() => {
        this.loadParticipationStatistics();
      });
    },
    participationChanged(participation) {
      this.updateParticipation({
        participationId: participation.id,
        ...participation,
      }).then(() => {
        this.loadParticipationStatistics();
      });
    },
    setAllPresent() {
      this.participations = this.participants.map((el) => ({ ...el, presence: true }));
    },
    extraQueryArgsParamBuilder() {
      return `activiteId=${this.activite.id}`;
    },
    createOrUpdateRapport(rapport) {
      this.submitting = true;
      return this.createOrUpdateRapportActivite(rapport).then((data) => {
        this.submitting = false;
        return data;
        // this.editableItem = {
        //   ...data
        // }
      });
    },
    validerListePresence() {
      return this.createOrUpdateRapport({
        ...this.editableItem,
        statusPresence: "TERMINE",
      }).then(() => {
        this.editableItem = {
          ...this.editableItem,
          statusPresence: "TERMINE",
        };
      });
    },
    generateEvaluationRapport(rapport) {
      this.createOrUpdateRapport(rapport).then((val) => {
        if (val && val.id != null) {
          this.hasGrille = val.grille != null;
          this.editableItem = {
            ...val,
          };
          if (this.hasGrille && this.modules.length == 0) {
            this.fetchGrilleModules({
              grilleId: val.grille.id,
            }).then((modules) => {
              this.modules = modules;
            });
          }
        }
      });
    },
    continueLater() {
      return this.createOrUpdateRapport({
        ...this.editableItem,
      }).then(() => this.hide());
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cloturerRapport() {
      this.editableItem.titre = this.currentActiviteTitle;
      if (this.editableItem.titre == null) {
        this.error = "Le titre du rapport n' pas été renseigné.";
        return;
      }

      if (this.editableItem.description == null) {
        this.error = "Le résumé du rapport n' pas été renseigné.";
        return;
      }

      if (this.editableItem.statusPresence != "TERMINE") {
        this.error = "La liste de présence à la séancee pas été renseigné.";
        return;
      }

      this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôturer le rapport d\'activité? 
         Une fois le rapport clôturé il pourra plus être modifié ultérieurement.
          Si vous envisagez des ajustements ultérieures sur ce rapport, utilisez plutôt le button <<Continuer plus tard>>.
         `,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Je veux clôturer",
        rejectLabel: "Annuler",
        accept: () => {
          this.cloturerRapportActivite(this.editableItem).then((rapport) => {
            this.$emit("save", rapport);
            this.hide();
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
