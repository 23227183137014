<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <piece-identite-dialog
        v-show="false"
        @save="savePiece"
        :title="
          activeItem != null && activeItem.piece == null
            ? 'Ajouter pièce'
            : 'Editer pièce'
        "
        ref="editor"
        :item="activeItem"
      />
      <slot name="header" :addPiece="addPiece">
        <h6 class="mb-0 text-uppercase flex-fill"></h6>
        <button @click.prevent="addPiece()" type="button" class="btn btn-primary">
          Ajouter
        </button>
      </slot>
    </div>
    <div class="mb-5">
      <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">Pieces</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->
      <template v-if="pieces.length != 0">
        <div class="mb-4 border py-2 px-2" v-for="(emp, index) in pieces" :key="index">
          <div class="d-flex justify-content-between mb-2 align-items-center">
            <div class="">
              <h5 class="fw-bold text-uppercase mb-0">
                {{ emp.typePiece.libelle }} |
                <span class="text-muted">{{ emp.numero || "pas numéro" }}</span>
              </h5>
              <small class="text-muted">{{ emp.piece }}</small>
            </div>
            <div>
              <a
                @click.prevent="updatePiece(emp)"
                class="btn btn-success rounded-0 btn-sm"
                ><i class="bi bi-pencil-square"></i
              ></a>
              <a @click.prevent="deletePiece(emp)" class="btn btn-danger rounded-0 btn-sm"
                ><i class="bi bi-trash"></i
              ></a>
            </div>
          </div>
          <div class="fw-" v-if="emp.dateExp">
            Expire le
            <span class="text-muted">{{ emp.dateExp | formatDate }}</span>
          </div>
          <div class="fw-" v-else>
            Expire le: <span class="text-muted">non précisé</span>
          </div>
        </div>
      </template>
      <div class="mb-4 bg-light" v-else>
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Aucun pièce à afficher</span>
          <button
            @click.prevent="addPiece()"
            type="button"
            class="btn btn-sm btn-outline-primary"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PieceIdentiteDialog from "./editor/dialog/PieceIdentiteDialog.vue";
export default {
  props: ["value", "azoli"],
  components: { PieceIdentiteDialog },
  data() {
    return {
      activeItem: {},
      pieces: [...this.value],
    };
  },
  watch: {
    pieces(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    emptyPiece() {
      return {
        pieces: null,
        typePiece: null,
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateAzoliPiece: "azoliPiece/createOrUpdateAzoliPiece",
      deleteAzoliPiece: "azoliPiece/deleteAzoliPiece",
    }),
    updatePiece(emp) {
      this.activeItem = { ...emp };
      this.$refs.editor.show();
    },
    addPiece() {
      // this.pieces.push(emp)
      this.activeItem = { ...this.emptyPiece };
      this.$refs.editor.show();
    },
    savePiece(emp) {
      this.createOrUpdateAzoliPiece({
        ...emp,
        beneficiaire: this.azoli,
      }).then((savedItem) => {
        emp = savedItem;
        this.pieces = this.pieces.filter(
          (item) => item.typePiece.code != emp.typePiece.code
        );

        this.pieces.push(emp);
        this.activeItem = { ...this.emptyPiece };
      });
    },
    deletePiece(emp) {
      this.deleteAzoliPiece(emp).then((el) => {
        this.pieces = this.pieces.filter((item) => item.typePiece.id != emp.typePiece.id);
      });
      // this.pieces = this.pieces.filter(item => item.typePiece.id != emp.typePiece.id)
    },
  },
};
</script>
