<template>
  <div>
    <slot name="action">
      <a href="#" class="btn btn-warning btn- text-center" @click="show()">
        <i class="bi bi-plus-circle"></i> Ajouter
      </a>
      <!-- <b-button variant="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Créer</b-button> -->
    </slot>
    <PDialog
      class="stepper-dialog"
      :header="'Editeur de contenu de prescription'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'scroll' }"
      :containerStyle="{ width: '60vw' }"
    >
      <template #header>
        <!-- <slot name="formTitle">
          <h5 class="modal-title" id="staticBackdropLabel">Référentiel</h5>
        </slot>
        <button
          type="button"
          class="style_btn btn-close"
          :data-bs-dismiss="modalRefName"
          @click.prevent="cancel"
          aria-label="Close"
        ></button> -->
        <div class="card px-0 border-0 py-0 my-0 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <slot name="formTitle">
                <div>
                  {{ title }}
                </div>
              </slot>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
          </div>
        </div>
      </template>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <slot
            name="formContent"
            :handleInput="handleInput"
            :editableItem="editableItem"
            :updateValue="updateValue"
          >
            <div>Aucun contenu pour le formulaire</div>
          </slot>
          <!-- <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div> -->
        </form>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <PButton
          class="p-button-suc"
          :label="'Valider'"
          icon="pi pi-check"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId();

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: {},
      modalRefName: "ref_" + uid(),
      display: false,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    setActiveItem(item) {
      this.editableItem = {
        ...item,
      };
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    updateValue(val) {
      console.log(val);
      this.editableItem = { ...val };
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
