<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <!-- <h6>Compétences</h6> -->
                  <StringListInput
                    v-if="editableItem"
                    v-model="editableItem"
                    :listeTitle="'Compétences'"
                    :placeholder="'Ajouter une compétence'"
                  />
                  <!-- <MetierSelect v-model="editableItem" :multiple="true" :options="metiers">
                  <template #label>
                    <label for="metier">Compétences</label>
                  </template>
                </MetierSelect> -->
                  <!-- <multiselect
                  v-model="editableItem"
                  id="competence"
                  name="competences"
                  tag-placeholder="Compétences"
                  placeholder="Compétences"
                  label="libelle"
                  track-by="id"
                  :options="metiers"
                  :multiple="true"
                  :taggable="true"
                ></multiselect> -->
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <Divider />
          <div class="d-grid gap-2 mt-3">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import MetierSelect from "../../../../../common/MetierSelect.vue";
import StringListInput from "../../../../../common/StringListInput.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Array,
      require: true,
    },
  },
  mixins: [],
  components: { Modal, MetierSelect, StringListInput },
  data() {
    return {
      editableItem: this.item,
    };
  },
  created() {},
  watch: {
    item() {
      this.editableItem = [...this.item];
    },
  },
  computed: {
    ...mapGetters({
      metiers: "rome/romes",
    }),
  },
  methods: {
    ...mapActions({
      fetchRomes: "rome/fetchRomes",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
      this.$emit("change", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.$emit("change", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
