<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit()"
        class=""
        method="post"
        accept-charset="utf-8"
        action=""
      >
        <block-qualifications
          v-model="editableItem.qualifications"
          @change="handleValueChange"
          :demandeur="editableItem"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import BlockQualifications from "../BlockQualifications.vue";

export default {
  components: { BlockQualifications },
  props: ["value"],
  data() {
    return {
      editableItem: {},
    };
  },
  watch: {
    value: {
      handler() {
        this.editableItem = this.value;
      },
      deep: true,
    },
  },
  mixins: [setepperFormMixin],
  // watch:{
  //   editableItem:{
  //     handler(){
  //      this.$emit('input', this.editableItem)
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    handleValueChange(qualifications) {
      this.$emit("input", {
        ...this.editableItem,
        qualifications,
      });
    },
  },
};
</script>

<style scoped></style>
