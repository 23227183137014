/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow- me-2"
                      v-model="sexeDe"
                      :options="sexeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurVulnerabilite"
                      :options="demandeurVulnerabiliteOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Vulnérabilité'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurType"
                      :options="demandeurTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type de DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="status"
                      :options="statusOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut Microprojet'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="mxSubmitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
              <div class="row p-fluid grid">
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateDeb">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateFin">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                    v-if="option.visible"
                    :key="index"
                  >
                    <label :for="option.labelField">{{ option.label }}</label>

                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
              <div class="d-flex justify-content-end">
                <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
                <PButton
                  class="p-button-danger p-button- py-1 p-button-sm ms-1"
                  @click.prevent.stop="reinitialiserFiltres"
                >
                  Réinitialiser les filtres
                </PButton>
              </div>
            </div>
          </transition>
        </div>
        <p-divider />
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des affectations</h4>
          </div>
          <div class="dropdown">
            <button
              v-if="$can('transfer', 'FinOc')"
              class="btn ms-4 style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Affectation
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li v-if="$can('transferToAgent', 'FinOc')" class="border">
                <conseiller-selector
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="conseillerSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter à des conseillers</a
                    >
                  </template>
                </conseiller-selector>
              </li>
              <li v-if="$can('transferToAntenne', 'FinOc')" class="border">
                <antenne-selector-dialog
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="antenneSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter vers une antenne</a
                    >
                  </template>
                </antenne-selector-dialog>
                <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
              </li>
            </ul>
          </div>
          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton
              label="Exporter"
              :model="exportActions"
              class="p-button-outlined p-button-primary"
            />
          </div>
          <div class="btn-group ms-4">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Affichage mode tableau"
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-success': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-success': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
      </template>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    <PBadge :value="de.status" :severity="getStatusColor(de.status)" />
                    <!-- <azoli-status-badge :de="de" v-tooltip.top="`${de.validePar != null? 'NPI validé ' + de.validePar.nomPrenom : 'Non validé'}` " /> -->
                  </div>
                </template>
                <template #cell(createdDate)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    {{ $moment(de.createdDate).format("DD/MM/YYYY") }}
                  </div>
                </template>

                <template #cell(tauxRemplissage)="{ item }">
                  {{ item.tauxRemplissage }}%
                </template>
                <template #cell(npi)="{ item: de }">
                  <span v-if="de.npiValide == null">{{ de.npi }}</span>
                  <span
                    v-else
                    class="text-uppercase"
                    v-tooltip.top="
                      `NPI validé ${de.validePar != null ? de.validePar.nomPrenom : ''}`
                    "
                    style="font-size: 1.2rem"
                    v-badge.primary="de.npiValide ? 'Ok' : ''"
                    >{{ de.npi }}</span
                  >
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="Prise En Charge">
                        <PriseEnCharge :demandeurId="row.item.beneficiaire.id" />
                      </PTabPanel>
                      <PTabPanel header="CV">
                        <DemandeurMonCv
                          :checkHasCv="false"
                          :demandeurId="row.item.beneficiaire.id"
                        />
                      </PTabPanel>
                      <PTabPanel header="Fiche d'enquête">
                        <!-- v-if="row.item.beneficiaire != null" -->
                        <FicheAzoli :demandeurId="row.item.beneficiaire.id" />
                        <!-- <div v-else>Ce demandeur n'a pas de fiche d'enquête</div> -->
                      </PTabPanel>
                      <PTabPanel header="Tâches plannifiées">
                        <ActiviteAnpeList
                          :extraQueryArg="`participantId=${row.item.beneficiaire.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Liste programmes">
                        <ParticipationProgrammeList
                          :extraQueryArg="`participantId=${row.item.beneficiaire.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Consignes">
                        <ConsigneList
                          :extraQueryArg="`participantId=${row.item.beneficiaire.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Prescriptions">
                        <!-- <PrescriptionList
                          :extraQueryArg="`participantId=${row.item.id}`" -->
                        />
                      </PTabPanel>
                      <PTabPanel header="Placements"> Content III </PTabPanel>
                      <PTabPanel header="Screening">
                        <Screening :microprojetId="row.item.id" />
                      </PTabPanel>
                      <PTabPanel header="Candiatures"> </PTabPanel>
                      <PTabPanel header="Payements"> </PTabPanel>
                      <PTabPanel header="Statistiques"> Content III </PTabPanel>
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a>
                    <button
                      v-tooltip.left="'Valider le microprojet'"
                      @click.prevent="showDetailDialog(row.item)"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        :class="{ 'text-danger': row.item.status == 'VALIDE' }"
                        class="bi bi-clipboard-check"
                      ></i>
                    </button>
                    <button
                      v-tooltip.left="'Voir détail du microprojet'"
                      @click.prevent="showDetailDialog(row.item)"
                      class="btn btn-outline-info btn-sm"
                    >
                      <i
                        :class="{ 'text-danger': row.item.status == 'VALIDE' }"
                        class="bi bi-eye"
                      ></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";

import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import ParticipationProgrammeList from "./ParticipationProgrammeList.vue";
import PriseEnCharge from "./PriseEnCharge.vue";
import DemandeurMonCv from "../demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../conseiller/DemandeurDetailAzoli.vue";
import MicroprojetDetail from "./MicroprojetDetail.vue";

import { FILE_UPLOAD_API_URL } from "../../../config/api-config";
import MicroprojetEditor from "../../../components/espace/common/prise-en-charge/editor/MicroprojetEditor.vue";
import Screening from "@/views/espace/entreprise/screening/Screening.vue";
import MicroprojetStatistic from "../../../components/statistic/microprojet/MicroprojetStatistic.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeStatistic,
    Screening,
    FicheAzoliReport,
    FicheAzoli,
    MicroprojetDetail,
    ReportHeader,
    ConseillerSelector,
    DemandeurMonCv,
    AntenneSelectorDialog,
    ActiviteAnpeList,
    ConsigneList,
    CDetailViewer,
    PrescriptionList,
    ParticipationProgrammeList,
    AzoliStatusBadge,
    PriseEnCharge,
    CAutoComplete,
    MicroprojetEditor,
    AsyncMultiSelect,
    MicroprojetStatistic,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PPI" },
        { value: true, label: "CI" },
        { value: false, label: "INSTRUIT" },
      ],
      demandeurType: null,
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "EN_ATTENTE", label: "EN_ATTENTE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      status: null,
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PLACE" },
        { value: false, label: "NON PLACE" },
      ],
      statutPlacement: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des microprojets",
                subtitle: "Liste des microprojets",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "microprojets/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des microprojets.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des microprojets",
                subtitle: "Liste des microprojets",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "microprojets/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des microprojets.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["titre"],
      searchFieldsOptions: [
        {
          label: "Titre",
          value: "titre",
          visible: true,
        },
        {
          label: "Bénéficiaire",
          value: "beneficiaire.nomPrenom",
          visible: true,
        },
        {
          label: "NPI",
          value: "beneficiaire.npi",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "programme.id",
          value: [],
          label: "Programme",
          dropdown: true,
          labelField: "libelle",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "programmes",
          visible: true,
        },
        {
          column: "secteur.id",
          value: [],
          label: "Secteur d'Activité",
          dropdown: true,
          labelField: "libelle",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "naemas",
          visible: true,
        },
        {
          column: "metier.id",
          value: "",
          label: "Métier",
          valueKey: "id",
          dropdown: true,
          dropdown: true,
          displayMode: "classic",
          labelField: "libelle",
          queryUrl: "romes",
          visible: true,
        },
        {
          column: "departement.id",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "mega-menu",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "commune.id",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "arrondissement.id",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement.nom",
            value: null,
            label: "Département",
          },
          {
            column: "commune.nom",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeProjet: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeProjet = {
          ...val,
          ...val.azoli, // écrase id du demandeur par id azoli
          id: val.id, //remettre Id du demandeur
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "dossier.nom", label: "Nom" },
        { key: "dossier.prenom", label: "Prénom" },
        { key: "dossier.sexe", label: "Sexe" },
        { key: "dossier.departement.nom", label: "Département" },
        { key: "dossier.commune.nom", label: "Commune" },
        { key: "transfereA.nomPrenom", label: "Transf. A." },
        { key: "transfereA.fonction.nom", label: "Transf A. Fonct." },
        { key: "transfereA.nom", label: "Programme" },
        { key: "transfereA.nom", label: "Programme" },
        { key: "tauxRemplissage", label: "TauxRemp." },
        { key: "fnmAccessCount", label: "Nbre.Access" },
      ];

      fields = fields.concat([
        { key: "status", label: "Statut" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ]);

      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      validerMicroprojet: "microprojet/validerMicroprojet",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    getStatusColor(status) {
      let color = "primary";
      if (status == "VALIDE") {
        color = "success";
      }
      return color;
    },
    editMicroprojet(stepName) {
      this.$refs.microprojetEditor.showFromStep(stepName);
    },
    saveMicroprojet(item) {
      this.createOrUpdateMicroprojet(item)
        .then((data) => {
          this.$toast.success("Opération réussie", {
            position: "top-right",
            duration: 10000,
          });
          this.$refs.microprojetDetailViewer.setMicroprojet(data);
        })
        .catch((err) => this.$toast.error("Une erreur s'est produite." + err));
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "transfertDossier",
        search: this.search,
        fetcherMethod: "transfertDossier/fetchTransfertDossiers",
        dataGetter: "transfertDossier/transfertDossiers",
        paginationGetter: "transfertDossier/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = "";
          // let paramPlus = this.mxAddQueryParam("beneficiaire.categorieDemandeur", this.demandeurType);
          //   paramPlus = this.mxAddQueryParam(
          //     "beneficiaire.estVulnerable",
          //     this.demandeurVulnerabilite,
          //     paramPlus
          //   );
          //   paramPlus = this.mxAddQueryParam("beneficiaire.sexe", this.sexeDe, paramPlus);
          //   paramPlus = this.mxAddQueryParam("status", this.status, paramPlus);
          //   paramPlus = this.mxAddQueryParam(
          //     "beneficiaire.estPlace",
          //     this.statutPlacement,
          //     paramPlus
          //   );

          let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },

    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(de) {
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id,
      }).then(() => {
        console.log(this.$refs.html2Pdf);
        this.$refs.html2Pdf.generatePdf();
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeProjet = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDetailDialog(demandeur) {
      this.activeProjet = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    remplirFiche(demandeur) {
      if (!demandeur.estPPI) {
        this.$toast.info(
          "Vous ne pouvez pas remplir de fiche pour ce demandeur INSTRUIT.",
          {
            position: "top-right",
            duration: 8000,
          }
        );
      } else {
        this.$router.push({
          name: "espace.ce.azoli.edit",
          params: { demandeurId: demandeur.id },
        });
      }
    },
    async showDossierValidationDialog(micro) {
      this.$refs.detailViewer.hide();
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment le microprojet ${micro.titre.toUpperCase()}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerMicroprojet({
            ...micro,
            status: "VALIDE",
          }).then((data) => {
            this.$toast.success("Microprojet validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.mxReplayQuery();
          });
        },
      });
      // this.activeProjet = { ...demandeur }
      // this.$refs.microprojetValidator.show()
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
