<template>
  <div class="">
    <PDialog :header="title" :modal="true" :visible.sync="display" :containerStyle="{width: '40vw'}">
        <h6 v-if="demandeur.estPPI" class="mb-4">
          Ce PPI avait opté pour le type d'emploi <PTag severity="danger">{{ demandeur.azoli.typeEmploi }}</PTag> sur sa fiche d'enquête.
        </h6>

        <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
            <div class="row">
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <c-select
                      id="decision"
                      name="decision"
                      placeholder="decision"
                      option-label="label"
                      option-value="value"
                      v-model="editableItem"
                      :options="decisions"
                      class="form-select"
                    >
                    </c-select>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                    <label for="decision">Décision</label>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <template #footer>
          <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text"/>
          <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
        </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    demandeur: {
      type: Object,
      required: true
    },
    value: {
        type: String,
        required: true
    }
  },
  components: {
  },
  data () {
    return {
      display: false,
      editableItem: this.value == null && this.demandeur.estPPI && ['SALARIE', 'INDEPENDANT'].includes(this.demandeur.azoli.typeEmploi)? { value: this.demandeur.azoli.typeEmploi, label: this.demandeur.azoli.typeEmploi } : { value: this.value, label: this.value }
    }
  },
  created () {
    this.fetchStatutProfessionnels()
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      statutProfessionnels: 'statutProfessionnel/statutProfessionnels'
    }),
    decisions () {
      return [
        { value: 'SALARIE', label: 'SALARIE' },
        { value: 'INDEPENDANT', label: 'INDEPENDANT' },
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchStatutProfessionnels: 'statutProfessionnel/fetchStatutProfessionnels'
    }),
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    ok () {
      this.$emit('saved', this.editableItem)
      this.editableItem = {}
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('saved', this.editableItem)
      this.hide()
    },
  }
}
</script>