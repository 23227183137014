<template>
  <div class="row mt-3">
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('NOUVEAU')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-danger': activeBlock == 'NOUVEAU' }"
      >
        <div class="flex-shrink-0">
          <div
            class="bg-danger style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-white"><i class="bi-bar-chart"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" :class="{ active: activeBlock == 'NOUVEAU' }">
          <h6 class="my-0 text-uppercase">SANS DOSSIER</h6>
          <small>Inscrits non traité</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="sansDossierCount" :options="options" />
          </h2>
        </div>
      </div>
      <div class="bg-block rounded px-3 py-2 border">
        <div class="d-flex justify-content-between">
          <div class="">
            PPI: <span class="text-muted fs-5">{{ deCount.ppiNouveauCount }}</span>
          </div>
          <div>
            INST: <span class="text-muted fs-5"> {{ deCount.instruitNouveauCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('EN_COURS')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-info': activeBlock == 'EN_COURS' }"
      >
        <div class="flex-shrink-0">
          <div
            class="bg-info style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-white"><i class="bi-broadcast"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0">EN COURS</h6>
          <small>Fiche inachevée</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="enCoursCount" :options="options" />
          </h2>
        </div>
      </div>
      <div class="bg-block rounded px-3 py-2 border">
        <div class="d-flex justify-content-between">
          <div class="">
            PPI: <span class="text-muted fs-5">{{ deCount.ppiEnCoursCount }}</span>
          </div>
          <div>
            INST: <span class="text-muted fs-5"> {{ deCount.instruitEnCoursCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('TERMINE')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-primary': activeBlock == 'TERMINE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="bg-primary style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-white"><i class="bi-bag-check"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0 text-uppercase">TERMINE</h6>
          <small>Fiche renseignée</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="termineCount" :options="options" />
          </h2>
        </div>
      </div>
      <div class="bg-block rounded px-3 py-2 border">
        <div class="d-flex justify-content-between">
          <div class="">
            PPI: <span class="text-muted fs-5">{{ deCount.ppiTermineCount }}</span>
          </div>
          <div>
            INST: <span class="text-muted fs-5"> {{ deCount.instruitTermineCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('VALIDE')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-success': activeBlock == 'VALIDE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="bg-success style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-white"><i class="bi-bookmark"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0 text-uppercase">Validé</h6>
          <small>Fiche validée</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="valideCount" :options="options" />
          </h2>
        </div>
      </div>
      <div class="bg-block rounded px-3 py-2 border">
        <div class="d-flex justify-content-between">
          <div class="">
            PPI: <span class="text-muted fs-5">{{ deCount.ppiValideCount }}</span>
          </div>
          <div>
            INST: <span class="text-muted fs-5"> {{ deCount.instruitValideCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock(null)"
        ripple
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
      >
        <div class="flex-shrink-0">
          <div
            class="bg-secondary style_level_offre d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-white"><i class="bi-broadcast"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0">TOTAL</h6>
          <small>Disponibles</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="totalCount" :options="options" />
          </h2>
        </div>
      </div>
      <div class="bg-block rounded px-3 py-2 border">
        <div class="d-flex justify-content-between">
          <div class="">
            PPI: <span class="text-muted fs-5">{{ deCount.ppiTotalCount }}</span>
          </div>
          <div>
            INST: <span class="text-muted fs-5"> {{ deCount.instruitTotalCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ICountUp from "vue-countup-v2";

export default {
  props: {
    dateDeb: {
      type: [String, Date],
    },
    dateFin: {
      type: [String, Date],
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    ICountUp,
  },
  data() {
    return {
      delay: 5000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      deCount: {
        ppiNouveauCount: 0,
        ppiEnCoursCount: 0,
        ppiTermineCount: 0,
        ppiValideCount: 0,
        ppiTotalCount: 0,
        instruitNouveauCount: 0,
        instruitEnCoursCount: 0,
        instruitTermineCount: 0,
        instruitValideCount: 0,
        instruitTotalCount: 0,
        totalCount: 0,
      },
      activeBlock: null,
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dateDeb() {
      this.loadData();
    },
    dateFin() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      // deCount: 'statistic/deCount',
    }),
    sansDossierCount() {
      // return this.deCount['NOUVEAU'] || 0
      return (
        (this.deCount.ppiNouveauCount || 0) + (this.deCount.instruitNouveauCount || 0)
      );
    },
    enCoursCount() {
      // return this.deCount['EN_COURS'] || 0
      return (
        (this.deCount.ppiEnCoursCount || 0) + (this.deCount.instruitEnCoursCount || 0)
      );
    },
    termineCount() {
      return (
        (this.deCount.ppiTermineCount || 0) + (this.deCount.instruitTermineCount || 0)
      );
      // return this.deCount['TERMINE'] || 0
    },
    valideCount() {
      // return this.deCount['VALIDE'] || 0
      return (this.deCount.ppiValideCount || 0) + (this.deCount.instruitValideCount || 0);
    },
    totalCount() {
      return this.deCount.totalCount || 0;
      // return this.deCount['TOTAL'] || 0
    },
  },
  methods: {
    ...mapActions({
      fetchDeCount: "statistic/fetchDeCount",
    }),
    selectBlock(status) {
      this.activeBlock = this.activeBlock != status ? status : null;
      this.$emit("selectionChange", this.activeBlock);
    },
    reload() {
      this.loadData();
    },
    loadData() {
      this.fetchDeCount({
        // createdDate: this.dateDeb,
        // createdDate: this.dateFin,
        extraQueryArg: this.extraQueryArg,
      }).then((data) => {
        this.deCount = data;
      });
    },
  },
};
</script>

<style scoped>
.bg-block {
  background: #ebe2c3;
}
</style>
