<template>
  <div>
    <slot name="action">
      <b-button variant="warning" v-b-modal.modal-footer-sm>Ajouter</b-button>
    </slot>
    <modal
      id="modal-footer-sm"
      hide-footer
      ref="modal"
      @ok="ok"
      @cancel="cancel"
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row px-3">
                <div class="col-sm-12 mb-4">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <input
                                :value="editableItem.centreDeFormation"
                                @input="handleInput"
                                type="text"
                                class="form-control"
                                id="centreDeFormation"
                                name="centreDeFormation"
                              />
                              <label for="centreDeFormation"
                                >Etablissement/Université/Faculté</label
                              >
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-6">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <c-select
                                id="pays"
                                name="pays"
                                option-label="nom"
                                option-value="id"
                                v-model="editableItem.pays"
                                :options="nationalites"
                                class="form-select"
                              >
                              </c-select>
                              <label for="pays">Pays</label>
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-6">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <div class="form-floating mb-3">
                              <input
                                :value="editableItem.ville"
                                @input="handleInput"
                                type="text"
                                class="form-control"
                                id="ville"
                                name="ville"
                              />
                              <label for="ville">Ville/Village</label>
                              <span
                                v-for="(err, index) in errors"
                                :key="index"
                                class="text-danger"
                                >{{ err }}</span
                              >
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <!-- <select :value="editableItem.niveau" @input="handleInput" class="form-select" id="niveau" name="niveau" aria-label="Votre niveau">
                              <option :value="niveau.id" v-for="niveau in niveaux" :key="niveau.id">{{ niveau.libelle }}</option>
                            </select> -->
                            <c-select
                              id="niveau"
                              name="niveau"
                              option-label="description"
                              option-value="code"
                              v-model="editableItem.niveau"
                              :options="niveaux"
                              class="form-select"
                            >
                            </c-select>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                            <label for="niveau">Niveau diplôme</label>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-floating mb-3">
                            <c-select
                              id="domaine"
                              name="domaine"
                              option-label="description"
                              option-value="id"
                              v-model="editableItem.domaine"
                              :options="domaines"
                              class="form-select"
                            >
                            </c-select>
                            <span
                              v-for="(err, index) in errors"
                              :key="index"
                              class="text-danger"
                              >{{ err }}</span
                            >
                            <label for="domaine">Domaine d'étude</label>
                          </div>
                        </ValidationProvider>
                      </div>
                      <!-- <div class="col-sm-12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                          <div class="form-floating mb-3">
                            <c-select id="competence" name="competence" option-label="intitule" option-value="id" v-model="editableItem.competence" :options="competences" class="form-select">
                            </c-select>
                            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                            <label for="competencePrincipale">Compétence Principale</label>
                          </div>
                        </ValidationProvider>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 mb-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.diplome"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="diplome"
                        name="diplome"
                        placeholder="Diplôme obtenu"
                      />
                      <label for="diplome">Diplôme obtenu</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 mb-4">
                  <div class="row">
                    <div class="col-sm-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <h6>Date de début</h6>
                          <p-calendar
                            id="dateDeb"
                            class="col-12 md:col-12"
                            :showIcon="true"
                            size="sm"
                            v-model="editableItem.dateDeb"
                            name="dateDeb"
                            :max="maxDate"
                            view="month"
                            dateFormat="MM yy"
                            :manualInput="true"
                          ></p-calendar>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <h6>Date de fin</h6>
                          <p-calendar
                            id="dateFin"
                            class="col-12 md:col-12"
                            :showIcon="true"
                            size="sm"
                            v-model="editableItem.dateFin"
                            name="dateFin"
                            :max="maxDate"
                            :manualInput="false"
                            view="month"
                            dateFormat="MM yy"
                          ></p-calendar>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" class="btn rounded-0" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm" class="btn rounded-0 btn btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CSelect from "@/components/CSelect.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: { CSelect, Modal },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: {},
    };
  },
  created() {
    this.fetchInstructions();
    this.fetchCompetences();
    this.fetchNiveauDiplomes();
    this.fetchDomaineEtudes();
    this.fetchNationalites();
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item }
      },
      deep: true,
    },
    editableItem(val, oldVal) {
      console.log(val, oldVal);
    },
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      domaines: "domaine/domaines",
      nationalites: "nationalite/nationalites",
      competences: "competence/competences",
      niveaux: "niveau/niveaux",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
      fetchCompetences: "competence/fetchCompetences",
      fetchNationalites: "nationalite/fetchNationalites",
      fetchNiveauDiplomes: "niveau/fetchNiveaux",
      fetchDomaineEtudes: "domaine/fetchDomaines",
    }),
    handleInput(e) {
      if (e.target.name === "profession") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },

    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("canceled");
    },
  },
};
</script>
