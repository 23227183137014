<template>
  <div
    class="bg align-items-center justify-content-center bg- pb-5 mt-4"
    style="min-height: 40vh"
  >
    <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />
    <div class="row">
      <div class="col-sm-12">
        <div
          v-if="!hasCv"
          class="d-flex flex-column align-items-center justify-content-center bg-light pa-5 ma-5"
          style="min-height: 40vh"
        >
          <div class="mb-3 text-muted">Vous n'avez pas encore constitué votre cv.</div>
          <a @click="gotoCvEdit" class="btn btn-outline-primary">Contituer mon dossier</a>
        </div>
        <div v-else class="cv-show">
          <div class="container mb-5">
            <div class="bg-white">
              <div class="row">
                <div class="col-sm-4 bg-dark">
                  <div class="p-3 p-lg-5 text-white">
                    <div class="mb-4">
                      <a href="#" class="">
                        <div
                          class="rounded-circle style_bg_img border"
                          :style="`background-image: url(${
                            de != null && de.fullPhotoUrl
                              ? de.fullPhotoUrl
                              : '/assets/img/profilMan.jpg'
                          }); height: 120px; width: 120px;`"
                        >
                          <profile-photo-drag-and-drop
                            :autoUpload="true"
                            :hidePreview="true"
                            :method="'POST'"
                            v-if="demandeur"
                            @fileSelected="fileChanged"
                            @completed="profileUpdate"
                            :uploadUrl="
                              API_URL + `/demandeurs/${demandeur.id}/update/photo`
                            "
                            icon="fa fa-3x fa-cloud-upload-alt"
                            action-label=""
                          />
                        </div>
                      </a>
                    </div>
                    <h4 class="text-uppercase text-wrap">
                      {{ demandeur.nom }}
                      {{ demandeur.prenom }}
                    </h4>
                    <small class="text-uppercase text-white-50">
                      {{ demandeur.prenom }}</small
                    >
                    <!-- Button trigger modal -->
                    <br />
                    <modal ref="languageDialog" title="Ajouter une langues" />
                    <hr class="my-4" />
                    <div class="mb-4">
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <h6 class="mb-0 text-uppercase flex-fill">Profil</h6>
                        <cv-description-editor-dialog
                          title="Edition profile"
                          ref="profilEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                        <a
                          @click.prevent="$refs['profilEditorDialog'].show;"
                          class="text-warning"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <small class="text-muted">{{ demandeur.descriptionProfil }}</small>
                    </div>
                    <div class="mb-3">
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <contact-editor-dialog
                          title="Edition contacts"
                          ref="contactEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                        <h6 class="mb-0 text-uppercase flex-fill">Contact</h6>
                        <a
                          @click.prevent="$refs['contactEditorDialog'].show;"
                          class="text-warning cursor-pointer"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-telephone"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">
                            229
                            {{ demandeur.phone }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-envelope"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">
                            {{ demandeur.email }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-whatsapp"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">
                            {{ demandeur.whatsapp }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-geo-alt-fill"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">
                            {{ demandeur.adresse }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 mb-3">
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <h6 class="mb-0 text-uppercase flex-fill">
                          Compétences Professionnelles
                        </h6>
                        <a class="text-warning"><i class="bi bi-pencil-square"></i></a>
                      </div>
                      <ul class="text-muted ps-4">
                        <li class="mb-2">Web Design</li>
                        <li class="mb-2">UI / UX Design</li>
                        <li class="mb-2">Programmation web</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="p-3 p-lg-5">
                    <!-- <section-donnees-personnelle v-model="demandeur" class="mb-3" />             -->
                    <!-- <section-qualification v-model="demandeur" class="mb-3" /> -->
                    <block-qualifications
                      v-model="demandeur.qualifications"
                      @save="updateDemandeurQualifications"
                    >
                      <template #header="{ addQualification }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Formations et diplômes
                        </h5>
                        <a
                          @click.prevent="addQualification"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>
                    </block-qualifications>
                    <block-emplois
                      v-model="demandeur.emplois"
                      :displayMode="'nice'"
                      @save="updateDemandeurEmplois"
                    >
                      <template #header="{ addEmploi }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Expérience professionnelle
                        </h5>
                        <a
                          @click.prevent="addEmploi"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>
                    </block-emplois>
                    <block-langues
                      v-model="demandeur.langues"
                      :displayMode="'nice'"
                      @save="updateDemandeurLangues"
                    >
                      <template #header="{ addEmploi }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Langues
                        </h5>
                        <a
                          @click.prevent="addEmploi"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>
                    </block-langues>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <block-qualifications v-if="false" v-model="demandeur" />
          <block-emplois v-if="false" v-model="demandeur" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockQualifications from "./cv/BlockQualifications.vue";
import BlockEmplois from "./cv/BlockEmplois.vue";
import BlockLangues from "./cv/BlockLangues.vue";
import ContactEditorDialog from "./cv/editor/dialog/ContactEditorDialog.vue";
import CvDescriptionEditorDialog from "./cv/editor/dialog/CvDescriptionEditorDialog.vue";
// import Emplois from '../../../components/espace/demandeur/cv/steps/Emplois.vue'
// import Qualifications from '../../../components/espace/demandeur/cv/steps/Qualifications.vue'
import ProfilePhotoDragAndDrop from "../../uploader/ProfilePhotoDragAndDrop.vue";
import Modal from "../../Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";
import loading from "../../../directives/loading";

export default {
  props: {
    demandeurId: {
      type: [Number, String],
    },
  },
  components: {
    Modal,
    BlockEmplois,
    BlockLangues,
    ContactEditorDialog,
    CvDescriptionEditorDialog,
    ProfilePhotoDragAndDrop,
    BlockQualifications,
    // Emplois,
    // Qualifications
  },
  data() {
    return {
      hasCv: false,
      busy: false,
      API_URL,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        whatsapp: null,
        adresse: null,
        lieuNaissance: null,
        paysNaissance: null,
        departementNaissance: null,
        communeNaissance: null,
        arrondissementNaissance: null,
        villeNaissance: null,
        niveauInstruction: null,
        disponible: null,
        sousContrat: null,
        dejaInscrit: null,
        domaine: null,
        estInstruit: null,
        competencePrincipale: null,
        publicProfil: true,
        demandeurActif: true,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        arrondissement: null,
        ville: null,
        departement: null,
        emplois: [],
        qualifications: [],
        phone: null,
        nomMere: null,
        prenomMere: null,
        nomPere: null,
        prenomPere: null,
        lignee: null,
        contactMere: null,
        contactPere: null,
        urlPhoto: null,
        tauxRemplissageProfil: null,
        libelleProfil: null,
        descriptionProfil: null,
        permis: null,
        situationMatrimoniale: null,
        ethnie: null,
        nationalite: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      return this.demandeur;
    },
  },
  watch: {
    dossier() {
      if (this.dossier != null) {
        this.hasCv = true;
        this.demandeur = {
          ...this.dossier,
          emplois: this.dossier.emplois != undefined ? this.dossier.emplois : [],
          qualifications:
            this.dossier.qualifications != undefined ? this.dossier.qualifications : [],
        };

        this.$emit("loaded", this.demandeur);
      }
    },
  },
  created() {
    this.busy = true;
    this.fetchDossier({
      demandeurId: this.demandeurId,
    }).then(() => {
      this.busy = false;
    });
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchDossier: "demandeur/fetchCardreDossier",
    }),
    loadData() {
      this.busy = true;
      this.fetchDossier({
        demandeurId: this.demandeurId,
      }).then((data) => {
        const dossier = data;

        this.hasCv = true;
        this.demandeur = {
          ...dossier,
          emplois: dossier.emplois != undefined ? dossier.emplois : [],
          qualifications:
            dossier.qualifications != undefined ? dossier.qualifications : [],
        };

        this.$emit("loaded", this.demandeur);
        this.busy = false;
      });
    },
    editProfile() {
      this.$refs.languageDialog.show();
    },
    gotoCvEdit() {
      this.$router.push({ name: "espace.de.cv.edit" });
    },
    fileChanged(e) {
      console.log(e);
      this.demandeur.urlPhoto = e.previewUrl;
    },
    profileUpdate(e) {
      console.log(e);
      this.demandeur = {
        ...this.demandeur,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
    updateDemandeurEmplois(emplois) {
      let demandeur = {
        ...this.demandeur,
        emplois,
      };
      this.save(demandeur);
    },
    updateDemandeurQualifications(qualifications) {
      let demandeur = {
        ...this.demandeur,
        qualifications,
      };
      this.save(demandeur);
    },
    updateDemandeurLangues(langues) {
      let demandeur = {
        ...this.demandeur,
        langues,
      };
      this.save(demandeur);
    },
    save(item) {
      this.demandeur = {
        ...this.demandeur,
        ...item,
      };
      this.updateDemandeurCv(this.demandeur).then(() => {
        this.$emit("save", this.demandeur);
      });
    },
  },
};
</script>
