

import { updateSavedSelection, mergeByProperty } from '@/utils'
import { paginatorMixin } from '@/mixins/paginator-mixin'

export const  multiPageItemSelectorMixin = {
  mixins: [ paginatorMixin ],
  data () {
    return {
      selected: [],
      saved: [],
      updatingTable: false,
      preparingForSelection: false,
    }
  },
  watch: {
    mxFilteredItems(val){
      if(val && this.selected.length !== 0){
        mergeByProperty(this.saved, this.selected, 'id')
        this.preparingForSelection = true //Evite la mise à jour de saved lors de  this.selected = []
        this.selected = []
      }
    },
    selected(val, old){
      if(!this.preparingForSelection) {
          this.saved = updateSavedSelection(this.saved, val, old, 'id')
          this.$emit('input', this.saved)
      }
      this.preparingForSelection = false
    }
  },
  mounted () {
    this.$watch(
      () => {
          return this.$refs.table.items
      },  
      (val) => {
        console.log(val)
        this.watchTableData(val)
      }
    )
  },
  computed: {
    tableData () {
      return this.mxFilteredItems.map(item => {
        return { ...item }
      })
    },
    referentielCaptilizedName(){
      let lowerName = this.referentielName
      return lowerName[0].toUpperCase()+lowerName.slice(1, lowerName.length)
    },
    referentielLowercaseName(){
      return this.referentielName //Not use toLowerCase
    },
  },
  methods: {
    clearSelection(){
      this.saved = []
      this.selected = []
      this.$refs.table.clearSelected()
    },
    watchTableData (val) {
      this.updatingTable = true
      if(val && this.saved.length !== 0){
        let items = this.tableData
        // const savedIds = this.saved.map(el => el.id)
        const currentListIds = items.map(el => el.id)
        let savedVisibleItems = []
        let savedUnvisiableItems = []

        this.saved.forEach(el => {
          if(currentListIds.includes(el.id)){
            savedVisibleItems.push(el)
          }
          else {
            savedUnvisiableItems.push(el)
          }
        })

        const savedVisibleItemsIds = savedVisibleItems.map(el => el.id)
        items.forEach((el, index) => {
          if(savedVisibleItemsIds.includes(el.id)){
            this.$refs.table.selectRow(index)
          }
        })
        this.saved = [ ...savedUnvisiableItems  ]
      }
      this.updatingTable = false
    },
    selectAll(evt){
      if(evt.target.checked){
        this.$refs.table.selectAllRows()
      }
      else {
        this.$refs.table.clearSelected()
      }
    },
    selectionChanged(evt, data){
      console.log(evt.target.checked, data)
      if(evt.target.checked){
        this.$refs.table.selectRow(data.index)
      }
      else {
        this.$refs.table.unselectRow(data.index)
      }
    },
    onRowSelected(items) {
      if(this.updatingTable) return
      this.selected = [...items]
    },
    tableRefeshed(items) {
      this.selected = [...items]
    }
  }
}
