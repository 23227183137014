<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
             
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Reponse</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown v-model="editableItem.reponse" class="col-12 md:col-12" :options="rep" optionLabel="name"
                    optionValue="code">

                  </PDropdown>
                </div>
              </div>
              <hr />
              <div v-if="editableItem.reponse">
                <div class="row" v-show="editableItem.questionScreening.combien">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Combien</h6>
                  </div>
                  <div class="col-sm-7">
                    <PInputText type="number" class="col-12 " v-model="editableItem.combien" />
                  </div>
                </div>
                <hr />
                <div class="row" >
                  <div class="col-sm-5">
                    <h6 class="mb-0">Observation</h6>
                  </div>
                  <div class="col-sm-7">
                    <PInputText type="text" class="col-12 " v-model="editableItem.observation" />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Impact</h6>
                  </div>
                  <div class="col-sm-7">
                    <PInputText type="text" class="col-12 " v-model="editableItem.impact" />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Mesures d’atténuations</h6>
                  </div>
                  <div class="col-sm-7">
                    <PInputText type="text" class="col-12 " v-model="editableItem.mesureAttenuation" />
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div class="d-grid gap-2">
            <button @click.prevent="save" type="submit" class="style_btn btn btn-success btn-lg">
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'


export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },


  },
  mixins: [],
  components: {
    Modal,

  },
  data() {
    return {
      editableItem: {},
      rep: [
        { code: true, name: "Oui" },
        { code: false, name: "Non" },
      ],


    };
  },
  created() {

  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
      // console.log(this.editableItem.femme);
    },
    'editableItem.reponse':{
      handler(val){
        if (val==false){
            this.editableItem.mesureAttenuation=""
            this.editableItem.impact=""
            this.editableItem.observation=""
            this.editableItem.combien=""
        }
      },
      deep:true
    }
  },
  computed: {
    ...mapGetters({

    }),

    // total() {
    //   return this.editableItem.homme + this.editableItemfemme;
    // },
  },
  methods: {
    ...mapActions({


    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
