<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <CrudTable
          :formTitle="'Edition plan d’action pour la mise en œuvre'"
          :tableFields="crudTableFields"
          :crudUrl="'planMiseEnOeuvres'"
          :extraQueryArg="`microProjet.id=${editableItem.id}`"
          :emptyReferentielItem="emptyCrudItem"
        >
          <template #listTitle>
            <h4>Plan d’action pour la mise en œuvre</h4>
          </template>

          <template #formTitle="{}">
            <h5>Edition plan d’action pour la mise en œuvre</h5>
          </template>

          <template #formContent="{ editableItem, updateValue }">
            <PlanActionEditor
              :value="editableItem"
              @changed="(val) => updateValue(val)"
            />
          </template>
        </CrudTable>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import FfomCrudForm from "./ffom/FfomCrudForm.vue";
import PlanActionEditor from "./plan-action/PlanActionEditor.vue";
export default {
  components: { CYesOrNoInput, CSelect, CrudTable, FfomCrudForm, PlanActionEditor },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    emptyCrudItem() {
      return {
        activite: null,
        dateDebut: null,
        dateFin: null,
        personneResponsable: null,
        personnesAssocies: null,
        microProjet: this.editableItem,
      };
    },
    crudTableFields() {
      return [
        "#",
        { key: "activite", label: "Activité" },
        { key: "dateDebut", label: "Date Début", isDate: true },
        { key: "dateFin", label: "Date Fin", isDate: true },
        { key: "personneResponsable", label: "Personne Responsable" },
        { key: "personnesAssocies", label: "Personnes Associées" },
        {
          key: "statut",
          label: "Statut",
          isBadge: true,
          colorMapping: {
            NON_DEMARRE: "primary",
            EN_COURS: "warning",
            TERMINE: "success",
          },
        },
        "actions",
      ];
    },
  },
  created() {},
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
