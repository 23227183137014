<template>
  <div
    class="container bg align-items-center justify-content-center bg- pb-5 mt-4"
    style="min-height: 40vh"
  >
    <div class="row">
      <div class="col-sm-12"></div>
      <div class="row">
        <div class="col-sm-4 bg-dark">
          <div class="p-3 p-lg-5 text-white">
            <div class="mb-4">
              <a href="#" class="">
                <div
                  class="rounded-circle style_bg_img border"
                  :style="`background-image: url(${
                    de != null && de.fullPhotoUrl
                      ? de.fullPhotoUrl
                      : '/assets/img/profilMan.jpg'
                  }); height: 120px; width: 120px;`"
                >
                  <profile-photo-drag-and-drop
                    :autoUpload="true"
                    :hidePreview="true"
                    :method="'POST'"
                    v-if="demandeur"
                    @fileSelected="fileChanged"
                    @completed="profileUpdate"
                    :uploadUrl="
                      API_URL + `/demandeurs/${demandeur.id}/update/photo`
                    "
                    icon="fa fa-3x fa-cloud-upload-alt"
                    action-label=""
                  />
                </div>
              </a>
            </div>
            <h2 class="text-uppercase">
              {{ demandeur.nom }} {{ demandeur.prenom }}
            </h2>
            <!-- <small class="text-uppercase text-white-50"
                      >Hounsinou Mondji Assuris</small
                    >-->
            <!-- Button trigger modal -->
            <br />
            <modal ref="languageDialog" title="Ajouter une langues" />
            <hr class="my-4" />
            <div class="mb-4">
              <div
                class="d-flex justify-content-between align-items-center mb-4"
              >
                <h6 class="mb-0 text-uppercase flex-fill">Profil</h6>
                <cv-description-editor-dialog
                  title="Edition profile"
                  ref="profilEditorDialog"
                  :item="demandeur"
                  @save="save"
                />
                <a
                  @click.prevent="$refs['profilEditorDialog'].show"
                  class="text-warning"
                  ><i class="bi bi-pencil-square"></i
                ></a>
              </div>
              <small class="text-muted">{{
                demandeur.descriptionProfil
              }}</small>
            </div>
            <div class="mb-3">
              <div
                class="d-flex justify-content-between align-items-center mb-4"
              >
                <contact-editor-dialog
                  title="Edition contacts"
                  ref="contactEditorDialog"
                  :item="demandeur"
                  @save="save"
                />
                <h6 class="mb-0 text-uppercase flex-fill">Contact</h6>
                <a
                  @click.prevent="$refs['contactEditorDialog'].show"
                  class="text-warning cursor-pointer"
                  ><i class="bi bi-pencil-square"></i
                ></a>
              </div>
              <div class="d-flex align-items-center text-muted mb-2">
                <div
                  class="flex-shrink-0 d-flex justify-content-center align-items-center"
                >
                  <h6 class="me-2"><i class="be bi-telephone"></i></h6>
                </div>
                <div class="flex-grow-1 ms-2">
                  <h6 class="">229 {{ demandeur.phone }}</h6>
                </div>
              </div>
              <div class="d-flex align-items-center text-muted mb-2">
                <div
                  class="flex-shrink-0 d-flex justify-content-center align-items-center"
                >
                  <h6 class="me-2"><i class="be bi-envelope"></i></h6>
                </div>
                <div class="flex-grow-1 ms-2">
                  <h6 class="">{{ demandeur.email }}</h6>
                </div>
              </div>
              <div class="d-flex align-items-center text-muted mb-2">
                <div
                  class="flex-shrink-0 d-flex justify-content-center align-items-center"
                >
                  <h6 class="me-2"><i class="be bi-whatsapp"></i></h6>
                </div>
                <div class="flex-grow-1 ms-2">
                  <h6 class="">{{ demandeur.whatsapp }}</h6>
                </div>
              </div>
              <div class="d-flex align-items-center text-muted mb-2">
                <div
                  class="flex-shrink-0 d-flex justify-content-center align-items-center"
                >
                  <h6 class="me-2">
                    <i class="be bi-geo-alt-fill"></i>
                  </h6>
                </div>
                <div class="flex-grow-1 ms-2">
                  <h6 class="">{{ demandeur.adresse }}</h6>
                </div>
              </div>
            </div>

            <!-- <div class="mb-4 mb-3">
                      <div
                        class="d-flex justify-content-between align-items-center mb-4"
                      >
                        <h6 class="mb-0 text-uppercase flex-fill">
                          Compétences Professionnelles
                        </h6>
                        <a class="text-warning"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <ul class="text-muted ps-4">
                        <li class="mb-2">Web Design</li>
                        <li class="mb-2">UI / UX Design</li>
                        <li class="mb-2">Programmation web</li>
                      </ul>
                    </div>-->
          </div>
        </div>

        <div class="col">
          <div class="p-3 p-lg-5">
            <!-- <section-donnees-personnelle v-model="demandeur" class="mb-3" />             -->
            <!-- <section-qualification v-model="demandeur" class="mb-3" /> -->
            <block-qualifications
              v-model="demandeur.qualifications"
              :displayMode="'nice'"
            >
              <template #header="{ addQualification }">
                <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                  Formations et diplômes
                </h5>
                <a
                  @click.prevent="addQualification"
                  class="btn btn-primary rounded-0 btn-sm"
                  ><i class="bi bi-clipboard-plus"></i
                ></a>
              </template>
            </block-qualifications>
            <block-emplois v-model="demandeur.emplois" :displayMode="'nice'">
              <template #header="{ addEmploi }">
                <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                  Expérience professionnelle
                </h5>
                <a
                  @click.prevent="addEmploi"
                  class="btn btn-primary rounded-0 btn-sm"
                  ><i class="bi bi-clipboard-plus"></i
                ></a>
              </template>
            </block-emplois>
            <block-langues v-model="demandeur.langues" :displayMode="'nice'">
              <template #header="{ addEmploi }">
                <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                  Langues
                </h5>
                <a
                  @click.prevent="addEmploi"
                  class="btn btn-primary rounded-0 btn-sm"
                  ><i class="bi bi-clipboard-plus"></i
                ></a>
              </template>
            </block-langues>

            <block-signatures
              v-model="demandeur.signature"
              :displayMode="'nice'"
            >
              <template #header="{ addEmploi }">
                <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                  Signature
                </h5>
                <a
                  @click.prevent="addEmploi"
                  class="btn btn-primary rounded-0 btn-sm"
                  ><i class="bi bi-clipboard-plus"></i
                ></a>
              </template>
            </block-signatures>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <block-qualifications v-if="false" v-model="demandeur" />
          <block-emplois v-if="false" v-model="demandeur" /> -->
</template>

<script>
import BlockQualifications from "../../../components/espace/demandeur/cv/export/BlockQualifications.vue";
import BlockEmplois from "../../../components/espace/demandeur/cv/export/BlockEmplois.vue";
import BlockLangues from "../../../components/espace/demandeur/cv/export/BlockLangues.vue";
import BlockSignatures from "../../../components/espace/demandeur/cv/export/BlockSignature.vue";
import ContactEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/ContactEditorDialog.vue";
import CvDescriptionEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/CvDescriptionEditorDialog.vue";
// import Emplois from '../../../components/espace/demandeur/cv/steps/Emplois.vue'
// import Qualifications from '../../../components/espace/demandeur/cv/steps/Qualifications.vue'
import ProfilePhotoDragAndDrop from "../../../components/uploader/ProfilePhotoDragAndDrop.vue";
// import signature from '../../../components/Signature.vue'
import Modal from "../../../components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";

export default {
  props: ["demandeurId"],
  components: {
    Modal,
    BlockEmplois,
    BlockLangues,
    ContactEditorDialog,
    CvDescriptionEditorDialog,
    ProfilePhotoDragAndDrop,
    BlockQualifications,
    BlockSignatures,

    // Emplois,
    // Qualifications
  },
  data() {
    return {
      hasCv: false,
      API_URL,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        whatsapp: null,
        adresse: null,
        lieuNaissance: null,
        paysNaissance: null,
        departementNaissance: null,
        communeNaissance: null,
        arrondissementNaissance: null,
        villeNaissance: null,
        niveauInstruction: null,
        disponible: null,
        sousContrat: null,
        dejaInscrit: null,
        domaine: null,
        estInstruit: null,
        competencePrincipale: null,
        publicProfil: true,
        demandeurActif: true,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        arrondissement: null,
        ville: null,
        departement: null,
        emplois: [],
        qualifications: [],
        phone: null,
        nomMere: null,
        prenomMere: null,
        nomPere: null,
        prenomPere: null,
        lignee: null,
        contactMere: null,
        contactPere: null,
        urlPhoto: null,
        tauxRemplissageProfil: null,
        libelleProfil: null,
        descriptionProfil: null,
        permis: null,
        situationMatrimoniale: null,
        ethnie: null,
        nationalite: null,
        signature: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      return this.demandeur;
    },
  },
  watch: {
    dossier() {
      if (this.dossier != null) {
        this.demandeur = {
          ...this.dossier,
        };
      }
    },
    // demandeurId() {
    //   this.fetchMonDossier({
    //     demandeurId: this.demandeurId,
    //   }).then((result) => {
    //     console.log(result);
    //   });
    // },
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    // this.fetchMonDossier();
    if (this.demandeurId) {
      this.fetchMonDossier({}).then((result) => {
        console.log(result);
      });
    }
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonDossier: "demandeur/fetchMonDossier",
    }),
    // editProfile() {
    //   this.$refs.languageDialog.show();
    // },
    // gotoCvEdit() {
    //   this.$router.push({ name: "espace.de.cv.edit" });
    // },
    // fileChanged(e) {
    //   console.log(e);
    //   this.demandeur.urlPhoto = e.previewUrl;
    // },
    // profileUpdate(e) {
    //   console.log(e);
    //   this.demandeur = {
    //     ...this.demandeur,
    //     fullPhotoUrl: e.fullPhotoUrl,
    //   };
    // },
    // generateReport() {
    //    this.$refs.html2Pdf.generatePdf()
    // },
    save(item) {
      this.demandeur = {
        ...this.demandeur,
        ...item,
      };
      this.updateDemandeurCv(this.demandeur);
    },
  },
};
</script>
