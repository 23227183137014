<template>
  <modal :title="title" ref="modal" size="xl" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-0">Comment le site du sous projet a-t-il été choisi ?</h6>
                </div>
                <div class="col-sm-12">
                  <PTextarea
                    type="text"
                    class="col-12"
                    v-model="editableItem.commentChoisiSiteSP"
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-">Nombre de bénéficiaires directs :</h6>
                </div>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareDirectHomme"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareDirectHomme"
                            name="nombreBeneficiareDirectHomme"
                            placeholder="Direct Homme"
                          />
                          <label for="nombreBeneficiareDirectHomme">Direct Homme</label>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareDirectFemme"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareDirectFemme"
                            name="nombreBeneficiareDirectFemme"
                            placeholder="Direct Femme"
                          />
                          <label for="nombreBeneficiareDirectFemme">Direct Femme</label>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareDirectEnfant"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareDirectEnfant"
                            name="nombreBeneficiareDirectEnfant"
                            placeholder="Direct Femme"
                          />
                          <label for="nombreBeneficiareDirectEnfant">Direct Enfant</label>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            disabled
                            :value="totalBeneficiaireDirectHomme"
                            type="number"
                            class="form-control"
                            id="totalBeneficiaireDirectHomme"
                            name="totalBeneficiaireDirectHomme"
                            placeholder="Direct Total"
                          />
                          <label for="totalBeneficiaireDirectHomme">Direct Total</label>
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-">Nombre de bénéficiaires indirects :</h6>
                </div>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareIndirectHomme"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareIndirectHomme"
                            name="nombreBeneficiareIndirectHomme"
                            placeholder="Indirect Homme"
                          />
                          <label for="nombreBeneficiareIndirectHomme"
                            >Indirect Homme</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareIndirectFemme"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareIndirectFemme"
                            name="nombreBeneficiareIndirectFemme"
                            placeholder="Indirect Femme"
                          />
                          <label for="nombreBeneficiareIndirectFemme"
                            >Indirect Femme</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            v-model="editableItem.nombreBeneficiareIndirectEnfant"
                            type="number"
                            class="form-control"
                            id="nombreBeneficiareIndirectEnfant"
                            name="nombreBeneficiareIndirectEnfant"
                            placeholder="Indirect Enfant"
                          />
                          <label for="nombreBeneficiareIndirectEnfant"
                            >Indirect Enfant</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-floating mb-3">
                          <input
                            disabled
                            :value="totalBeneficiaireIndirectHomme"
                            type="number"
                            class="form-control"
                            id="totalBeneficiaireIndirectHomme"
                            name="totalBeneficiaireIndirectHomme"
                            placeholder="Total"
                          />
                          <label for="totalBeneficiaireIndirectHomme"
                            >Indirect Total</label
                          >
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-0">Origine ethnique ou sociale:</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.origineEthique"
                    class="col-12 md:col-12"
                    :options="originesSocioEthniques"
                    optionLabel="name"
                    optionValue="code"
                  >
                  </PDropdown>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-0">Statut du site d’implantation du sous-projet:</h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.statutSiteImplantation"
                    class="col-12 md:col-12"
                    :options="statutSiteImplantationOptions"
                    optionLabel="name"
                    optionValue="code"
                  >
                  </PDropdown>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="mb-0">
                    Y’a-t-il un acte attestant la propriété, la location ou la cession
                    gratuite ?
                  </h6>
                </div>
                <div class="col-sm-7">
                  <PDropdown
                    v-model="editableItem.acteAttestantPropriete"
                    class="col-12 md:col-12"
                    :options="yesNoOptions"
                    optionLabel="name"
                    optionValue="code"
                  >
                  </PDropdown>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
  },
  mixins: [],
  components: {
    Modal,
  },
  data() {
    return {
      editableItem: {},

      consultationPublics: [
        { code: true, name: "Oui" },
        { code: false, name: "Non" },
      ],
      yesNoOptions: [
        { code: true, name: "Oui" },
        { code: false, name: "Non" },
      ],
      originesSocioEthniques: [
        { code: "AUTOCHTONES", name: "AUTOCHTONES" },
        { code: "ALLOGENES", name: "ALLOGENES" },
        { code: "MIGRANT", name: "MIGRANT" },
        { code: "MIXTES", name: "MIXTES" },
      ],
      statutSiteImplantationOptions: [
        { code: "PROPIETE", name: "PROPIETE" },
        { code: "LOCATION", name: "LOCATION" },
        { code: "CESSATION_GRATUIT", name: "CESSATION_GRATUIT" },
      ],
    };
  },
  created() {},
  watch: {
    item() {
      this.editableItem = { ...this.item };
      // console.log(this.editableItem.femme);
    },
    "editableItem.consultationPublic": {
      handler(val) {
        if (val == false) {
          this.editableItem.descripionConsultationPublic = "";
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({}),

    // total() {
    //   return this.editableItem.homme + this.editableItemfemme;
    // },
    totalBeneficiaireDirectHomme() {
      return (
        parseInt(this.editableItem.nombreBeneficiareDirectHomme ?? 0) +
        parseInt(this.editableItem.nombreBeneficiareDirectFemme ?? 0) +
        parseInt(this.editableItem.nombreBeneficiareDirectEnfant ?? 0)
      );
    },
    totalBeneficiaireIndirectHomme() {
      return (
        parseInt(this.editableItem.nombreBeneficiareIndirectHomme ?? 0) +
        parseInt(this.editableItem.nombreBeneficiareIndirectFemme ?? 0) +
        parseInt(this.editableItem.nombreBeneficiareIndirectEnfant ?? 0)
      );
    },
  },
  methods: {
    ...mapActions({}),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
