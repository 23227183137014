<template>
  <div>
    <PDialog
      class="stepper-dialog"
      :header="''"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card shadow-3 px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-">
              <!-- <PButton icon="pi pi-times" class="p-button-rounded p-button-primary" /> -->
            </h4>
            <h4 class="d-flex justify-content-between">
              <div>Mutation du demandeur - {{ demandeur.nom }} {{ demandeur.prenom }}</div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
        </div>
      </template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        
        <div v-if="!acceptedAgreement">
          <div class="card shadow-3 px-0 border-0 mb-2 w-100">
            <div class="card-body">
              <h4 class="text-uppercase text-white bg-danger py-1 px-2">A votre attention!</h4>
              <p>
                L'opération de mutation de DE consiste à changer le niveau d'instruction
                du DE pour le faire passer céventuellement d'INSTRUIT à PPI ou
                vice-versa.
              </p>

              <p>
                <h5 class="text-uppercase text- bg-warning px-2 py-1">Implications de l'opération:</h5>
                <ul>
                    <li class="mb-4">
                        <div class="fw-bold">Passage de INSTRUIT à PPI:</div>
                        <ol>
                            <li>Le parcours actuel (et donc la prise en charge actuelle) du DE sera <PBadge severity="danger" value="GELE"></PBadge>. Ce qui implique la création d'une nouvelle prise en charge pour le DE.</li>
                            <li>Une nouvelle fiche d'enquête sera générée pour le DE.</li>
                            <li>Son dossier sera invalidé (réinitialisé avec le statut <PBadge severity="danger" value="NOUVEAU"></PBadge>) vu que la nouvelle fiche d'enquête est vide.</li>
                            <li>La prise en charge actuelle sera désormais disponible en <span class="fw-bold text-danger bg-warning py-0 px-2">lecture seule</span>.</li>
                            <li class="">
                                <PTag severity="danger" class="text-white bg-danger py-0 px-2">Vous aurez à reprendre la prise en charge du DE.</PTag>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div class="fw-bold">Passage de PPI à INSTRUIT:</div>
                        <ol>
                            <li>Le parcours actuel (et donc la prise en charge actuelle) du DE sera <PBadge severity="danger" value="GELE"></PBadge>. Ce qui implique la création d'une nouvelle prise en charge pour le DE.</li>
                            <li>La fiche d'enquête actuelle du DE sera supprimée (ne sera donc plus accessible).</li>
                            <li>Son dossier sera invalidé (réinitialisé avec le statut <PBadge severity="danger" value="NOUVEAU"></PBadge>).</li>
                            <li>La prise en charge actuelle sera désormais disponible en <span class="fw-bold text-danger bg-warning py-0 px-2">lecture seule</span>.</li>
                            <li class="">
                                <PTag severity="danger" class="text-white bg-danger py-0 px-2 text-">Vous aurez à reprendre la prise en charge du DE.</PTag>
                            </li>
                        </ol>
                    </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div v-else class="card mt border-0 px-3">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <div class="">
              <MutationDemandeurEditor v-if="display" v-model="editableItem" :demandeur="demandeur"/>
            </div>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton v-if="!acceptedAgreement" class="p-button-warning" label="Je comprends" icon="pi pi-check" @click.prevent.stop="acceptedAgreement=true" />
        <PButton v-else label="Valider" icon="pi pi-check" @click="save" />
      </template>
    </PDialog>
  </div>
</template>

<script>
import MutationDemandeurEditor from "./MutationDemandeurEditor.vue";

export default {
  props: ["demandeur", "priseEnChargeId"],
  components: { MutationDemandeurEditor },
  data() {
    return {
      note: null,
      display: false,
      acceptedAgreement: false,
      editableItem: {},
      maxScore: 10,
      activeStepIndex: 0,
    };
  },
  watch: {
    // demandeur: {
    //   handler() {
    //     this.editableItem = {};
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
      this.resetContent();
    },
    resetContent() {
      this.editableItem = {
        priseEnChargeId: this.priseEnChargeId,
      };
      this.activeStepIndex = 0;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        priseEnChargeId: this.priseEnChargeId,
      });
      this.resetContent();
      this.hide();
    },
  },
};
</script>

<style>
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.stepper-dialog .p-dialog-header-icons {
  display: none;
}
</style>
