<template>
<div>
  <!-- <button type="button" @click="$bvModal.show(modalId)" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#${modalId}`"></button> -->
  <!-- <div class="modal fade text-dark" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> -->
  <!-- <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="title" :title="title">
            <h5 class="modal-title" :id="`${modalId}BackdropLabel`">{{ title }}</h5>
          </slot>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot>Aucun contenu</slot>
        </div>
        <div class="modal-footer">
          <button @click.prevent="ok" type="button" class="btn btn-primary btn-md">Valider</button>
          <button type="button" data-bs-dismiss="modal" class="btn btn-outline-primary btn-md">Annuler</button>
          <button @click.prevent="ok" type="button" class="btn btn-secondary">Close</button>
        </div>
      </div>
    </div> -->
    <b-modal
      :id="modalId"
      :title="title"
      :size="size"
      @ok="handleOk"
      :hide-footer="hideFooter"
    >
      <template #modal-header="{ close }" class="modal-header">
        <slot name="title" :title="title">
          <h5 class="modal-title" :id="`${modalId}BackdropLabel`">{{ title }}</h5>
        </slot>
        <button type="button" class="btn-close" @click="close" aria-label="Close"></button>
      </template>
      <div class="modal-body" >
        <slot :ok="ok" :close="close">Aucun contenu</slot>
      </div>
      <template #modal-footer="{ close, ok }">
        <slot name="modal-footer">
          <button @click.prevent="ok" type="button" class="btn btn-primary btn-md">Valider</button>
          <button type="button" @click.prevent="close"  class="btn btn-outline-primary btn-md">Annuler</button>
        </slot>
      </template>
  </b-modal>
</div>
</template>

<script>
import ShortUniqueId from 'short-unique-id'
const uid = new ShortUniqueId({ length: 10 })

export default {
  props: ['title', 'hideFooter', 'size'],
  data() {
    return {
      modalId: 'modal_'+ uid(),
      myModal: null,
      modalShow: false
    }
  },
  mounted() {
  },
  methods: {
    hide(){
      this.$bvModal.hide(this.modalId)
    },
    close(){
      this.hide()
    },
    show(){
      this.$bvModal.show(this.modalId)
    },
    ok(){
      this.handleOk()
    },
    handleOk(){
      this.$emit('ok')
      this.$emit('save')
    }
  },
}
</script>

<style>
 footer.modal-footer{
   background: white;
 }
</style>