<template>
  <div>
    <reponse-editor-dialog
      v-show="false"
      @save="updateReponse($event)"
      :title="
        activeItem != null && activeItem == null
          ? 'DETAILS DE REPONSE'
          : 'DETAILS DE REPONSE'
      "
      ref="editor"
      :item="activeItem"
    />

    <PCard class="shadow-none">
      <template #title>
        <div class="d-flex justify-content-between">
          <span> IDENTIFICATION DES IMPACTS ENVIRONNEMENTAUX ET SOCIAUX </span>
          <!-- <a class="btn btn-light" href=""><i class="pi pi-caret-right"></i> EVADA</a> -->
        </div>
      </template>
      <template #subtitle>
        <p-progress-bar v-if="mxLoading" style="height: 0.4em" mode="indeterminate" />
        <template v-if="!mxLoading">
          <div class="bg-">
            <div class="bg-white p-3 border">
              <div class="table-responsive">
                <b-table
                  show-empty
                  empty-text="Aucune donnée à afficher"
                  :fields="tableFields"
                  class="align-middle"
                  striped
                  select-mode="multi"
                  ref="table"
                  selectable
                  :items="reponsescreenings"
                >
                  <template #head()="data">
                    <span>{{ data.label }} </span>
                  </template>

                  <!-- A virtual column -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(reponse)="data">
                    <yes-or-no-badge :value="data.item.reponse" />
                  </template>

                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-sm py-1 btn-outline-success me-1"
                        v-tooltip.top="'Ajouter une observation'"
                        @click="addReponse(row.item)"
                      >
                        <i class="bi bi-pencil"></i>
                      </button>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </template>
      </template>
    </PCard>
  </div>
</template>

<script>
import ReponseEditorDialog from "./ReponseEditorDialog";
import { mapActions, mapGetters, mapMutations } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";

export default {
  props: ["microprojetId", "secteurId"],
  components: {
    ReponseEditorDialog,
    YesOrNoBadge,
  },
  data() {
    return {
      screening: {},
      activeStepIndex: 0,
      mxLoading: false,
      param: {},
      rep: [
        { code: "true", name: "Oui" },
        { code: "false", name: "Non" },
      ],
      screening: {},
      activeItem: {},
    };
  },
  computed: {
    ...mapGetters({
      secteurs: "screeningsecteur/secteurs",
      reponsescreenings: "screeningreponse/reponsescreenings",
    }),

    tableFields() {
      return [
        "#",
        "index",
        // { key: "id", label: "N°" },
        { key: "questionScreening.libelle", label: "Libelle" },
        { key: "reponse", label: "Reponse" },
        { key: "observation", label: "Observation" },
        { key: "impact", label: "Impacts" },
        { key: "mesureAttenuation", label: "Mesures d’atténuations" },
        "actions",
      ];
    },
  },
  watch: {
    microprojetId: {
      handler() {
        if (this.microprojetId) {
          this.mxLoading = true;
          console.log(this.param);
          this.param = {
            microprojetId: this.microprojetId,
            secteurId: this.secteurId,
          };
          this.fetchScreeningsReponseBySecteur(this.param).then((data) => {
            this.fetchScreeningsMicroProjet(this.microprojetId).then((data) => {
              this.screening = data;
            });
            this.mxLoading = false;
            // this.screening = { ...data }
            console.log(data);
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    // console.clear()
    // console.log("je suis dans questionnnaire" + this.secteurId + "-----" + this.microprojetId)
  },
  methods: {
    ...mapActions({
      fetchScreenings: "screening/fetchScreenings",
      fetchSecteurs: "screeningsecteur/fetchSecteurs",
      fetchScreeningsReponseBySecteur: "screeningreponse/fetchScreeningsReponseBySecteur",
      fetchScreeningsMicroProjet: "screening/fetchScreeningsMicroProjet",
      createOrUpdateReponsescreening: "screeningreponse/createOrUpdateReponsescreening",
      updateReponsescreeningList: "screeningreponse/updateReponsescreeningList",
      //   updateReponsescreeningOne: "screeningreponse/updateReponsescreeningOne",
    }),
    ...mapMutations({
      addReponsescreenings2: "screeningreponse/addReponsescreenings2",
    }),

    updateQuestion() {
      this.param = {
        reponsescreenings: this.reponsescreenings,
        screeningId: this.screening.id,
      };
      this.updateReponsescreeningList(this.param)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    addReponse(payload) {
      this.activeItem = payload;
      this.$refs.editor.show();
    },

    updateReponse(payload) {
      this.param = {
        idSreening: this.screening.id,
        reponsescreening: payload,
      };
      this.mxLoading = true;
      //   this.createOrUpdateReponsescreening(this.param)
      this.createOrUpdateReponsescreening(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
          this.mxLoading = false;
        })
        .catch((err) => {
          this.mxLoading = false;
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
  },
};
</script>
