export const stepperFormMixin = {
    data() {
        return {
            mxHasErrors: false
        }
    },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", val);
        this.mxHasErrors = false;
      },
    },
    deep: true,
  },
  methods: {
    async isCompleted() {
      const valid = await this.$refs["observer"].validate();
      this.mxHasErrors = !valid;
      return valid;
    },
    // async save() {
    //   if (!(await this.isCompleted())) return;
    //   this.$emit("save", this.editableItem);
    // },
  },
}