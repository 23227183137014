<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <h5>Depuis quand êtes-vous sans emploi ou à la recherche d'un emploi?</h5>
              <label for="dateNais">Date de début de chômage ou de sous-emploi </label>
              <div class="form-floating mb-4">
                <p-calendar
                  id="dateNais"
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  view="month"
                  dateFormat="MM yy"
                  v-model="editableItem.datePerteTravail"
                  name="dateNais"
                ></p-calendar>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Pourquoi recherchez-vous un emploi?"
                v-model="editableItem.raisonRechercheEmploi"
                :responses="raisonEmplois"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <!-- <div class="col-sm-12 mb-4">
          <c-yes-or-no-input
            question="Recherchez-vous un emploi salarié ou indépendant?"
            v-model="editableItem.typeEmploi"
            option
            :options="typeEmplois"
            />
        </div> -->
          <div class="col-sm-12">
            <h5>Recherchez-vous un emploi salarié ou indépendant?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.typeEmploi"
                  @input="handleInput"
                  id="typeEmploi"
                  name="typeEmploi"
                  class="form-select"
                >
                  <option v-for="n in typeEmplois" :key="n.value" :value="n.value">
                    {{ n.label }}
                  </option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="typeEmploi">Type d'emploi recherché</label>
              </div>
            </ValidationProvider>
          </div>
          <template v-if="editableItem.typeEmploi == 'SALARIE'">
            <h5>Dans quel(s) métier(s) voulez-vous être salarié?</h5>
            <block-metier-salarie
              :maxItems="3"
              v-if="editableItem"
              :azoli="editableItem"
              v-model="editableItem.metierSalaries"
            />
          </template>
          <template v-if="editableItem.typeEmploi == 'AUTO_EMPLOI'">
            <h5>Dans quel(s) métier(s) voulez-vous entreprendre?</h5>
            <block-metier-auto
              :maxItems="3"
              v-if="editableItem"
              :azoli="editableItem"
              v-model="editableItem.metierIndependants"
            />
          </template>

          <template>
            <div class="col-sm-12 col-md-12">
              <h5>Comment avez-vous entendu parler de Azôli?</h5>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="sourceInfo"
                    name="sourceInfo"
                    option-label="libelle"
                    option-value="id"
                    v-model="editableItem.sourceInfo"
                    :options="sourceInfos"
                    class="form-select"
                  >
                  </c-select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="sourceInfo">Source information</label>
                </div>
              </ValidationProvider>
            </div>
            <div
              v-if="editableItem.sourceInfo && editableItem.sourceInfo.code == 'AUTRES'"
              class="col-sm-12 col-md-12"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.autreSourceInfo"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="autreSourceInfo"
                    name="autreSourceInfo"
                  />
                  <label for="autreSourceInfo">Autre source d'information</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import BlockMetierAuto from "../BlockMetierAuto.vue";
import BlockMetierSalarie from "../BlockMetierSalarie.vue";

export default {
  components: { CYesOrNoInput, BlockMetierAuto, BlockMetierSalarie },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      datePerteTravail: this.value.datePerteTravail,
      travaillerSemainePasse: this.value.travaillerSemainePasse != null,
      hasEmploi: this.value.hasEmploi != null,
      hasFormationProfessionnelle: this.value.formationProfessionnelle != null,
      editableItem: this.value,
      niveauAlphabetions: [
        { value: "PAS_LIRE_PAS_ECRIRE", label: "Je ne sais pas lire et écrire" },
        { value: "LIRE_PAS_ECRIRE", label: "Je peux lire mais pas écrire" },
        { value: "LIRE_ET_ECRIRE", label: "Je sais lire et écrire" },
      ],
      plageSalaires: [
        { value: "MOINS_DE_40000", label: "Moins de 52 000 FCFA" },
        { value: "ENTRE_40000_ET_70000", label: "[52 000, 70 000 [" },
        { value: "PLUS_DE_70000", label: "70 000 FCFA ou plus" },
      ],
      raisonEmplois: [
        { value: "PERTE_DU_PRECEDENT", label: "Perte de l'emploi précédent" },
        {
          value: "NOUVELLE_INSERTION",
          label: "Nouvelle insertion sur le marché du travail (n'a jamais travaillé)",
        },
        {
          value: "TRAVAILLER_PLUS",
          label: "Souhaite travailler plus.",
        },
      ],
      typeEmplois: [
        { value: "SALARIE", label: "Salarié" },
        { value: "AUTO_EMPLOI", label: "Auto-emploi" },
        { value: "INDIFFERENT", label: "Indifférent" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      serviceRendus: "serviceRendu/serviceRendus",
      sourceInfos: "sourceInfo/sourceInfos",
      formationProfessionnelles: "formationProfessionnelle/formationProfessionnelles",
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  watch: {},
  created() {
    this.fetchFormationProfessionnelles();
    this.fetchQualificationProfessionnelles();
    this.fetchSourceInfos();
  },
  methods: {
    ...mapActions({
      fetchServiceRendus: "serviceRendu/fetchServiceRendus",
      fetchSourceInfos: "sourceInfo/fetchSourceInfos",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
