<template>
  <div>
    <div v-if="busy">
      <p-progress-bar style="height: 0.33em" mode="indeterminate" />
      <p class="mt-2">
        <i>Chargement en cours...</i>
      </p>
    </div>
    <PCard v-else class="shadow-0 flat border-0">
      <template #content>
        <h4 class="py-0 my-0">
          Candidat: {{ candidature.candidat.nom }}
          {{ candidature.candidat.prenom }}
        </h4>
        <div>
          <span>{{ activeOffre.lieuDeDepot }}</span>
        </div>
        <div class="text-muted">
          <span
            >Postulé le {{ $moment(candidature.createdDate).format("MM/DD/YYYY") }} -
            offre n° 000{{ activeOffre.id }}</span
          >
        </div>
        <div class="row mt-4">
          <div class="col-md-8">
            <div v-html="candidature.motivation"></div>
          </div>
          <div class="col-md-4">
            <div class="col-sm- mb-4">
              <div class="border p-2 h-100">
                <p class="my-0 fw-bold">Expérience</p>
                <p class="text-muted my-0">{{ activeOffre.annExp }} ans</p>
              </div>
            </div>
            <div class="col-sm- mb-4">
              <div class="border p-2 h-100">
                <p class="my-0 fw-bold">Pièces</p>
                <div v-for="(piece, index) in activeOffre.typePieces" :key="index">
                  <p class="text-muted my-0">{{ piece.libelle }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm- mb-4">
              <div class="border p-2 h-100">
                <p class="my-0 fw-bold">Niveau d'étude</p>
                <div class="d-flex flex-wrap justify-content">
                  <span v-for="(niv, index) in activeOffre.niveaus" :key="index">
                    <span class="text-muted my-0 badge badge-primary" v-if="niv.code">
                      {{ niv.code }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-sm- mb-4">
              <div class="border p-2 h-100">
                <p class="my-0 fw-bold">Domaine</p>
                <div class="d-flex flex-wrap justify-content">
                  <div v-for="(domaine, index) in activeOffre.domaines" :key="index">
                    <span class="badge bg-primary text-white rounded- px-4 me-3 mb-2"
                      ><h6 class="m-0">{{ domaine.description }}</h6></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </PCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    candidatureId: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      default: "PSidebar",
    },
  },
  computed: {},
  components: {},
  data() {
    return {
      candidature: {},
      activeOffre: {},
      duration: 0,
      activeTabIndex: 0,
      loading: false,
      busy: false,
      showViewer: false,
    };
  },
  created() {},
  watch: {
    candidatureId: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchCandidature: "candidature/fetchCandidature",
      updateCandidature: "candidature/updateCandidature",
    }),
    loadData() {
      this.candidature = {};
      this.busy = true;
      this.fetchCandidature({
        candidatureId: this.candidatureId,
      }).then((data) => {
        this.candidature = { ...data };
        this.activeOffre = data.offre;
        this.busy = false;
      });
    },
    hide() {
      this.showViewer = false;
    },
    show() {
      this.loadData();
      this.showViewer = true;
    },
    preselectionerCandidature() {
      this.updateCandidature({
        ...this.candidature,
        status: "PRESELECTIONNE",
      }).then((data) => {
        console.log(data);
        this.candidature = data;
        this.duration = 4000;
      });
    },
    rejeterCandidature() {
      this.updateCandidature({
        ...this.candidature,
        status: "RECALE",
      }).then((data) => {
        console.log(data);
        this.candidature = data;
        this.duration = 4000;
      });
    },
  },
};
</script>

<style>
.p-sidebar-header {
  display: none;
}
</style>
