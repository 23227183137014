<template>
  <div>
    <!-- <multiselect v-model="selected" id="ajax" label="libelle" track-by="id" placeholder="Type to search" open-direction="bottom" :options="items" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="20" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind"> -->
    <multiselect
      v-model="selected"
      id="ajax"
      v-bind="$attrs"
      open-direction="bottom"
      :options="items"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :limit="20"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="asyncFind"
      v-on="{
        ...$listeners,
      }"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <PTag severity="pri" class="custom__tag mt-1 me-2"
          ><span>{{ option.libelle }}</span
          ><span class="custom__remove" @click="remove(option)"
            ><i class="pi pi-close"></i></span
        ></PTag>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selected.length"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div> </template
      ><span slot="noResult">Oops! Aucun élément trouvé.</span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { paginatorMixin } from "../mixins/paginator-mixin";
import { get } from "../api/api";

export default {
  props: {
    value: {
      type: [String | Object | Array],
    },
    option: {
      type: Object,
      required: true,
    },
    queryUrl: {
      type: String,
      required: true,
    },
    searchOptions: {
      type: Array,
      default: [],
    },
  },
  components: {
    Multiselect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      items: [],
      isLoading: false,
      suggestions: [],
    };
  },
  computed: {
    disabled() {
      if (this.option.enabled == null) return false;

      if (this.option.enabled instanceof Function) {
        return !this.option.enabled();
      }

      return !this.option.enabled;
    },
  },
  methods: {
    limitText(count) {
      return `et  ${count} autres éléments`;
    },
    asyncFind(query) {
      this.isLoading = true;
      let queryArg = "";

      this.searchOptions.forEach((opt) => (queryArg += opt + "=" + query + "&"));
      console.log(`${this.queryUrl}?${queryArg}`);
      get(`${this.queryUrl}?${queryArg}`).then(({ data }) => {
        this.items = data instanceof Array ? data : data.content;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selected = [];
    },
    loadData(event) {
      // if(this.this.option.url == undefined) return this.suggestions = []

      let url = `${this.option.url}?${this.option.key}=${event.query}`;
      if (this.option.urlBuilder != null) {
        url = this.option.urlBuilder();
      }

      get(url).then(({ data }) => {
        this.suggestions = data instanceof Array ? data : data.content;
      });
    },
  },
};
</script>

<style></style>
