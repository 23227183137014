<template>
  <component
    :is="mode"
    header="Header"
    footer="Footer"
    :class="{ 'p-sidebar-lg p-sidebar-close': mode == 'PSidebar' }"
    :dismissable="true"
    :visible.sync="showViewer"
    :position="mode == 'PSidebar' ? 'right' : ''"
    :containerStyle="{ width: mode == 'PSidebar' ? '70vw' : '40vw' }"
  >
    <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />
    <PCard class="shadow-0 border-0">
      <template #title>
        <div class="d-flex justify-content-between align-items">
          <h4 v-if="candidature.candidat">
            <span
              >Candidat: {{ candidature.candidat.nom }}
              {{ candidature.candidat.prenom }}</span
            >
            <PBadge
              :severity="candidature.status == 'PRESELECTIONNE' ? 'primary' : 'danger'"
              :value="candidature.status"
            ></PBadge>
          </h4>

          <div class="d">
            <template
              v-if="
                $can('treat', 'Candidature') &&
                candidature.status != 'EXIGE' &&
                candidature.status != 'RETENU'
              "
            >
              <!-- <PButton @click="rejeterCandidature()" label="Rejeter" class="me-2 p-button-outlined p-button-rounded" />
              <PButton @click="preselectionerCandidature()" label="Présélectionner" class="p-button-rounded" /> -->
            </template>
            <PButton
              @click.stop="hide()"
              icon="pi pi-times"
              class="p-button-outlined p-button-rounded p-button-sm ms-2"
            />
          </div>
        </div>
        <PDivider />
      </template>
      <template #content>
        <div>
          <!-- <PMessage :life="duration" severity="success">Candidat {{ candidature.status }}</PMessage> -->
          <PTabView ref="tabview1" :activeIndex.sync="activeTabIndex">
            <PTabPanel header="Evaluation">
              <CandidatureEvaluateur
                :candidatureId="candidatureId"
                :evaluation-type="
                  candidature.typeCandidature != 'RECOMMANDE'
                    ? 'MOTIVATION_ET_CV'
                    : 'NORMAL'
                "
                v-if="activeTabIndex == 0"
                @change="$emit('change')"
              />
            </PTabPanel>
            <PTabPanel header="Candidature">
              <CandidatureInfo
                v-if="activeTabIndex == 1"
                :candidatureId="candidatureId"
              />
            </PTabPanel>
            <PTabPanel header="CV du candidat">
              <DemandeurCvDetail
                ref="cvDetail"
                v-if="
                  activeTabIndex == 2 && candidature.candidat && candidature.candidat.id
                "
                :demandeurId="candidature.candidat.id"
              />
            </PTabPanel>
            <PTabPanel header="Offre">
              <OffreDetailViewer
                v-if="activeTabIndex == 3"
                :offreId="candidature.offre.id"
              />
            </PTabPanel>
          </PTabView>
        </div>
      </template>
    </PCard>
  </component>
</template>

<script>
import { mapActions } from "vuex";
import DemandeurCvDetail from "../../../../components/espace/demandeur/DemandeurCvDetail.vue";
import OffreDetailViewer from "../../../portail/OffreDetailViewer.vue";
import CandidatureEvaluateur from "./CandidatureEvaluateur.vue";
import CandidatureInfo from "./CandidatureInfo.vue";

export default {
  props: {
    candidatureId: {
      type: Number,
      required: true,
    },
    candidature: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "PSidebar",
    },
  },
  computed: {
    // ...mapGetters({
    //   candidature: 'candidature/candidature'
    // })
  },
  components: {
    DemandeurCvDetail,
    OffreDetailViewer,
    CandidatureEvaluateur,
    CandidatureInfo,
  },
  data() {
    return {
      candidature: {},
      activeOffre: {},
      duration: 0,
      activeTabIndex: 0,
      busy: false,
      showViewer: false,
    };
  },
  created() {},
  watch: {
    candidatureId: {
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCandidature: "candidature/fetchCandidature",
      updateCandidature: "candidature/updateCandidature",
    }),
    loadData() {
      //   this.candidature = {};
      //   this.busy = true;
      //   this.fetchCandidature({
      //     candidatureId: this.candidatureId,
      //   }).then((data) => {
      //     this.candidature = { ...data };
      //     this.busy = false;
      //   });
    },
    hide() {
      this.showViewer = false;
    },
    show() {
      this.loadData();
      this.showViewer = true;
    },
  },
};
</script>

<style>
.p-sidebar-header {
  display: none;
}
</style>
