<template>
  <PCard class="shadow-none">
    <template #title>
      <div class="d-flex justify-content-between">
        <span> PREOCCUPATIONS ENVIRONNEMENTALES ET SOCIALES </span>
        <!-- <a class="btn btn-light" href=""><i class="pi pi-caret-right"></i> EVADA</a> -->
      </div>
    </template>
    <template #subtitle>
      <p-progress-bar v-if="mxLoading" style="height: 0.4em" mode="indeterminate" />
      <template>
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="secteurs"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <Column :expander="true" :headerStyle="{ width: '3rem' }" />
                <template #row-details="row">
                  <questionnaire
                    :microprojetId="microprojetId"
                    :secteurId="row.item.id"
                  />
                </template>
                <!-- <template #cell(noteCriticite)="{ item: constat }">
                                   
                                    <PTag class="badge " :class="constat.codeCouleurCritique"> {{ constat.noteCriticite }}
                                    </PTag>
                                </template> -->

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les questionnaires'"
                      @click="row.toggleDetails"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                <!-- Affichage de l’élément
                {{ mxPagination.page *mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments -->
              </p>
              <!-- <b-pagination v-model="mxPagination.page" :total-rows="mxTotalCount"
                                :per-page="mxPagination.rowsPerPage" aria-controls="my-table"></b-pagination> -->
            </div>
          </div>
        </div>
      </template>
    </template>
  </PCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Questionnaire from "./Questionnaire.vue";
export default {
  props: ["microprojetId"],
  components: {
    Questionnaire,
  },
  data() {
    return {
      screening: {},
      activeStepIndex: 0,
      mxLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      secteurs: "screeningsecteur/secteurs",
      reponsescreenings: "screeningreponse/reponsescreenings",
    }),
    tableFields() {
      return [
        "#",
        "index",
        // { key: "id", label: "N°" },
        { key: "libelle", label: "Libelle" },
        "actions",
      ];
    },
  },
  created() {
    this.fetchSecteurs();
  },
  methods: {
    ...mapActions({
      fetchScreenings: "screening/fetchScreenings",
      fetchSecteurs: "screeningsecteur/fetchSecteurs",
      fetchScreeningsReponseBySecteur: "screeningreponse/fetchScreeningsReponseBySecteur",
      fetchRapportsScreening: "rapport/fetchRapportsScreening",
      fetchScreeningsMicroProjet: "screening/fetchScreeningsMicroProjet",
      createOrUpdateScreening: "screening/createOrUpdateScreening",
    }),
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
  },
};
</script>
