<template>
  <div class="row">
    <form ref="form" method="post" @submit.prevent="handleSubmit()">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <h6>Type de poste</h6>
            <div class="form-floating mb-3">
              <c-select
                id="typePosteRh"
                name="typePosteRh"
                option-label="label"
                option-value="value"
                :emitObject="false"
                v-model="editableItem.typePosteRh"
                :options="
                  typePosteRhs.filter((item) =>
                    editableItem.groupePersonnel != 'DEJA_EN_PLACE'
                      ? item.value != 'PROMOTEUR'
                      : true
                  )
                "
                class="form-select"
              >
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <label for="typePosteRh">Type de poste</label>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 col-md-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <h6>
              Formation ou Qualification professionnelle dans le métier objet du
              microprojet
            </h6>
            <div class="form-floating mb-3">
              <c-select
                id="qualificationProfessionnelle"
                name="qualificationProfessionnelle"
                option-label="libelle"
                option-value="id"
                v-model="editableItem.qualificationProfessionnelle"
                :options="qualificationProfessionnelles"
                class="form-select"
              >
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <label for="qualificationProfessionnelle"
                >Qualification pour le méter</label
              >
            </div>
          </ValidationProvider>
        </div>
        <div
          v-if="
            editableItem.qualificationProfessionnelle &&
            editableItem.qualificationProfessionnelle.code == 'AUTRES'
          "
          class="col-sm-12 col-md-12"
        >
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.autreFormation"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="autreFormation"
                  name="autreFormation"
                />
                <label for="autreFormation">Autres formations</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-12 col-md-12">
          <h6>Dans quel métier?</h6>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form- mb-3">
              <metier-select v-model="editableItem.metierQualification">
                <template #label>
                  <label for="metierQualification">Métier de qualification</label>
                </template>
              </metier-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 col-md-4">
          <h6>Effectif Homme</h6>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.effectifHomme"
                  type="number"
                  class="form-control"
                  id="effectifHomme"
                  name="effectifHomme"
                />
                <label for="effectifHomme"> Effectif Homme</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 col-md-4">
          <h6>Effectif Femme</h6>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.effectifFemme"
                  type="number"
                  class="form-control"
                  id="effectifFemme"
                  name="effectifFemme"
                />
                <label for="effectifFemme"> Effectif Femme</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 col-md-4">
          <h6>Total Effectif</h6>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <div class="form-floating mb-3">
                <input
                  :value="totalEffectif"
                  type="number"
                  class="form-control"
                  id="totalEffectif"
                  name="totalEffectif"
                  readonly
                />
                <label for="totalEffectif"> Total Effectif</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12 col-md-12">
          <h6>Salaire mensuel</h6>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.salaireMensuel"
                  type="number"
                  class="form-control"
                  id="salaireMensuel"
                  name="salaireMensuel"
                />
                <label for="salaireMensuel">Salaire mensuel</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>

    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <PTextarea
            v-model="editableItem.taches"
            type="text"
            class="form-control"
            id="taches"
            name="taches"
            placeholder="Tâches"
            :autoResize="true"
            rows="3"
            cols="30"
          />
          <label for="taches">Tâches</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import MetierSelect from "@/components/common/MetierSelect.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["value"],
  components: { DatePicker, CYesOrNoInput, MetierSelect },
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchQualificationProfessionnelles();
    this.fetchMoyenQualifications();
  },
  computed: {
    ...mapGetters({
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      moyenQualifications: "moyenQualification/moyenQualifications",
      uniteExperiences: "setting/uniteExperiences",
      typePosteRhs: "setting/typePosteRhs",
    }),
    totalEffectif() {
      return (
        parseInt(this.editableItem.effectifFemme || 0) +
        parseInt(this.editableItem.effectifHomme || 0)
      );
    },
  },
  methods: {
    ...mapActions({
      fetchPrerequisMicroprojets: "prerequisMicroprojet/fetchPrerequisMicroprojets",
      createOrUpdatePrerequisMicroprojet:
        "prerequisMicroprojet/createOrUpdatePrerequisMicroprojet",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchMoyenQualifications: "moyenQualification/fetchMoyenQualifications",
    }),
  },
};
</script>
