export const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    let targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
  })
}

export const arrayDiff = (arr1, arr2, prop) => {
  let val = arr1.filter(el => !arr2.map(el2 => el2[prop]).includes(el[prop]) )
  return val
}

export const arrayRemove = (arr1, arr2, prop) => {

  let val = arr1.filter(el => !arr2.map(el2 => el2[prop]).includes(el[prop]) )
  return val
}


export const updateSavedSelection = (saved, newSelection, oldSelection, prop) => {
  let selectionDiff = arrayDiff(oldSelection, newSelection, prop)
  console.log('selectionDiff:', selectionDiff)
  let savedMinusSelectionDiff = arrayRemove(saved, selectionDiff, prop) 
  console.log('savedMinusSelectionDiff:', savedMinusSelectionDiff)
  
  mergeByProperty(savedMinusSelectionDiff, newSelection, prop)
  console.log('saved:', savedMinusSelectionDiff)
  // saved = saved.concat([...savedMinusSelectionDiff])
  return savedMinusSelectionDiff
}

export const getImg64 = async() => {
  const convertImgToBase64URL = (url) => {
  console.log(url)
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
          let canvas = document.createElement('CANVAS')
          const ctx = canvas.getContext('2d')
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL();
          canvas = null;
          resolve(dataURL)
      }
      img.src = url;
    })
  }
}


export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
