<template>
  <div class="">
    <div class="row fw-bold bg-white">
      <div class="col border-right">
        <span class="stat-label">PRESENT:</span>
        <span class="stat-value">{{ stat.presentCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">ABSENT:</span>
        <span class="stat-value">{{ stat.absentCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">INCONNU:</span>
        <span class="stat-value">{{ stat.presenceInconnueCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">TOTAL:</span>
        <span class="stat-value">{{ stat.totalCount }}</span>
      </div>
    </div>
    <PDivider />
    <div class="row fw-bold bg-white">
      <div class="col">
        <span class="stat-label">DENA:</span>
        <span class="stat-value">{{ stat.autonomieDenaCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">DESA:</span>
        <span class="stat-value">{{ stat.autonomieDesaCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">DEA:</span>
        <span class="stat-value">{{ stat.autonomieDeaCount }}</span>
      </div>
      <div class="col">
        <span class="stat-label">TOTAL:</span>
        <span class="stat-value">{{ stat.totalCount }}</span>
      </div>
    </div>
    <PDivider />
    <SearchableItemActiviteParticipantSelector
      :listTitle="`Liste des participant à l\'activite ${activite.titre}`"
      :referentielName="referentielName"
      :tableFields="tableFields"
      :selectable="false"
      v-model="activite.participants"
      :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
      :emptyReferentielItem="emptyReferentielItem"
    />
  </div>
</template>

<script>
import SearchableItemActiviteParticipantSelector from "../../../../components/common/SearchableItemActiviteParticipantSelector.vue";
import { mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    activite: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SearchableItemActiviteParticipantSelector,
  },
  data() {
    return {
      display: false,
      stat: {
        presentCount: 0,
        absentCount: 0,
        presenceInconnueCount: 0,
        autonomieDeaCount: 0,
        autonomieDenaCount: 0,
        autonomieDesaCount: 0,
        autonomieInconnueCount: 0,
        totalCount: 0,
      },
      editableItem: {
        datePrevue: null,
        typeActivite: null,
        participants: [],
      },
    };
  },
  created() {
    this.loadParticipationStatistics();
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    referentielName() {
      return "participation";
    },
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "participant.nom", label: "Nom" },
        { key: "participant.prenom", label: "Prénom" },
        { key: "participant.phone", label: "Téléphone" },
        { key: "participant.commune.nom", label: "Commune" },
        { key: "presenceStatus", label: "Présence" },
      ];
      if (this.activite.typeActivite.code == "ENTRETIEN_DIAGNOSTIC") {
        fields.push({ key: "classement", label: "D° Autonomie" });
      }

      return fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    ...mapActions({
      fetchActiviteParticipationStatistics:
        "activitePlanifie/fetchActiviteParticipationStatistics",
    }),
    loadParticipationStatistics() {
      this.fetchActiviteParticipationStatistics({
        activiteId: this.activite.id,
      }).then((data) => {
        this.stat = data;
      });
    },
    extraQueryArgsParamBuilder() {
      return `activiteId=${this.activite.id}`;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>

<style>
.stat-label {
  margin-right: 10px;
}
.stat-value {
  font-size: 1.2rem;
}
</style>
