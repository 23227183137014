var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{ref:"form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Au cours de la semaine dernière (7 derniers jours), avez-vous travaillé ne serait-ce qu'une heure?"},model:{value:(_vm.editableItem.travaillerAuMoinsUneHeureSemainePasse),callback:function ($$v) {_vm.$set(_vm.editableItem, "travaillerAuMoinsUneHeureSemainePasse", $$v)},expression:"editableItem.travaillerAuMoinsUneHeureSemainePasse"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),(
            _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
            _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse !== true
          )?_c('div',{staticClass:"col-sm-12"},[_c('h5',[_vm._v(" Bien que vous ayez déclaré ne pas avoir travaillé la semaine dernière, avez-vous réalisé l'une des activités suivantes la semaine dernière, à domicile ou à l'extérieur, pour aider la famille: ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form-floating mb-3"},[_c('c-select',{staticClass:"form-select",attrs:{"id":"serviceRenduSemaineDerniere","name":"serviceRenduSemaineDerniere","aria-label":"Sélection votre service rendu","option-label":"libelle","option-value":"id","options":_vm.serviceRendus},model:{value:(_vm.editableItem.serviceRenduSemaineDerniere),callback:function ($$v) {_vm.$set(_vm.editableItem, "serviceRenduSemaineDerniere", $$v)},expression:"editableItem.serviceRenduSemaineDerniere"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])}),_c('label',{attrs:{"for":"serviceRenduSemaineDerniere"}},[_vm._v("Service rendu")])],2)]}}],null,true)})],1):_vm._e(),(
            _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
            _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse !== true
          )?_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Bien que vous n'ayez pas travaillé la semaine dernière, avez-vous un emploi?"},model:{value:(_vm.editableItem.hasEmploi),callback:function ($$v) {_vm.$set(_vm.editableItem, "hasEmploi", $$v)},expression:"editableItem.hasEmploi"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1):_vm._e(),(_vm.editableItem.hasEmploi)?[_c('div',{staticClass:"col-sm-12"},[_c('h5',[_vm._v(" Combien d'heures avez-vous consacrées à votre emploi principal au cours de la semaine dernière ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form-floating mb-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"number","id":"heureConsacreAEmploiPrincipal","name":"heureConsacreAEmploiPrincipal"},domProps:{"value":_vm.editableItem.heureConsacreAEmploiPrincipal},on:{"input":_vm.handleInput}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])}),_c('label',{attrs:{"for":"heureConsacreAEmploiPrincipal"}},[_vm._v("Nombre d'heure de travail")])],2)]}}],null,true)})],1)]:_vm._e(),[(
              _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
              _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse == true
            )?_c('div',{staticClass:"col-sm-12 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('c-yes-or-no-input',{attrs:{"question":"Souhaiteriez-vous travailler plus?"},model:{value:(_vm.editableItem.travaillerPlus),callback:function ($$v) {_vm.$set(_vm.editableItem, "travaillerPlus", $$v)},expression:"editableItem.travaillerPlus"}}),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1):_vm._e(),(
              _vm.editableItem.travaillerAuMoinsUneHeureSemainePasse === true ||
              _vm.editableItem.hasEmploi === true
            )?_c('div',{staticClass:"col-sm-12"},[_c('h5',[_vm._v(" Actuellement le salaire minimum mensuel est de 52 000 FCFA, quel est le montant de votre rémunération par rapport au salaire minimum? ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form-floating mb-3"},[_c('select',{staticClass:"form-select",attrs:{"id":"plageSalaireActuel","name":"plageSalaireActuel"},domProps:{"value":_vm.editableItem.plageSalaireActuel},on:{"input":_vm.handleInput}},_vm._l((_vm.plageSalaires),function(n){return _c('option',{key:n.value,domProps:{"value":n.value}},[_vm._v(" "+_vm._s(n.label)+" ")])}),0),_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"text-danger"},[_vm._v(_vm._s(err))])}),_c('label',{attrs:{"for":"plageSalaireActuel"}},[_vm._v("Plage de sailaire")])],2)]}}],null,true)})],1):_vm._e()]],2)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }