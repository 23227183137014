<template>
  <div>
    <PDialog
      class="stepper-dialog"
      :header="''"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '50vw' }"
    >
      <template #header>
        <div class="card shadow-3 px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-">
              <!-- <PButton icon="pi pi-times" class="p-button-rounded p-button-primary" /> -->
            </h4>
            <h4 class="d-flex justify-content-between">
              <div>
                Geler le parcours du demandeur - {{ demandeur.nom }}
                {{ demandeur.prenom }}
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
        </div>
      </template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card py-4 border-0 px-3">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <div class="">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <h5>
                  Pour quelle raison voulez-vous geler cette prise en charge du DE
                  {{ demandeur.nom }}?
                </h5>
                <div class="form-floating mb-3">
                  <textarea
                    v-model="editableItem.metierApprisDescription"
                    type="text"
                    rows="30"
                    style="height: 200px"
                    class="form-control"
                    id="raisonAnnulation"
                    name="raisonAnnulation"
                    placeholder="Raison d'annulation de la prise en charge"
                  />
                  <label for="raisonAnnulation"
                    >Raison d'annulation de la prise en charge
                  </label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" />
      </template>
    </PDialog>
  </div>
</template>

<script>
export default {
  props: ["demandeur", "priseEnChargeId"],
  components: {},
  data() {
    return {
      note: null,
      display: false,
      editableItem: {},
      maxScore: 10,
      activeStepIndex: 0,
    };
  },
  watch: {},
  methods: {
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
      this.resetContent();
    },
    resetContent() {
      this.editableItem = {
        priseEnChargeId: this.priseEnChargeId,
      };
      this.activeStepIndex = 0;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        priseEnChargeId: this.priseEnChargeId,
      });
      this.resetContent();
      this.hide();
    },
  },
};
</script>

<style>
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.stepper-dialog .p-dialog-header-icons {
  display: none;
}
</style>
