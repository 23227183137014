<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <select
              :value="editableItem.sexe"
              @input="handleInput"
              class="form-select form-select mb-4"
              id="sexe"
              name="sexe"
              aria-label="Votre sexe"
            >
              <option value="M">Masculin</option>
              <option value="F">Féminin</option>
            </select>
            <label for="sexe">Sexe</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <hr />
        <div class="text-uppercase">Langues nationales parlées</div>
        <block-maitrise-langues
          :max-items="5"
          :hideNiveau="true"
          v-model="editableItem.maitriseLangues"
          :azoli="editableItem"
        />
        <!-- <block-maitrise-langues title="" v-model="editableItem.maitriseLangues" :hideAlphabetisation="true" /> -->
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.situationMatrimoniale"
                  @input="handleInput"
                  class="form-select form-select mb-4"
                  id="situationMatrimoniale"
                  name="situationMatrimoniale"
                  aria-label="Votre situation matrimoniale"
                >
                  <option
                    v-for="st in situationMatrimoniales"
                    :key="st.value"
                    :value="st.value"
                  >
                    {{ st.label }}
                  </option>
                </select>
                <label for="situationMatrimoniale">Situation matrimoniale</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <template v-if="editableItem.sexe == 'F'">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.nombreEnfantsMoins"
                    @input="handleInput"
                    type="number"
                    id="nombreEnfantsMoins"
                    name="nombreEnfantsMoins"
                    class="form-control"
                    placeholder="Nombre d’enfants de moins de trois mois (mettre 0, si aucun)"
                  />
                  <label for="nombreEnfantsMoins"
                    >Nombre d’enfants de moins de trois mois (mettre 0, si aucun)</label
                  >
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.nombreEnfantsPlus"
                    @input="handleInput"
                    type="number"
                    id="nombreEnfantsPlus"
                    name="nombreEnfantsPlus"
                    class="form-control"
                    placeholder="Nombre d’enfants âgés de trois mois à trois ans inclus (mettre 0, si aucun)"
                  />
                  <label for="nombreEnfantsPlus"
                    >Nombre d’enfants âgés de trois mois à trois ans inclus (mettre 0, si
                    aucun)</label
                  >
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <!-- <div class="col-sm-12">
              <c-yes-or-no-input
                question="Etes-vous actuellement en état de grossesse?"
                v-model="editableItem.grossesse"
                :responses="gressesseResponses"
              >
              </c-yes-or-no-input>
            </div> -->
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CYesOrNoInput from "../../../../CYesOrNoInput.vue";
import BlockMaitriseLangues from "../BlockMaitriseLangues.vue";
export default {
  props: ["value"],
  components: {
    CYesOrNoInput,
    BlockMaitriseLangues,
  },
  data() {
    return {
      hasNip: null,
      pieces: [],
      editableItem: this.value,
      situationMatrimoniales: [
        { value: "CELIBATAIRE", label: "Célibataire" },
        { value: "MARIE_MONOGAME", label: "Marié monogame" },
        { value: "MARIE_POLYGAME", label: "Marié polygame" },
        { value: "UNION_LIBRE", label: "Union libre" },
        { value: "VEUF", label: "Veuf(ve)" },
        { value: "DIVORCE", label: "Divorcé" },
        { value: "SEPARE", label: "Séparé" },
        { value: "AUTRE", label: "Non classé(e)" },
      ],
      gressesseResponses: [
        { value: true, label: "Oui" },
        { value: false, label: "Non" },
        { value: null, label: "Je ne sais pas" },
      ],
    };
  },
  computed: {},
  watch: {
    "editableItem.maitriseLangues": {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
