/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box">
      <!-- <activite-statistic ref="activiteStatistic" class="mt-3 mb-2"/> -->
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: .33em" mode="indeterminate"/>
      <div class="bg-white  p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field  col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />           
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
                />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                  <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText type="text" v-model="mxSearch" :placeholder="'Rechercher par'" />
                    <p-multi-select v-model="mxSearchFields" :options="searchFieldsOptions.filter(el => el.visible)" optionLabel="label" optionValue="value" :editable="true"/>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a href="#" @click.prevent="mxSubmitQuery" class="style_btn btn btn-primary"  id="button-query"><i class="bi bi-search"></i></a>
              </div>
            </div>
            <div class="col-auto">
              <button type="button"  @click.prevent="showAdvancedFilter = !showAdvancedFilter" class="style_btn btn btn-warning"  id="button-query"><i class="bi bi-filter" ></i></button>
            </div>
          </div>
        </form>
        <transition>
        <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
          <div class="row p-fluid p-3 grid">
            <template v-for="(option, index) in filterOptions">
              <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                <label for="basic">{{option.label}}</label>
                <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" />
                <p-multi-select v-else-if="option.items && option.items.length != 0" v-model="option.value" :options="option.items" :optionLabel="option.key" :placeholder="option.label" display="chip"/>
                <p-input-text v-else v-model="option.value" :placeholder="option.label" />
              </div>
            </template>
          </div>
        </div>
      </transition>
      </div>
      <p-divider />
       <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des prescriptions</h4>
          </div>   
          <div v-if="$can('create', 'Azoli')"  class="btn-group">
            <a @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })" href="" class="style_btn btn btn-warning">Ajouter une prescription</a>
          </div>
          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton label="Exporter" :model="exportActions" class="p-button-outlined p-button-primary"/>
          </div>
          <div class="btn-group ms-4">
            <button data-bs-toggle="tooltip" data-bs-placement="top" title="Affichage mode tableau" @click.prevent="viewMode='card'" class="btn style_btn btn-md" :class="{ 'btn-success': viewMode == 'card', 'btn-light': viewMode == 'table' }">
              <i class="bi-grid"/>
            </button>
            <button @click.prevent="viewMode='table'" class="btn style_btn" :class="{ 'btn-light': viewMode == 'card', 'btn-success': viewMode == 'table' }" >
              <i class="bi-table"/>
            </button>
          </div>
        </div>
      <template v-if="!mxLoading">
        <div class="bg-white -">
          <Prescriptions v-model="tableData" />
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>Affichage de l’élément {{ mxPagination.page *mxPagination.rowsPerPage }} à {{ (mxPagination.page + 1)*mxPagination.rowsPerPage }} de {{ mxTotalCount }} éléments</p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
      </template>
      <template v-else>
        <div>
          <PDataTable :value="[1,2,3,4,5,6,7,8,9,10].map(e => {e})">
            <PColumn v-for="f in tableFields" :key="f.key" :field="f.key" :header="f.label">
              <template #body>
                <PSkeleton ></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if=" !mxLoading && mxFilteredItems.length == 0">
        <div style="min-height: 200px" class="no-content pa-4 d-flex flex-column justify-content-center align-items-center">
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a v-if="$can('create', 'Azoli')" @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })" href="" class="style_btn btn btn-sm btn-outline-primary">Ajouter un PPI</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { paginatorMixin } from '@/mixins/paginator-mixin'
import Prescriptions from "../../../components/espace/common/prise-en-charge/Prescriptions.vue";
import CAutoComplete from '../../../components/common/CAutoComplete.vue'
// import ActiviteStatistic from '../../../components/statistic/activite/ActiviteStatistic.vue'

// import * as XLSX from 'xlsx'

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ''
    }
  },
  components: {
    // ActiviteStatistic,
    CAutoComplete,
    Prescriptions,
  },
  mixins: [paginatorMixin],
  data () {
    return {
      selected: [],
      search: '',
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem('viewMode') || 'card', //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      exportActions: [
				{
					label: 'Excel',
					icon: 'pi pi-file-excel',
					command: () => {
            let params = this.mxGetQueryBuilder()
            let route = this.$router.resolve({
              name: 'common.export',
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: 'Liste des bénéficiaires Azoli.xlsx'
              }
            })
            window.open(route.href, '_blank')
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
					}
				},
			],
      searchFields: [ 'titre' ],
      searchFieldsOptions: [
        { label: 'Auteur' , value: 'creePar', visible: this.$can('filter.beneficiaire', 'Azoli') },
        { label: 'Titre' , value: 'titre', visible: this.$can('filter.beneficiaire', 'Azoli') },
        { label: 'Statut de l\'activité' , value: 'statusActivite',  visible: this.$can('filter.statusAbeneficiairectivite', 'Azoli') },
        { label: 'Type Activité' , value: 'typeActivite',  visible: this.$can('filter.beneficiaire', 'Azoli') }
      ],
      filterOptions: [
        {
          column: 'titre',
          value: '',
          label: 'Titre',
          dropdown: false,
          visible: this.$can('filter.beneficiaire', 'Azoli')
        },
        {
          column: 'creePar',
          value: '',
          label: 'Auteur',
          dropdown: false,
          visible: this.$can('filter.ae', 'Azoli')
        },
        {
          column: 'statusActivite',
          key: 'value',
          value: '',
          label: 'Statut de l\'activité',
          multiple: true,
          valueKey: 'value',
          items: [
            { value: 'CREE', label: 'Créée' },
            { value: 'CLOTUREE', label: 'Clôturée' },
            { value: 'NON_TENUE', label: 'Non tenue' },
            { value: 'TENUE', label: 'Tenue' },
          ],
          visible: this.$can('filter.status', 'Azoli')
        },
        {
          column: 'typeActivite',
          key: 'code',
          valueKey: 'code',
          value: '',
          label: 'Type activité',
          dropdown: true,
          url: '/typeActivites',
          visible: this.$can('filter.typeActivite', 'Azoli')
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: 'departement',
            value: null,
            label: 'Département'
          },
          {
            column: 'commune',
            value: null, 
            label: 'Commune'
          },
        ],
        ordering: ''
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },  
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'Demandeurs',
          active: true
        }
      ]
    }
  },
  created () {
    this.queryStatistics()
  },
  watch: {
    dossier(val){
      if(val.azoli){
        this.activeDemandeur = {
          ...val,
          ...val.azoli
        }
      }
    },
    viewMode(){
      localStorage.setItem('viewMode', this.viewMode)
    }
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: 'demandeur/demandeur',
      statutDossiers: 'setting/statutDossiers',
      // demandeurs: 'demandeur/demandeurs',
      pagination: 'demandeur/pagination'
    }),
    tableFields () {
      return [
        '#',
        // 'index',
        { key: 'id', label: 'Ref' },
        { key: 'participant', label: 'Bénéficiaire' },
        { key: 'programme.nom', label: 'Porgramme' },
        { key: 'dateDebut', label: 'Date Début' },
        { key: 'dateFin', label: 'Date Fin' },
        { key: 'statusParticipation', label: 'Statut' },
        'actions',
      ]
    },
    tableData () {
      return this.mxFilteredItems.map(item => {
        return { ...item }
      })
    }
  },
  methods: {
    ...mapActions({
    }),
    getStatusColor(activite){
      let bg = 'event-box-pending text-white'
        
        if( activite.statusActivite == 'ACTIF'){
          bg = 'event-box-done text-white'
        }
        else if(activite.statusActivite == 'ANNULE'){
          bg = 'event-box-missed text-white'
        }
        else if(activite.statusActivite == 'SUSPENDU'){
          bg = 'event-box-reported text-white'
        }
      return bg
    },
    statisticBlockSelected(status){
      this.mxSetOptionValue('status', status, true)
    },
    queryStatistics(){

      this.mxInitializePaginator({
        queryModel: 'prescription',
        search: this.search,
        fetcherMethod: 'prescription/fetchPrescriptions',
        dataGetter: 'prescription/prescriptions',
        paginationGetter: 'prescription/pagination',
        autoSubmitQuery: false,
        pagination: {
          sortBy: 'id',
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
          const dateFin = (this.dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)     
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`
        },
        searchFields: this.searchFields
      })
    },
    selectionChanged(evt, data){
      console.log(evt.target.checked, data)

      if(evt.target.checked){
        this.$refs.table.selectRow(data.index)
      }
      else {
        this.$refs.table.unselectRow(data.index)
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAll(evt){
      if(evt.target.checked){
        this.$refs.table.selectAllRows()
      }
      else {
        this.$refs.table.clearSelected()
      }
    },
    editDemandeur (demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$router.push({ name: 'espace.conseiller.de.edit' })
    }
  }
}
</script>

<style>

</style>