var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.mode,{tag:"component",class:{ 'p-sidebar-lg p-sidebar-close': _vm.mode == 'PSidebar' },attrs:{"header":"Header","footer":"Footer","dismissable":true,"visible":_vm.showViewer,"position":_vm.mode == 'PSidebar' ? 'right' : '',"containerStyle":{ width: _vm.mode == 'PSidebar' ? '70vw' : '40vw' }},on:{"update:visible":function($event){_vm.showViewer=$event}}},[(_vm.busy)?_c('p-progress-bar',{staticStyle:{"height":"0.33em"},attrs:{"mode":"indeterminate"}}):_vm._e(),_c('PCard',{staticClass:"shadow-0 border-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items"},[(_vm.candidature.candidat)?_c('h4',[_c('span',[_vm._v("Candidat: "+_vm._s(_vm.candidature.candidat.nom)+" "+_vm._s(_vm.candidature.candidat.prenom))]),_c('PBadge',{attrs:{"severity":_vm.candidature.status == 'PRESELECTIONNE' ? 'primary' : 'danger',"value":_vm.candidature.status}})],1):_vm._e(),_c('div',{staticClass:"d"},[(
              _vm.$can('treat', 'Candidature') &&
              _vm.candidature.status != 'EXIGE' &&
              _vm.candidature.status != 'RETENU'
            )?void 0:_vm._e(),_c('PButton',{staticClass:"p-button-outlined p-button-rounded p-button-sm ms-2",attrs:{"icon":"pi pi-times"},on:{"click":function($event){$event.stopPropagation();return _vm.hide()}}})],2)]),_c('PDivider')]},proxy:true},{key:"content",fn:function(){return [_c('div',[_c('PTabView',{ref:"tabview1",attrs:{"activeIndex":_vm.activeTabIndex},on:{"update:activeIndex":function($event){_vm.activeTabIndex=$event},"update:active-index":function($event){_vm.activeTabIndex=$event}}},[_c('PTabPanel',{attrs:{"header":"Evaluation"}},[(_vm.activeTabIndex == 0)?_c('CandidatureEvaluateur',{attrs:{"candidatureId":_vm.candidatureId,"evaluation-type":_vm.candidature.typeCandidature != 'RECOMMANDE'
                  ? 'MOTIVATION_ET_CV'
                  : 'NORMAL'},on:{"change":function($event){return _vm.$emit('change')}}}):_vm._e()],1),_c('PTabPanel',{attrs:{"header":"Candidature"}},[(_vm.activeTabIndex == 1)?_c('CandidatureInfo',{attrs:{"candidatureId":_vm.candidatureId}}):_vm._e()],1),_c('PTabPanel',{attrs:{"header":"CV du candidat"}},[(
                _vm.activeTabIndex == 2 && _vm.candidature.candidat && _vm.candidature.candidat.id
              )?_c('DemandeurCvDetail',{ref:"cvDetail",attrs:{"demandeurId":_vm.candidature.candidat.id}}):_vm._e()],1),_c('PTabPanel',{attrs:{"header":"Offre"}},[(_vm.activeTabIndex == 3)?_c('OffreDetailViewer',{attrs:{"offreId":_vm.candidature.offre.id}}):_vm._e()],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }