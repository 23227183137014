<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
          <MicroprojetEditor
            :title="
              activeMicroprojet && activeMicroprojet.id
                ? 'Traiter le microprojet'
                : 'Traiter le  microprojet'
            "
            :item="activeMicroprojet"
            :beneficiaire="demandeur"
            ref="microprojetEditor"
            @save="saveMicroprojetItem"
          />
          <slot name="header" :addMicroprojet="confirmThenAddMicroprojet">
            <div class="d-flex justify-content-between">
              <div class="mb-0 text-uppercase d-flex justify-content-between">
                <span>Microprojet</span>
                <button
                  @click.prevent="confirmThenAddMicroprojet()"
                  type="button"
                  class="btn btn-primary"
                  v-tooltip.top="'Ajouter un microprojet'"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </slot>
          <PDataTable
            class="shadow-none"
            :value="microprojets"
            :expandedRows.sync="expandedRows"
            stripedRows
            editMode="row"
            dataKey="id"
            stacked="sm"
            :editingRows.sync="editingRows"
          >
            <PColumn field="microprojet" header="Microprojet">
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.titre }}
                </a>
              </template>
            </PColumn>
            <PColumn field="programme" header="Programme">
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.programme.nom }}
                </a>
              </template>
            </PColumn>
            <PColumn field="artisanatSecteur" header="Secteur">
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.artisanatSecteur.libelle }}
                </a>
              </template>
            </PColumn>
            <PColumn
              field="artisanatMetier"
              :styles="{ width: colWidth }"
              header="Métier"
            >
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.artisanatMetier.libelle }}
                </a>
              </template>
            </PColumn>
            <PColumn field="tauxRemplissage" :styles="{ width: colWidth }" header="Taux">
              <template #body="{ data: item }">
                <!-- {{ slotProps.data.tauxRemplissage }}% -->
                <span v-if="!item.hasScreening">{{ item.tauxRemplissage }}%</span>
                <template v-else>
                  <span
                    v-if="item.statutScreening == 'TERMINE'"
                    class="text"
                    v-tooltip.top="
                      'Screening ' +
                      (item.statutScreening == null ? 'EN_COURS' : item.statutScreening)
                    "
                    v-badge.primary="'Scr'"
                    >{{ item.tauxRemplissage }}%</span
                  >
                  <span
                    v-else-if="item.statutScreening == 'VALIDE'"
                    class="text"
                    v-tooltip.top="'Screening VALIDE'"
                    v-badge.success="'Scr'"
                    >{{ item.tauxRemplissage }}%</span
                  >
                  <span
                    v-else
                    class="text"
                    v-tooltip.top="
                      'Screening ' +
                      (item.statutScreening == null ? 'EN_COURS' : item.statutScreening)
                    "
                    v-badge.danger="'Scr'"
                    >{{ item.tauxRemplissage }}%</span
                  >
                </template>
              </template>
            </PColumn>
            <PColumn field="status" :styles="{ width: colWidth }" header="Statut">
              <template #body="slotProps">
                <PBadge
                  :value="slotProps.data.status"
                  :severity="getStatusColor(slotProps.data.status)"
                />
              </template>
            </PColumn>
            <!-- <PColumn field="createdDate" :styles="{ width: colWidth }" header="Cré">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field] | moment("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn> -->
            <PColumn :styles="{ width: colWidth }" header="Action">
              <template #body="slotProps">
                <div class="d-flex justify-space-between">
                  <PButton
                    @click.prevent="$refs.microprojetEditor.show()"
                    icon="pi pi-pencil"
                    v-tooltip.top="'Consulter/Modifier'"
                    class="p-button-rounded p-button-primary"
                    @click="editMicroprojetItem(slotProps.data)"
                  />
                  <PButton
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    v-tooltip.top="'Supprimer'"
                    @click="confirmDeleteItem(slotProps.data)"
                  />
                </div>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MicroprojetEditor from "./editor/MicroprojetEditor.vue";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions } from "vuex";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    demandeur: {
      type: Object,
      required: true,
    },
    prescription: {
      type: Object,
      required: true,
    },
  },
  mixins: [paginatorMixin],
  components: {
    MicroprojetEditor,
  },
  data() {
    return {
      display: false,
      loading: true,
      microprojets: [],
      expandedRows: [],
      search: "",
      editingRows: [],
      activeMicroprojet: null,
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    mxFilteredItems() {
      this.microprojets = [...this.mxFilteredItems];
    },
  },
  computed: {
    colWidth() {
      return "100%";
    },
    etats() {
      return [
        {
          value: "NON_REALISE",
          label: "Non Réalisé",
        },
        {
          value: "EN_COURS",
          label: "En cours",
        },
        {
          value: "TERMINE",
          label: "Terminé",
        },
      ];
    },
    emptyMicroprojet() {
      return {
        participant: this.demandeur,
        observation: null,
        beneficiaireId: this.demandeur != null ? this.demandeur.id : null,
        // microprojet: null,
        departement: null,
        commune: null,
        arrondissement: null,
        ville: null,
        secteur: null,
        programme: null,
        // metier: null,
        statut: null,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchMicroprojets: "microprojet/fetchMicroprojets",
      createMicroprojet: "microprojet/createMicroprojet",
      updateMicroprojet: "microprojet/updateMicroprojet",
      deleteMicroprojet: "microprojet/deleteMicroprojet",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    getStatusColor(status) {
      let color = "primary";
      if (status == "NOUVEAU") {
        color = "danger";
      }
      if (status == "VALIDE") {
        color = "success";
      }
      if (status == "EN_COURS") {
        color = "warning";
      }
      if (status == "TERMINE") {
        color = "primary";
      }
      return color;
    },
    loadData() {
      this.mxLoading = true;
      this.mxInitializePaginator({
        queryModel: "microprojet",
        fetcherMethod: "microprojet/fetchMicroprojets",
        dataGetter: "microprojet/microprojets",
        paginationGetter: "microprojet/pagination",
        autoSubmitQuery: false,
        search: this.search,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: [],
        searchFields: [],
        extraQueryArgsParamBuilder: () => {
          return `&${this.extraQueryArg}`;
        },
      });
    },
    confirmDeleteItem(item) {
      this.deleteMicroprojet(item).then(() => {
        this.microprojets = this.microprojets.filter((prog) => prog.id != item.id);
        this.$toast.success("Contenu supprimé avec succès!");
      });
    },
    saveMicroprojetItem(item) {
      this.$emit("save", this.microprojets);
      // this.$emit('updatePrescription', {
      //   ...this.prescription,
      //   microprojets: this.microprojets.map(prog => ({
      //     ...prog,
      //     participant: this.demandeur,
      //     prescription: this.prescription
      //   })),
      // })
      this.$emit("input", this.microprojets);
      // this.$emit('input', this.microprojets)
      // this.$emit('save', item)

      this.createOrUpdateMicroprojet(item)
        .then((data) => {
          // this.microprojets = this.microprojets.filter(
          //   (prog) => prog.programme.id != data.programme.id
          // );
          //   this.microprojets.unshift(data);
          this.$toast.success("Opération réussie");
          window.location.reload();
        })
        .catch((err) => this.$toast.error("Une erreur s'est produite." + err));
    },
    async confirmThenAddMicroprojet() {
      if (this.microprojets.length > 0) {
        await this.$confirm.require({
          group: "confirmDialog",
          message: `Le DE ${this.demandeur.nom} ${this.demandeur.prenom} a déjà un microprojet. Voulez-vous vraiment lui ajouter un autre microprojet?\n (Si vous voulez modifier/consulter un microprojet existant utilisez plutôt le bouton bleu ayant l'icône d'un crayon.`,
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.addMicroprojet();
          },
        });
      } else {
        this.addMicroprojet();
      }
    },
    addMicroprojet() {
      if (this.demandeur.npiValide) {
        this.activeMicroprojet = {
          ...this.emptyMicroprojet,
          prescription: this.prescription,
          prescriptionId: this.prescription.id,
          beneficiaire: this.demandeur,
        };
        this.$refs.microprojetEditor.show();
      } else {
        this.$toast.error("La validation du NPI est réquise.");
      }
    },
    editMicroprojetItem(prog) {
      this.activeMicroprojet = { ...prog };
      this.$refs.microprojetEditor.show();
    },
  },
};
</script>
