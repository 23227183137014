<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="card mt border-0">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <template>
            <div class="row">
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      id="posteOccupe"
                      class="form-control"
                      name="posteOccupe"
                      v-model="editableItem.posteOccupe"
                    />
                    <label for="posteOccupe">Intitulé du poste</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>

              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="mb-3">
                    <label for="posteOccupe mb-1">Métier</label>
                    <metier-select v-model="editableItem.metier" />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>

              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <c-select
                      id="typePlacement"
                      name="typePlacement"
                      option-label="libelle"
                      v-model="editableItem.typePlacement"
                      :options="typePlacements"
                      class="form-select"
                    >
                    </c-select>
                    <label for="typePlacement">Type de placement (Stage/Formation)</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <c-select
                      id="natureContrat"
                      name="natureContrat"
                      option-label="description"
                      option-value="id"
                      v-model="editableItem.natureContrat"
                      :options="natureContrats"
                      class="form-select"
                    >
                    </c-select>
                    <label for="natureContrat">Nature contrat</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div>Date de début</div>
                  <p-calendar
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    name="dateDebut"
                    :manualInput="false"
                    v-model="editableItem.dateDebut"
                    :showWeek="true"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-6 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div>Date de fin</div>
                  <p-calendar
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    name="dateFin"
                    :manualInput="false"
                    v-model="editableItem.dateFin"
                    :showWeek="true"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div>Date prévue de prise de service</div>
                  <p-calendar
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    name="datePrevuePriseDeService"
                    :manualInput="false"
                    v-model="editableItem.datePrevuePriseDeService"
                    :showWeek="true"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 field mt-3">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <textarea
                      type="text"
                      rows="10"
                      style="height: 120px"
                      id="observation"
                      class="form-control"
                      name="observation"
                      v-model="editableItem.observation"
                    />
                    <label for="observation">Observation</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </template>
        </form>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import MetierSelect from "../../../../common/MetierSelect.vue";

export default {
  components: {
    MetierSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: { ...this.value },
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
    editableItem: {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },
  },
  created() {
    this.fetchNatureContrats();
    this.fetchTypePlacements();
  },
  computed: {
    ...mapGetters({
      typePlacements: "typePlacement/typePlacements",
      natureContrats: "natureContrat/natureContrats",
    }),
  },
  methods: {
    ...mapActions({
      fetchTypePlacements: "typePlacement/fetchTypePlacements",
      fetchNatureContrats: "natureContrat/fetchNatureContrats",
    }),
    resetContent() {
      this.mxInitializePaginator({
        queryModel: "entreprise",
        search: this.search,
        fetcherMethod: "entreprise/fetchEntreprises",
        dataGetter: "entreprise/entreprises",
        paginationGetter: "entreprise/pagination",
        pagination: {
          sortBy: "desc",
          descending: false,
          page: 0,
          rowsPerPage: 6,
        },
        filterOptions: this.filterOptions,
        searchFields: ["search", "ifu", "raisonSocial"],
      });
    },
    selectTypePlacement() {
      this.error = false;
      this.activeStepIndex = 1;
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
