<template>
  <div class="">
    <div class="field col-12 md:col-6" v-if="loading">
      <PSkeleton class="mb-2"></PSkeleton>
      <PSkeleton width="10rem" class="mb-2"></PSkeleton>
      <PSkeleton width="5rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="3rem" class="mb-2"></PSkeleton>
      <PSkeleton height="4rem" class="mb-2"></PSkeleton>
      <PSkeleton height="2rem" class="mb-2"></PSkeleton>
      <PSkeleton height="5rem"></PSkeleton>
    </div>
    <ValidationObserver v-if="!loading" ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div>Institution de financement</div>
              <AsyncSearchInput
                v-model="editableItem.credit.institutionFinancement"
                :queryUrl="'institutionFinancements'"
                :optionLabel="'denomination'"
                placeholder="Rechercher une institution financiaire"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 col-md-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.credit.montantCredit"
                    type="number"
                    class="form-control"
                    id="montantCredit"
                    name="montantCredit"
                  />
                  <label for="montantCredit"> Montant du crédit (en FCFA) </label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.credit.dureeCredit"
                        type="number"
                        class="form-control"
                        id="dureeCredit"
                        name="dureeCredit"
                      />
                      <label for="idee"> Durée du crédit</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <c-select
                      id="uniteDuree"
                      name="uniteDuree"
                      option-label="label"
                      option-value="code"
                      :emit-object="false"
                      v-model="editableItem.credit.uniteDuree"
                      :options="uniteDurees"
                      class="form-select"
                    >
                    </c-select>
                    <label for="uniteDuree">Unité durée</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="row">
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.credit.differe"
                        type="number"
                        class="form-control"
                        id="differe"
                        name="differe"
                      />
                      <label for="differe"> Différé</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.credit.remboursementMensuel"
                        type="number"
                        class="form-control"
                        id="remboursementMensuel"
                        name="remboursementMensuel"
                      />
                      <label for="remboursementMensuel">
                        Remboursement (mensualité)</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="row">
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.credit.tauxInteret"
                        type="number"
                        class="form-control"
                        id="tauxInteret"
                        name="tauxInteret"
                      />
                      <label for="tauxInteret"> Taux d’intérêt </label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.credit.montantTotalARembourser"
                        type="number"
                        class="form-control"
                        id="montantTotalARembourser"
                        name="montantTotalARembourser"
                      />
                      <label for="montantTotalARembourser">
                        Montant total à rembourser (en FCFA)</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-12 mb-4">
              <h6>Observation</h6>
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="col-sm-12 field md:col-12 field">
                  <div class="col-12 md:col-12">
                    <PTextarea
                      rows="5"
                      cols="30"
                      v-model="editableItem.credit.observation"
                      class="col-12"
                      :autoResize="true"
                      placeholder="Observation"
                    />
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";

export default {
  components: { CYesOrNoInput, AsyncSearchInput },
  props: ["value"],
  mixins: [stepperFormMixin],
  data() {
    return {
      loading: false,
      editableItem: {
        ...this.value,
        credit: {
          microProjet: this.value,
          montantCredit: null,
          dureeCredit: null,
          uniteDuree: null,
          differe: null,
          remboursementMensuel: null,
          tauxInteret: null,
          montantTotalARembourser: null,
          institutionFinancement: null,
        },
      },
    };
  },
  created() {
    this.loading = true;
    this.fetchCredits({
      extraQueryArg: `microProjet.id=${this.value.id}`,
    }).then((data) => {
      if (data.length != 0) {
        this.editableItem = {
          ...this.editableItem,
          credit: data[0],
        };
      }
      this.loading = false;
    });
    this.fetchQualificationProfessionnelles();
    this.fetchMoyenQualifications();
  },
  computed: {
    ...mapGetters({
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      moyenQualifications: "moyenQualification/moyenQualifications",
      uniteDurees: "setting/uniteExperiences",
    }),
  },
  methods: {
    ...mapActions({
      fetchCredits: "credit/fetchCredits",
      createOrUpdateCredit: "credit/createOrUpdateCredit",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchMoyenQualifications: "moyenQualification/fetchMoyenQualifications",
    }),

    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateCredit({
        microProjet: this.value,
        ...this.editableItem.credit,
      });
      this.$emit("save", { ...this.editableItem });
    },
  },
};
</script>

<style scoped></style>
