<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit()"
        class=""
        method="post"
        accept-charset="utf-8"
        action=""
      >
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="artisanatSecteur"
                  name="artisanatSecteur"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.artisanatSecteur"
                  :options="artisanatSecteurs"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="artisanatSecteur">Secteur</label>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  :disabled="editableItem.artisanatSecteur == null"
                  id="artisanatBranche"
                  name="artisanatBranche"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.artisanatBranche"
                  :options="artisanatBranches"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="commune">Branche/Division</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  :disabled="editableItem.artisanatBranche == null"
                  id="artisanatGroupe"
                  name="artisanatGroupe"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.artisanatGroupe"
                  :options="artisanatGroupes"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="arrondissement">Groupe</label>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  :disabled="editableItem.artisanatGroupe == null"
                  id="artisanatGroupe"
                  name="artisanatGroupe"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.artisanatMetier"
                  :options="artisanatMetiers"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="ville">Métier</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <template> </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      editableItem: this.value,
    };
  },
  mixins: [setepperFormMixin],
  computed: {
    ...mapGetters({
      artisanatSecteurs: "artisanatSecteur/artisanatSecteurs",
      artisanatBranches: "artisanatBranche/artisanatBranches",
      artisanatGroupes: "artisanatGroupe/artisanatGroupes",
      artisanatMetiers: "artisanatMetier/artisanatMetiers",
    }),
  },
  watch: {
    "editableItem.artisanatSecteur": {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.fetchArtisanatBranches({
            extraQueryArg: `artisanatSecteurId=${this.editableItem.artisanatSecteur.id}`,
          });
        }
      },
    },
    "editableItem.artisanatBranche": {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.fetchArtisanatGroupes({
            extraQueryArg: `artisanatBrancheId=${this.editableItem.artisanatBranche.id}`,
          });
        }
      },
    },
    "editableItem.artisanatGroupe": {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.fetchArtisanatMetiers({
            extraQueryArg: `artisanatGroupeId=${this.editableItem.artisanatGroupe.id}`,
          });
        }
      },
    },
  },
  created() {
    this.fetchArtisanatSecteurs();
    if (this.editableItem.artisanatSecteur != null) {
      this.fetchArtisanatBranches({
        extraQueryArg: `artisanatSecteurId=${this.editableItem.artisanatSecteur.id}`,
      });
      this.fetchArtisanatGroupes({
        extraQueryArg: `artisanatBrancheId=${this.editableItem.artisanatBranche.id}`,
      });
      this.fetchArtisanatMetiers({
        extraQueryArg: `artisanatGroupeId=${this.editableItem.artisanatGroupe.id}`,
      });
    }
  },
  methods: {
    ...mapActions({
      fetchArtisanatSecteurs: "artisanatSecteur/fetchArtisanatSecteurs",
      fetchArtisanatBranches: "artisanatBranche/fetchArtisanatBranches",
      fetchArtisanatGroupes: "artisanatGroupe/fetchArtisanatGroupes",
      fetchArtisanatMetiers: "artisanatMetier/fetchArtisanatMetiers",
    }),
  },
};
</script>

<style scoped></style>
