<template>
  <div>
    <PDialog
      :header="`Modification des informations du DE - ${demandeur.nom} ${demandeur.prenom}`"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '70vw' }"
      :contentStyle="{ overflow: 'auto' }"
    >
      <div class="card border-none bg-light">
        <div class="card-body">
          <DemandeurCiviliteEdit
            ref="civiliteEditor"
            :item="demandeur"
            @updated="handleUpdated"
          />
        </div>
      </div>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click.prevent="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click.prevent="save" />
      </template>
    </PDialog>
  </div>
</template>

<script>
import DemandeurCiviliteEdit from "./DemandeurCiviliteEdit.vue";
export default {
  props: ["demandeur"],
  components: {
    DemandeurCiviliteEdit,
  },
  data() {
    return {
      display: false,
      editableItem: {},
      activeStepIndex: 0,
    };
  },
  watch: {
    demandeur: {
      handler() {
        this.editableItem = { ...this.demandeur };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
      this.resetContent();
    },
    resetContent() {
      this.editableItem = {};
      this.activeStepIndex = 0;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      return await this.$refs.civiliteEditor.isCompleted();
    },
    handleUpdated(data) {
      //   this.$emit("updated", data);
    },
    async save() {
      if (!(await this.$refs.civiliteEditor.save())) return;
      this.$emit("save", {
        ...this.editableItem,
      });
      this.resetContent();
      this.hide();
    },
  },
};
</script>

<style>
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.stepper-dialog .p-dialog-header-icons {
  display: none;
}
</style>
