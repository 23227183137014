<template>
  <div class="row">
    <div class="col-sm-6 col-lg mb-2">
      <activite-value-box
        label="A venir"
        :showPercentage="showPercentage"
        :percentage="creePercentage"
        :borderClass="'event-box-pending'"
        :subtitle="subtitle"
        :rightSubtitle="rightSubtitle"
        :value="creeCount"
        :rightValue="rightTotalCount"
      />
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <activite-value-box
        label="Tenue"
        :showPercentage="showPercentage"
        :percentage="tenuePercentage"
        :borderClass="'event-box-done'"
        :subtitle="subtitle"
        :rightSubtitle="rightSubtitle"
        :value="tenueCount"
        :rightValue="rightcreeCount"
      />
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <activite-value-box
        label="Non tenue"
        :showPercentage="showPercentage"
        :percentage="nonTenueCount"
        :borderClass="'event-box-missed'"
        :subtitle="subtitle"
        :rightSubtitle="rightSubtitle"
        :value="nonTenueCount"
        :rightValue="righttenueCount"
      />
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <activite-value-box
        label="Clôturée"
        :showPercentage="showPercentage"
        :percentage="clotureeCount"
        :borderClass="'event-box-reported'"
        :subtitle="subtitle"
        :rightSubtitle="rightSubtitle"
        :value="clotureeCount"
        :rightValue="righttenueCount"
      />
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <activite-value-box
        label="Total"
        :showPercentage="showPercentage"
        :percentage="totalPercentage"
        :borderClass="'event-box-total'"
        :subtitle="subtitle"
        :rightSubtitle="rightSubtitle"
        :value="totalCount"
        :rightValue="righttenueCount"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActiviteValueBox from "./ActiviteValueBox.vue";

export default {
  props: {
    label: {
      type: String,
      require: true,
    },
    borderClass: {
      type: String,
      default: "event-box-danger",
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
    dateDeb: {
      type: String,
    },
    dateFin: {
      type: String,
    },
    subtitle: {
      type: String,
      require: true,
    },
    criteria: {
      type: String,
      require: true,
    },
    regroupements: {
      type: String,
      require: true,
    },
    rightCriteria: {
      type: String,
    },
    rightRegroupements: {
      type: String,
    },
    rightLabel: {
      type: String,
    },
    rightSubtitle: {
      type: String,
    },
  },
  components: {
    ActiviteValueBox,
  },
  data() {
    return {
      delay: 5000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      stat: {},
      rightStat: {},
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dateDeb() {
      this.loadData();
    },
    dateFin() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      activiteCount: "statistic/activiteCount",
    }),
    creeCount() {
      return this.activiteCount["CREE"] || 0;
    },
    tenueCount() {
      return this.activiteCount["TENUE"] || 0;
    },
    clotureeCount() {
      return this.activiteCount["CLOTUREE"] || 0;
    },
    nonTenueCount() {
      return this.activiteCount["NON_TENUE"] || 0;
    },
    totalCount() {
      return this.activiteCount["TOTAL"] || 0;
    },
    creePercentage() {
      return this.computePercentage(this.creeCount, this.percentageDivider);
    },
    tenuePercentage() {
      return this.computePercentage(this.tenueCount, this.percentageDivider);
    },
    percentageDivider() {
      return this.totalCount ? this.totalCount : 1;
    },
    totalPercentage() {
      return this.computePercentage(this.totalCount, this.percentageDivider);
    },
    isDual() {
      return this.rightCriteria != null && this.rightRegroupements != null;
    },
    rightcreeCount() {
      return this.rightStat["F"] || 0;
    },
    righttenueCount() {
      return this.rightStat["M"] || 0;
    },
    rightTotalCount() {
      return this.rightStat["TOTAL"] || 0;
    },
  },
  methods: {
    ...mapActions({
      fetchActiviteCount: "statistic/fetchActiviteCount",
    }),
    computePercentage(value, divider) {
      return this.divider == 0 ? 1 : (value / divider) * 100;
    },
    loadData() {
      this.fetchActiviteCount({}).then((data) => {
        this.stat = data;
      });
    },
    reload() {
      this.loadData();
    },
  },
};
</script>
