<template>
  <PCard class="shadow-none">
    <template #title>
      <div class="d-flex justify-content-between">
        <span> INFOMATION DU SCREENING </span>
        <!-- <a class="btn btn-light" href=""><i class="pi pi-caret-right"></i> EVADA</a> -->
      </div>
    </template>
    <template #subtitle>
      <template>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Comment le site du sous projet a-t-il été choisi ?</h6>
                </div>
                <div class="col-sm-7">
                  <PInputText
                    type="text"
                    class="col-12"
                    v-model="screening.commentChoisiSiteSP"
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nombre de bénéficiaires directs</h6>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Hommes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Femmes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Enfants</h6>
                    </div>
                    <div class="col-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Total</h6>
                    </div>
                    <div class="col-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nombre de bénéficiaires indirects</h6>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Hommes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Femmes</h6>
                    </div>
                    <div class="col-sm-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Enfants</h6>
                    </div>
                    <div class="col-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-5">
                      <h6 class="mb-0">Total</h6>
                    </div>
                    <div class="col-7">
                      <PInputText
                        type="text"
                        class="col-12"
                        v-model="screening.commentChoisiSiteSP"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </template>
    </template>
  </PCard>
</template>

<script>
import DStepper from "../../../../components/common/DStepper.vue";
export default {
  components: {
    DStepper,
  },
  data() {
    return {
      screening: {},
      activeStepIndex: 0,
    };
  },
  computed: {},
  methods: {},
};
</script>
