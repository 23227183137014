<template>
    <div>
        <div class="container-fluid mb-5">
            <PTabView :activeIndex.sync="activeTab">
                <PTabPanel header="FICHE SCREENNING">
                    <fiche-screening :microprojetId="microprojetId" />
                </PTabPanel>
                <!-- <PTabPanel header="INFOMATION DU SCREENING">
                    <partie-a />
                </PTabPanel>
                <PTabPanel header="BREVE DESCRIPTION DU SOUS PROJET">
                    <partie-b />
                </PTabPanel> -->
                <PTabPanel header="PREOCCUPATIONS ENVIRONNEMENTALES ET SOCIALES">
                    <partie-b :microprojetId="microprojetId" />
                </PTabPanel>

            </PTabView>
        </div>
    </div>
</template>

<script>
import FicheScreening from './FicheScreening.vue'
import partieA from './PartieA.vue'
import partieB from './PartieB.vue'
export default {
    props: ["microprojetId"],
    components: {
        partieA,
        partieB,
        FicheScreening

    },
    data() {
        return {
            screening: {},
            activeStepIndex: 0,
        }
    },
    computed: {

        steps() {
            return [{
                label: 'PARTIE A',
                subtitle: 'Brève description du sous projet',
                to: '#',
                completed: true,
                command: () => {
                    console.log(this.activeStepIndex)
                    this.activeStepIndex = 0
                }
            },
            {
                label: 'PARTIE B',
                subtitle: 'Identification des impacts environnementaux et sociaux',
                to: '#',
                completed: true,
                command: () => {

                    console.log(this.activeStepIndex)
                    this.activeStepIndex = 1
                }
            },

            ]
        },

    },
    methods: {

    },

}
</script>