<template>
  <div class="pt-2 pb-5">
    Sélectionnées <PTag>{{ saved.length }}</PTag>
    <button @click.prevent="clearSelection" class="btn btn-sm btn-danger">
      <i class="bi bi-close"></i>Vider
    </button>
    <div class="bg">
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <slot name="listTitle">
            <h6>{{ listTitle }}</h6>
          </slot>
        </div>
      </div>
      <div>
        <slot name="listDescription"></slot>
      </div>
      <div class="bg-white p-3 border shadow-0">
        <div class="container- mt-1">
          <div class="border- shadow-0 rounded-4">
            <form class="bg-" method="post" accept-charset="utf-8" action="">
              <div class="row">
                <div class="col">
                  <div class="input-group">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <p-multi-select
                        :multiple="false"
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      />
                      <p-dropdown
                        class="flex-grow-1"
                        v-model="statutCandidature"
                        :options="statutCandidatureOptions"
                        optionLabel="label"
                        optionValue="value"
                        :placeholder="'Statut de Mise En Relation'"
                        display="chip"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <a
                    href="#"
                    @click.prevent="mxSubmitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i>Rechercher</a
                  >
                </div>
              </div>
            </form>
            <transition v-if="!hideFilters">
              <div class="border-0 shadow-0 mt-2">
                <div class="row p-fluid grid">
                  <template v-for="(option, index) in filterOptions">
                    <div
                      class="col-12 col-sm-6 col-md-3 mb-4"
                      v-if="option.visible"
                      :key="index"
                    >
                      <!-- <label for="basic">{{option.label}}</label> -->
                      <!-- <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" /> -->
                      <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                      <AsyncMultiSelect
                        v-model="option.value"
                        :multiple="true"
                        :queryUrl="option.queryUrl"
                        :optionLabel="option.labelField"
                        :placeholder="option.label"
                        :emitObject="false"
                        v-if="option.queryUrl"
                        :displayMode="option.displayMode"
                        :searchOptions="[option.labelField]"
                      />
                      <p-dropdown
                        class="flex-grow-1 me-2"
                        v-model="option.value"
                        :options="option.items"
                        v-else
                        optionLabel="label"
                        optionValue="value"
                        :placeholder="option.label"
                        display="chip"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />

        <div class="table-responsive">
          <b-table
            show-empty
            empty-text="Aucune donnée à afficher"
            :fields="tableFields"
            class="align-middle"
            striped
            select-mode="multi"
            ref="table"
            :sort-by.sync="mxPagination.sortBy"
            :sort-desc.sync="mxPagination.sortDesc"
            responsive="sm"
            selectable
            :tbody-tr-class="rowClass"
            sort-icon-left
            @row-selected="onRowSelected"
            :items="tableData"
          >
            <template #head()="data">
              <span>{{ data.label }} </span>
            </template>
            <template #head(index)="">
              <span> N° </span>
            </template>

            <template #head(#)="data">
              <div class="form-check">
                <input
                  @change="selectAll"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                  :value="false"
                />
              </div>
            </template>
            <template #cell(#)="data">
              <div class="form-check">
                <input
                  @input="selectionChanged($event, data)"
                  :checked="data.rowSelected"
                  class="form-check-input"
                  type="checkbox"
                  :id="`${data.index}`"
                  name="disponible"
                />
              </div>
            </template>

            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(rang)="data">
              {{
                (data.index + 1) *
                (mxPagination.page == null || mxPagination.page == 1
                  ? mxPagination.page
                  : 0)
              }}{{
                data.index + 1 == 1
                  ? data.item.candidat.sexe == "M"
                    ? "er"
                    : "ère"
                  : "ème"
              }}
            </template>
            <template #cell(enabled)="data">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  v-model="data.item.enabled"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  :checked="data.item.enabled"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked">{{
                  data.item.enabled ? "Actif" : "Inactif"
                }}</label>
              </div>
            </template>
            <template #cell(estSuivi)="{ item }">
              <div class="position-relative">
                <PTag
                  rounded
                  :severity="item.estSuivi ? 'primary' : 'danger'"
                  size="large"
                  >{{ item.estSuivi ? "Affecté" : "Non.Affecté" }}</PTag
                >
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="d-flex justify-content-end">
                <slot name="actions" :data="data">
                  <button class="btn btn-sm py-1 btn-outline-primary">
                    <i class="bi bi-journal-check"></i>
                  </button>
                </slot>
              </div>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(presence)="data">
              <span class="d-flex justify-content-"
                ><PInputSwitch
                  @click.stop=""
                  v-model="data.item.presence"
                  @change="presenceChange(data.item)"
                  class="me-3"
                />
                <PTag :severity="getPresenceColor(data.item)">{{
                  getPresenceLabel(data.item)
                }}</PTag></span
              >
            </template>
          </b-table>
        </div>
        <!-- <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" /> -->

        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p>
            Affichage de l’élément
            {{ mxPagination.page * mxPagination.rowsPerPage }} à
            {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }}
            de {{ mxTotalCount }} éléments
          </p>
          <b-pagination
            v-model="mxPagination.page"
            :total-rows="mxTotalCount"
            :per-page="mxPagination.rowsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { multiPageItemSelectorMixin } from "@/mixins/multipage-item-selector-mixin";
import AsyncMultiSelect from "../../../common/AsyncMultiSelect.vue";
import CAutoComplete from "../../../common/CAutoComplete.vue";
import { mapActions } from "vuex";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    topSelectionCount: {
      type: Number,
      required: true,
    },
    referentielName: {
      type: String,
      required: true,
    },
    fetcherMethod: {
      type: String,
      default: null,
    },

    tableFields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: () => {},
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CAutoComplete,
    AsyncMultiSelect,
  },
  mixins: [paginatorMixin, multiPageItemSelectorMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      saved: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      filterOptions: [],
      updatingTable: false,
      showAdvancedFilter: false,
      activeReferentiel: { ...this.emptyReferentielItem },
      searchFields: ["candidat.nomPrenom"],
      statutCandidatureOptions: [
        { value: null, label: "Tous" },
        { value: "RECOMMANDE", label: "RECOMMANDE" },
        { value: "CANDIDATE", label: "CANDIDATE" },
        { value: "PROPOSE", label: "PROPOSE" },
        { value: "RETENU", label: "RETENU" },
        { value: "EXIGE", label: "EXIGE" },
      ],
      statutCandidature: null,
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "candidat.nomPrenom",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "typeCheminEmploi",
          value: "",
          key: "label",
          label: "Type D'emploi Positionnement",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "SALARIE", label: "SALARIE" },
            { value: "INDEPENDANT", label: "INDEPENDANT" },
          ],
        },
        {
          column: "annExp",
          value: "",
          label: "Expériences",
          labelField: "label",
          valueKey: "label",
          displayMode: "classic",
          queryUrl: "anneeExpriences",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "langue",
          value: [],
          label: "Langues",
          dropdown: true,
          labelField: "nom",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
          multiple: true,
        },
        {
          column: "metierAppris",
          value: "",
          label: "Métier(s) appris",
          valueKey: "id",
          queryUrl: "romes",
          displayMode: "classic",
          labelField: "libelle",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "metierDesires",
          value: "",
          label: "Métier(s) recherché(s)",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "competence",
          value: "",
          label: "Aptitudes",
          getterName: "competences",
          valueKey: "id",
          displayMode: "classic",
          labelField: "intitule",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        // {
        //   column: "diplome",
        //   value: "",
        //   label: "Diplômes",
        //   valueKey: "id",
        //   dropdown: true,
        //   displayMode: "classic",
        //   labelField: "code",
        //   queryUrl: "niveaux",
        //   visible: true,
        // },
        {
          column: "niveauInstruction",
          value: "",
          label: "Niveau instruction",
          dropdown: true,
          valueKey: "id",
          labelField: "code",
          displayMode: "classic",
          queryUrl: "instructions",
          visible: true,
          multiple: true,
        },
        {
          column: "programmesPrescrits",
          value: "",
          labelField: "nom",
          label: "Programme prescrit",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "programmes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        // {
        //     column: "nature",
        //     value: "",
        //     label: "Nature contrat",
        //     labelField: "code",
        //     displayMode: "classic",
        //     queryUrl: "natures",
        //     valueKey: "id",
        //     dropdown: true,
        //     visible: true,
        // },
        {
          column: "departementIds",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          valueKey: "id",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "communeIds",
          value: "",
          labelField: "nom",
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "arrondissementIds",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "villeIds",
          value: "",
          labelField: "nom",
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
      ],
    };
  },
  created() {
    // this.fetchDomaines();
    // this.fetchInstructions();
    // this.fetchDepartements();
    // this.fetchCommunes();
    // this.fetchArrondisements();
    // this.fetchVilles();
    // this.fetctNatures();
    // this.fetchNiveaux();
    // this.fetchCompetences();
    // this.fetchTypePieces();
    // this.fetchTypePieces();
    // this.fetchRomes();
    // this.fetchEthnies();
    this.makeQuery();
  },
  computed: {
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchRechercherProfils: "demandeur/fetchRechercherProfils",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",

      fetchDemandeurs: "demandeur/fetchDemandeurs",
      fetchCompetences: "competence/fetchCompetences",
      fetchDomaines: "domaine/fetchDomaines",
      fetchDepartements: "departement/fetchDepartements",
      fetchCommunes: "departement/fetchCommunes",
      fetchArrondisements: "departement/fetchArrondisements",
      fetchVilles: "departement/fetchVilles",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetchInstructions: "instruction/fetchInstructions",
      fetctNatures: "nature/fetchNatures",
      fetchTypePieces: "piece/fetchTypePieces",
      fetchRomes: "rome/fetchRomes",
      fetchEthnies: "ethnie/fetchEthnies",
      selectdemandeur: "demandeur/getOneDemandeur",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    rowClass(item, type) {
      if (!item || type !== "row" || item.niveauService == "SERVICE_NON_DELEGUE") return;
      return this.getIndexOf(item) + 1 <= this.topSelectionCount ? "table-success" : "";
    },
    getIndexOf(item) {
      return this.mxFilteredItems.map((el) => el.id).indexOf(item.id);
    },
    makeQuery() {
      this.mxInitializePaginator({
        queryModel: this.referentielName,
        search: this.search,
        fetcherMethod:
          this.fetcherMethod != null
            ? this.fetcherMethod
            : `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
        dataGetter: `${this.referentielName}/${this.referentielName}s`,
        paginationGetter: `${this.referentielName}/pagination`,
        autoSubmitQuery: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 8,
        },
        filterOptions: this.filterOptions,
        searchFields: this.searchFields,
        // extraQueryArgsParamBuilder: () => {
        //     const dateDeb = (this.dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
        //     const dateFin = (this.dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)
        //     let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`
        //     return params
        // },
        // extraQueryArgsParamBuilder: this.extraQueryArgsParamBuilder,
        extraQueryArgsParamBuilder: () => {
          let paramPlus = this.mxAddQueryParam("status", this.statutCandidature);

          let params = `${this.extraQueryArg}&${paramPlus}&${
            this.extraQueryArgsParamBuilder() || ""
          }`;
          return params;
        },
      });
    },
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    getPresenceLabel(item) {
      return item.presence == null ? "N/A" : item.presence ? "Présent" : "Absent";
    },
    getPresenceColor(item) {
      return item.presence == null ? "light" : item.presence ? "primary" : "danger";
    },
    clearSelection() {
      this.saved = [];
      this.selected = [];
      this.$refs.table.clearSelected();
    },
    presenceChange(item) {
      this.$emit("presenceChange", item);
    },
  },
};
</script>

<style></style>
