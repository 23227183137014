<template>
  <div
    :class="{ container: $screen.breakpoint != 'sm' }"
    class="align-items-center justify-content-center bg- pb-5 mt-4"
    style="min-height: 40vh"
  >
    <div v-if="loading" class="">Chargement en cours....</div>
    <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
    <div class="row" v-else>
      <div class="col-sm-12">
        <div v-if="!hasCv" style="min-height: 40vh">
          <template>
            <DemandeurCvEdit @completed="reload" />
          </template>
        </div>
        <div v-else class="cv-show">
          <div class="container mb-5">
            <div class="d-flex flex-wrap justify-content-between mb-0">
              <div class="my-1 flex-fill">
                <PTabView
                  v-if="!$isUserWithId(de.id) && de && de.id != null"
                  :activeIndex.sync="activeTabIndex"
                >
                  <PTabPanel header="Mon Curriculum Vitae"> </PTabPanel>
                  <PTabPanel header="Informations personnelles du DE"> </PTabPanel>
                </PTabView>
                <h3 v-else>Mon Curriculum Vitae</h3>
              </div>

              <div class="dropdown" v-if="activeTabIndex == 0">
                <button
                  class="btn style_btn btn-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Exportation
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end py-0"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li class="border">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.stop.prevent="generateReport"
                    >
                      <i class="me-2 bi-file-pdf-fill"></i>
                      Exporter en PDF</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <DemandeurDetailInstruit
              v-if="activeTabIndex == 1 && de && de.id != null"
              :demandeur="de"
            />

            <div v-if="activeTabIndex == 0">
              <div class="bg-white">
                <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="false"
                  :filename="'CV_' + demandeur.nom + '_' + demandeur.prenom"
                  :pdf-quality="4"
                  :paginate-elements-by-height="800"
                  :manual-pagination="true"
                  pdf-format="a4"
                  height="800px"
                  v-if="render2Pdf"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                >
                  <section slot="pdf-content">
                    <demandeur-cv-export></demandeur-cv-export>
                    <!-- <demandeur-cv-report
                      :demandeurId="de.id"
                    ></demandeur-cv-report> -->
                  </section>
                </vue-html2pdf>
              </div>
              <div class="row ps-3">
                <div class="col-sm-4 bg-dark">
                  <div class="p-3 p-lg-5 text-white">
                    <div class="mb-4">
                      <a href="#" class="">
                        <div
                          class="rounded-circle style_bg_img border"
                          :style="`background-image: url(${
                            de != null && de.fullPhotoUrl
                              ? de.fullPhotoUrl
                              : '/assets/img/profilMan.jpg'
                          }); height: 120px; width: 120px;`"
                        >
                          <profile-photo-drag-and-drop
                            :autoUpload="true"
                            :hidePreview="true"
                            :method="'POST'"
                            v-if="demandeur"
                            @fileSelected="fileChanged"
                            @completed="profileUpdate"
                            :uploadUrl="
                              API_URL + `/demandeurs/${demandeur.id}/update/photo`
                            "
                            icon="fa fa-3x fa-cloud-upload-alt"
                            action-label=""
                          />
                        </div>
                      </a>
                    </div>
                    <h2 class="text-uppercase">
                      {{ demandeur.nom }} {{ demandeur.prenom }}
                    </h2>

                    <!-- <small class="text-uppercase text-white">
                      {{ de.libelleProfil }}
                    </small> -->
                    <!-- Button trigger modal -->
                    <!-- <br /> -->

                    <modal ref="languageDialog" title="Ajouter une langues" />
                    <hr class="my-4" />
                    <div class="mb-4">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="mb-0 py-0 my-0 text-uppercase flex-fill">Profil</h6>
                        <a
                          @click.prevent="$refs['profilEditorDialog'].show"
                          class="text-warning"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <small class="text-muted">{{ demandeur.libelleProfil }}</small>
                    </div>

                    <div class="mb-4">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="mb-0 text-uppercase flex-fill">Description Profil</h6>
                        <cv-description-editor-dialog
                          title="Edition profile"
                          ref="profilEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                        <a
                          @click.prevent="$refs['profilEditorDialog'].show"
                          class="text-warning"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <small class="text-muted">{{ demandeur.descriptionProfil }}</small>
                    </div>
                    <div class="mb-3">
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <contact-editor-dialog
                          title="Edition contacts"
                          ref="contactEditorDialog"
                          :item="demandeur"
                          @save="save"
                        />
                        <h6 class="mb-0 text-uppercase flex-fill">Contact</h6>
                        <a
                          @click.prevent="$refs['contactEditorDialog'].show"
                          class="text-warning cursor-pointer"
                          ><i class="bi bi-pencil-square"></i
                        ></a>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="bi bi-telephone"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">229 {{ demandeur.phone }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="be bi-envelope"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.email }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2"><i class="be bi-whatsapp"></i></h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.whatsapp }}</h6>
                        </div>
                      </div>
                      <div class="d-flex align-items-center text-muted mb-2">
                        <div
                          class="flex-shrink-0 d-flex justify-content-center align-items-center"
                        >
                          <h6 class="me-2">
                            <i class="be bi-geo-alt-fill"></i>
                          </h6>
                        </div>
                        <div class="flex-grow-1 ms-2">
                          <h6 class="">{{ demandeur.adresse }}</h6>
                        </div>
                      </div>
                    </div>
                    <block-competences
                      v-model="demandeur.competences"
                      :displayMode="'cv'"
                      @change="saveCompetences"
                    >
                    </block-competences>
                  </div>
                </div>
                <div class="col bg-white">
                  <div class="p-3 p-lg-5">
                    <block-qualifications
                      v-model="demandeur.qualifications"
                      :demandeur="demandeur"
                      @change="saveQualifications"
                    >
                      <template #header="{ addQualification }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Formations et diplômes
                        </h5>
                        <a
                          @click.prevent="addQualification"
                          class="btn btn-primary rounded-0 btn-sm"
                          v-tooltip.top="'Ajouter une qualification'"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>

                      <template #default="{ addQualification, isEmpty }">
                        <div
                          class="d-flex bg-light justify-content-center align-items-center text-muted mb-2"
                          :style="`height: ${isEmpty ? '140px' : ''}`"
                        >
                          <button
                            class="btn btn-primary btn-sm"
                            v-tooltip.top="'Ajouter une qualification'"
                            @click="addQualification"
                          >
                            Ajouter une qualification
                          </button>
                        </div>
                      </template>
                    </block-qualifications>
                    <block-emplois
                      v-model="demandeur.emplois"
                      :demandeur="demandeur"
                      :displayMode="'nice'"
                      @change="saveEmplois"
                    >
                      <template #header="{ addEmploi }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Expérience professionnelle
                        </h5>
                        <a
                          @click.prevent="addEmploi"
                          v-tooltip.top="'Ajouter un emploi'"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>

                      <template #default="{ addEmploi, isEmpty }">
                        <div
                          class="d-flex bg-light justify-content-center align-items-center text-muted mb-2"
                          :style="`height: ${isEmpty ? '140px' : ''}`"
                        >
                          <button
                            v-tooltip.top="'Ajouter un emploi'"
                            class="btn btn-primary btn-sm"
                            @click="addEmploi"
                          >
                            Ajouter un emploi
                          </button>
                        </div>
                      </template>
                    </block-emplois>
                    <block-langues
                      v-model="demandeur.langues"
                      :demandeur="demandeur"
                      :displayMode="'nice'"
                      @change="saveLangues"
                    >
                      <template #header="{ addLangue }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Langues
                        </h5>

                        <a
                          @click.prevent="addLangue"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>
                      <template #default="{ addLangue, isEmpty }">
                        <div
                          class="d-flex bg-light justify-content-center align-items-center text-muted mb-2"
                          :style="`height: ${isEmpty ? '140px' : ''}`"
                        >
                          <button
                            v-tooltip.top="'Ajouter une langue'"
                            class="btn btn-primary btn-sm"
                            @click="addLangue"
                          >
                            Ajouter une langue
                          </button>
                        </div>
                      </template>
                    </block-langues>

                    <block-centre-interets
                      v-model="demandeur.centreInterets"
                      :displayMode="'nice'"
                      @change="saveCentreInterets"
                    >
                      <template #header="{ addInteret }">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">
                          Centre d'interêt
                        </h5>
                        <a
                          @click.prevent="addInteret"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </template>
                      <template #default="{ addInteret, isEmpty }">
                        <div
                          class="d-flex bg-light justify-content-center align-items-center text-muted mb-2"
                          :style="`height: ${isEmpty ? '140px' : ''}`"
                        >
                          <button class="btn btn-primary btn-sm" @click="addInteret">
                            Ajouter mes centres d'intérêt
                          </button>
                        </div>
                      </template>
                    </block-centre-interets>
                    <SignatureEditorDialog
                      ref="signatureEditor"
                      @save="apposerSignature"
                    />

                    <a
                      v-if="demandeur && !demandeur.fullSignatureUrl"
                      @click.prevent="showSignatureDossierEditor(demandeur)"
                      class="btn btn-primary rounded-0 btn-sm"
                      >Ajouter une signature</a
                    >
                  </div>
                  <div class="d-flex justify-content-end mb-5">
                    <div>
                      <img
                        v-if="demandeur"
                        height="200px"
                        :src="demandeur.fullSignatureUrl"
                      />
                      <h6>{{ de.nom }} {{ de.prenom }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <block-qualifications v-if="false" v-model="demandeur" />
          <block-emplois v-if="false" v-model="demandeur" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockQualifications from "../../../components/espace/demandeur/cv/BlockQualifications.vue";
import BlockEmplois from "../../../components/espace/demandeur/cv/BlockEmplois.vue";
import BlockLangues from "../../../components/espace/demandeur/cv/BlockLangues.vue";
import BlockCentreInterets from "../../../components/espace/demandeur/cv/BlockCentreInterets.vue";
import BlockCompetences from "../../../components/espace/demandeur/cv/BlockCompetences.vue";
import BlockSignatures from "../../../components/espace/demandeur/cv/BlockSignature.vue";
import ContactEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/ContactEditorDialog.vue";
import CvDescriptionEditorDialog from "../../../components/espace/demandeur/cv/editor/dialog/CvDescriptionEditorDialog.vue";
import ProfilePhotoDragAndDrop from "../../../components/uploader/ProfilePhotoDragAndDrop.vue";
import Modal from "../../../components/Modal.vue";
import DemandeurCvReport from "../../../components/espace/demandeur/DemandeurCvReport.vue";
import DemandeurCvExport from "./DemandeurCvExport.vue";
import SignatureEditorDialog from "../../../components/espace/common/signature/SignatureEditorDialog.vue";

import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";
import VueHtml2pdf from "vue-html2pdf";
import DemandeurCvEdit from "./DemandeurCvEdit.vue";
import DemandeurDetailInstruit from "../../espace/conseiller/DemandeurDetailInstruit.vue";
import { authMixin } from "../../../mixins/auth-mixin";
export default {
  props: {
    demandeurId: {
      type: [Number, String],
      default: null,
    },
    checkHasCv: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [authMixin],
  components: {
    Modal,
    BlockEmplois,
    BlockLangues,
    ContactEditorDialog,
    CvDescriptionEditorDialog,
    ProfilePhotoDragAndDrop,
    BlockCentreInterets,
    BlockQualifications,
    BlockSignatures,
    BlockCompetences,
    SignatureEditorDialog,
    VueHtml2pdf,
    DemandeurCvReport,
    DemandeurCvEdit,
    DemandeurDetailInstruit,
  },
  data() {
    return {
      API_URL,
      loading: true,
      activeTabIndex: 0,
      render2Pdf: true,
      disableCvCheck: true,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        whatsapp: null,
        adresse: null,
        lieuNaissance: null,
        paysDeResidence: null,
        departementNaissance: null,
        communeNaissance: null,
        arrondissementNaissance: null,
        villeNaissance: null,
        niveauInstruction: null,
        disponible: null,
        sousContrat: null,
        dejaInscrit: null,
        domaine: null,
        estInstruit: null,
        competencePrincipale: null,
        publicProfil: true,
        demandeurActif: true,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        arrondissement: null,
        ville: null,
        departement: null,
        emplois: [],
        // metiers: [],
        competences: [],
        poste: null,
        qualifications: [],
        phone: null,
        nomMere: null,
        prenomMere: null,
        nomPere: null,
        prenomPere: null,
        lignee: null,
        contactMere: null,
        contactPere: null,
        urlPhoto: null,
        tauxRemplissageProfil: null,
        libelleProfil: null,
        descriptionProfil: null,
        permis: null,
        situationMatrimoniale: null,
        ethnie: null,
        nationalite: null,
        signature: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      // dossier: "agent/demandeur/demandeur",
      dossier: "demandeur/demandeur",
    }),
    de() {
      return this.demandeur;
    },
    hasCv() {
      return !this.checkHasCv || this.disableCvCheck
        ? true
        : this.de != null &&
            this.de.descriptionProfil != null &&
            this.de.emplois.length != 0;
    },
  },
  watch: {
    dossier() {
      if (this.dossier != null) {
        this.demandeur = {
          ...this.dossier,
          // emplois:
          //   this.dossier.emplois != undefined ? this.dossier.emplois : [],
          // qualifications:
          //   this.dossier.qualifications != undefined
          //     ? this.dossier.qualifications
          //     : [],
          // qualifications:
          //   this.dossier.qualifications != undefined
          //     ? this.dossier.qualifications
          //     : [],
        };
      }
    },
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    this.loading = true;
    if (this.demandeurId != null) {
      this.fetchDossier({
        demandeurId: this.demandeurId,
      }).then(() => {
        this.loading = false;
      });
    } else {
      this.fetchMonDossier().then(() => (this.loading = false));
    }
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonDossier: "demandeur/fetchMonDossier",
      fetchDossier: "demandeur/fetchDossier",
      // fetchDossier: "agent/demandeur/fetchCardreDossier",
      apposerSignatureDossier: "demandeur/apposerSignatureDossier",
    }),
    reload() {
      window.location.reload();
    },
    apposerSignature(signatureUrl) {
      this.apposerSignatureDossier({
        urlSignature: signatureUrl,
        contratId: this.demandeur.id,
      }).then(() => {
        this.$toast.success("Contrat signé avec succès", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
    showSignatureDossierEditor(demandeur) {
      if (demandeur.signature == null) {
        this.$refs.signatureEditor.show();
      } else {
        this.$toast.info("Ce contrat a déjà été signé!", {
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
    editProfile() {
      this.$refs.languageDialog.show();
    },
    gotoCvEdit() {
      this.$router.push({ name: "espace.de.cv.edit" });
    },
    fileChanged(e) {
      console.log(e);
      this.demandeur.urlPhoto = e.previewUrl;
    },
    profileUpdate(e) {
      console.log(e);
      this.demandeur = {
        ...this.demandeur,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
    generateReport() {
      this.render2Pdf = true;
      this.$refs.html2Pdf.generatePdf();
    },
    saveQualifications(qualifications) {
      console.log(qualifications);
      //   this.save({
      //     ...this.demandeur,
      //     qualifications,
      //   });

      // this.demandeur
    },
    saveEmplois(emplois) {
      this.save({
        ...this.demandeur,
        emplois,
      });
    },
    saveLangues(langues) {
      this.save({
        ...this.demandeur,
        langues,
      });
    },
    saveCompetences(competences) {
      this.save({
        ...this.demandeur,
        competences,
      });
    },
    saveCentreInterets(centreInterets) {
      this.save({
        ...this.demandeur,
        centreInterets,
      });
    },
    save(item) {
      this.demandeur = {
        ...this.demandeur,
        ...item,
      };
      this.updateDemandeurCv(this.demandeur).then((data) => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
        // this.disableCvCheck = true;
        // this.demandeur = { ...data };
      });
    },
  },
};
</script>
