<template>
  <main>
    <div :class="inline ? 'container' : 'container-fluid'">
      <div :class="{ 'col-sm-10 py-5': !inline, 'col-sm-12': inline }" class="mx-auto">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12" v-if="!stepless">
              <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                  class="bg-primary style_level d-flex justify-content-center align-items-center"
                >
                  <h1 class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                </div>
              </div>
              <div class="text-center mb-4">
                <h4 class="text-primary">
                  Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }}
                </h4>
                <!-- <h6 class="text-muted">Je suis une <a href="inscription_2.html" class="badge bg-success text-white">Entreprise</a></h6> -->
                <h6 class="text-muted">Remplissage de la fiche AZOLI</h6>
              </div>
            </div>
            <div v-else>
              <h4 class="d-flex justify-content-between text-uppercase">
                <div>
                  MODIFICATION DES INFORMATIONS/<span class="text-muted caption">{{
                    steps[currentStep].title
                  }}</span>
                </div>
                <button @click.prevent="save" type="submit" class="btn btn-success ms-2">
                  Enrégistrer
                </button>
              </h4>
              <hr />
            </div>
          </div>
          <div
            v-if="loading"
            class="d-flex justify-content-center pb-4"
            style="min-height: 10vh"
          >
            <div
              class="spinner-border text-primary"
              style="width: 5rem; height: 5rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="row" v-if="demandeur.id != null">
            <div class="col-sm-12">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="azoli"
                  :readOnly="readOnly"
                  v-if="index == currentStep"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>

              <div class="row" v-if="!stepless">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep"
                      @click.prevent="nextStep"
                      type="submit"
                      :disabled="loadingNextStep"
                      class="btn btn-primary btn-lg"
                    >
                      <span
                        v-if="loadingNextStep"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Suivant
                    </button>
                    <button
                      v-else
                      @click.prevent="validateThenSubmit"
                      :disabled="loadingNextStep"
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      <span
                        v-if="loadingNextStep"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="">
                <div class="d-flex justify-content-end">
                  <button
                    @click.prevent.stop="$emit('cancel')"
                    type="submit"
                    class="btn btn-outline-danger btn-lg"
                  >
                    Annuler
                  </button>
                  <button
                    @click.prevent="save"
                    type="submit"
                    class="btn btn-success btn-lg ms-2"
                  >
                    Enrégistrer
                  </button>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
        <p-progress-bar
          v-if="loadingNextStep"
          style="height: 0.33em"
          mode="indeterminate"
        />
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
// import Prestations from '../../../components/espace/demandeur/cv/steps/Prestations.vue'
import Identification from "../../../components/espace/demandeur/fiche/steps/Identification.vue";
import Domicile from "../../../components/espace/demandeur/fiche/steps/Domicile.vue";
import NiveauInstruction from "../../../components/espace/demandeur/fiche/steps/NiveauInstruction.vue";
import InfoSociodemographique from "../../../components/espace/demandeur/fiche/steps/InfoSociodemographique.vue";
import EmploiActuel from "../../../components/espace/demandeur/fiche/steps/EmploiActuel.vue";
import ChomageRechercheEmploi from "../../../components/espace/demandeur/fiche/steps/ChomageRechercheEmploi.vue";
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  props: {
    demandeurId: {
      type: [Number, String],
      required: true,
    },
    stepless: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    initialStep: {
      type: Number,
      default: 0,
    },
  },
  mixins: [authMixin],
  data() {
    return {
      currentStep: this.initialStep,
      azoli: {},
      loading: false,
      loadingNextStep: false,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        whatsapp: null,
        adresse: null,
        lieuNaissance: null,
        paysNaissance: null,
        departementNaissance: null,
        communeNaissance: null,
        arrondissementNaissance: null,
        villeNaissance: null,
        niveauInstruction: null,
        disponible: null,
        sousContrat: null,
        dejaInscrit: null,
        domaine: null,
        estInstruit: null,
        competencePrincipale: null,
        publicProfil: true,
        demandeurActif: true,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        arrondissement: null,
        ville: null,
        departement: null,
        emplois: [],
        qualifications: [],
        phone: null,
        nomMere: null,
        prenomMere: null,
        nomPere: null,
        prenomPere: null,
        lignee: null,
        contactMere: null,
        contactPere: null,
        urlPhoto: null,
        tauxRemplissageProfil: null,
        libelleProfil: null,
        descriptionProfil: null,
        permis: null,
        situationMatrimoniale: null,
        ethnie: null,
        nationalite: null,
      },
      steps: [
        {
          icon: "fa fa-home",
          name: "first",
          title: "Identification",
          component: Identification,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Domicile",
          component: Domicile,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Informations sociodémographiques",
          component: InfoSociodemographique,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Niveau d'instruction",
          component: NiveauInstruction,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Emploi actuel",
          subtitle: "Subtitle sample",
          component: EmploiActuel,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Emplois",
          subtitle: "Subtitle sample",
          component: ChomageRechercheEmploi,
        },
        // {s
        //   icon: 'fa fa-money-bill-alt',
        //   name: 'third',
        //   title: 'Identifiant',
        //   subtitle: 'Booking adresse',
        //   component: Prestations,
        //   hideFooter: true,
        //   hiddeHeader: false
        // }
      ],
    };
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    this.loading = true;
    this.$emit("ready");
    this.fetchDossier({
      demandeurId: this.demandeurId,
    })
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },
  watch: {
    dossier() {
      if (this.dossier != null) {
        this.demandeur = {
          ...this.dossier,
          password: "password",
        };

        this.azoli = {
          ...this.dossier.azoli,
          // maitriseLangues: this.dossier.azoli.maitriseLangues || []
        };
      }
    },
    currentStep() {
      localStorage.setItem("currentStep", this.currentStep);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
  },
  methods: {
    ...mapActions({
      updateDossier: "demandeur/updateDossier",
      fetchDossier: "demandeur/fetchDossier",
    }),
    //This fonction is called from outside the component
    setActiveStep(step) {
      this.currentStep = step;
    },
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    submitForm() {
      console.log(this.azoli);
      this.updateDossier({
        ...this.demandeur,
        azoli: this.azoli,
      }).then(() => {
        this.$router.push({
          name: "espace.common.azolis.list",
          params: { userRole: this.$userRoleName.toLowerCase() },
        });
      });
    },
    partialSubmitForm() {
      return this.updateDossier({
        ...this.demandeur,
        azoli: this.azoli,
        status: "EN_COURS",
      }).then((data) => {
        console.log(data);
        this.azoli = {
          ...data.azoli,
          // maitriseLangues: this.dossier.azoli.maitriseLangues || []
        };
        return data;
      });
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.loadingNextStep = true;
        this.partialSubmitForm().then(() => {
          this.loadingNextStep = false;
          this.currentStep++;
        });
      }
    },
    async validateThenSubmit() {
      if (await this.validateStep(this.currentStep)) {
        this.submitForm();
      }
    },
    async validateStep(step) {
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
    },
    save() {
      this.updateDossier({
        ...this.demandeur,
        azoli: this.azoli,
      }).then((data) => {
        this.$emit("updated", data);
        this.$toast.success("Modification enrégistrée avec succès!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
