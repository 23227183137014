<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <CrudTable
          :formTitle="'Volume d’activités et bénéfice prévisionnel (en FCFA)'"
          :tableFields="crudTableFields"
          :crudUrl="'beneficePrevisonnels'"
          :emptyReferentielItem="emptyForceCrudItem"
          :extraQueryArg="`microProjet.id=${editableItem.id}&sort=moisBenefice,ASC`"
        >
          <template #listTitle>
            <h4>Volume d’activités et bénéfice prévisionnel (en FCFA)</h4>
          </template>

          <template #formTitle="{}">
            <h5>Volume d’activités et bénéfice prévisionnel (en FCFA)</h5>
          </template>

          <template #formContent="{ editableItem, updateValue }">
            <RentabiliteEditor
              :value="editableItem"
              @changed="(val) => updateValue(val)"
            />
          </template>
        </CrudTable>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import CrudTable from "@/components/common/crud-table/CrudTable.vue";
import RentabiliteEditor from "./rentabilite/RentabiliteEditor.vue";

export default {
  components: { CYesOrNoInput, CSelect, CrudTable, RentabiliteEditor },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    crudTableFields() {
      return [
        // "#",
        {
          key: "moisBenefice",
          label: "Mois",
        },
        {
          key: "quantiteProduitPrincipal",
          label: "Quantité du produit/service principal (Qi)",
        },
        {
          key: "prixUnitaireProduitPrincipal",
          label: "Prix unitaire du produit/service principal (Pi)",
        },
        {
          key: "recettesProduitPrincipal",
          label: "Recettes issues du produit/service principal (Pi x Qi)",
        },
        {
          key: "autresRecettesProduitSecondaire",
          label: "Autres recettes issues des produits / services secondaires (ARi)",
        },
        {
          key: "totalRecettes",
          label: "Recettes totales (RT=Pi x Qi+ARi)",
        },
        {
          key: "chargesMatierePremiere",
          label: "Matières premières (MPi)",
        },
        {
          key: "chargesMarchandise",
          label: "Marchandises (Mi)",
        },
        {
          key: "chargesMainOeuvre",
          label: "Main d’œuvre (MOi)",
        },
        {
          key: "chargesConsommationIntermediaire",
          label: "Consommations intermédiaires (CIi)",
        },
        {
          key: "chargesServiceConsomme",
          label: "Services consommés  (SCi)",
        },
        {
          key: "totalCharges",
          label: "Total des charges (CT=MPi+Mi+Moi+CIi+SCi)",
        },
        {
          key: "totalBeneficeBrut",
          label: "Bénéfice brut (RT-CT)",
        },
        "actions",
      ];
    },
    emptyForceCrudItem() {
      return {
        quantiteProduitPrincipal: null,
        prixUnitaireProduitPrincipal: null,
        recettesProduitPrincipal: null,
        autresRecettesProduitSecondaire: null,

        chargesMatierePremiere: null,
        chargesMarchandise: null,
        chargesMainOeuvre: null,
        chargesConsommationIntermediaire: null,
        chargesServiceConsomme: null,

        totalRecettes: null,
        totalCharges: null,
        totalBeneficeBrut: null,

        microProjet: this.editableItem,
      };
    },
  },
  created() {},
  methods: {
    ...mapActions({
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style scoped></style>
