<template>
  <div class="">
    <PDialog
      class="stepper-dialog"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '60vw' }"
      :contentStyle="{ overflow: [0].includes(activeStepIndex) ? 'visible' : 'auto' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              {{ title }}
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
        </div>
      </template>
      <template>
        <div class="bg-white shadow-light rounded-3 bg-r p-3">
          <h6>{{ currentActiviteTitle }}</h6>
          <ValidationObserver
            ref="observer0"
            v-if="activeStepIndex == 0"
            v-slot="{ handleSubmit }"
          >
            <form ref="form0" method="post" @submit.prevent="handleSubmit()">
              <div class="row">
                <ValidationProvider
                  v-if="singleParticipantId == null"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="field mb-3">
                    <div>Catégorie d'activité</div>
                    <div class="d-flex mt-2">
                      <div class="field-radiobutton me-4">
                        <PRadioButton
                          id="categorieActivite1"
                          name="categorieActivite"
                          :value="'INTERNE'"
                          v-model="editableItem.categorieActivite"
                        />
                        <label for="categorieActivite1" class="ms-2 cursor-pointer"
                          >Interne
                          <span class="text-muted">(ne concerne pas des DEs)</span></label
                        >
                      </div>
                      <div class="field-radiobutton">
                        <PRadioButton
                          id="categorieActivite2"
                          name="categorieActivite"
                          :value="'EXTERNE'"
                          v-model="editableItem.categorieActivite"
                        />
                        <label for="categorieActivite2" class="ms-2 cursor-pointer"
                          >Externe
                          <span class="text-muted"
                            >(implique la participation des DEs)</span
                          ></label
                        >
                      </div>
                    </div>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <!-- <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      :value="editableItem.titre"
                      @input="handleInput"
                      required
                      readonly
                      type="text"
                      class="form-control"
                      id="titre"
                      name="titre"
                      placeholder="Titre de l'activité"
                    />
                    <label for="titre">Titre de l'activité</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider> -->
                <div class="field col-12 md:col-12">
                  <div class="form-floating mb-3 flex w-full">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <DatePicker
                        class="flex-grow"
                        mode="dateTime"
                        :is24hr="true"
                        :first-day-of-week="2"
                        :masks="{ title: 'MMM YYYY' }"
                        locale="fr"
                        v-model="editableItem.datePrevue"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="form-floating mb-3 flex w-full">
                            <input
                              class="form-control"
                              :value="inputValue"
                              required
                              type="text"
                              v-on="inputEvents"
                            />
                            <label for="titre">Date et heure de l'activité</label>
                          </div>
                        </template>
                      </DatePicker>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <c-select
                        id="typeActivite"
                        :disabled="makeTypeActiveReadOnly"
                        name="typeActivite"
                        option-label="libelle"
                        :readonly="makeTypeActiveReadOnly"
                        option-value="id"
                        v-model="editableItem.typeActivite"
                        :options="typeActivites"
                        class="form-select"
                      >
                      </c-select>
                      <label for="libelle">Type de d'activité</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div
                  v-if="
                    editableItem.typeActivite != null &&
                    editableItem.typeActivite.tags.length != 0
                  "
                  class="col-sm-12 field"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label for="libelle">Thématique</label>
                    <div class="form-floating mb-3">
                      <!-- <c-select id="tag" name="tag" option-label="libelle" option-value="id" v-model="editableItem.tags" :options="editableItem.typeActivite.tags" class="form-select">
                      </c-select> -->
                      <multiselect
                        v-model="editableItem.tags"
                        :options="editableItem.typeActivite.tags"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :multiple="true"
                        placeholder="Secteur concerné"
                        label="libelle"
                        track-by="id"
                        :preselect-first="false"
                      />
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="field mb-3">
                      <div>Mode de participation</div>
                      <div class="field-radiobutton">
                        <PRadioButton
                          id="modeDeParticipation1"
                          name="modeDeParticipation"
                          :value="'SUR_SITE'"
                          v-model="editableItem.modeDeParticipation"
                        />
                        <label for="modeDeParticipation1" class="ms-2 cursor-pointer"
                          >En présentiel</label
                        >
                      </div>
                      <div class="field-radiobutton">
                        <PRadioButton
                          id="modeDeParticipation2"
                          name="modeDeParticipation"
                          :value="'EN_LIGNE'"
                          v-model="editableItem.modeDeParticipation"
                        />
                        <label for="modeDeParticipation2" class="ms-2 cursor-pointer"
                          >En ligne</label
                        >
                      </div>
                      <div class="field-radiobutton">
                        <PRadioButton
                          id="modeDeParticipation3"
                          name="modeDeParticipation"
                          :value="'CALL_CENTER'"
                          v-model="editableItem.modeDeParticipation"
                        />
                        <label for="modeDeParticipation3" class="ms-2 cursor-pointer"
                          >Par call center</label
                        >
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="editableItem.modeDeParticipation == 'EN_LIGNE'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.lienConferenceEnLine"
                        @input="handleInput"
                        required
                        type="text"
                        class="form-control"
                        id="lienConferenceEnLine"
                        name="lienConferenceEnLine"
                        placeholder="Lien de la réunion en ligne"
                      />
                      <label for="lienConferenceEnLine"
                        >Lien de la réunion en ligne</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>

          <ValidationObserver
            ref="observer1"
            v-if="activeStepIndex == 1"
            v-slot="{ handleSubmit }"
          >
            <form ref="form1" method="post" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-sm-12 field">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="field mb-3">
                      <div>Cannaux de notifications</div>
                      <div class="field-checkbox">
                        <PCheckbox
                          id="notifierParEmail"
                          name="notifierParEmail"
                          :binary="true"
                          v-model="editableItem.notifierParEmail"
                        />
                        <label for="notifierParEmail" class="ms-2 cursor-pointer"
                          >Par email</label
                        >
                      </div>
                      <div class="field-checkbox">
                        <PCheckbox
                          id="notifierParSms"
                          name="notifierParSms"
                          :binary="true"
                          v-model="editableItem.notifierParSms"
                        />
                        <label for="notifierParSms" class="ms-2 cursor-pointer"
                          >Par sms</label
                        >
                      </div>
                      <div class="field-checkbox">
                        <PCheckbox
                          id="notifierParWhatsap"
                          name="notifierParWhatsapp"
                          :binary="true"
                          v-model="editableItem.notifierParWhatsapp"
                        />
                        <label for="notifierParWhatsap" class="ms-2 cursor-pointer"
                          >Par Whatsapp</label
                        >
                      </div>
                      <div class="field-checkbox">
                        <PCheckbox
                          id="notifierCallCenter"
                          name="notifierCallCenter"
                          :binary="true"
                          v-model="editableItem.notifierCallCenter"
                        />
                        <label for="notifierCallCenter" class="ms-2 cursor-pointer"
                          >Par call center</label
                        >
                      </div>
                      <div class="field-checkbox">
                        <PCheckbox
                          id="notifierAucun"
                          name="notifierAucun"
                          :binary="true"
                          v-model="editableItem.notifierAucun"
                        />
                        <label for="notifierAucun" class="ms-2 cursor-pointer"
                          >Ne pas notifier</label
                        >
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>

          <ValidationObserver
            ref="observer2"
            v-if="activeStepIndex == 2"
            v-slot="{ handleSubmit }"
          >
            <form ref="form2" method="post" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-sm-12 field" v-if="singleParticipantId == null">
                  <div>Participants</div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <SearchableItemActiviteParticipantSelector
                      :listTitle="''"
                      :referentielName="referentielName"
                      :tableFields="tableFields"
                      v-model="editableItem.participants"
                      :emptyReferentielItem="emptyReferentielItem"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </template>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <PButton
          label="Valider"
          icon="pi pi-check"
          @click="save"
          autofocus
          v-if="activeStepIndex >= steps.length - 1"
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchableItemActiviteParticipantSelector from "../../common/SearchableItemActiviteParticipantSelector.vue";
import Multiselect from "vue-multiselect";
import DStepper from "../../common/DStepper.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    singleParticipantId: {
      type: [Number, String],
      default: () => null,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SearchableItemActiviteParticipantSelector,
    Multiselect,
    DStepper,
    DatePicker,
  },
  data() {
    return {
      activeStepIndex: 0,
      currentActiviteTitle: null,
      display: false,
      datePickerMasks: {
        input: "DD-MM-YYYY",
      },
      makeTypeActiveReadOnly: true,
      editableItem: {
        datePrevue: null,
        typeActivite: null,
        categoryActivite: this.singleParticipantId != null ? "EXTERNE" : null,
        modeDeParticipation: null,
        notifierParSms: null,
        notifierParWhatsapp: null,
        notifierParEmail: null,
        participants:
          this.singleParticipantId != null ? [{ id: this.singleParticipantId }] : [],
      },
    };
  },
  created() {
    this.fetchTypeActivites();
  },
  watch: {
    item() {
      if (this.item && this.item.datePrevue instanceof String) {
        this.item.datePrevue = new Date(this.item.datePrevue);
      }
      this.editableItem = { ...this.item };
    },
    "editableItem.typeActivite": {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (
          newVal != null ||
          (newVal != null && oldVal != null && newVal.id != oldVal.id)
        ) {
          this.genererTitreActivite(newVal).then(({ titre }) => {
            this.editableItem.titre = titre;
            this.currentActiviteTitle = titre;
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      typeActivites: "typeActivite/typeActivites",
      activites: "activite/activites",
      modeDeParticipations: "setting/modeDeParticipations",
    }),
    steps() {
      let steps = [
        {
          name: "PROGRAMMER",
          label: "PROGRAMMER L'ACTIVITE",
          subtitle: "Définier les infos de l'activité",
          to: "#programmer-activiter",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
        {
          name: "CANNAUX",
          label: "CAUNAUX DE NOTIFICATIONS",
          subtitle: "Choix des cannaux de notifications",
          to: "#cannaux-notification",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        },
      ];

      if (
        this.editableItem.categorieActivite == "EXTERNE" &&
        this.singleParticipantId == null
      ) {
        steps.push({
          label: "SELECTION DES PARTICIPANTS",
          subtitle: "Sélection des participants",
          to: "#selection-des-participants",
          completed: this.activeStepIndex >= 2,
          command: () => {},
        });
      }
      return steps;
    },
    referentielName() {
      return "demandeur";
    },
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ];
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    ...mapActions({
      fetchTypeActivites: "typeActivite/fetchTypeActivites",
      fetchActivites: "activite/fetchActivites",
      genererTitreActivite: "typeActivite/genererTitreActivite",
    }),
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      // if(!await this.isCompleted(this.activeStepIndex)) return;
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    show() {
      this.resetContent();
      this.makeTypeActiveReadOnly = false;
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    async selectTypeActiviteByCode(code) {
      if (this.typeActivites.length == 0) {
        await this.fetchTypeActivites();
      }
      this.typeActivites.forEach((ta) => {
        if (ta.code == code) {
          this.editableItem.typeActivite = ta;
          this.makeTypeActiveReadOnly = true;
        }
      });
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #e1e4e1;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}
</style>
