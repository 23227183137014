<template>
  <PDialog
    class="stepper-dialog"
    :header="'Editeur de contenu de prescription'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '90vw', height: '100vh' }"
    :maximizable="true"
  >
    <template #header>
      <div class="card px-0 border-0 mb-2 w-100">
        <div>
          <h4 class="d-flex justify-content-between">
            <div>
              {{ title }}
              {{ beneficiaire != null ? " - " + beneficiaire.nomPrenom : "" }}
            </div>
            <PButton
              @click="cancel()"
              icon="pi pi-times"
              class="p-button-rounded p-button-outlined p-button-danger"
            />
          </h4>
          <PDivider />

          <div class="d-flex justify-content-between">
            <div>
              <h5>{{ activeStep.label }}</h5>
              <span>Etape {{ activeStepIndex + 1 }}/{{ steps.length }}</span>
            </div>
            <div>
              <h5>Statut de validation actuel</h5>
              <PBadge
                :value="editableItem.statutValidation || 'NON_SOUMIS'"
                :severity="
                  getStatusValidationColor(editableItem.statutValidation || 'NON_SOUMIS')
                "
              ></PBadge>
            </div>
            <div>
              <button
                v-if="
                  $can('SOUMETTRE', 'MICRO_PROJET') &&
                  (editableItem.statutValidation == null ||
                    editableItem.statutValidation == 'NON_SOUMIS')
                "
                class="btn btn-lg btn-warning me-2"
                @click="soumettreMicroprojet"
              >
                Soumettre pour approbation
              </button>
              <button
                v-if="
                  $can('APPROVE', 'MICRO_PROJET') &&
                  editableItem.statutValidation == 'SOUMIS' &&
                  editableItem.statutTransmission == 'NON_TRANSMIS'
                "
                class="btn btn-lg btn-info me-2"
                @click="approuverMicroprojet"
              >
                Approver le microprojet
              </button>
              <button
                v-if="
                  $can('VALIDATE', 'MICRO_PROJET') &&
                  editableItem.statutValidation == 'APPROUVE' &&
                  editableItem.statutTransmission == 'NON_TRANSMIS'
                "
                class="btn btn-lg btn-success me-2"
                @click="validateMicroprojet"
              >
                Valider le microprojet
              </button>
              <button
                v-if="
                  $can('TRANSMETTRE', 'MICRO_PROJET') &&
                  editableItem.statutValidation == 'VALIDE' &&
                  editableItem.statutTransmission == 'NON_TRANSMIS'
                "
                class="btn btn-lg btn-warning me-2"
                @click="transmettreMicroprojet"
              >
                Transmettre le microprojet
              </button>
              <div v-if="editableItem.statutTransmission == 'TRANSMIS'">
                <h5>Statut de transmission</h5>
                <PBadge value="TRANSMIS" severity="success"></PBadge>
              </div>
              <!-- <button class="btn btn-primary" @click="validateMicroprojet">
                Valider etTransmettre le microprojet
              </button> -->
            </div>
          </div>
        </div>
        <!-- {{ hasValidationErrors }} -->
        <div class="alert alert-danger" v-if="hasValidationErrors">
          Le formulaire contient des erreurs. Vérifiez que tous les champs ont été bien
          rempli.
        </div>
        <div class="alert alert-danger" v-if="editableItem.estDoublonMicroProjet">
          Ce microprojet est un doublon(Le DE concerné a déjà un microprojet transmis au
          FNM). IL NE SERA PAS PRIS EN COMPTE.
        </div>
        <PDivider />
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            Montant INV subvention:
            <span class="fw-bold ms-3"
              >{{ editableItem.partInverstissementSubvention }}
            </span>
          </div>
          <div class="d-flex">
            Montant FBR subvention:
            <span class="fw-bold ms-3">{{
              editableItem.partFonctionnementSubvention
            }}</span>
          </div>
          <div class="d-flex">
            Total subvention(A):
            <span
              class="fw-bold ms-3"
              :class="{
                'text-danger':
                  editableItem.partInverstissementSubvention +
                    editableItem.partFonctionnementSubvention >
                  200000,
              }"
              >{{
                editableItem.partInverstissementSubvention +
                editableItem.partFonctionnementSubvention
              }}</span
            >
            <span
              class="d-flex"
              v-if="
                editableItem.partInverstissementSubvention +
                  editableItem.partFonctionnementSubvention >
                200000
              "
            >
              <PTag severity="danger" class="ms-2"
                >Attention! Subvention supérieure à 200 000 FCFA.</PTag
              >
            </span>
            <span
              class="d-flex"
              v-else-if="
                editableItem.partInverstissementSubvention +
                  editableItem.partFonctionnementSubvention <
                200000
              "
            >
              <PTag severity="warning" class="ms-2"
                >Attention! Subvention inférieure à 200 000 FCFA.</PTag
              >
            </span>
          </div>
          <!-- <div
            class="d-flex"
            v-if="
              editableItem.partInverstissementSubvention +
                editableItem.partFonctionnementSubvention >
              200000
            "
          >
            <div class="alert alert-danger">Subvetion supérieur à 200 000 FCFA.</div>
          </div> -->
        </div>

        <p-progress-bar v-if="loading" style="height: 0.25em" mode="indeterminate" />
      </div>
    </template>

    <div class="d-flex">
      <div class="h-100" style="width: 300px">
        <DStepper
          class="shadow-light"
          :steps="steps"
          orientation="vertical"
          :activeStepIndex="activeStepIndex"
          :readonly="false"
        />
      </div>
      <StatutAcceptationSelectorDialog
        ref="statutAcceptationDialog"
        :statutAcceptationType="statutAcceptationType"
        @save="saveAcceptation"
      />
      <RetierMicroprojetDialog
        ref="statutRetraitDialog"
        :microProjet="microProjet"
        @save="saveRetrait"
      />
      <div class="px-3" style="width: calc(100% - 300px)">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <h5>{{ activeStep.label }}</h5>
                <span
                  >{{ activeStep.subtitle }} {{ activeStepIndex + 1 }}/{{
                    steps.length
                  }}</span
                >
                <span class="badge bg-primary text-white"
                  >{{ activeStepIndex + 1 }}/{{ steps.length }}</span
                >
              </div>
              <div>
                <PButton
                  label="Quitter"
                  icon="pi pi-times"
                  @click="cancel"
                  class="p-button-text p-button-danger"
                />
                <template v-if="!singleStepDisplayMode">
                  <PButton
                    label="Précédent"
                    icon="pi pi-chevron-left"
                    :disabled="activeStepIndex <= 0"
                    class="p-button-outlined me-2"
                    @click.prevent="goToPrevious"
                  />
                  <PButton
                    label="Suivant"
                    icon="pi  pi-chevron-right"
                    v-if="activeStepIndex < steps.length - 1"
                    @click.prevent="goToNext"
                  />
                </template>
                <!-- <PButton
                  :label="editableItem.id != null ? 'Enrégister' : 'Valider'"
                  icon="pi piC-check"
                  @click="save"
                  autofocus
                  v-if="activeStepIndex >= steps.length - 1 || editableItem.id != null"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white shadow-light rounded-3 bg-r p-3">
          <template v-for="(step, index) in steps">
            <component
              ref="activeComponent"
              v-if="index == activeStepIndex && editableItem.beneficiaire != null"
              :is="step.component"
              v-model="editableItem"
              :key="index"
              @save="stepSaved"
            />
          </template>
        </div>
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <PButton
                label="Quitter"
                icon="pi pi-times"
                @click="cancel"
                class="p-button-text p-button-danger"
              />
              <template v-if="!singleStepDisplayMode">
                <PButton
                  label="Précédent"
                  icon="pi pi-chevron-left"
                  :disabled="activeStepIndex <= 0"
                  class="p-button-outlined me-2"
                  @click.prevent="goToPrevious"
                />
                <PButton
                  label="Suivant"
                  icon="pi  pi-chevron-right"
                  v-if="activeStepIndex < steps.length - 1"
                  @click.prevent="goToNext"
                />
              </template>
              <!-- <PButton
                :label="editableItem.id != null ? 'Enrégister' : 'Valider'"
                icon="pi pi-check"
                @click="save"
                autofocus
                v-if="activeStepIndex >= steps.length - 1 || editableItem.id != null"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer
      ><button class="btn btn-lg btn-danger me-2" @click="retierMicroprojet">
        <!-- v-if="$can('RETIER', 'MICRO_PROJET')" -->
        Retirer le microprojet
      </button>
      <PButton
        label="Quitter"
        icon="pi pi-times"
        @click="cancel"
        class="p-button-text p-button-danger"
      />
      <template v-if="!singleStepDisplayMode">
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
      </template>
      <PButton
        :label="'Enrégister et quitter'"
        icon="pi pi-check"
        class="p-button p-button-success"
        @click="save"
        autofocus
      />
    </template>
  </PDialog>
</template>

<script>
import { mapActions } from "vuex";
import Echelon from "../../../../common/echelon/Echelon.vue";
import CAutoComplete from "../../../../common/CAutoComplete.vue";
import Multiselect from "vue-multiselect";
import MetierSelect from "../../../../common/MetierSelect.vue";
import NaemaSelect from "../../../../common/NaemaSelect.vue";
import DStepper from "../../../../common/DStepper.vue";
import Presentation from "./microprojet-steps/Presentation.vue";
import PrerequisMicroprojet from "./microprojet-steps/PrerequisMicroprojet.vue";
import Ffom from "./microprojet-steps/Ffom.vue";
import Marketing from "./microprojet-steps/Marketing.vue";
import RessourcesHumaines from "./microprojet-steps/RessourcesHumaines.vue";
import Equipement from "./microprojet-steps/Equipement.vue";
import BesoinFormation from "./microprojet-steps/BesoinFormation.vue";
import Budget from "./microprojet-steps/Budget.vue";
import Rentabilite from "./microprojet-steps/Rentabilite.vue";
import Credit from "./microprojet-steps/Credit.vue";
import PlanAction from "./microprojet-steps/PlanAction.vue";
import PromoteurInfo from "./microprojet-steps/PromoteurInfo.vue";
import StatutAcceptationSelectorDialog from "./microprojet-steps/dialog/StatutAcceptationSelectorDialog.vue";
import RetierMicroprojetDialog from "./microprojet-steps/dialog/RetierMicroprojetDialog.vue";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    beneficiaire: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    Echelon,
    DStepper,
    CAutoComplete,
    Multiselect,
    MetierSelect,
    NaemaSelect,
    Presentation,
    PrerequisMicroprojet,
    StatutAcceptationSelectorDialog,
    RetierMicroprojetDialog,
    PromoteurInfo,
    Ffom,
    Budget,
  },
  data() {
    return {
      display: false,
      singleStepDisplayMode: false,
      statutAcceptationType: null,
      activeStepIndex: 0,
      editableItem: { ...this.item },
      loading: false,
    };
  },
  created() {},
  computed: {
    steps() {
      let steps = [
        {
          name: "INFO_PROMOTEUR",
          label: "INFO DU PROMOTEUR",
          subtitle: "Info du promoteur",
          to: "#info-promoteur",
          component: PromoteurInfo,
          completed: this.activeStepIndex >= 0,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          name: "PRESENTATION",
          label: "PRESENTATION MICRO",
          subtitle: "Présentation du microprojet",
          to: "#info-microprojet",
          component: Presentation,
          completed: this.activeStepIndex >= 1,
          command: () => {
            this.activeStepIndex = 1;
          },
        },
        {
          name: "FAISABILITE_FFOM",
          label: "FFOM",
          subtitle: "Faisabilité FFOM",
          to: "#faisabilite-ffom",
          component: Ffom,
          completed: this.activeStepIndex >= 2,
          command: () => {
            this.activeStepIndex = 2;
          },
        },
        {
          name: "FAISABILITE_MARKETING",
          label: "Marketing",
          subtitle: "Faisabilité Marketing",
          to: "#faisabilite-marketing",
          component: Marketing,
          completed: this.activeStepIndex >= 3,
          command: () => {
            this.activeStepIndex = 3;
          },
        },
        {
          name: "FAISABILITE_EQUIPEMENT",
          label: "Equipememt",
          subtitle: "Faisabilité Equipememt",
          to: "#faisabilite-equipement",
          component: Equipement,
          completed: this.activeStepIndex >= 4,
          command: () => {
            this.activeStepIndex = 4;
          },
        },
        {
          name: "FAISABILITE_RH",
          label: "Ressoures Humaines",
          subtitle: "Faisabilité RH",
          to: "#faisabilite-rh",
          component: RessourcesHumaines,
          completed: this.activeStepIndex >= 5,
          command: () => {
            if (this.wasSaved) this.activeStepIndex = 5;
          },
        },
        {
          name: "FAISABILITE_FTCD",
          label: "Besoins FTCD",
          subtitle: "Faisabilité Besoins FTCD",
          to: "#faisabilite-ftcd",
          component: BesoinFormation,
          completed: this.activeStepIndex >= 6,
          command: () => {
            this.activeStepIndex = 6;
          },
        },
        {
          name: "FAISABILITE_BUDGET",
          label: "Budget",
          subtitle: "Faisabilité Budget",
          to: "#faisabilite-budget",
          component: Budget,
          completed: this.activeStepIndex >= 7,
          command: () => {
            this.activeStepIndex = 7;
          },
        },
        {
          name: "FAISABILITE_CREDIT",
          label: "Crédit",
          subtitle: "Faisabilité Crédit",
          to: "#faisabilite-credit",
          completed: this.activeStepIndex >= 8,
          component: Credit,
          command: () => {
            this.activeStepIndex = 8;
          },
        },
        {
          name: "FAISABILITE_RENTABILITE",
          label: "Rentabilité",
          subtitle: "Faisabilité Rentabilité",
          to: "#faisabilite-rentabilite",
          component: Rentabilite,
          completed: this.activeStepIndex >= 9,
          command: () => {
            this.activeStepIndex = 9;
          },
        },
        {
          name: "PLAN_ACTION",
          label: "Plan action",
          subtitle: "Plan de mise en oeuvre",
          to: "#plan-action",
          component: PlanAction,
          completed: this.activeStepIndex >= 10,
          command: () => {
            this.activeStepIndex = 10;
          },
        },
        {
          name: "PREREQUIS",
          label: "SYNTHESE PREREQUIS",
          subtitle: "Synthèse Préréquis",
          to: "#prerequis",
          component: PrerequisMicroprojet,
          completed: this.activeStepIndex >= 11,
          command: () => {
            this.activeStepIndex = 11;
          },
        },
      ];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    wasSaved() {
      return this.editableItem != null && this.editableItem.id != null;
    },
    hasValidationErrors() {
      return this.$refs["activeComponent"] != undefined
        ? this.$refs["activeComponent"][0].mxHasErrors
        : false;
    },
  },
  watch: {
    item() {
      // if (this.item && this.item.beneficiaire == null) {
      //   this.editableItem = { ...(await this.fetchMicroProjet()) };
      // } else {
      //   this.editableItem = { ...this.item };
      // }

      //   const beneficiaireIdChanged =
      //     this.editableItem.beneficiaireId != this.item.beneficiaireId ||  ;

      //   if (beneficiaireIdChanged) {

      //   }

      this.editableItem = {
        ...this.item,
      };
      if (
        this.beneficiaire == null ||
        Object.keys(this.beneficiaire).length == 0 ||
        (this.beneficiaire != null &&
          this.item.id != null &&
          this.beneficiaire.id != this.item.beneficiaireId)
      ) {
        this.beneficiaire == null;
        this.editableItem.beneficiaire = null;
        this.loading = true;
        this.fetchDossier({
          demandeurId: this.item.beneficiaireId,
        })
          .then((data) => {
            this.beneficiaire = data;
            this.editableItem.beneficiaire = data;
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      } else {
        this.editableItem.beneficiaire = this.beneficiaire;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchMicroProjet: "microProjet/fetchMicroProjet",
      updateStatutValidation: "microProjet/updateStatutValidation",
      updateStatutTransmission: "microProjet/updateStatutTransmission",
      retirerMicroProjet: "microProjet/retirerMicroProjet",
      fetchDossier: "demandeur/fetchDossier",
    }),
    showStatutAccepatationDialog(statutAcceptationType) {
      this.statutAcceptationType = statutAcceptationType;
      this.$refs.statutAcceptationDialog.show();
    },
    showRetierMicroProjetDialog() {
      this.$refs.statutRetraitDialog.show();
    },
    async saveRetraitMicroprojet(microProjet) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment retirer/suspendre ce microprojet?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.retirerMicroProjet({
            ...microProjet,
          }).then((data) => {
            this.editableItem.raisonRetrait = data.raisonRetrait;
            this.editableItem.statutValidation = "SUSPENDU";
            this.editableItem.status = "SUSPENDU";
            this.editableItem.commentaireRetrait = data.commentaireRetrait;
            this.$toast.success("Microprojet retiré avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    saveAcceptation({ statutAcceptationType, statutAcceptation }) {
      if (statutAcceptationType == "SOUMISSION") {
        this.editableItem.statutAcceptationSoumission = statutAcceptation;
        this.doSoumettreMicroprojet();
      }

      if (statutAcceptationType == "APPROBATION") {
        this.editableItem.statutAcceptationApprobation = statutAcceptation;
        this.doApprouverMicroprojet();
      }

      if (statutAcceptationType == "VALIDATION") {
        this.editableItem.statutAcceptationValidation = statutAcceptation;
        this.doValidateMicroprojet();
      }

      if (statutAcceptationType == "TRANSMISSION") {
        this.editableItem.statutAcceptationTransmission = statutAcceptation;
        this.doTransmettreMicroprojet();
      }
    },
    indexOf(stepName) {
      return this.getStepIndexByName(stepName);
    },
    async soumettreMicroprojet() {
      this.showStatutAccepatationDialog("SOUMISSION");
    },
    async approuverMicroprojet() {
      this.showStatutAccepatationDialog("APPROBATION");
    },
    async validateMicroprojet() {
      this.showStatutAccepatationDialog("VALIDATION");
    },
    async transmettreMicroprojet() {
      this.showStatutAccepatationDialog("TRANSMISSION");
    },
    async retierMicroprojet() {
      this.showRetierMicroProjetDialog();
    },
    async doSoumettreMicroprojet() {
      if (this.editableItem.momoPhone == null) {
        this.$toast.error(
          "Vous devez obiligatoirement renseigner le numéro MoMo du DE à l'étape 1 de la fiche d'enquête avant toute soumission!",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        return;
      }
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment soumettre ce microprojet pour approbation?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.updateStatutValidation({
            ...this.editableItem,
            statutValidation: "SOUMIS",
          }).then((data) => {
            this.editableItem.statutValidation = "SOUMIS";
            this.$toast.success("Microprojet soumis pour approbation!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    async doApprouverMicroprojet() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment approuver ce microprojet?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.updateStatutValidation({
            ...this.editableItem,
            statutValidation: "APPROUVE",
          }).then((data) => {
            this.editableItem.statutValidation = "APPROUVE";

            this.$toast.success("Microprojet approuvé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    async doValidateMicroprojet() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider ce microprojet?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.editableItem.statutValidation = "VALIDE";

          this.updateStatutValidation({
            ...this.editableItem,
            statutValidation: "VALIDE",
          }).then((data) => {
            this.$toast.success("Microprojet validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });

            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    async doTransmettreMicroprojet() {
      if (this.editableItem.estDoublonMicroProjet) {
        this.$toast.error(
          "Ce microprojet est un doublon. Il ne peut pas être transmis!",
          {
            position: "top-right",
            duration: 10000,
          }
        );
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment transmettre ce microprojet pour son financement?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.updateStatutTransmission({
            ...this.editableItem,
            statutTransmission: "TRANSMIS",
          }).then((data) => {
            this.editableItem.statutTransmission = "TRANSMIS";

            this.$toast.success("Microprojet transmis avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    async validerPuisTransmettreMicroprojet() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider et transmettre ce microprojet pour son financement?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.updateStatutTransmission({
            ...this.editableItem,
            statutTransmission: "VALIDE",
          }).then((data) => {
            this.editableItem.statutTransmission = "TRANSMIS";

            this.$toast.success("Microprojet transmis avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },

    getStepIndexByName(stepName) {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].name == stepName) return i;
      }
      return -1;
    },
    show() {
      this.display = true;
    },
    resetContent() {
      //   this.beneficiaire = null;
      //   this.editableItem.beneficiaire = null;
    },
    showFromStep(stepName, singleStepDisplayMode = true) {
      this.resetContent();

      const step = this.getStepIndexByName(stepName);
      this.activeStepIndex = step != -1 ? step : 0;
      this.singleStepDisplayMode = singleStepDisplayMode;
      this.show();
    },
    hide() {
      this.display = false;
    },
    getStatusValidationColor(status) {
      let color = "primary";
      if (status == "NON_SOUMIS") {
        color = "danger";
      }
      if (status == "VALIDE") {
        color = "success";
      }
      if (status == "SOUMIS") {
        color = "warning";
      }
      if (status == "APPROVE") {
        color = "info";
      }
      return color;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      await this.saveCurrentStep();
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    async isCompleted(step) {
      //The index of this.$refs["activeComponent"] is always 0 because only one component is rendered at a time (the activeStepIndex component)
      const valid = await this.$refs["activeComponent"][0].isCompleted();
      return valid;
    },
    async saveCurrentStep() {
      if (this.loading) return;

      this.loading = true;
      try {
        let data = await this.$refs["activeComponent"][0].save();
        if (this.activeStepIndex == 0) {
          this.editableItem = {
            ...this.editableItem,
            ...data,
            beneficiaire: this.beneficiaire,
          };
        }
      } finally {
        this.loading = false;
      }
    },
    stepSaved(item) {
      //   if (item.titre) {
      //     this.editableItem = item;
      // }
      this.editableItem = {
        ...item,
        beneficiaire: this.beneficiaire,
      };
      //   console.log(item);
      //   this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async save() {
      if (
        !(await this.isCompleted(
          this.singleStepDisplayMode ? this.activeStepIndex : this.steps.length - 1
        ))
      ) {
        return;
      }
      await this.saveCurrentStep();

      this.$emit("save", {
        ...this.editableItem,
        beneficiaire: this.beneficiaire,
      });
      this.hide();
    },
  },
};
</script>

<style scoped>
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}
</style>
