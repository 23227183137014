<template>
  <PDialog
    :header="'Sélection du statut d\'acceptation'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '60vw', background: 'white' }"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 field">
            <!-- <h6>Statut Acceptation</h6> -->
            <div class="form-floating mb-3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="col-sm-12">
                  <div
                    v-for="(t, i) in statutAcceptations"
                    :key="i"
                    :for="'ta' + t.id"
                    @click="statutAcceptation = t"
                    class="field-checkbox mt-2 py-1 border px-2 cursor-pointer"
                  >
                    <PRadioButton
                      :id="'ta' + t.id"
                      name="acceptation"
                      :value="t"
                      v-model="statutAcceptation"
                    />
                    <label :for="'ta' + t.id" class="ms-2 cursor-pointer">{{
                      t.libelle
                    }}</label>
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <template #footer>
      <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    statutAcceptationType: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      display: false,
      statutAcceptation: null,
    };
  },
  created() {
    this.fetchStatutAcceptations();
  },
  computed: {
    ...mapGetters({
      statutAcceptations: "statutAcceptation/statutAcceptations",
    }),
  },
  watch: {
    item() {},
  },
  methods: {
    ...mapActions({
      fetchStatutAcceptations: "statutAcceptation/fetchStatutAcceptations",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", {
        statutAcceptationType: this.statutAcceptationType,
        statutAcceptation: this.statutAcceptation,
      });
      this.hide();
    },
  },
};
</script>
