<template>
  <div>
    <signature-editor-dialog
      v-if="active"
      @sign="onSign($event)"
      :title="
        activeItem != null && activeItem.signature == null
          ? 'Ajouter signature'
          : 'Editer signature'
      "
      ref="editor"
      :item="activeItem"
    />
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h6 class="mb-0 text-uppercase flex-fill">Signatures</h6>
      <button
        v-if="!active"
        @click.prevent="addSignature()"
        type="button"
        class="btn btn-primary"
      >
        {{ libBouton }}
      </button>
    </div>
    <div class="mb-5" v-if="!active">
      <div class="mb-2">
        <div class="d-flex justify-content-between mb-2 align-items-center">
          <div class="">
            <h6 class="mb-0">
              <img :src="signature" />
            </h6>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignatureEditorDialog from "./editor/dialog/SignatureEditorDialog.vue";
import { mapActions } from "vuex";

export default {
  components: { SignatureEditorDialog },
  props: ["value"],
  data() {
    return {
      activeItem: {},
      signature: this.value,
      signature2: { ...this.value },
      active: false,
      libBouton: "Ajouter",
    };
  },
  methods: {
    ...mapActions({
      uploadSignature: "media/uploadSignature",
    }),
    updateSignature(lang) {
      this.activeItem = { ...lang };
      this.active = true;
    },
    addSignature() {
      this.active = true;
    },
    onSign(sign) {
      const blob = this.b64toBlob(sign);
      var url = window.URL.createObjectURL(blob);
      console.log(url);
      this.uploadSignature(blob).then((data) => {
        console.log(data);
      });

      this.getUrl(sign).then((data) => {
        console.log(data);
      });
      this.signature = sign;
      this.signature2.file = sign;
      this.signature2.url = url;
      this.activeItem = { signature: null };
      this.$emit("input", this.signature);
      this.active = false;
      this.libBouton = "Modifier";
    },
    deleteSignature() {
      this.active = true;
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      });
    },
    async getUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
  },
};
</script>
