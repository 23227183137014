<template>
  <div>
    <competence-editor-dialog
      @save="saveCompetence"
      :title="
        competences != null && competences.length == 0
          ? 'Ajouter competence'
          : 'Editer competence'
      "
      ref="editor"
      :item="competences"
    />
    <div>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h6 class="mb-0 text-uppercase flex-fill">Compétences</h6>
        <slot name="buttonButton">
          <a
            v-if="!readOnly && btnType == 'edit'"
            class="text-warning"
            @click.prevent="addCompetence()"
          >
            <i class="bi bi-pencil-square"></i>
          </a>
          <button
            v-if="!readOnly && btnType == 'add'"
            @click.prevent="addCompetence()"
            type="button"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </slot>
      </div>
      <slot :addCompetence="addCompetence" :isEmpty="competences.length == 0"></slot>

      <ul class="text-muted ps-4">
        <li v-for="(competence, i) in competences" :key="i" class="mb-2">
          {{ competence }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CompetenceEditorDialog from "./editor/dialog/CompetenceEditorDialog.vue";

export default {
  components: { CompetenceEditorDialog },
  props: {
    value: {
      type: Array,
    },
    displayMode: {
      default: "cv", // 'cv', 'nice'
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: String,
      validator: (val) => ["add", "edit"].includes(val),
      default: "edit",
    },
  },
  data() {
    return {
      competences: [...this.value],
    };
  },
  watch: {
    value() {
      this.competences = [...this.value];
    },
  },
  created() {},
  methods: {
    ...mapActions({}),
    addCompetence() {
      this.$refs.editor.show();
    },
    saveCompetence(competences) {
      this.$emit("input", competences);
      this.$emit("change", competences);
    },
  },
};
</script>
