<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
   <template #default="{ }">
    <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()" >
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select id="langue" name="langue" option-label="nom" option-value="id" v-model="editableItem.langue" :options="ethnies" class="form-select">
                  <!-- <option selected>Sélectionner la langue</option>
                  <option :value="d" v-for="d in ethnies" :key="d.id">{{ d.nom }}</option> -->
                </c-select>
                <!-- <select id="langue" name="langue" :value="editableItem.langue" @input="handleInput" class="form-select" >
                  <option selected>Sélectionner la langue</option>
                  <option :value="d.id" v-for="d in ethnies" :key="d.id">{{ d.nom }}</option>
                </select> -->
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                <label for="langue">Langue</label>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select id="niveau" name="niveau" :value="editableItem.niveau" @input="handleInput" class="form-select" aria-label="Sélection niveau langue">
                  <option value="PASSABLE">Bas Niveau</option>
                  <option value="ABIEN">Assez Bien</option>
                  <option value="BIEN">Bon Niveau</option>
                  <option value="TBIEN">Très Bien</option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                <label for="niveau">Niveau</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button @click.prevent="save" type="submit" class="style_btn btn btn-success btn-lg">Enrégistrer</button>
        </div>
      </form>
    </ValidationObserver>
   </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { mapActions, mapGetters } from 'vuex'
import CSelect from '@/components/CSelect.vue'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      require: true
    }
  },
  mixins: [],
  components: { Modal, CSelect },
  data() {
    return {
      editableItem: this.item
    }
  },
  created(){
    this.fetchEthnies()
  },
  watch: {
    item(){
      this.editableItem = { ...this.item }
    }
  },
  computed: {
    ...mapGetters({
      ethnies: 'ethnie/ethnies'
    }),
  },
  methods: {
    ...mapActions({
      fetchEthnies: 'ethnie/fetchEthnies',
      fetchLangues: 'langue/fetchLangues'
    }),
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    show(){
      this.$refs.modal.show()
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('save', this.editableItem)
      this.close()
    },
    close(){
      this.$refs.modal.close()
    }
  },
}
</script>