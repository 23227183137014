<template>
  <div class="container-">
    <div class="row">
      <div class="d-flex justify-content-between mb-2">
        <!-- <span
          >Statut complétion:
          <PBadge
            v-if="microprojet != null && microprojet.id != null"
            :value="microprojet.status"
            :severity="getStatusColor(microprojet.status)"
        /></span>
        <span
          >Statut actuel:
          <PBadge
            v-if="microprojet != null && microprojet.id != null"
            :value="microprojet.status"
            :severity="getStatusColor(microprojet.status)"
        /></span>
        <span
          >Statut transmission:
          <PBadge
            v-if="microprojet != null && microprojet.id != null"
            :value="microprojet.statutTransmission"
            :severity="getStatusColor(microprojet.statutTransmission)"
        /></span> -->
        <div class="alert alert-danger" v-if="microprojet.estDoublonMicroProjet">
          Ce microprojet est un doublon(Le DE concerné a déjà un microprojet transmis au
          FNM). IL NE SERA PAS PRIS EN COMPTE.
        </div>
      </div>
      <div class="col-md-12 mb-4">
        <template v-if="loading">
          <div class="card shadow-0 border-0 px-3">
            <div class="field col-12 md:col-6 mt-2" v-for="i in 3" :key="i">
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2" height="2rem"></PSkeleton>
            </div>
          </div>
        </template>
        <div
          v-else
          class="card card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Information sur le microprojet
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span
                      ><b-btn
                        size="xs"
                        @click.prevent="editerDossier('INFORMATION')"
                        variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Programme :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.programme != null ? microprojet.programme.nom : "-"
                      }}
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Titre :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.titre || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Etape :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <template v-if="microprojet.etapeMicroprojet != null">
                        <PBadge
                          v-if="microprojet.etapeMicroprojet.code == 'INITIALISATION'"
                          severity="primary"
                          :value="microprojet.etapeMicroprojet.libelle"
                          >{{
                        }}</PBadge>
                        <PBadge
                          v-else
                          severity="warning"
                          :value="microprojet.etapeMicroprojet.libelle"
                          >{{
                        }}</PBadge>
                      </template>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Secteur :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.artisanatSecteur != null
                          ? microprojet.artisanatSecteur.libelle
                          : "-"
                      }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Branche/Division :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.artisanatBranche != null
                          ? microprojet.artisanatBranche.libelle
                          : "-"
                      }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Groupe :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.artisanatGroupe != null
                          ? microprojet.artisanatGroupe.libelle
                          : "-"
                      }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Métier :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.artisanatMetier != null
                          ? microprojet.artisanatMetier.libelle
                          : "-"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Investissement du microprojet
                <slot name="title">
                  <div>
                    <span
                      ><b-btn
                        size="xs"
                        @click.prevent="editerDossier('INVESTISSEMENT')"
                        variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Investissement propre :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partInverstissementPropre || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonctionnement propre :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partFonctionnementPropre || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Investissement subvention :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partInverstissementSubvention || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonctionnement subvention:</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partFonctionnementSubvention || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Investissement crédit:</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partInverstissementCredit || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonctionnement Crédit:</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.partFonctionnementCredit || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Rentabilité du micro-projet
                <slot name="title">
                  <div>
                    <span
                      ><b-btn
                        size="xs"
                        @click.prevent="editerDossier('RENTABILITE')"
                        variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Total Recettes prévisionnelles sur 6 mois :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.recettePrevisionelleSur6Mois || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Total Charges prévisionnelles sur 6 mois :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.chargePrevisionelleSur6Mois || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Bénéfice brut prévisionnel sur 6 mois:</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.beneficeBrutPrevisionelleSur6Mois || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Taux brut de rentabilité :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.tauxBrutRentabilite || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Localisation du microprojet
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span
                      ><b-btn
                        size="xs"
                        @click.prevent="editerDossier('LOCALISATION')"
                        variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Departement :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.departement != null
                          ? microprojet.departement.nom
                          : "-"
                      }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Commune :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.commune != null ? microprojet.commune.nom : "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Arrondissement :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        microprojet.arrondissement != null
                          ? microprojet.arrondissement.nom
                          : "-"
                      }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Village/Quartier :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ microprojet.ville != null ? microprojet.ville.nom : "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table table-borderless">
                        <thead class="border-bottom border-top">
                          <tr>
                            <th scope="col">Rue</th>
                            <th scope="col">Carrée</th>
                            <th scope="col">Maison</th>
                            <th scope="col">Hameau</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span> {{ microprojet.rue || "-" }}</span>
                            </td>
                            <td>
                              <span> {{ microprojet.carre || "-" }}</span>
                            </td>
                            <td>
                              <span> {{ microprojet.maison || "-" }}</span>
                            </td>
                            <td>
                              <span> {{ microprojet.hameau || "-" }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card d-flex flex-column justify-content-center">
          <!-- <button class="btn btn-primary" @click="validateMicroprojet">
            Valider le microprojet
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";

export default {
  props: ["microprojetId", "value"],
  components: {
    YesOrNoBadge,
  },
  data() {
    return {
      microprojet: this.value,
      loading: true,
    };
  },
  created() {},
  watch: {
    microprojetId: {
      handler() {
        this.loadData();
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    ...mapActions({
      fetchMicroprojet: "microprojet/fetchMicroprojet",
    }),
    reload() {
      this.loadData();
    },
    setMicroprojet(data) {
      this.microprojet = {
        ...data,
      };
    },
    loadData() {
      if (this.microprojetId) {
        this.loading = true;
        this.fetchMicroprojet({
          microprojetId: this.microprojetId,
        }).then((data) => {
          this.loading = false;
          this.microprojet = { ...data };
        });
      }
    },
    editerDossier(stepName) {
      this.$emit("edit", stepName);
    },
    validateMicroprojet() {
      this.$emit("validate", this.microprojet);
      this.microprojet = {};
      // this.hide()
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
