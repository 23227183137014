<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div>
          <SignatureEditorDialog ref="signatureEditor" @save="apposerSignature" />

          <a
            v-if="editableItem && !editableItem.fullSignatureUrl"
            @click.prevent="showSignatureDossierEditor()"
            class="btn btn-primary rounded-0 btn-sm"
            >Ajouter une signature</a
          >
        </div>
        <div class="d-flex justify-content-center mb-5">
          <div>
            <img
              v-if="editableItem"
              height="200px"
              :src="editableItem.fullSignatureUrl"
            />
            <h6>{{ editableItem.nom }} {{ editableItem.prenom }}</h6>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapActions } from "vuex";
import SignatureEditorDialog from "../../../common/signature/SignatureEditorDialog.vue";

export default {
  components: { SignatureEditorDialog },
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value },
    };
  },

  mixins: [setepperFormMixin],
  methods: {
    ...mapActions({
      apposerSignatureDossier: "demandeur/apposerSignatureDossier",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    apposerSignature(signatureUrl) {
      this.apposerSignatureDossier({
        urlSignature: signatureUrl,
        contratId: this.editableItem.id,
      }).then((data) => {
        let { fullSignatureUrl, signatureUrl } = data;
        this.editableItem = {
          signatureUrl,
          fullSignatureUrl,
        };
        this.$toast.success("Contrat signé avec succès", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
    showSignatureDossierEditor() {
      if (this.editableItem.signature == null) {
        this.$refs.signatureEditor.show();
      } else {
        this.$toast.info("Ce contrat a déjà été signé!", {
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
    handleValueChange(emplois) {
      this.$emit("input", {
        ...this.editableItem,
        emplois,
      });
    },
  },
};
</script>

<style scoped></style>
