<template>
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <h6>Mois d'activité</h6>
        <div class="form-floating mb-3">
          <c-select
            id="moisBenefice"
            name="moisBenefice"
            option-label="label"
            option-value="value"
            :emitObject="false"
            v-model="editableItem.moisBenefice"
            :options="moisBenefices"
            class="form-select"
          >
          </c-select>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
          <label for="moisBenefice">Mois d'activité</label>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-12">
      <h5>Prévisions de ventes</h5>
    </div>
    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.quantiteProduitPrincipal"
            type="number"
            class="form-control"
            id="quantiteProduitPrincipal"
            name="quantiteProduitPrincipal"
          />
          <label for="quantiteProduitPrincipal"
            >Quantité du produit/service principal (Qi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.prixUnitaireProduitPrincipal"
            type="number"
            class="form-control"
            id="prixUnitaireProduitPrincipal"
            name="prixUnitaireProduitPrincipal"
          />
          <label for="prixUnitaireProduitPrincipal"
            >Prix unitaire du produit/service principal (Pi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.autresRecettesProduitSecondaire"
            type="number"
            class="form-control"
            id="autresRecettesProduitSecondaire"
            name="autresRecettesProduitSecondaire"
          />
          <label for="autresRecettesProduitSecondaire"
            >Autres recettes issues des produits / services secondaires (ARi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="recettesProduitPrincipal"
            type="number"
            class="form-control"
            id="recettesProduitPrincipal"
            name="recettesProduitPrincipal"
            readonly
          />
          <label for="recettesProduitPrincipal"
            >Recettes issues du produit/service principal (Pi x Qi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            :value="totalRecettes"
            type="number"
            class="form-control"
            id="cout"
            name="cout"
            placeholder="Coût"
            readonly
          />
          <label for="cout">Recettes totales (RT=Pi x Qi+ARi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <PDivider />
    <div class="col-sm-12">
      <h5>Charges</h5>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesMatierePremiere"
            type="number"
            class="form-control"
            id="chargesMatierePremiere"
            name="chargesMatierePremiere"
          />
          <label for="chargesMatierePremiere">Matières premières (MPi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesMarchandise"
            type="number"
            class="form-control"
            id="chargesMarchandise"
            name="chargesMarchandise"
          />
          <label for="chargesMarchandise">Marchandises (Mi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-4">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesMainOeuvre"
            type="number"
            class="form-control"
            id="chargesMainOeuvre"
            name="chargesMainOeuvre"
          />
          <label for="chargesMainOeuvre">Main d’œuvre (MOi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesConsommationIntermediaire"
            type="number"
            class="form-control"
            id="chargesConsommationIntermediaire"
            name="chargesConsommationIntermediaire"
          />
          <label for="chargesConsommationIntermediaire"
            >Consommations intermédiaires (CIi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesServiceConsomme"
            type="number"
            class="form-control"
            id="chargesServiceConsomme"
            name="chargesServiceConsomme"
          />
          <label for="chargesServiceConsomme">Services consommés (SCi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>

    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesDotationAmortissement"
            type="number"
            class="form-control"
            id="chargesDotationAmortissement"
            name="chargesDotationAmortissement"
          />
          <label for="chargesDotationAmortissement"
            >Dotation aux amortissements (Dta)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.chargesFraisFinancier"
            type="number"
            class="form-control"
            id="chargesFraisFinancier"
            name="chargesFraisFinancier"
          />
          <label for="chargesFraisFinancier"
            >Frais financiers/Services financiers (Sfc)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="editableItem.autresCharges"
            type="number"
            class="form-control"
            id="autresCharges"
            name="autresCharges"
          />
          <label for="autresCharges">Autres Frais (ACi)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="totalCharges"
            type="number"
            class="form-control"
            id="totalCharges"
            name="totalCharges"
            readonly
          />
          <label for="totalCharges"
            >Total des charges (CT=MPi+Mi+Moi+CIi+SCi+Sfc+Dta+ACi)</label
          >
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="col-sm-12 col-md-6">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <div class="form-floating mb-3">
          <input
            v-model="totalBeneficeBrut"
            type="number"
            class="form-control"
            id="totalCharges"
            name="totalCharges"
            readonly
          />
          <label for="totalCharges">Bénéfice brut (RT-CT)</label>
          <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
            err
          }}</span>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: { ...this.value },
      moisBenefices: [
        { value: "MOIS_1", label: "MOIS_1" },
        { value: "MOIS_2", label: "MOIS_2" },
        { value: "MOIS_3", label: "MOIS_3" },
        { value: "MOIS_4", label: "MOIS_4" },
        { value: "MOIS_5", label: "MOIS_5" },
        { value: "MOIS_6", label: "MOIS_6" },
      ],
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("changed", val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    totalCharges() {
      return (
        parseInt(this.editableItem.chargesMatierePremiere || 0) +
        parseInt(this.editableItem.chargesMarchandise || 0) +
        parseInt(this.editableItem.chargesMainOeuvre || 0) +
        parseInt(this.editableItem.chargesConsommationIntermediaire || 0) +
        parseInt(this.editableItem.chargesDotationAmortissement || 0) +
        parseInt(this.editableItem.chargesFraisFinancier || 0) +
        parseInt(this.editableItem.autresCharges || 0) +
        parseInt(this.editableItem.chargesServiceConsomme || 0)
      );
    },
    totalRecettes() {
      return (
        parseInt(this.editableItem.autresRecettesProduitSecondaire || 0) +
        this.recettesProduitPrincipal
      );
    },
    recettesProduitPrincipal() {
      return (
        parseInt(this.editableItem.prixUnitaireProduitPrincipal || 0) *
        parseInt(this.editableItem.quantiteProduitPrincipal || 0)
      );
    },
    totalBeneficeBrut() {
      return this.totalRecettes - this.totalCharges;
    },
  },
  created() {},
  methods: {},
};
</script>
