<template>
 <PDialog :header="'Editeur information embauche'" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{width: '40vw'}">
  <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
    <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()" >
      <div class="row">
        <div class="col-sm-12 md:col-12 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="libelle">Nom de la structure</label>
            <div>
              <PInputText class="col-12 md:col-12" v-model="editableItem.structureEmbauche" />
            </div>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
          </ValidationProvider>
        </div>
        <div class="col-sm-12 md:col-12 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label for="libelle">IFU de la structure</label>
            <div>
              <PInputText class="col-12 md:col-12" v-model="editableItem.ifuStructure" />
            </div>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
          </ValidationProvider>
        </div>
        <div class="col-sm-12 field ">
          <div class="form-floating mb-3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="libelle">Commentaire</label>
              <div>
                <PTextarea class="col-12 md:col-12" v-model="editableItem.commentaireEmbauche" :autoResize="true" rows="8"/>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
            </ValidationProvider>
          </div>
        </div>
        <div class="col-sm-12 field">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div>Date embauche</div>
              <p-calendar
                class="col-12 md:col-12"
                :showIcon="true"
                size="sm"
                :showTime="true"
                locale="fr"
                name="dateNais"
                :manualInput="false"
                v-model="editableItem.dateEmbauche"
                :showWeek="true"
                />  
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <template #footer>
    <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
    <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
  </template>
</PDialog>
</template>

<script>

export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    item: {
      type:  Object,
      default: () => ({})
    }
  },
  data () {
    return {
      display: false,
      editableItem: this.item,
    }
  },
  computed: {
  },
  watch: {
    item(){
      this.editableItem = { ...this.item }
    }
  },
  methods: {
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    cancel () {
      this.hide()
      this.$emit('cancel')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('save', this.editableItem)
      this.hide()
    },
  }
  
}
</script>