<template>
  <PDialog
    :header="'Prise en charge automatique'"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '60vw', background: 'white' }"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <!-- <div class="col-sm-12 md:col-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="reference">Référence du microprojet à cloner</label>
              <div>
                <PInputText
                  v-uppercase
                  class="col-12 md:col-12"
                  v-model="editableItem.reference"
                />
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div> -->
          <div class="col-sm-12 field">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="reference">Référence du microprojet à cloner</label>
              <div>
                <input ref="btnUpload" type="file" name="file" @change="onFileChange" />
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <template #footer>
      <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      display: false,
      statutAcceptation: null,
      editableItem: {
        reference: null,
        file: null,
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {
    item() {},
  },
  methods: {
    ...mapActions({
      appliquerAutoPriseEnCharge: "priseEnCharge/appliquerAutoPriseEnCharge",
    }),
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      const file = files[0];
      this.editableItem.file = file;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      this.$toast.success(
        "Opération de clonage en cours. Cette opération pourrait durer plusieurs minutes....",
        {
          duration: 28000,
          position: "bottom-right",
        }
      );
      //   if (!(await this.isCompleted())) return;
      this.appliquerAutoPriseEnCharge(this.editableItem)
        .then(() => {
          this.$emit("save", {
            ...this.editableItem,
          });
          this.$toast.success("Opération réussie!", {
            duration: 8000,
            position: "top-right",
          });
          this.hide();
        })
        .catch((err) => {
          this.$toast.error("Une erreur s'est produite lors de l'opération.");
        });
    },
  },
};
</script>
