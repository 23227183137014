<template>
  <div class="">
    <PDialog :header="title" :modal="true" :visible.sync="display" :containerStyle="{width: '40vw'}">
        <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
            <div class="row">
              <div class="col-sm-12 field">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form- mb-3">
                  <PEditor v-model="editableItem" editorStyle="height: 320px"/>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                  <!-- <label for="statutProfessionnel">statutProfessionnel</label> -->
                </div>
              </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <template #footer>
          <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text"/>
          <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
        </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    value: {
      type: String
    }
  },
  components: {
  },
  data () {
    return {
      display: false,
      editableItem: this.value
    }
  },
  created () {
    this.fetchStatutProfessionnels()
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      statutProfessionnels: 'statutProfessionnel/statutProfessionnels'
    })
  },
  methods: {
    ...mapActions({
      fetchStatutProfessionnels: 'statutProfessionnel/fetchStatutProfessionnels'
    }),
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    ok () {
      this.$emit('saved', this.editableItem)
      this.editableItem = {}
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('saved', this.editableItem)
      this.hide()
    },
  }
}
</script>