<template>
  <PDialog
    class="stepper-dialog"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: 'scroll' }"
    :containerStyle="{ width: '80vw', height: '100vh' }"
    :maximizable="true"
  >
    <template #header>
      <div class="card px-0 border-0 mb-2 w-100">
        <div>
          <h4 class="d-flex justify-content-between">
            <div>{{ title }} - {{ offre.intitule }}</div>
            <PButton
              @click="cancel()"
              icon="pi pi-times"
              class="p-button-rounded p-button-outlined p-button-danger"
            />
          </h4>
          <PDivider />
        </div>
        <p-progress-bar v-if="loading" style="height: 0.25em" mode="indeterminate" />
        <DStepper
          class="shadow-light"
          :steps="steps"
          :activeStepIndex="activeStepIndex"
          :readonly="false"
        />
      </div>
    </template>

    <div class="d-flex mx-4 bg-white px-3 py-3">
      <!-- <div class="h-100" style="width: 300px"></div> -->
      <SearchableItemCandidatureTransmissibleSelector
        :listTitle="title"
        :referentielName="'candidatureSelector'"
        :tableFields="tableFields"
        :hideFilters="true"
        class="w-100"
        v-if="steps[activeStepIndex].name == 'SELECTION_CANDIDATION'"
        :topSelectionCount="count"
        :emptyReferentielItem="emptyReferentielItem"
        v-model="editableItem.candidatures"
        fetcherMethod="candidatureSelector/fetchTopNCandidatureSelectors"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
      />
      <PlacementAccepterForm
        ref="placementEditor"
        v-model="editableItem"
        v-if="steps[activeStepIndex].name == 'PLACEMENT_INFO'"
      />
    </div>
    <template #footer>
      <PButton
        label="Quitter"
        icon="pi pi-times"
        @click="cancel"
        class="p-button-text p-button-danger"
      />
      <template v-if="!singleStepDisplayMode">
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
      </template>
      <PButton
        :label="'Enrégister et quitter'"
        icon="pi pi-check"
        v-if="activeStepIndex == 1"
        class="p-button p-button-success"
        @click="save"
        autofocus
      />
    </template>
  </PDialog>
</template>

<script>
import { mapActions } from "vuex";
import SearchableItemCandidatureTransmissibleSelector from "./SearchableItemCandidatureTransmissibleSelector";
import PlacementAccepterForm from "../../common/placement/editor/PlacementAccepterForm.vue";
import DStepper from "../../../common/DStepper.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    offre: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    PlacementAccepterForm,
    SearchableItemCandidatureTransmissibleSelector,
    DStepper,
  },
  data() {
    return {
      display: false,
      singleStepDisplayMode: false,
      statutAcceptationType: null,
      activeStepIndex: 0,
      count: 0,
      editableItem: { ...this.item },
      loading: false,
      tableFields: [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "candidat.nom", label: "Nom" },
        { key: "candidat.prenom", label: "Prénom" },
        { key: "candidat.sexe", label: "Sexe" },
        { key: "candidat.phone", label: "Téléphone" },
        { key: "candidat.departement.nom", label: "Département" },
        { key: "candidat.commune.nom", label: "Commune" },
        { key: "candidat.ville.nom", label: "Ville" },
        { key: "totalPoints", label: "Points" },
        { key: "rang", label: "Range" },
        { key: "status", label: "Statut" },
      ],
    };
  },
  created() {},
  computed: {
    emptyReferentielItem() {
      return {};
    },
    steps() {
      let steps = [
        {
          name: "SELECTION_CANDIDATION",
          label: "SELECTION CANDIDATURE",
          subtitle: "Candidatures à accepter",
          to: "#selection-candidature",
          component: SearchableItemCandidatureTransmissibleSelector,
          completed: this.activeStepIndex >= 0,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          name: "PLACEMENT_INFO",
          label: "INFORMATIONS DU PLACEMENT",
          subtitle: "Détails du placement",
          to: "#placement-info",
          component: PlacementAccepterForm,
          completed: this.activeStepIndex >= 1,
          command: () => {
            this.activeStepIndex = 1;
          },
        },
      ];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    wasSaved() {
      return this.editableItem != null && this.editableItem.id != null;
    },
    hasValidationErrors() {
      return this.$refs["activeComponent"] != undefined
        ? this.$refs["activeComponent"][0].mxHasErrors
        : false;
    },
  },
  watch: {
    item() {
      this.editableItem = {
        ...this.item,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchMicroProjet: "microProjet/fetchMicroProjet",
      updateStatutValidation: "microProjet/updateStatutValidation",
      updateStatutTransmission: "microProjet/updateStatutTransmission",
      fetchDossier: "demandeur/fetchDossier",
    }),
    extraQueryArgsParamBuilder() {
      return `offreId=${this.offre.id}&status=PROPOSE`;
    },
    indexOf(stepName) {
      return this.getStepIndexByName(stepName);
    },
    async validerPuisTransmettreMicroprojet() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider et transmettre ce microprojet pour son financement?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.updateStatutTransmission({
            ...this.editableItem,
            statutTransmission: "VALIDE",
          }).then((data) => {
            this.editableItem.statutTransmission = "TRANSMIS";

            this.$toast.success("Microprojet transmis avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.$emit("change", {
              ...this.editableItem,
            });
            this.hide();
          });
        },
      });
    },
    getStepIndexByName(stepName) {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].name == stepName) return i;
      }
      return -1;
    },
    show() {
      this.activeStepIndex = 0;
      this.display = true;
    },
    resetContent() {
      //   this.beneficiaire = null;
      //   this.editableItem.beneficiaire = null;
    },
    showFromStep(stepName, singleStepDisplayMode = true) {
      this.resetContent();

      const step = this.getStepIndexByName(stepName);
      this.activeStepIndex = step != -1 ? step : 0;
      this.singleStepDisplayMode = singleStepDisplayMode;
      this.show();
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("cancel");
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    async isCompleted(step) {
      if (step == 0) {
        const valid = this.editableItem.candidatures.length != 0;
        if (!valid)
          this.$toast.error("Vous devez sélectionner au moins une candidature!", {
            position: "top-right",
            duration: 10000,
          });

        return valid;
      }
      const valid = await this.$refs["placementEditor"].isCompleted();
      return valid;
    },
    async save() {
      if (
        !(await this.isCompleted(
          this.singleStepDisplayMode ? this.activeStepIndex : this.steps.length - 1
        ))
      ) {
        return;
      }

      this.$emit("save", {
        ...this.editableItem,
      });
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #e1e4e1;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}
</style>
