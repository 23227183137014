<template>
 <PDialog :header="'Sélection du mode de programmation'" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{width: '40vw'}">
  <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
    <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()" >
      <div class="row">
        <div class="col-sm-12 field ">
          <h6>Que voulez-vous programmer?</h6>
          <div class="form-floating mb-3">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div   class="col-sm-12">
                  <div v-for="(t, i) in activitePP.tags" :key="i" class="field-checkbox mt-1">
                    <PCheckbox :id="'ta'+t.id" name="tag" :value="t" v-model="tags" />
                    <label :for="'ta'+t.id" class="ms-2 cursor-pointer">{{t.libelle}}</label>
                  </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>  
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <template #footer>
    <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
    <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
  </template>
</PDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    item: {
      type:  Object,
      default: () => ({})
    }
  },
  data () {
    return {
      display: false,
      editableItem: this.item,
      activitePP: {},
      tags: []
    }
  },
  created () {
    this.fetchTypeActivites()
  },
  computed: {
    ...mapGetters({
      typeActivites: 'typeActivite/typeActivites',
    })
  },
  watch: {
    typeActivites(){
      this.typeActivites.forEach(el => {
        if(el.code == 'ATELIER_PP'){
          this.activitePP = { ...el }
        }
      })
    },
    item(){
      this.editableItem = { ...this.item }
    }
  },
  methods: {
    ...mapActions({
      fetchProgrammes: 'programme/fetchProgrammes',
      fetchTypeActivites: 'typeActivite/fetchTypeActivites'
    }),
    selectionChange(evt, item){
      console.log(evt.target.checked, item)
    },
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    cancel () {
      this.hide()
      this.$emit('cancel')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('save', {
        typeActivite: this.activitePP,
        tags: this.tags
      })
      this.hide()
    },
  }
  
}
</script>