<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Au cours de la semaine dernière (7 derniers jours), avez-vous travaillé ne serait-ce qu'une heure?"
                v-model="editableItem.travaillerAuMoinsUneHeureSemainePasse"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div
            v-if="
              editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
              editableItem.travaillerAuMoinsUneHeureSemainePasse !== true
            "
            class="col-sm-12"
          >
            <h5>
              Bien que vous ayez déclaré ne pas avoir travaillé la semaine dernière,
              avez-vous réalisé l'une des activités suivantes la semaine dernière, à
              domicile ou à l'extérieur, pour aider la famille:
            </h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="serviceRenduSemaineDerniere"
                  name="serviceRenduSemaineDerniere"
                  aria-label="Sélection votre service rendu"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.serviceRenduSemaineDerniere"
                  :options="serviceRendus"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="serviceRenduSemaineDerniere">Service rendu</label>
              </div>
            </ValidationProvider>
          </div>
          <div
            v-if="
              editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
              editableItem.travaillerAuMoinsUneHeureSemainePasse !== true
            "
            class="col-sm-12 mb-4"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Bien que vous n'ayez pas travaillé la semaine dernière, avez-vous un emploi?"
                v-model="editableItem.hasEmploi"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <template v-if="editableItem.hasEmploi">
            <div class="col-sm-12">
              <h5>
                Combien d'heures avez-vous consacrées à votre emploi principal au cours de
                la semaine dernière
              </h5>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    type="number"
                    :value="editableItem.heureConsacreAEmploiPrincipal"
                    @input="handleInput"
                    class="form-control"
                    id="heureConsacreAEmploiPrincipal"
                    name="heureConsacreAEmploiPrincipal"
                  />
                  <!-- <vue-timepicker class="form-control" v-show="true" ref="tpicker" auto-scroll input-width="100%" format="HH:mm" v-model="editableItem.heureConsacreAEmploiPrincipal"></vue-timepicker> -->
                  <!-- <the-mask v-model="editableItem.heureConsacreAEmploiPrincipal" :mask="['##:##']" class="form-control" /> -->
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="heureConsacreAEmploiPrincipal"
                    >Nombre d'heure de travail</label
                  >
                </div>
              </ValidationProvider>
            </div>
          </template>
          <template>
            <div
              v-if="
                editableItem.travaillerAuMoinsUneHeureSemainePasse !== null &&
                editableItem.travaillerAuMoinsUneHeureSemainePasse == true
              "
              class="col-sm-12 mb-4"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <c-yes-or-no-input
                  question="Souhaiteriez-vous travailler plus?"
                  v-model="editableItem.travaillerPlus"
                />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
            <!-- <div class="col-sm-12">
            <c-yes-or-no-input
              question="Actuellement le salaire minimum mensuel est de 52 000 FCFA, quel est le montant de votre rémunération par rapport au salaire minimum?"
              :responses="plageSalaires"
              v-model="editableItem.heureTravail" 
            />
          </div> -->
            <div
              class="col-sm-12"
              v-if="
                editableItem.travaillerAuMoinsUneHeureSemainePasse === true ||
                editableItem.hasEmploi === true
              "
            >
              <h5>
                Actuellement le salaire minimum mensuel est de 52 000 FCFA, quel est le
                montant de votre rémunération par rapport au salaire minimum?
              </h5>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <select
                    :value="editableItem.plageSalaireActuel"
                    @input="handleInput"
                    id="plageSalaireActuel"
                    name="plageSalaireActuel"
                    class="form-select"
                  >
                    <option v-for="n in plageSalaires" :key="n.value" :value="n.value">
                      {{ n.label }}
                    </option>
                  </select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="plageSalaireActuel">Plage de sailaire</label>
                </div>
              </ValidationProvider>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
export default {
  components: { CYesOrNoInput, CSelect },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      travaillerSemainePasse: this.value.travaillerSemainePasse != null,
      hasFormationProfessionnelle: this.value.formationProfessionnelle != null,
      editableItem: this.value,
      niveauAlphabetions: [
        { value: "PAS_LIRE_PAS_ECRIRE", label: "Je ne sais pas lire et écrire" },
        { value: "LIRE_PAS_ECRIRE", label: "Je peux lire mais pas écrire" },
        { value: "LIRE_ET_ECRIRE", label: "Je sais lire et écrire" },
      ],
      plageSalaires: [
        { value: "MOINS_DE_40000", label: "Moins de 52 000 FCFA" },
        { value: "ENTRE_40000_ET_70000", label: "[52 000, 70 000 [" },
        { value: "PLUS_DE_70000", label: "52 000 FCFA ou plus" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      serviceRendus: "serviceRendu/serviceRendus",
      formationProfessionnelles: "formationProfessionnelle/formationProfessionnelles",
    }),
  },
  watch: {
    "editableItem.travaillerAuMoinsUneHeureSemainePasse": {
      handler(val) {
        if (!val) {
          this.editableItem.serviceRenduSemaineDerniere = null;
        }
      },
    },
  },
  created() {
    this.fetchInstructions();
    this.fetchServiceRendus();
    this.fetchFormationProfessionnelles();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
      fetchServiceRendus: "serviceRendu/fetchServiceRendus",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
    }),
    handleInput(e) {
      if (e.target.name === "niveauInstruction") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
