<template>
<div>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <qualification-editor-dialog v-show="false" @save="saveQualification" :title="activeItem != null && activeItem.qualification == null ? 'Ajouter qualification' : 'Editer qualification'" ref="editor" :item="activeItem" />
    <slot name="header" :addQualification="addQualification">
      <h6 class="mb-0 text-uppercase flex-fill">Qualifications</h6>
      <button @click.prevent="addQualification()" type="button" class="btn btn-primary">Ajouter</button>
    </slot> 
  </div>
  <div class="mb-5">
    <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">Qualifications</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->

    <div class="mb-4">
      <div class="d-flex justify-content-between mb-2 align-items-center" v-for="(qualif, index) in qualifications" :key="index">
        <div class="" >
          <small class="">{{ qualif.dateDeb | moment('YYYY') }} à {{ qualif.dateFin | moment('YYYY')  }} | Cotonou - Bénin</small>
          <h5 class="mb-0">{{ qualif.diplome }}</h5>
          <small class="text-muted">{{ qualif.centreDeFormation }}</small>
        </div>
       <!--  <div>
          <a @click.prevent="updateQualification(qualif)" class="btn btn-success rounded-0 btn-sm"><i class="bi bi-pencil-square"></i></a>
          <a @click.prevent="deleteQualification(qualif)" class="btn btn-danger rounded-0 btn-sm"><i class="bi bi-trash"></i></a>
        </div>-->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import QualificationEditorDialog from '../editor/dialog/QualificationEditorDialog.vue'
export default {
  components: { QualificationEditorDialog },
  props: ['value'],
  data() {
    return {
      activeItem: {},
      qualifications: [...this.value]
    }
  },
  computed: {
    emptyQualification(){
      return {
        diplome: null, 
        competence: null, 
        niveau: null, 
        centreDeFormation: null,
        dateDeb: null,
        dateFin: null,
      }
    }
  },
  methods: {
    updateQualification(qualif){
      this.activeItem = { ...qualif }
      this.$refs.editor.show()
    },
    addQualification(){
      // this.qualifications.push(qualif)
      this.activeItem = { ...this.emptyQualification }
      this.$refs.editor.show()
    },
    saveQualification(qualif){
      
      // this.qualifications = this.qualifications.filter(item => item.niveau.id != qualif.niveau.id)

      this.qualifications.push(qualif)
      this.activeItem = { ...this.emptyQualification }
      this.$emit('input', this.qualifications)
    },
    deleteQualification(qualif){
      this.qualifications = this.qualifications.filter(item => item.niveau.id != qualif.niveau.id)
    },
  },
}
</script>