<template>
  <div class="pb-5">
    <div
      class="row bg-white me-2 pt-2 pb-1 shadow-light"
      style="position: absolute; top: 65px; z-index: 100; width: 99.6%"
    >
      <!-- <div class="col-md-12">
        <div class="d-flex justify-content-betwee mb-2 align-items-center">
          <span class="h6 me-4">Type de matching:</span>
          <div class="d-flex justify-content- align-items-center me-3" v-for="opt in matchingTypeOptions " :key="opt.value">
            <PRadioButton :inputId="'ID_' + opt.value" :id="'ID_' + opt.value" name="matchingType" :value="opt.value" v-model="matchingType" />
            <label class="ms-2 cursor-pointer" :for="'ID_' + opt.value">{{ opt.label }}</label>
          </div>
        </div>
        <PDivider />
      </div> -->
      <div class="col-md-6 col-sm-12">
        Sélectionnées <PTag>{{ saved.length }}</PTag>
        <button @click.prevent="clearSelection" class="btn btn-sm btn-danger">
          <i class="bi bi-close"></i>Vider
        </button>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="border ratio-box py-2 px-4">
          <span class="ratio-label text-uppercase fw-bold h6">Femme:</span>
          <span class="ratio-label ms-3 fw-bold text-primary"
            >{{ ratio.femmeRecommandeCount
            }}<span class="text-muted fw-normal ms-5">
              ({{ ratio.pourcentageFemme }}%)</span
            ></span
          >
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="border ratio-box py-2 px-4">
          <span class="ratio-label text-uppercase fw-bold h6">Homme:</span>
          <span class="ratio-label ms-3 fw-bold text-primary"
            >{{ ratio.hommeRecommandeCount }}
            <span class="text-muted fw-normal ms-5">
              ({{ ratio.pourcentageHomme }}%)</span
            ></span
          >
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="bg mt-5" style="position: ">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <slot name="listTitle">
              <!-- <h6>{{ listTitle }}</h6> -->
            </slot>
          </div>
        </div>
        <div>
          <slot name="listDescription"></slot>
        </div>
        <div class="bg-white p-3 mt-2 border shadow-0">
          <!-- <div class="d-flex justify-content-between mb-2">
            <span>Matcher automatique avec les critères de l'offre - <span class="text-uppercase">{{offre.intitule}}</span> - #{{offre.reference}}</span>
            <PInputSwitch v-tooltip.top="'Matching automatique'" v-model="autoOfferMatching"/>
            <PInputSwitch v-tooltip.top="'Matching automatique'" v-model="autoOfferMatching"/>
            <PInputSwitch v-tooltip.top="'Matching automatique'" v-model="autoOfferMatching"/>
          </div> -->
          <div class="d-flex justify-content-between mb-2 align-items-center">
            <div class="d-flex align-items-center">
              <span class="h6 me-4">Type de matching:</span>
              <div
                class="d-flex justify-content- align-items-center me-3"
                v-for="opt in matchingTypeOptions"
                :key="opt.value"
              >
                <PRadioButton
                  :inputId="'ID_' + opt.value"
                  :id="'ID_' + opt.value"
                  name="matchingType"
                  :value="opt.value"
                  v-model="matchingType"
                />
                <label class="ms-2 cursor-pointer" :for="'ID_' + opt.value">{{
                  opt.label
                }}</label>
              </div>
              <PDivider layout="vertical" />
            </div>
            <div class="d-flex">
              <div
                class="d-flex border ps-2 pe-3 py-1 rounded border-info align-items-center me-3"
              >
                <PCheckbox
                  :inputId="'inclureAncienBeneficiaire'"
                  :id="'inclureAncienBeneficiaire'"
                  name="inclureAncienBeneficiaire"
                  :binary="true"
                  v-model="inclureAncienBeneficiaire"
                />
                <label
                  class="ms-2 text-danger cursor-pointer fw-bold"
                  :for="'inclureAncienBeneficiaire'"
                >
                  Je veux inclure aussi les DEs déjà placés
                </label>
              </div>
            </div>
          </div>
          <div class="alert alert-warning" v-if="inclureAncienBeneficiaire">
            <i class="bi bi-exclamation-triangle me-2" style=""></i>
            <i
              >Vous avez choisi d'inclure les DEs qui ont déjà bénéficiés d'un placement
              ou d'un microprojet. Ceci pourrait entrainer des risques de placements
              multiples du DE dont vous vous engagez à assumer la reponsabilité.</i
            >
          </div>
          <PDivider />
          <div class="container- my-1">
            <p-progress-bar
              v-if="mxLoading"
              style="height: 0.33em"
              mode="indeterminate"
            />
            <div class="border- shadow-0 rounded-4">
              <form class="bg-" method="post" accept-charset="utf-8" action="">
                <div class="row">
                  <div class="col">
                    <div class="input-group">
                      <div class="p-inputgroup">
                        <PInputText
                          type="text"
                          v-model="mxSearch"
                          :placeholder="'Rechercher par'"
                        />
                        <p-multi-select
                          :multiple="false"
                          v-model="mxSearchFields"
                          :options="searchFieldsOptions.filter((el) => el.visible)"
                          optionLabel="label"
                          optionValue="value"
                          :editable="true"
                        />
                        <p-dropdown
                          class="flex-grow-auto me-2"
                          v-model="demandeurType"
                          :options="demandeurTypeOptions"
                          optionLabel="label"
                          optionValue="value"
                          :placeholder="'Type de DE'"
                          display="chip"
                        />
                        <p-dropdown
                          class="flex-grow-auto me-2"
                          v-model="demandeurVulnerabilite"
                          :options="demandeurVulnerabiliteOptions"
                          optionLabel="label"
                          optionValue="value"
                          :placeholder="'Vulnérabilité'"
                          display="chip"
                        />
                        <p-dropdown
                          class="flex-grow-1 me-2"
                          v-model="demandeurSexe"
                          :options="demandeurSexeOptions"
                          optionLabel="label"
                          optionValue="value"
                          :placeholder="'Sexe DE'"
                          display="chip"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <a
                      href="#"
                      @click.prevent="mxSubmitQuery"
                      class="style_btn btn btn-primary"
                      id="button-query"
                      ><i class="bi bi-search me-2"></i>Rechercher</a
                    >
                  </div>
                </div>
              </form>
              <transition>
                <div class="border-0 shadow-0 mt-2">
                  <div class="row p-fluid grid">
                    <template v-for="(option, index) in filterOptions">
                      <div
                        class="col-12 col-sm-6 col-md-3 mb-2"
                        v-if="option.visible"
                        :key="index"
                      >
                        <!-- <label for="basic">{{option.label}}</label> -->
                        <!-- <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" /> -->
                        <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                        <template v-if="option.queryUrl || option.items">
                          <AsyncMultiSelect
                            v-model="option.value"
                            :multiple="true"
                            :queryUrl="option.queryUrl"
                            :optionLabel="option.labelField"
                            :placeholder="option.label"
                            :emitObject="false"
                            v-if="option.queryUrl"
                            :displayMode="option.displayMode"
                            :searchOptions="[option.labelField]"
                          />
                          <p-dropdown
                            class="flex-grow-1 me-2"
                            v-model="option.value"
                            :options="option.items"
                            v-else
                            optionLabel="label"
                            optionValue="value"
                            :placeholder="option.label"
                            display="chip"
                          />
                        </template>

                        <p-input-text
                          v-else
                          v-model="option.value"
                          :placeholder="option.label"
                        />
                      </div>
                    </template>
                  </div>
                  <div class="d-flex justify-content-end">
                    <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
                  Matching avec les critères de l'offre
                </PButton> -->
                    <PButton
                      class="p-button-danger p-button- py-1 p-button-sm ms-1"
                      @click.prevent.stop="
                        filterOptions.forEach((option) => (option.value = []))
                      "
                    >
                      Réinitialiser les filtres
                    </PButton>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap text-wrap mb-4">
            <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
              Filtres appliquées:
            </h6>
            <template v-for="(option, index) in filterOptions">
              <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
              <template v-if="option.multiple">
                <PTag
                  severity="warning"
                  class="ms-2 mb-2"
                  v-for="(item, i) in option.value"
                  :key="index + '_' + i"
                >
                  <small class="p-1 text-caption">
                    {{ item[option.labelField] }}
                    <PButton
                      v-tooltip.top="`Retirer du filtre`"
                      icon="pi pi-times cursor-pointer"
                      class="p-button-danger p-button-sm ms-1 p-0"
                      @click.prevent.stop="
                        option.value = option.value.filter((el) => el != item)
                      "
                      style="height: 14px; width: 14px"
                    />
                  </small>
                </PTag>
              </template>

              <template v-else>
                <PTag
                  severity="warning"
                  class="ms-2 mb-2"
                  :key="index + '_' + i"
                  v-if="option.value"
                >
                  <small class="p-1 text-caption">
                    {{ option.value }}
                    <PButton
                      v-tooltip.top="`Retirer du filtre`"
                      icon="pi pi-times cursor-pointer"
                      class="p-button-danger p-button-sm ms-1 p-0"
                      @click.prevent.stop="option.value = option.value = null"
                      style="height: 14px; width: 14px"
                    />
                  </small>
                </PTag>
              </template>
            </template>
            <!-- <PButton v-if="mxActiveFiltersCount > 0" class="p-button-danger p-button-outlined py-1 p-button-sm ms-1" @click.prevent.stop="filterOptions.forEach((option) => option.value = [])">
                Réinitialiser les filtres
              </PButton> -->
          </div>
          <div class="table-responsive">
            <b-table
              show-empaty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :sort-by.sync="mxPagination.sortBy"
              :sort-desc.sync="mxPagination.sortDesc"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(index)="">
                <span> N° </span>
              </template>

              <template #head(#)="data">
                <div class="form-check">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <template #cell(#)="data">
                <div class="form-check">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(enabled)="data">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    v-model="data.item.enabled"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="data.item.enabled"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{{
                    data.item.enabled ? "Actif" : "Inactif"
                  }}</label>
                </div>
              </template>
              <template #cell(estSuivi)="{ item }">
                <div class="position-relative">
                  <PTag
                    rounded
                    :severity="item.estSuivi ? 'primary' : 'danger'"
                    size="large"
                    >{{ item.estSuivi ? "Affecté" : "Non.Affecté" }}</PTag
                  >
                </div>
              </template>
              <template #cell(estPPI)="{ item }">
                <div class="position-relative">
                  <azoli-status-badge :de="item" :key="'_statut_c_' + item.id" />
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <slot name="actions" :data="data">
                    <button class="btn btn-sm py-1 btn-outline-primary">
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </slot>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
              <template #cell(presence)="data">
                <span class="d-flex justify-content-"
                  ><PInputSwitch
                    @click.stop=""
                    v-model="data.item.presence"
                    @change="presenceChange(data.item)"
                    class="me-3"
                  />
                  <PTag :severity="getPresenceColor(data.item)">{{
                    getPresenceLabel(data.item)
                  }}</PTag></span
                >
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }}
              de {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { multiPageItemSelectorMixin } from "@/mixins/multipage-item-selector-mixin";
import CAutoComplete from "./CAutoComplete.vue";
import { mapActions, mapGetters } from "vuex";
import AsyncMultiSelect from "./AsyncMultiSelect.vue";
import AzoliStatusBadge from "./AzoliStatusBadge.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    offre: {
      type: Object,
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: () => {},
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  components: {
    CAutoComplete,
    AsyncMultiSelect,
    AzoliStatusBadge,
  },
  mixins: [paginatorMixin, multiPageItemSelectorMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      saved: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      inclureAncienBeneficiaire: false,
      demandeurType: null,
      demandeurSexe: null,
      demandeurVulnerabilite: null,
      // filterOptions: [],
      updatingTable: false,
      showAdvancedFilter: false,
      autoOfferMatching: false,
      recommendationRationStat: {},
      matchingType: "MANUEL",
      activeReferentiel: { ...this.emptyReferentielItem },
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nomPrenom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
      ],
      affectationStatusOptions: [
        { value: "AFFECTABLE", label: "Tous" },
        { value: "AFFECTE", label: "Affecté" },
        { value: "NON_AFFECTE", label: "Non Affecté" },
      ],
      matchingTypeOptions: [
        { value: "MANUEL", label: "Manuelle" },
        { value: "SEMI_AUTOMATIQUE", label: "Semi Automatique" },
        { value: "AUTOMATIQUE", label: "Automatique" },
      ],
      affectationStatus: "AFFECTABLE",
      filterOptions: [
        {
          column: "typeCheminEmploi",
          value: "",
          key: "label",
          label: "Type D'emploi Positionnement",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "SALARIE", label: "SALARIE" },
            { value: "INDEPENDANT", label: "INDEPENDANT" },
          ],
        },
        {
          column: "annExp",
          value: "",
          label: "Expériences",
          labelField: "label",
          valueKey: "label",
          displayMode: "classic",
          queryUrl: "anneeExpriences",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "langue",
          value: [],
          label: "Langues",
          dropdown: true,
          labelField: "nom",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
          multiple: true,
        },
        {
          column: "metierAppris",
          value: "",
          label: "Métier(s) appris",
          valueKey: "id",
          queryUrl: "romes",
          displayMode: "classic",
          labelField: "libelle",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "metierDesires",
          value: "",
          label: "Métier(s) recherché(s)",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "competence",
          value: "",
          label: "Aptitudes",
          getterName: "competences",
          valueKey: "id",
          displayMode: "classic",
          labelField: "intitule",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        // {
        //   column: "diplome",
        //   value: "",
        //   label: "Diplômes",
        //   valueKey: "id",
        //   dropdown: true,
        //   displayMode: "classic",
        //   labelField: "code",
        //   queryUrl: "niveaux",
        //   visible: true,
        // },
        {
          column: "niveauInstruction",
          value: "",
          label: "Niveau instruction",
          dropdown: true,
          valueKey: "id",
          labelField: "code",
          displayMode: "classic",
          queryUrl: "instructions",
          visible: true,
          multiple: true,
        },
        {
          column: "programmesPrescrits",
          value: "",
          labelField: "nom",
          label: "Programme prescrit",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "programmes",
          dropdown: true,
          visible: true,
          multiple: true,
        },

        // {
        //     column: "nature",
        //     value: "",
        //     label: "Nature contrat",
        //     labelField: "code",
        //     displayMode: "classic",
        //     queryUrl: "natures",
        //     valueKey: "id",
        //     dropdown: true,
        //     visible: true,
        // },
        {
          column: "departementIds",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          valueKey: "id",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "communeIds",
          value: "",
          labelField: "nom",
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "arrondissementIds",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "villeIds",
          value: "",
          labelField: "nom",
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "phone",
          label: "Téléphone",
          visible: true,
        },
        {
          column: "npi",
          label: "NPI",
          visible: true,
        },
      ],
    };
  },
  watch: {
    affectationStatus() {
      //   this.makeQuery();
    },
    matchingType(val) {
      if (val == "AUTOMATIQUE") {
        // this.makeQuery();
      } else if (val == "SEMI_AUTOMATIQUE") {
        this.filterOptions = this.filterOptions.map((flt) => {
          if (flt.column == "metierDesires") {
            flt.value = [this.offre.poste];
          } else if (flt.column == "domaine") {
            flt.value = [...this.offre.domaines];
          } else if (flt.column == "langue") {
            flt.value = [...this.offre.langues];
          } else if (flt.column == "diplome") {
            flt.value = [...this.offre.niveaus];
          }
          return { ...flt };
        });
      }
    },
  },
  mounted() {},
  created() {
    this.makeQuery();
    this.fetchOneOffreStatistics({ offreId: this.offre.id }).then((data) => {
      this.recommendationRationStat = { ...data };
    });
  },
  computed: {
    ...mapGetters({
      demandeurSexeOptions: "setting/demandeurSexeOptions",
      demandeurTypeOptions: "setting/demandeurTypeOptions",
      demandeurVulnerabiliteOptions: "setting/demandeurVulnerabiliteOptions",
    }),
    localStat() {
      let localStat = {
        hommeRecommandeCount: 0,
        femmeRecommandeCount: 0,
      };

      for (let i = 0; i < this.saved.length; i++) {
        localStat.hommeRecommandeCount += this.saved[i].sexe == "M" ? 1 : 0;
        localStat.femmeRecommandeCount += this.saved[i].sexe == "F" ? 1 : 0;
      }

      localStat.totalRecommandeCount =
        localStat.hommeRecommandeCount + localStat.femmeRecommandeCount;

      return localStat;
    },
    ratio() {
      let stat = {
        hommeRecommandeCount:
          (this.recommendationRationStat.hommeRecommandeCount || 0) +
          this.localStat.hommeRecommandeCount,
        femmeRecommandeCount:
          (this.recommendationRationStat.femmeRecommandeCount || 0) +
          this.localStat.femmeRecommandeCount,
        totalRecommandeCount:
          (this.recommendationRationStat.hommeRecommandeCount || 0) +
          (this.recommendationRationStat.femmeRecommandeCount || 0) +
          this.localStat.totalRecommandeCount,
      };

      stat.pourcentageHomme = (stat.totalRecommandeCount == 0
        ? 0
        : 100 * (stat.hommeRecommandeCount / stat.totalRecommandeCount)
      ).toFixed(2);
      stat.pourcentageFemme = (stat.totalRecommandeCount == 0
        ? 0
        : 100 * (stat.femmeRecommandeCount / stat.totalRecommandeCount)
      ).toFixed(2);

      return stat;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    ...mapActions({
      fetchOneOffreStatistics: "statistic/fetchOneOffreStatistics",
    }),
    initFiltersWithOfferCriterias() {
      this.filt;
    },
    makeQuery() {
      this.mxInitializePaginator({
        queryModel: this.referentielName,
        search: this.search,
        fetcherMethod: `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
        dataGetter: `${this.referentielName}/${this.referentielName}s`,
        paginationGetter: `${this.referentielName}/pagination`,
        autoSubmitQuery: false,
        submitQueryOnInit: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 8,
        },
        filterOptions: this.filterOptions,
        searchFields: this.searchFields,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("regroupements", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "inclureAncienBeneficiaire",
            this.inclureAncienBeneficiaire,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "vulnerabilite",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("sexe", this.demandeurSexe, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "offre",
            this.matchingType == "AUTOMATIQUE" ? this.offre.id : null,
            paramPlus
          );

          //   let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        // extraQueryArgsParamBuilder: this.extraQueryArgsParamBuilder
      });
    },
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    getPresenceLabel(item) {
      return item.presence == null ? "N/A" : item.presence ? "Présent" : "Absent";
    },
    getPresenceColor(item) {
      return item.presence == null ? "light" : item.presence ? "primary" : "danger";
    },
    clearSelection() {
      this.saved = [];
      this.selected = [];
      this.$refs.table.clearSelected();
    },
    presenceChange(item) {
      this.$emit("presenceChange", item);
    },
  },
};
</script>

<style>
.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
