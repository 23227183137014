<template>
  <div class="col-sm-12">
    <ValidationProvider rules="required" v-slot="{ errors }">
      <div class="form-floating mb-3">
        <textarea
          v-model="editableItem.libelle"
          type="text"
          class="form-control"
          id="contenu"
          rows="10"
          cols="30"
          name="contenu"
          placeholder="Contenu"
        />
        <label for="nom">Contenu</label>
        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
          err
        }}</span>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: {
        ...this.value,
      },
    };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  created() {},
  methods: {},
};
</script>
