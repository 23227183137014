<template>
  <div class="uploader-box" @dragover.prevent @drop.prevent>
    <input
      ref="btnUpload"
      type="file"
      name="fileUpload"
      @change="onFileChange"
      capture="user"
      style="display: none"
    />
    <div
      class="drop-zone b-info"
      @click.prevent="$refs.btnUpload.click()"
      @dragover="dragover"
      @dragleave="dragleave"
      :accept="acceptedExtension"
      @drop="onFileChange"
      v-cloak
    >
      <div class="drop-area" @dragover.prevent @drop.prevent>
        <label for="assetsFieldHandle" class="block cursor-pointer">
          <div class="action" v-if="file === null">
            <template v-if="!file">
              <i :class="icon" class="text-g"></i>
              <div class="mt-4">
                {{ actionLabel }}
              </div>
            </template>
            <template v-else
              ><i :class="icon"></i> {{ file.name | truncate(5) }}.pdf</template
            >
          </div>
          <div class="preview" v-else-if="!hidePreview">
            <img
              :src="previewUrl"
              class="w-100"
              v-if="file.type.indexOf('image') != -1"
            />
            <template v-else>
              <div @click.stop="reset()" class="close">
                <i class="fa fa-window-close cursor-pointer"></i>
              </div>
              <div class="page-count">Pages {{ pageCount }}</div>
            </template>
          </div>
        </label>
      </div>
    </div>
    <!-- {{previewUrl}} -->
    <!-- <img :src="fileUrl" /> -->
  </div>
</template>

<script>
export default {
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
    acceptedExtension: {
      type: String,
      default: "*",
    },
    hasRoundBtn: {
      type: Boolean,
      default: false,
    },
    method: {
      type: String,
      default: "POST",
    },

    hidePreview: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "fa-cloud-upload-alt",
    },

    actionLabel: {
      type: String,
      default: "Choisir un fichier",
    },

    data: {
      type: Object,
      required: false,
    },

    fileKey: {
      type: String,
      default: "file",
    },
    name: {
      type: String,
      default: "fileUpload",
    },
    uploadUrl: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      file: null,
      progress: 0,
      error: null,
      pageCount: 0,
      previewUrl: "",
    };
  },
  watch: {
    error(err) {
      if (err !== null && err.type !== undefined) {
        this.$toast.error(err.message, {
          position: "bottom-right",
          duration: 10000,
        });
      }
    },
  },
  computed: {
    fileUrl() {
      return this.file !== null
        ? (window.URL || window.webkitURL).createObjectURL(this.file)
        : "";
    },
  },
  methods: {
    dragleave(event) {
      event.currentTarget.classList.add("bg-drag-default");
      event.currentTarget.classList.remove("bg-drag-enter");
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-drag-default")) {
        event.currentTarget.classList.remove("bg-drag-enter");
        event.currentTarget.classList.add("bg-drag-default");
      }
    },
    // drop(event) {
    //   event.preventDefault();
    //   // this.$refs.file.files = event.dataTransfer.files;
    //   // this.onChange(); // Trigger the onChange event manually
    //   // // Clean up
    //   event.currentTarget.classList.add('bg-warning');
    //   event.currentTarget.classList.remove('bg-info');
    // },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      const file = files[0];

      let path = (window.URL || window.webkitURL).createObjectURL(file);

      console.log(this.acceptedExtension.split(",").includes(file.type.split("/").pop()));
      if (
        this.acceptedExtension === "*" ||
        this.acceptedExtension.split(",").includes(file.type.split("/").pop())
      ) {
        this.file = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
          this.emitter("fileSelected", {
            source: {
              key: this.key,
            },
            file: this.file,
            filePath: path,
            previewUrl: e.target.result,
          });

          if (this.autoUpload) {
            this.uploadSelectedFile();
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.error = {
          type: "error.invalid-file",
          message: `Le fichier chargé est invalide. Seul les fichiers de type ${this.acceptedExtension} sont acceptés`,
        };
        this.emitter("error", this.error);
        // this.emitter('fileSelected', {
        //   'source': {
        //     key: this.key
        //   },
        //   'file': this.file,
        //   'filePath': path,
        // })
      }
    },
    uploadSelectedFile() {
      return this.uploadFile(this.file);
    },
    uploadFile(file) {
      let vm = this;

      if (!this.uploadUrl || this.uploadUrl === "") {
        console.log("Please provide the target uploadUrl");
      } else if (!this.method || this.method === "") {
        console.log("Please provide file upload url ( POST / PUT )");
      }

      let formData = new FormData();
      let fileKey = this.fileKey;
      //Update fileKey Name to ensure it a valide
      fileKey = fileKey ? fileKey : "file";

      //Send request only when user choosed a file
      if (file) formData.append(fileKey, file);

      //Append extra data
      if (this.data) {
        for (var key in this.data) {
          if (key !== fileKey) {
            formData.append(key, this.data[key]);
          }
        }
      }

      if (!this.useAxios) {
        console.log("Default upload mode");
        this.progress = 0;
        var xhr = new XMLHttpRequest();
        console.log(this.$axios);
        // let url = !this.url.startsWith('http')? this.$http.defaults.baseUrl + this.url : this.url
        xhr.open(this.method, this.uploadUrl);

        xhr.onloadstart = function (e) {
          vm.emitter("start", e);
        };
        xhr.onloadend = function (e) {
          vm.emitter("finish", e);
        };
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            var status = xhr.status;
            if (status || (status >= 200 && status < 400)) {
              vm.emitter("completed", JSON.parse(e.currentTarget.response));
            } else {
              this.notifyServerError();
              console.log(xhr.statusText, e);
            }
          }
        };
        xhr.upload.onprogress = vm.uploadProgress;
        xhr.send(formData);
      } else {
        console.log("Axios Upload mode");
        let responsePromise = this.$http[this.method.toLowerCase()](
          this.uploadUrl,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: vm.uploadProgress,
          }
        );

        responsePromise
          .then((resp) => {
            this.$emit("finish", resp.data);
          })
          .catch((err) => {
            console.log(err);
            this.notifyServerError();
          });

        return responsePromise;
      }
    },
    dragFile(e) {
      this.file = e.dataTransfer.files;
    },
    reset() {
      this.file = null;
      this.error = null;
    },
    click() {
      this.$refs.btnUpload.click();
    },
    emitter(event, data) {
      this.$emit(event, data);
    },
    hasFile() {
      return this.file != null;
    },
    notifyError(err) {
      this.error = err;
      this.$emit("error", err);
    },
    notifyServerError() {
      this.notifyError({
        type: "error.server-error",
        message: `Une erreure s'est produite lors de l'envoie du fichier.`,
      });
    },
  },
};
</script>

<style scoped>
.drop-zone {
  /* background-color: #1b8f6a29; */
  border: 0px dashed #d0ddf4;
  color: white;
  /* margin-bottom: 10px; */
  min-width: 100%;
  min-height: 350px;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  color: #717274;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-drag-enter {
  background: pink;
  /* background: transparent; */
}

.bg-drag-left,
.bg-drag-default {
  background: #f7f8ff;
  /* background: transparent; */
}

.drop-area {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.uploader-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is-justify-center {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
</style>
