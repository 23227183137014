<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <langue-editor-dialog
        :hideAlphabetisation="hideAlphabetisation"
        :hideNiveau="hideNiveau"
        v-show="false"
        @save="saveMaitriseLangue"
        :title="
          activeItem != null && activeItem.langue == null
            ? 'Ajouter langue maîtrisée'
            : 'Editer langue maîtrisée'
        "
        ref="editor"
        :item="activeItem"
      />
      <slot name="header" :addMaitriseLangue="addMaitriseLangue">
        <h6 class="mb-0 text-uppercase flex-fill">{{ title }}</h6>
        <button
          @click.prevent="addMaitriseLangue()"
          type="button"
          class="btn btn-primary"
        >
          Ajouter
        </button>
      </slot>
    </div>
    <div class="mb-5">
      <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">MaitriseLangues</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->
      <template v-if="hideAlphabetisation && maitriseLangues.length != 0">
        <div
          v-for="(maitrise, index) in maitriseLangues"
          :key="index"
          class="border py-2 px-2 d-flex justify-content-between mb-2 align-items-center"
        >
          <div class="">
            <h6 class="mb-0">{{ maitrise.ethnie.nom }}</h6>
            <small>{{ niveauLanguesMap[maitrise.niveauLangue] }}</small>
          </div>
          <div>
            <a
              @click.prevent="updateMaitriseLangue(maitrise)"
              class="btn btn-success rounded-0 btn-sm"
              ><i class="bi bi-pencil-square"></i
            ></a>
            <a
              @click.prevent="deleteMaitriseLangue(maitrise)"
              class="btn btn-danger rounded-0 btn-sm"
              ><i class="bi bi-trash"></i
            ></a>
          </div>
        </div>
      </template>
      <template v-if="!hideAlphabetisation && maitriseLangues.length != 0">
        <template v-for="(maitrise, index) in maitriseLangues">
          <div
            :key="index"
            class="border py-2 px-2 d-flex justify-content-between mb-2 align-items-center"
          >
            <div class="">
              <h6 class="mb-0">{{ maitrise.ethnie.nom }}</h6>
              <small>{{ niveauAlphabetionsMap[maitrise.niveauAlphabetisation] }}</small>
            </div>
            <div>
              <a
                @click.prevent="updateMaitriseLangue(maitrise)"
                class="btn btn-success rounded-0 btn-sm"
                ><i class="bi bi-pencil-square"></i
              ></a>
              <a
                @click.prevent="removeMaitriseLangue(maitrise)"
                class="btn btn-danger rounded-0 btn-sm"
                ><i class="bi bi-trash"></i
              ></a>
            </div>
          </div>
        </template>
      </template>
      <div class="mb-4 bg-light" v-if="maitriseLangues.length == 0">
        <div
          style="min-height: 150px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Aucune langue maîtrisée à afficher</span>
          <button
            @click.prevent="addMaitriseLangue()"
            type="button"
            class="btn btn-sm btn-outline-primary"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LangueEditorDialog from "./editor/dialog/LangueEditorDialog.vue";
export default {
  props: {
    value: {
      type: Array,
      require: true,
    },
    title: {
      type: String,
      default: () => "",
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    hideAlphabetisation: {
      type: Boolean,
      default: () => false,
    },
    azoli: {
      type: Object,
      required: true,
    },
  },
  components: { LangueEditorDialog },
  data() {
    return {
      activeItem: {},
      maitriseLangues: [...this.value],
    };
  },
  watch: {
    maitriseLangues: {
      handler(val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      niveauAlphabetionsMap: "instruction/niveauAlphabetionsMap",
      niveauLanguesMap: "instruction/niveauLanguesMap",
    }),
    emptyMaitriseLangue() {
      return {
        // niveauLangue: null,
        // niveauAlphabetisation: null,
        maitriseLangues: null,
      };
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateMaitriseLangue: "maitriseLangue/createOrUpdateMaitriseLangue",
      deleteMaitriseLangue: "maitriseLangue/deleteMaitriseLangue",
    }),
    updateMaitriseLangue(emp) {
      this.activeItem = { ...emp };
      this.$refs.editor.show();
    },
    addMaitriseLangue() {
      // this.maitriseLangues.push(emp)
      this.activeItem = { ...this.emptyMaitriseLangue };
      this.$refs.editor.show();
    },
    saveMaitriseLangue(emp) {
      this.createOrUpdateMaitriseLangue({
        ...emp,
        beneficiaire: this.azoli,
      }).then((savedItem) => {
        emp = savedItem;
        this.maitriseLangues = this.maitriseLangues.filter(
          (item) => item.ethnie.id != emp.ethnie.id
        );

        this.maitriseLangues.push(emp);
        this.activeItem = { ...this.emptyMaitriseLangue };
      });
    },
    removeMaitriseLangue(emp) {
      this.deleteMaitriseLangue(emp).then((el) => {
        this.maitriseLangues = this.maitriseLangues.filter(
          (item) => item.ethnie.id != emp.ethnie.id
        );
      });
    },
  },
};
</script>
