<template>
  <div class="string-list-editor">
    <div class="d- justify-content-between mb-4">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          v-model="newItem"
          :placeholder="placeholder"
          @keyup.prevent.enter="addItem"
        />
        <button
          class="btn input-group-btn btn-outline-success round btn-sm self-shrink"
          @click.prevent="addItem"
        >
          <i class="bi bi-plus-circle"></i>
        </button>
      </div>
    </div>
    <!-- <PDivider /> -->

    <h6>{{ listeTitle }}</h6>
    <ul class="list-group">
      <li
        class="list-group-item py-1 px-2 mx-0 d-flex justify-content-between align-items-center"
        v-for="(item, index) in items"
        :key="index"
      >
        <!-- <input type="text" v-model="items[index]" @input="updateList" /> -->
        <div>
          <span>{{ index + 1 }}. </span><span>{{ item }}</span>
        </div>
        <button class="btn btn-outline-danger btn-sm" @click="removeItem(index)">
          <i class="bi bi-trash"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    listeTitle: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Ajouter un élément item",
    },
  },
  data() {
    return {
      items: [...this.value],
      newItem: "",
    };
  },
  watch: {
    value(newVal) {
      this.items = [...newVal];
    },
  },
  methods: {
    addItem() {
      if (this.newItem.trim()) {
        this.items.push(this.newItem.trim());
        this.newItem = "";
        this.updateList();
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.updateList();
    },
    updateList() {
      this.$emit("input", this.items);
    },
  },
};
</script>

<style scoped></style>
