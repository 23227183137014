<template>
<PDialog :header="title" :modal="true" :visible.sync="display" :containerStyle="{width: '60vw'}">
  <div class="container-fluid px-0" style="background: #faF4Fa">
      <form ref="form" method="post" @submit.prevent="handleSubmit()" >
        <div class="card border-none" v-for="(mod) in modulesWithReponseCandidats" :key="mod.id">
          <div class="card-header bg-light">
            <div class="row">
              <div class="col">
                <h6 class="d-flex justify-content-between">{{ mod.libelle }}</h6>
              </div>
              <div class="col-auto">
                {{noteTotaleModules[mod.id].note}}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row py-2 border-top" v-for="(question, index) in mod.questions" :key="index">
              <div class="col">
                <small>{{ question.libelle }}</small>
              </div>
              <div class="col-sm-3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floatin">
                    <!-- <c-select size="sm" name="appreciation" option-label="libelle" option-value="id" v-model="editableItem.appreciation" :options="mod.appreciations" class="form-select">
                    </c-select> -->
                    <PDropdown :disabled="!canEdit" v-model="question.reponseCandidat.appreciation" :options="mod.appreciations" optionLabel="libelle" style="width: 100%" />
                    <!-- <label for="libelle">Type de d'activité</label> -->
                    <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-light px-2 mt-2">
          <div v-for="(noteKey, index) in Object.keys(noteTotaleModules)" :key="index" class="note d-flex justify-content-between">
            <div class="note-module"><h6>{{ noteTotaleModules[noteKey].libelle }}</h6></div>
            <div class="note-value">{{ noteTotaleModules[noteKey].note }}</div>
          </div>
          <div class="note d-flex justify-content-between">
            <div class="note-module"><h6>TOTAL</h6></div>
            <div class="note-value text-">{{ noteTotale }}</div>
          </div>
          <div class="card pa-0" v-if="noteTotale > 0 && positionnement != null">
              <h6>{{ positionnement.libelle }}</h6>
              <small>{{ positionnement.description }}</small>
          </div>
          <PDivider />  
          
        </div>
      </form>
  </div>
  <template #footer>
    <PButton label="Fermer" icon="pi pi-times" @click="cancel" class="p-button-text"/>
    <!-- <PButton label="Continuer plus tard" icon="pi pi-check" @click="hide" autofocus /> -->
  </template>
</PDialog>

</template>

<script>
import { get } from '@/api/api'
import { mapActions } from 'vuex'

export default {
  props: {
    // modules: {
    //   type: Array
    // },
    // candidat: {
    //   type: Object
    // }
    title: {
      type:  String,
      required: true
    },
  },
  data () { 
    return {
      value1: 10,
      display: false,
      editableItem: {
        appreciation: null,
        question: null,
        candidat: this.candidat
      },
      modules: [],
      candidat: {},
      // modulesWithReponseCandidats: [],
      classements: []
    }
  },
  created () {
    this.fetchClassements()
    get(`/participations/${69}`)
    .then(({ data }) => {
      console.log('Participation69: ', data)
      this.candidat = data

        this.fetchGrilleModules({
          grilleId: 1
        }).then(data => {
          // console.clear()
          // console.log(data)
          this.modules = data


        })
    }).catch(err => console.error(err))

  },
  watch: {
    modules(){
      }
  },
  computed: {
    modulesWithReponseCandidats(){
      return  this.modules.map(mod => {
          return {
            ...mod,
            questions: this.candidat.grilleReponses.length > 0?
              this.candidat.grilleReponses.map(r => {
                return {
                  ...r.question,
                  reponseCandidat:  {
                    ...r
                  }
                }
              }) : mod.questions.map(q => {
              return {
                ...q,
                reponseCandidat:  {
                  appreciation: null,
                  question: q,
                  candidat: this.candidat
                }
              }
            })
          }
        })

    },
    noteTotaleModules() {
      let notes = {}
      this.modulesWithReponseCandidats.forEach(mod => {
        notes[mod.id] = { ...mod, note: 0 }
        mod.questions.forEach(quest => {
          notes[mod.id].note += quest.reponseCandidat.appreciation? quest.reponseCandidat.appreciation.note : 0 
        })
      })
      return notes
    },
    noteTotale(){
      let total = 0
      Object.values(this.noteTotaleModules).forEach(item => total += item.note  )
      return total
    },
    canEdit(){
      return this.candidat.grilleReponses instanceof Array && this.candidat.grilleReponses.length == 0 
    },
    positionnement () {
      let position = null
      this.classements.forEach(item => {
        if(item.noteMin <= this.noteTotale && this.noteTotale <= item.noteMax){
          position = item
        }
      })
      return position
    }
  },
  methods: {
    ...mapActions({
      fetchGrilleModules: 'grille/fetchGrilleModules',
      updateParticipation: 'participation/updateParticipation',
      fetchParticipation: 'participation/fetchParticipation'
    }),
    fetchClassements(){
      get(`/classements`)
      .then(({ data }) => {
        console.log(data)
        this.classements = data.content
      }).catch(err => {
        console.error(err)
      })
    },
    show () {
      this.display = true
    },
    hide () {
      this.display = false;
    },
    saveParticipation(){
      let questions = []
      this.modulesWithReponseCandidats.forEach(mod => {
        questions = questions.concat(mod.questions)
      })
      let item = {
        ...this.candidat,
        grilleReponses: questions.map(quest => {
          return quest.reponseCandidat
        })
      }
      this.updateParticipation(item)
    }
  }
}
</script>

<style>
  .b-table-has-details{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    background: var(--gray-50);
  }
</style>