<template>
  <PDialog
    :header="title"
    :modal="true"
    :visible.sync="display"
    :containerStyle="{ width: '70vw' }"
    :contentStyle="{ overflow: 'scroll' }"
  >
    <div class="pa-2 bg-light">
      <div class="row bg-light mb-">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <div class="row mt-4">
              <div class="col-sm-12">
                <PTabView :activeIndex.sync="activeTab">
                  <PTabPanel header="Fiche d'enquête">
                    <DemandeurDetail :demandeurId="editableItem.id" />
                    <div class="text-muted fw-bold" v-if="!editableItem.estPPI">
                      Ce demandeur INTRUIT n'a pas de fiche d'enquête.
                    </div>
                  </PTabPanel>
                  <PTabPanel header="CV">
                    <DemandeurMonCv :checkHasCv="false" :demandeurId="editableItem.id" />
                  </PTabPanel>
                </PTabView>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <template #footer>
      <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton label="Valider" icon="pi pi-check" @click="save" />
    </template>
  </PDialog>
</template>

<script>
import { mapActions } from "vuex";
import DemandeurDetail from "../../../../views/espace/conseiller/DemandeurDetailAzoli.vue";
import DemandeurMonCv from "../../../../views/espace/demandeur/DemandeurMonCv.vue";
export default {
  props: ["value"],
  data() {
    return {
      activeTab: this.value.estPPI ? 0 : 1,
      editableItem: this.value,
      dateNais: this.value.dateNais,
      display: false,
    };
  },
  created() {},
  computed: {
    de() {
      return this.value;
    },
    title() {
      const de = this.de;
      return de
        ? `Modification du dossier de ${de.nom} ${de.prenom}`
        : "Curriculum Vitae";
    },
  },
  watch: {},
  methods: {
    ...mapActions({}),
    cvLoaded(de) {
      this.de = de;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    handleCvChange(cv) {
      this.editableItem = { ...cv };
      this.$emit("save", cv);
    },
    async save() {
      // if(!await this.isCompleted()) return;

      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
  components: { DemandeurDetail, DemandeurMonCv },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly],
.form-select:disabled {
  background-color: #fefaf4;
  /* background-color: transparent; */
  opacity: 0.87;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
